
const form = {
    formId: "agent-fee-schedule-editor-form",
    formField: {        
        scheduleId: {
            name: "scheduleId",
            label: "schedule id",
            type: "hidden"
        },
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "name",
            errorMsg: "Name is required.",
        },
        description: {
            name: "description",
            label: "Description",
            type: "text",
            placeholder: "description",
        },
        feeRate: {
            name: "feeRate",
            label: "Commission Rate",
            type: "number",
            placeholder: "commission percentage",
            errorMsg: "Commission rate is required",
        },
        feeThreshold: {
            name: "feeThreshold",
            label: "Commission exemption",
            type: "number",
            placeholder: "commission exemption",
            errorMsg: "Exempt amount is required",
        },
        feeScheduleScheme: {
            name: "feeScheduleScheme",
            label: "Commission Type",
            type: "number",
            placeholder: "commission type",
            errorMsg: "commission type is required",
        },
        aggregate:{
            name: "aggregate",
            label: "Apply in aggregate",
            type: "bool",
            placeholder: "aggregate earnings"
        },
        chipOffsetRate: {
            name: "chipOffsetRate",
            label: "Chip Offset Rate",
            type: "number",
            placeholder: "chip offset percentage",
            errorMsg: "Chip offset rate is required",
        },
        taxRebateRate: {
            name: "taxRebateRate",
            label: "Tax Rebate Rate",
            type: "number",
            placeholder: "tax rebate percentage",
            errorMsg: "Tax rebate rate is required",
        },
    },

};

export default form;
