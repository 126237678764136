

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";
import { useEffect, useState } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Switch from "@mui/material/Switch";

function FormSwitch({ label, name, variant, mask, readOnly, onChange, ...rest }) {

    const [switchChecked, setSwitchChecked] = useState(null);

    function intialCheckedValue() {
        if (!rest.values[name] && rest.values[name] !== 0) return rest.values[name];

        if (variant === "bit") {
            return ((parseInt(rest.values[name]) & mask) > 0);
        }
        else {
            return rest.values[name];
        }
    }

    const [switchInitialized, setSwitchInitialized] = useState(0);


    useEffect(() => {
        if (switchInitialized >= 0) {

            let v = intialCheckedValue();
            if (v === true || v === false) {
                setSwitchChecked(v);
                setSwitchInitialized(-1);
            }
            else {
                setSwitchInitialized(switchInitialized + 1);
            }
        }
    }, [switchInitialized]);

    /*
    function init() {
        setSwitchChecked(intialCheckedValue());
    }
*/
    function onSwitchToggle() {

        if (readOnly) return;

        let newValue = !switchChecked;
        setSwitchChecked(newValue);

        if (variant === "bit") {
            if (newValue === true) {
                newValue = rest.values[name] | mask;
            }
            else {
                //remove bit
                newValue = rest.values[name] & ~mask;
            }
        }

        rest.setFieldValue(name, newValue);

        if (onChange) {
            onChange(newValue);
        }
    }
    return (<>
        <SoftBox textAlign="left" >
            <SoftBox display="flex" py={0.5} mb={0.25}>
                {(variant !== 'bit') ? <>
                    {(label) ? <SoftBox width="85%" ml={1}>
                        <SoftTypography component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize">
                            {label}
                        </SoftTypography>
                    </SoftBox> : <></>}

                    <SoftBox mt={0.25}>
                        <Switch checked={switchChecked} disabled={readOnly} onChange={onSwitchToggle} />
                    </SoftBox></> : <>
                    <SoftBox mt={0.25}>
                        <Switch checked={switchChecked} disabled={readOnly} onChange={onSwitchToggle} />
                    </SoftBox>
                    <SoftBox ml={1}>
                        <SoftTypography component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize">
                            {label}
                        </SoftTypography>
                    </SoftBox>
                </>}

            </SoftBox>
        </SoftBox>
    </>);
}

// typechecking props for FormField
FormSwitch.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(["bit", "default"]),
    mask: PropTypes.number,
    readOnly: PropTypes.bool
};

export default FormSwitch;
