
import React from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";


const IconCell = ({ value, icon, color, clickHandler, id }) => {

    function onClicked() {
        if (clickHandler) {
            clickHandler(id);
        }
    }

    return (
        <SoftBox display="flex" alignItems="center">
            <SoftBox mr={1}>
                {clickHandler ?
                    <SoftButton variant="outlined" color={color} size="small" iconOnly circular onClick={onClicked}>
                        {React.isValidElement(icon) ? icon : <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>}
                    </SoftButton> : <SoftTypography color={color} variant="h5"> {React.isValidElement(icon) ? icon : <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>}</SoftTypography>}
            </SoftBox>
            {value ? <SoftTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
                {value}
            </SoftTypography> : <></>}
        </SoftBox>
    );
}
// Setting default values for the props of DefaultCell
IconCell.defaultProps = {
    value: "",
    icon: "",
    color: "text"
};

// Typechecking props for the DefaultCell
IconCell.propTypes = {
    value: PropTypes.string,
    icon: PropTypes.oneOfType(PropTypes.string, PropTypes.node),
    color: PropTypes.oneOf([
        "text",
        "white",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
    ]),
    clickHandler: PropTypes.func,
    id: PropTypes.any
};

export default IconCell;
