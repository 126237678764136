import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { schedule, scheduleDate, scheduleTime, copyPlayers, copyPlayersFromSession },
} = form;

const isDateTimeAValidFutureDate = (date, time) => {
    if (!date) return false;
    if (!time) return false;

    let d = new Date(Date.parse(date));
    d = new Date(Date.parse(d.toDateString() + " " + time));

    return d > Date.now();
}

const validations = {
    newSession: Yup.object().shape({

        [scheduleDate.name]: Yup.date().when([schedule.name], {
            is: (scheduleV) => (scheduleV === true),
            then: Yup.date(scheduleDate.errorMsg).required(scheduleDate.errorMsg)
        }).test("inTheFuture", "Scheduled date/time must be in the future", function (value, ctx) {
            if (ctx.parent.schedule === false)
                return true;

            return isDateTimeAValidFutureDate(value, ctx.parent.scheduleTime);
        }),
        [scheduleTime.name]: Yup.string().when([schedule.name], {
            is: (scheduleV) => (scheduleV === true),
            then: Yup.string(scheduleTime.errorMsg).required(scheduleTime.errorMsg)
        }).test("inTheFuture", "Scheduled date/time must be in the future", function (value, ctx) {

            if (ctx.parent.schedule === false)
                return true;

            return isDateTimeAValidFutureDate(ctx.parent.scheduleDate, value);
        }),
        [copyPlayers.name]: Yup.string().oneOf(["none", "session", "casino"]).required(copyPlayers.errorMsg),
        [copyPlayersFromSession.name]: Yup.string().nullable().when([copyPlayers.name], {
            is: (copyPlayersV) => (copyPlayersV === "session"),
            then: Yup.string().required(copyPlayersFromSession.errorMsg)
        }),
    })

};

export default validations;