
const form = {
    formId: "player-buy-in-transaction-form",
    formField: {
        type: {
            name: "type",
            type: "hidden"
        },
        amount: {
            name: "amount",
            label: "Amount",
            type: "number",
            placeholder: "amount",
            errorMsg: "Amount must be greater than 0.",
        },
        method: {
            name: "method",
            label: "Payment method",
            type: "text",
            placeholder: "payment method",
            errorMsg: "Payment method is required",
        },
        promoType: {
            name: "promoType",
            label: "Promotion type",
            type: "text",
            placeholder: "promo type",
            errorMsg: "Promotion type is required",
        },
        verified: {
            name: "verified",
            label: "Payment received and verified",
            type: "bool",
            placeholder: "",
            errorMsg: "",
        },
        accountBalance:{
            name: "accountBalance",
            type: "hidden"
        },
        markerBalance: {
            name: "markerBalance",
            type: "hidden"
        },
        availableCredit: {
            name: "availableCredit",
            type: "hidden",
            errorMsg: "Amount borrowing must be less than available credit"
        },
        provider: {
            name: "provider",
            label: "e-Wallet vendor",
            type: "text",
            placeholder: "eWallet vendor",
            errorMsg: "eWallet vendor is required when using eWallet payment method"
        },
        providerOther: {
            name: "providerOther",
            label: "e-Wallet vendor name",
            type: "text",
            placeholder: "eWallet vendor name",
            errorMsg: "eWallet vendor name is required when using eWallet payment method with 'other' provider"
        },
        providerRefMethod:{
            name: "providerRefMethod",
            label: "Vendor reference type",
            type: "text",
            placeholder: "vendor reference type",
            errorMsg: "Vendor reference type is required when payment is verified"
        },
        providerRef: {
            name: "providerRef",
            label: "Vendor reference #",
            type: "text",
            placeholder: "vendor reference #",
            errorMsg: "Vendor reference is required when payment is verified"
        },
        providerRefImage: {
            name: "providerRefImage",
            label: "Vendor reference document",
            type: "hidden",
            placeholder: "vendor reference doc",
            errorMsg: "Vendor ref document is required when using eWallet"
        },
        participantSignatureImage: {
            name: "participantSignatureImg",
            label: "Participant signature",
            type: "hidden",
            placeholder: "participant signature",
            errorMsg: "Participant signature is required for this transaction"
        },
        participantSignatureRequired: {
            name: "participantSignatureRequired",
            type: "bool"
        },
        requestSignature:{
            name: "requestSignature",
            label: "Request player signature via SMS",
            type: "bool",
            placeholder: "",
            errorMsg: ""
        },
        note: {
            name: "note",
            label: "Note",
            type: "text",
            placeholder: "Note",
            errorMsg: "Note is required"
        }
    },

};

export default form;
