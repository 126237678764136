
const form = {
    formId: "new-session-form",
    formField: {       
        schedule: {
            name: "schedule",
            label: "Schedule Session in future",
            type: "bool",
            placeholder: "",
            errorMsg: "",
        },
        scheduleDate: {
            name: "scheduleDate",
            label: "When will the session take place?",
            type: "date",
            placeholder: "scheduled date",
            errorMsg: "Scheduled date is required",
        },
        scheduleTime: {
            name: "scheduleTime",
            label: "When will the session take place?",
            type: "time",
            placeholder: "scheduled time",
            errorMsg: "Scheduled time is required",
        },
        copyPlayers: {
            name: "copyPlayers",
            label: "Add participants?",
            type: "string",
            placeholder: "",
            errorMsg: "",
        },
        copyPlayersFromSession: {
            name: "copyPlayersFromSession",
            label: "Choose a session to copy from",
            type: "string",
            placeholder: "copy from session",
            errorMsg: "Recent session is required"
        }
    },

};

export default form;
