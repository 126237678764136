import React, { useState, useEffect } from 'react';

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import wavesWhite from "assets/images/shapes/waves-white.svg";


import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import MiniActionCard from "components/Elements/MiniActionCard";


import { useCardroomContext } from 'features';
import { Link, useNavigate } from "react-router-dom";
import ClubReport from 'layouts/Club/components/Reports/ClubReport';
import UnionReport from 'layouts/Club/components/Reports/UnionReport';
import ReportUtilities from '../Navigation';
import { useLayoutEffect } from 'react';
import CardroomLogo from 'components/Elements/CardroomLogo';
import AgencyReport from 'layouts/Club/components/Reports/AgencyReport';


const ReportDetails = ({ cardroom, club, union, agency, reportingPeriod, audience }) => {

    function formatStartEndDates(start, end) {
        let result = "";
        if (start) {
            let sdt = new Date(Date.parse(start));
            result += sdt.toLocaleDateString();
        }

        if (end) {
            let edt = new Date(Date.parse(end));
            if (result) result += " - ";
            result += edt.toLocaleDateString();
        }

        return result;
    }

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report summary...", handleRetry)

    const [reportSummary, setReportSummary] = useState(null);
    const [lastClub, setLastClub] = useState(club);
    const [lastUnion, setLastUnion] = useState(union);
    const [lastAgency, setLastAgency] = useState(agency);

    useEffect(() => {
        if (lastClub != club) {
            setReportSummary(null);
            setLastClub(club);
        }
    }, [lastClub, club]);

    useEffect(() => {
        if (lastUnion != union) {
            setReportSummary(null);
            setLastUnion(union);
        }
    }, [lastUnion, union]);

    useEffect(() => {
        if (lastAgency != agency) {
            setReportSummary(null);
            setLastAgency(agency);
        }
    }, [lastAgency, agency]);



    useEffect(() => {
        if (!reportSummary) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading report summary...")
            showProgress();

            //[HttpGet("casino/{casinoId:Guid}/reports/{reportingPeriodId:Guid}/summary", Name = "GetReportingPeriodSummary")]
            let url = apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/summary";
            if (club || union || agency) {
                url += "?";
                if (club) {
                    url += "clubId=" + club.id;
                }
                if (union) {
                    if (club) url += "&";
                    url += "unionId=" + union.id;
                }

                if (agency) {
                    if (club || union) url += "&";
                    url += "agencyId=" + agency.id;
                }
            }

            clubRequest.execute("GET", url).then((response) => {
                if (response) {
                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }
                    setReportSummary(response);
                }
                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load report summary, please try again...")
            });
        }
    }, [clubRequest.execute, reportSummary, lastClub, lastUnion, lastAgency, retryCounter]); //lastClub/lastUnion are used to forece data fetch when naving from one union to another and back

    const [context, actions, features] = useCardroomContext(cardroom);

    const navigate = useNavigate();
    function onNavigationRequested(e) {
        if (e && e.detail && e.detail.path) {
            setLastClub(null);
            setLastAgency(null);
            setLastUnion(null);

            setReportSummary(null);
            navigate(e.detail.path, { state: e.detail.state });
        }
    }

    useLayoutEffect(() => {
        //const [size, setSize] = useState([0, 0]);

        ReportUtilities.subscribe(onNavigationRequested);
        return () => ReportUtilities.unsubscribe(onNavigationRequested);
    }, []);

    function navigateToDetailPage() {
        let path = "/dashboards/union";
        if (agency) {
            path = "/dashboards/agency";
        }
        else if (club) {
            path = "/dashboards/club";

        }

        navigate(path, { state: { cardroom: cardroom } });
    }

    return <>
        {reportSummary && !progressIndicatorProps.visible ? <>
            <SoftBox >
                <SoftBox
                    position="relative"
                    bgColor="secondary"
                    py={3}
                    px={{ xs: 3, sm: 6, md: 1 }}
                    mt={3}
                    borderRadius="xl"
                    variant="gradient" >

                    <SoftBox
                        component="img"
                        src={wavesWhite}
                        alt="pattern-line"
                        width="100%"
                        position="absolute"
                        left="0"
                        top="0"
                        opacity={0.4}
                    />

                    <Grid container alignItems="center" position="relative">

                        <CardroomLogo cardroom={cardroom} club={club} union={union} agency={agency} canOpenProperties={false} customAction={navigateToDetailPage} />

                        <Grid item xs={12} lg={11}>
                            <Grid container>
                                <Grid item xs={12} textAlign={"center"} mr="auto" ml="auto" width="max-content">
                                    <SoftTypography variant="h4" fontWeight="medium" mt={{ md: -3.25, xs: 0 }} color="light">
                                        {audience === "union" && union ? union.name : null}
                                        {audience === "club" && club ? club.name : null}
                                        {audience === "agency" && agency ? agency.appAgentId : null}
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6} lg={3} p={1} >
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Reporting Period", fontWeight: "medium" }}
                                        count={{ text: reportingPeriod ? formatStartEndDates(reportSummary.startTime, reportSummary.endTime) : "" }}
                                        icon={{ component: "calendar_month_outlined" }}
                                        action={null}
                                    />

                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: audience === "union" ? "Clubs" : "Settlement", fontWeight: "medium" }}
                                        count={{
                                            text: audience === "union" ? reportSummary.clubCount : reportSummary.settlementAmount.toFixed(2),
                                            prefix: audience === "union" ? "" : "$",
                                            color: audience === "union" ? "success" : reportSummary.settlementAmount > 0 ? "success" : "error",
                                            useNumberFormatter: audience === "club"
                                        }}
                                        icon={{ component: audience === "union" ? "nightlife" : "attach_money" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Players", fontWeight: "medium" }}
                                        count={{ text: reportSummary.playerCount, color: "success", useNumberFormatter: true }}
                                        icon={{ component: "person" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Agents", fontWeight: "medium" }}
                                        count={{ text: reportSummary.agentCount, color: "success", useNumberFormatter: true }}
                                        icon={{ component: "support_agent" }}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </SoftBox>
            </SoftBox>
            <SoftBox mb={3} p={1}>
                <Grid container spacing={1.5} alignItems="center">
                    <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>
                        {reportSummary ?
                            <SoftBox>
                                {audience === "club" ?
                                    <ClubReport cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} summary={reportSummary} /> : null}
                                {audience === "union" ?
                                    <UnionReport cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} summary={reportSummary} /> : null}
                                {audience === "agency" ?
                                    <AgencyReport cardroom={cardroom} agency={agency} reportingPeriod={reportingPeriod} summary={reportSummary} /> : null}

                            </SoftBox> : null}
                    </Grid>
                </Grid>
            </SoftBox></> : <ProgressIndicator {...progressIndicatorProps} />
        }
    </>;
}

export default ReportDetails;