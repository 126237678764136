import SoftBox from "components/SoftBox";
import SettingsNav from "../SettingsNav";
import { Grid } from "@mui/material";
import { useState } from "react";
import UserProfile from "../UserProfile";
import CardroomProfile from "../CardroomProfile";
import InvitationRedemption from "components/Elements/Invitation/Redeem";


const SettingsPanel = ({ }) => {

    const [selection, setSelection] = useState("profile");

    function onSelectionChanged(item) {
        setSelection(item);
    }

    return <SoftBox mt={4} width="100%">
        <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
                <SettingsNav onSelectionChanged={onSelectionChanged} />
            </Grid>
            <Grid item xs={12} lg={9}>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {selection ? <>
                                {selection === "profile" ? <UserProfile /> :
                                    selection === "invitation" ? <InvitationRedemption /> :
                                        <CardroomProfile cardroom={selection} />}
                            </> : null}
                        </Grid>
                    </Grid>
                </SoftBox>
            </Grid>
        </Grid>
    </SoftBox>;
};

export default SettingsPanel;