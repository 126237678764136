import { Grid, Icon, ListItemIcon, ListItemText, Switch } from "@mui/material";

import { apiConfig } from "config/apiConfig";
import useFetchWithMsal from "hooks/useFetchWithMsal";


import Swal from "sweetalert2";

import Card from "@mui/material/Card";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useCardroomContext } from "features";

import { useEffect, useState } from "react";
import SoftAvatar from "components/SoftAvatar";



import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import SoftButton from "components/SoftButton";
import DataBoundList from "components/Elements/DataBound/List";
import { useMsal } from "@azure/msal-react";
import { InvitationEditor } from "components/Elements/Invitation/Editor";


const CardroomUserList = ({ cardroom, onSelectionChanged }) => {

    const [users, setUsers] = useState(null);

    const [selection, setSelection] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);


    function onNewSelection(item) {

        if (item) {
            setSelection(item.item);
            setSelectedIndex(item.index);
            if (onSelectionChanged) {
                onSelectionChanged(item.item, item.index);
            }
        }
        else {
            setSelectedIndex(-1);
        }
    }

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditUser() {
        if (!context) return false;
        return context.isAllowed(actions.cardroom.security.manageUser);
    }

    const newUserPlaceholder = canEditUser() ? { id: null, displayName: "Invite User" } : null;

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.user.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "loading authorized users...", handleRetry)

    useEffect(() => {
        if (cardroom && !users) { // || cardroomListData.length === 0) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("loading authorized users...")
            showProgress();

            execute("GET", apiConfig.user.endpoint + "/venue/" + cardroom.id).then((response) => {
                if (response) {
                    const list = [];

                    for (var i = 0; i < response.length; i++) {
                        if ((response[i].roles & 128) === 0) {
                            list.push(response[i]);
                        }
                    }

                    if (list.length > 0) {
                        list.sort(function (a, b) {
                            if (a.displayName < b.displayName) { return -1; }
                            if (a.displayName > b.displayName) { return 1; }
                            return 0;
                        });
                    }

                    if (newUserPlaceholder) list.unshift(newUserPlaceholder);

                    setUsers(list);
                }
                progressIndicatorProps.close();
            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load users please try again...")
            });
        }

    }, [execute, cardroom, users, retryCounter]);


    function buildListItem(item) {
        if (item.id) {
            return <ListItemText><SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize">
                {item.displayName}
            </SoftTypography></ListItemText>;
        }
        else {
            return <>
                <ListItemIcon style={{ minWidth: "32px" }}>
                    <Icon>add</Icon>
                </ListItemIcon>
                <ListItemText><SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {item.displayName}
                </SoftTypography></ListItemText></>;
        }
    }

    return (

        <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={2}
            m={0}
            sx={{ listStyle: "none" }}>
            <ProgressIndicator  {...progressIndicatorProps} />
            {!progressIndicatorProps.visible && users ?
                <DataBoundList data={users} valueName="id" textName="displayName" onChange={onNewSelection} selectedIndex={selectedIndex} onBuildListItem={buildListItem} maxHeight="375px" />
                : null}
        </SoftBox>

    );
};
//not using form swtich because forms aren't currently implemented
const RoleSwitch = ({ label, mask, value, readOnly, onChange }) => {

    const [switchChecked, setSwitchChecked] = useState(null);

    function intialCheckedValue() {
        if (!value || !mask) return false;

        //console.warn("intialCheckedValue: " + value.toString() + " - " + mask.toString());

        return ((value & mask) > 0);
    }

    const [switchInitialized, setSwitchInitialized] = useState(0);

    useEffect(() => {
        if (switchInitialized >= 0) {

            let v = intialCheckedValue();
            if (v === true || v === false) {
                setSwitchChecked(v);
                setSwitchInitialized(-1);
            }
            else {
                setSwitchInitialized(switchInitialized + 1);
            }
        }
    }, [switchInitialized]);

    useEffect(() => {
        setSwitchInitialized(switchInitialized + 1);
    }, [value, mask]);


    function onSwitchToggle() {

        if (readOnly) return;

        let newValue = !switchChecked;
        setSwitchChecked(newValue);


        if (newValue === true) {
            newValue = value | mask;
        }
        else {
            //remove bit
            newValue = value & ~mask;
        }

        if (onChange) {
            onChange(newValue);
        }
    }

    return (<SoftBox textAlign="left" >
        <SoftBox display="flex" py={0.5} mb={0.25}>

            <SoftBox mt={0.25}>
                <Switch checked={switchChecked} disabled={readOnly} onChange={onSwitchToggle} />
            </SoftBox>
            <SoftBox ml={1}>
                <SoftTypography component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {label}
                </SoftTypography>
            </SoftBox>

        </SoftBox>
    </SoftBox>
    );
};

const CardroomUserEditor = ({ cardroom, user }) => {

    const { instance } = useMsal();

    const [userRole, setUserRole] = useState(-1);
    const [currentUser, setCurrentUser] = useState(null);
    const [formInitialized, setFormInitialized] = useState(false);
    const [applicableRoles, setApplicableRoles] = useState(null);
    const [pendingUserRecord, setPendingUserRecord] = useState(null);

    function loadRoles() {
        const roles = [];

        roles.push({ name: "Owner", mask: 1 });
        roles.push({ name: "Admin", mask: 2 });
        if (cardroom && cardroom.type === 1) {
            roles.push({ name: "Chip Runner", mask: 4 });
            roles.push({ name: "Cage Manager", mask: 8 });
        }

        roles.push({ name: "Reader", mask: 16 });

        if (cardroom && cardroom.type === 1) {
            roles.push({ name: "Partner ", mask: 32 });
        }

        setApplicableRoles(roles);
    }

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditUser() {
        if (!context) return false;
        return context.isAllowed(actions.cardroom.security.manageUser);
    }

    useEffect(() => {
        if (!formInitialized && user) {
            loadRoles();

            setPendingUserRecord(null);

            progressIndicatorProps.close();

            setCurrentUser(user);
            setUserRole(-1);

            setFormInitialized(true);
        }
    }, [formInitialized, user]);

    useEffect(() => {
        if (user) {
            setFormInitialized(false);
        }
    }, [user]);

    useEffect(() => {
        if (currentUser) {
            setUserRole(currentUser.roles);
        }
    }, [currentUser]);

    function raiseOnClose() {
        // if (onClose) {
        //     onClose();
        // }
    }


    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving transaction...", handleRetry)


    function onRoleChanged(roleMask) {
        setUserRole(roleMask);
    }


    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.user.scopes.write,
    });

    function canSaveUserRecord() {
        if (!currentUser) return false;

        if (!currentUser.id) return false;
        if (!currentUser.displayName) return false;
        if (!currentUser.signInUserName) return false;

        if (currentUser.roles === userRole) return false; //nothing has changed

        return true;
    }
    function saveUserRecord() {
        if (!canSaveUserRecord()) return;

        function stageUserRecord() {
            const newUser = Object.assign({}, currentUser);
            newUser.roles = userRole;
            if (!newUser.lastName) newUser.lastName = "";
            if (!newUser.firstName) newUser.firstName = "";
            if (!newUser.lastName) newUser.lastName = "";

            setPendingUserRecord(newUser);
        }

        let needToWarn = false;
        let warningText = "";
        let warningTitle = "";
        const currentUserAccount = instance ? instance.getActiveAccount() : null;
        if (currentUserAccount && currentUserAccount.localAccountId === currentUser.id) {
            //find the difference in roles, if removing owner/admin 

            //if old role contained either Admin Or Owner and New Role Does not contain either

            const ownerOrAdmin = 3;

            if ((currentUser.roles & ownerOrAdmin) > 1 && (userRole & ownerOrAdmin) == 0) {
                needToWarn = true;
                warningTitle = "You're removing your own privilidged role assignment, are you sure you want to continue?";
                warningText = "You're about to remove yourself from 'Admin' or 'Owner' role, doing so may reduce your ability to manage and/or access this cardroom and, if not careful, could remove your access all together. You will need to log-out/in to have your new permissions take full effect!";
            }

            if (userRole === 0) {
                needToWarn = true;
                warningTitle = "You're removing all of your role assignment, are you sure you want to continue?";
                warningText = "You're about to remove all of your role assignemnts. This will result in you not being able to access this cardroom.";
            }
        }

        if (needToWarn) {
            //if self, show dialog

            const newSwal = Swal.mixin({
                buttonsStyling: true,
                cancelButtonColor: "#FBCF33",
                denyButtonColor: "#EA0606"
            });

            const diagResult = newSwal
                .fire({
                    inputAttributes: {
                        autocapitalize: "off",
                    },
                    focusCancel: true,
                    reverseButtons: true,
                    showCancelButton: true,
                    showDenyButton: true,
                    showConfirmButton: false,
                    confirmButtonText: "Yes, update my role",
                    denyButtonText: "Yes, update my role",
                    cancelButtonText: "No",
                    title: warningTitle,
                    text: warningText,
                    icon: "warning",
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    //confirm button style didn't work, so using DENY button instead...
                    if (result.isDenied) {
                        stageUserRecord();
                    }
                    else {

                    }
                });

        }
        else {
            stageUserRecord();
        }

    }

    useEffect(() => {
        if (cardroom && pendingUserRecord) { // || cardroomListData.length === 0) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving user details...")
            showProgress();

            //[HttpPost("{userId:Guid}/venue/{venueId:Guid}", Name = "SaveVenueUser")]
            execute("POST", apiConfig.user.endpoint + "/" + pendingUserRecord.id + "/venue/" + cardroom.id, pendingUserRecord).then((response) => {
                if (response) {
                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }


                    user = Object.assign(user, response);
                    setPendingUserRecord(null);
                    //check if this is current user record, if so, update permissions?
                    raiseOnClose();
                }

                progressIndicatorProps.close();
            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save user details please try again...")
            });
        }

    }, [execute, cardroom, pendingUserRecord, retryCounter]);

    return <Card
        sx={{
            borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
            position: "sticky",
            top: "1%",
            m: 1
        }}
    >
        {currentUser ?
            <SoftBox p={2} >
                <SoftBox >
                    <ProgressIndicator {...progressIndicatorProps} />

                    {progressIndicatorProps.visible ? <></> : <>
                        <SoftBox
                            component="ul"
                            display="flex"
                            flexDirection="column"
                            p={2}
                            m={0}
                            sx={{ listStyle: "none" }}>


                            <SoftBox>
                                <SoftBox mt={2}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={4} container justifyContent="center">
                                            <SoftBox position="relative" height="max-content" mx="auto">
                                                <SoftAvatar src={currentUser.displayName} size="xxl" variant="rounded" bgColor="dark" />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <SoftBox mb={2} textAlign="left">
                                                <Grid container xs={12}>
                                                    <Grid item xs={4} >
                                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                fontWeight="bold"
                                                                textTransform="capitalize">
                                                                System Id
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <SoftTypography
                                                            variant="caption"
                                                            textTransform="capitalize">
                                                            {currentUser.id}
                                                        </SoftTypography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container xs={12}>
                                                    <Grid item xs={4} >
                                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                fontWeight="bold"
                                                                textTransform="capitalize">
                                                                Display Name
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <SoftTypography
                                                            variant="caption"
                                                            textTransform="capitalize">
                                                            {currentUser.displayName}
                                                        </SoftTypography>
                                                    </Grid>
                                                </Grid>


                                                <Grid container xs={12}>
                                                    <Grid item xs={12} >
                                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                fontWeight="bold"
                                                                textTransform="capitalize">
                                                                Roles
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </Grid>

                                                    <Grid container xs={12}>

                                                        {applicableRoles ? applicableRoles.map((item) => {
                                                            return <Grid item xs={6} md={6} lg={4}>
                                                                <SoftBox ml={0.5} mb={0.25}>
                                                                    <RoleSwitch label={item.name} mask={item.mask} value={userRole} onChange={onRoleChanged} readOnly={!canEditUser()} />
                                                                </SoftBox>
                                                            </Grid>;
                                                        }) : null}
                                                    </Grid>
                                                </Grid>

                                            </SoftBox>
                                        </Grid>
                                    </Grid >
                                </SoftBox >
                            </SoftBox >
                        </SoftBox>

                        <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                            <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                cancel
                            </SoftButton>

                            <SoftButton
                                variant="gradient"
                                color="dark"
                                disabled={!canSaveUserRecord()}
                                onClick={saveUserRecord}
                            >
                                save
                            </SoftButton>
                        </SoftBox>
                    </>

                    }

                </SoftBox>
            </SoftBox>
            : null}

    </Card>;
};

const CardroomSecurityEditor = ({ cardroom }) => {

    const [user, setUser] = useState(null);

    function onSelectedUserChanged(usr, index) {
        if (usr) setUser(usr);
    }

    return <Grid width="100%" xs={12}>
        <Grid container xs={12}>
            <Grid item xs={4} p={2}>
                <CardroomUserList cardroom={cardroom} onSelectionChanged={onSelectedUserChanged} />
            </Grid>
            <Grid item xs={8}>
                <Card style={{ overflow: "auto" }}>
                    {user ?
                        user.id ?
                            <CardroomUserEditor cardroom={cardroom} user={user} /> :
                            <InvitationEditor cardroom={cardroom} dense={true} />
                        :
                        <Card sx={{ height: 375 }}>
                            <Grid container xs={12} justifyContent="center" alignItems="center">
                                <Grid item xs={12} textAlign={"center"}>

                                    <SoftTypography
                                        component="h5"
                                        variant="caption"
                                        color="info">
                                        Select user from the list or tap "add new" to create a new user
                                    </SoftTypography>

                                </Grid>
                            </Grid>
                        </Card>
                    }


                </Card>
            </Grid>
        </Grid>
    </Grid>;
};


export default CardroomSecurityEditor;