
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";


const TextCell = ({ value, color}) => {

    return (
        <SoftTypography variant="caption" fontWeight="medium" color={color}>
            {value}
        </SoftTypography>
    );
}

// Setting default values for the props of DefaultCell
TextCell.defaultProps = {
    value: "",
    color: "text"
};

// Typechecking props for the DefaultCell
TextCell.propTypes = {
  value: PropTypes.string,
  color: PropTypes.oneOf([
    "text",
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ])
};

export default TextCell;
