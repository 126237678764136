import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import BuyInTransactionForm from './BuyInForm';
import CashoutTransactionForm from './CashoutForm';
import PromotionTransactionForm from './PromotionForm';
import DefaultTransactionForm from './DefaultTransactionForm';

import { apiConfig } from 'config/apiConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

import { Formik, Form } from "formik";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import BalanceSettlementForm from './BalanceSettlementForm';
import PlayerPayoutForm from './PayoutForm';
import { Grid } from '@mui/material';
import InlineTransactionForm from './InlineTransactionForm';
import SessionPlayers from '../../Participants/Players';


function getFormContent(type, cardroom, session, player, formData) {
    //useEffect(()=>{
    //    if (formData) formData.resetForm();
    //}, []);

    switch (type) {
        case "buyin":
            return <BuyInTransactionForm cardroom={cardroom} session={session} player={player} formData={formData} />;
        case "cashout":
            return <CashoutTransactionForm cardroom={cardroom} session={session} player={player} formData={formData} />;
        case "promo":
            return <PromotionTransactionForm cardroom={cardroom} session={session} player={player} formData={formData} />;
        case "settle":
            return <BalanceSettlementForm cardroom={cardroom} session={session} player={player} formData={formData} />;
        case "payout":
            return <PlayerPayoutForm cardroom={cardroom} session={session} player={player} formData={formData} />;
        default:
            return <DefaultTransactionForm cardroom={cardroom} session={session} player={player} formData={formData} />;
    }
}

const PlayerTransactionInlineForm = ({ cardroom, session, onClose, onTransactionCreated }) => {

    const { formId, formField } = form;
    const currentValidation = validations["buyin"]; //change this dynamically?

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving transaction...", handleRetry)

    function handleSubmit() { }

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    const [players, setPlayers] = useState(null);
    function onSessionPlayersLoaded(list) {
        //add displayName...

        var result = [];

        if (list) {

            for (var i = 0; i < list.length; i++) {

                let pp = list[i];
                let phone = pp.phoneNumber, name = pp.name, nickName = null;

                if (pp.properties) {

                    phone = pp.properties.phoneNumber ? pp.properties.phoneNumber : phone;
                    name = pp.properties.name ? pp.properties.name : name;
                    nickName = pp.properties.nickName ? pp.properties.nickName : nickName;
                }

                if (nickName) {
                    name = name + " \"" + nickName + "\"";
                }

                if (phone) {
                    //format phone
                    let formattedPhone = phone;
                    if (phone.length === 10) {
                        formattedPhone = "+1 (" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + " - ****";
                    }

                    name = name + " @ " + formattedPhone + "";
                }

                pp.displayName = name;

                result.push(pp);
            }

            if (result.length > 0) {
                result.sort(function (a, b) {
                    if (a.displayName < b.displayName) { return -1; }
                    if (a.displayName > b.displayName) { return 1; }
                    return 0;
                });
            }

            setPlayers(result);
        }
    }

    return <Grid>
        <Grid container xs={12}>
            <Formik
                initialValues={initialValues}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
                    return <Form id={formId} autoComplete="off">
                        <SoftBox p={2} >
                            <ProgressIndicator {...progressIndicatorProps} />

                            {progressIndicatorProps.visible ? <></> : <>
                                <InlineTransactionForm cardroom={cardroom} session={session} players={players} formData={{
                                    values,
                                    touched,
                                    formField,
                                    errors,
                                    setFieldValue,
                                    setFieldError,
                                    setFieldTouched,
                                    validateForm,
                                    resetForm
                                }} />


                                <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                    <SoftBox>
                                    </SoftBox>
                                    <SoftButton
                                        variant="gradient"
                                        color="dark"
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        save
                                    </SoftButton>
                                </SoftBox>
                            </>

                            }

                        </SoftBox>

                    </Form>
                }}
            </Formik></Grid>
        <Grid container xs={12}>
            <SessionPlayers cardroom={cardroom} session={session} onPlayersLoaded={onSessionPlayersLoaded} />
        </Grid>
    </Grid>;
};

export { PlayerTransactionInlineForm };

const PlayerTransactionForm = ({ type, cardroom, session, player, onClose, onTransactionCreated }) => {

    const { formId, formField } = form;
    const currentValidation = validations[type];

    const [transactionSavePayload, setTransactionSavePayload] = useState(null);
    const [preAuthorizationContext, setPreAuthorizationContext] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving transaction...", handleRetry)

    function getPaymentType(method) {
        if (method === "cash") return 1;
        if (method === "electronic") return 2;
        if (method === "credit") return 3;
        if (method === "balance") return 4;
        return 0;
    }

    const handleSubmit = (values, actions) => {

        // const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
        const { amount, method, type, markerBalance, note } = formField;
        if (values.amount <= 0) {
            actions.setFieldError(amount.name, amount.errorMsg);
            actions.setSubmitting(false);
            return;
        }


        if (values.type === 2 && values.amount <= values.markerBalance && values.method != "credit") {
            actions.setFieldError(method.name, "'credit' must be selected");
            actions.setSubmitting(false);
            return;
        }


        const list = [];

        //split will also be needed for staff cashouts, split between their take and tax

        // check if we need to split transactions
        if (values.type === 2 && values.method != "credit" && values.markerBalance > 0 && values.amount > values.markerBalance) {
            var remainingAmount = Math.abs(values.amount);
            var accountBalance = Math.abs(values.accountBalance);

            list.push({
                sessionId: session.id,
                paymentType: getPaymentType("credit"),
                type: values.type, // -- why not have it be CREDIT redemption? 8, //balance xfer
                status: 2, //for cashouts, we should verify if cash or marker, pending if ewallt?
                amount: values.markerBalance,
                participantId: player.id,
                particpantType: 1, //player
                signatureRequired: false,
                participantSignature: values.participantSignatureImg ? values.participantSignatureImg : null,
                ePaymentVendor: values.provider === "other" ? values.providerOther : values.provider,
                ePaymentVendorReferenceNo: values.providerRef ? values.providerRef : null,
                ePaymentVendorReferenceImage: values.providerRefImage ? values.providerRefImage : null,
                note: values.note
            });

            remainingAmount -= values.markerBalance;
            if (accountBalance > 0 && remainingAmount > 0) {

                var payoffAmount = accountBalance;
                if (payoffAmount > remainingAmount) {
                    payoffAmount = remainingAmount;
                }

                list.push({
                    sessionId: session.id,
                    paymentType: getPaymentType("balance"),
                    type: values.type, // -- why not have it be CREDIT redemption? 8, //balance xfer
                    status: 2, //for cashouts, we should verify if cash or marker, pending if ewallt?
                    amount: payoffAmount,
                    participantId: player.id,
                    particpantType: 1, //player
                    signatureRequired: false,
                    participantSignature: values.participantSignatureImg ? values.participantSignatureImg : null,
                    ePaymentVendor: values.provider === "other" ? values.providerOther : values.provider,
                    ePaymentVendorReferenceNo: values.providerRef ? values.providerRef : null,
                    ePaymentVendorReferenceImage: values.providerRefImage ? values.providerRefImage : null,
                    note: values.note || "" + " account balance remainder"
                });

                remainingAmount -= payoffAmount;
            }

            if (remainingAmount > 0) {
                list.push({
                    sessionId: session.id,
                    paymentType: getPaymentType(values.method),
                    type: values.type,
                    status: values.verified ? 2 : 1, //for cashouts, we should verify if cash or marker, pending if ewallt?
                    amount: remainingAmount,
                    participantId: player.id,
                    particpantType: 1, //player
                    signatureRequired: false,
                    participantSignature: values.participantSignatureImg ? values.participantSignatureImg : null,
                    ePaymentVendor: values.provider === "other" ? values.providerOther : values.provider,
                    ePaymentVendorReferenceNo: values.providerRef ? values.providerRef : null,
                    ePaymentVendorReferenceImage: values.providerRefImage ? values.providerRefImage : null,
                    note: values.note || "" + " " + values.method + " remainder after marker payoff"
                });
            }
        }
        else if (values.type === 1 && values.method === "credit" && Math.abs(values.accountBalance > 0)) {
            //buying chips, have account balance, we should split
            //need to get athorization for amount
            setPreAuthorizationContext(values);
            return;
        }
        else //no split needed
        {
            let pMethod = values.method;

            let txType = values.type;
            if (values.type === 2 && values.markerBalance > 0 && values.amount <= values.markerBalance) {
                //cashout, w marker, and amount <= balance
                //txType = 8; //force transfer -- why??? force credit, but why force txType change???
                pMethod = "credit"; // should already be credit, but just in case...
            }

            let txAmount = Math.abs(values.amount);
            if (values.type === 8) {
                if (values.markerBalance > 0) {
                    txAmount = -1 * txAmount;
                }
            }

            list.push({
                sessionId: session.id,
                paymentType: getPaymentType(pMethod),
                type: txType,
                status: values.verified ? 2 : 1, //remap from verified
                amount: txAmount,
                participantId: player.id,
                particpantType: 1, //player
                signatureRequired: txType === 1 ? values.participantSignatureRequired : false,
                participantSignature: values.participantSignatureImg ? values.participantSignatureImg : null,
                ePaymentVendor: values.provider === "other" ? values.providerOther : values.provider,
                ePaymentVendorReferenceNo: values.providerRef ? values.providerRef : null,
                ePaymentVendorReferenceImage: values.providerRefImage ? values.providerRefImage : null,
                note: values.note
            });
        }

        //chip promo, add chip buy using credit tx
        if (values.type === 3 && values.method === "credit" && values.promoType === "chips") {
            list.push({
                sessionId: session.id,
                paymentType: getPaymentType(values.method),
                type: 1, //buy
                status: 2, //remap from verified
                signatureRequired: false,
                amount: Math.abs(values.amount),
                participantId: player.id,
                particpantType: 1, //player
                note: values.note
            });
        }


        setTransactionSavePayload(list);
    };

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnTransactionCreated() {
        if (onTransactionCreated) {
            onTransactionCreated();
        }
    }

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.write,
    });

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.write,
    });

    useEffect(() => {
        if (preAuthorizationContext) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Pre-authorizing transaction...")
            showProgress();

            const values = preAuthorizationContext;
            //what's the pre-auth amount
            let amount = Math.abs(values.accountBalance);
            if (amount > values.amount) {
                amount = values.amount;
            }

            // [HttpPost("{entityId:Guid}/holders/{holderId:Guid}/accounts/{accountId:Guid}/transactions/{type:int}/authorizations/{amount:decimal}", Name = "PreauthorizeTransaction")]
            accountRequest.execute("POST", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + player.id + "/accounts/" + player.account.id + "/transactions/2/authorizations/" + amount, null).then((response) => {
                const list = [];

                if (response) {
                    //build transactions payload...

                    list.push({
                        sessionId: session.id,
                        paymentType: getPaymentType("balance"),
                        type: values.type, // -- why not have it be CREDIT redemption? 8, //balance xfer
                        status: 1, //for cashouts, we should verify if cash or marker, pending if ewallt?
                        amount: amount,
                        participantId: player.id,
                        particpantType: 1, //player
                        signatureRequired: false,
                        participantSignature: null,
                        ePaymentVendor: null,
                        ePaymentVendorReferenceNo: null,
                        ePaymentVendorReferenceImage: null,
                        note: values.note,
                        preAuthorizationId: response
                    });

                    if (values.amount > amount) {
                        list.push({
                            sessionId: session.id,
                            paymentType: getPaymentType("credit"),
                            type: values.type,
                            status: 2, //for cashouts, we should verify if cash or marker, pending if ewallt?
                            amount: Math.abs(values.amount) - amount,
                            participantId: player.id,
                            particpantType: 1, //player
                            signatureRequired: false,
                            participantSignature: null,
                            ePaymentVendor: null,
                            ePaymentVendorReferenceNo: null,
                            ePaymentVendorReferenceImage: null,
                            note: values.note || "" + " marker remainder after account balance has been debited"
                        });
                    }
                }

                if (list.length === 0) {
                    progressIndicatorProps.close();
                }
                else {
                    setPreAuthorizationContext(null);
                    setTransactionSavePayload(list);
                }

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to pre-authorize transaction, please try again...")
            });
        }
    }, [accountRequest.execute, preAuthorizationContext, retryCounter]);

    useEffect(() => {

        if (transactionSavePayload && !preAuthorizationContext) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving transaction...")
            showProgress();

            //[HttpPost("{casinoId:Guid}/sessions/{sessionId:Guid}/transactions", Name = "CreateSessionTransaction")]
            execute("POST", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/transactions", transactionSavePayload).then((response) => {
                if (response) {
                    raiseOnTransactionCreated();
                }
                //clear payload and close dialog...
                setTransactionSavePayload(null);
                progressIndicatorProps.close();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save transaction, please try again...")
            });

        }

    }, [execute, transactionSavePayload, preAuthorizationContext, retryCounter]);

    /*
    useEffect(()=>{
        //load player account?
        //on cashout forms, should we allow players to cash out if they have balance?
        //accountBalance
    }, []);
*/
    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            {getFormContent(type, cardroom, session, player, {
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            })}

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

PlayerTransactionForm.defaultProps = {
    type: "buyin",
};

PlayerTransactionForm.propTypes = {
    type: PropTypes.oneOf(["buyin", "cashout", "promo", "settle", "payout"]).isRequired,
    sessionId: PropTypes.string.isRequired,
    cardroom: PropTypes.object //.isRequired
};


export default PlayerTransactionForm;