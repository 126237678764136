
import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import DataTable from 'components/Elements/DataTable';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import { Card } from '@mui/material';

const ClubPlayers = ({ cardroom, club }) => {

    function preparePlayersForPresentation(players, table) {
        let rows = [];

        for (var i = 0; i < players.length; i++) {
            let playerImage = players[i].name.substring(0, 1);

            rows.push({
                name: [players[i].name, { image: playerImage }],
                displayName: players[i].name,
                displayNameNoCase: players[i].name.toLowerCase(),
                nickname: players[i].nickname,
                appPlayerId: players[i].appPlayerId,
                agentName: players[i].agent ? players[i].agent.name : "None",
                agentId: players[i].agent ? players[i].agent.appAgentId : "None",
                superAgentName: players[i].agent && players[i].agent.superAgent ? players[i].agent.superAgent.name : "None",
                superAgentId: players[i].agent && players[i].agent.superAgent ? players[i].agent.superAgent.appAgentId : "None",
                sourceAppName: players[i].app ? players[i].app.name : "None",
                player: players[i]
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetEmptySchema(handler) {
        return {
            columns: [
                {
                    Header: "Player",
                    accessor: "name",
                    Cell: ({ value: [name, data], row }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} participant={row && row.original && row.original.player ? row.original.player : null} />
                    ),
                },
                { Header: "Player Id", accessor: "appPlayerId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Player Nickname", accessor: "nickname", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Agent", accessor: "agentName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Agent Id", accessor: "agentId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Super Agent", accessor: "superAgentName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Super Agent Id", accessor: "superAgentId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Source", accessor: "sourceAppName", Cell: ({ value }) => <TextCell value={value} /> },
            ],
            rows: []
        };
    }


    const [players, setPlayers] = useState(null);

    const [playersTable, setPlayersTable] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading club players...", handleRetry)

    const [context, actions, features] = useCardroomContext(cardroom);

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (!players) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading club players...")
            showProgress();
            // [HttpGet("{clubId:Guid}/casino/{casinoId:Guid}/players", Name = "GetClubPlayers")]
            clubRequest.execute("GET", apiConfig.club.endpoint + "/club/" + club.id + "/casino/" + cardroom.id + "/players").then((response) => {
                setPlayers(response);
                setPlayersTable(null);
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load club players, please try again...")
            });
        }
    }, [clubRequest.execute, players, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (players && !playersTable) {
            setPlayersTable(preparePlayersForPresentation(players, GetEmptySchema()));
            progressIndicatorProps.close();
        }
    }, [players, playersTable]);

    return <Card>
        <ProgressIndicator {...progressIndicatorProps} />
        {(cardroom && club) && !progressIndicatorProps.visible ? <>
            {playersTable ?
                <DataTable table={playersTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} pagination={{ color: "dark" }} /> : null}
        </> : null}
    </Card>;
};

export default ClubPlayers