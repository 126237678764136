import form from "./form";


const {
    formField: {
        unionDuesRate,
        xmttDuesRate,
        chipOffsetRate,
        taxRebateRate,
        unionDuesBase,
        winTaxRate,
        loyaltyRebateRate
    },
} = form;

const initialValues = {
    [unionDuesRate.name]: 0,
    [xmttDuesRate.name]: 0,
    [chipOffsetRate.name]: 0,
    [taxRebateRate.name]: 0,
    [unionDuesBase.name]: 0,
    [winTaxRate.name]: 0,
    [loyaltyRebateRate.name]: 0
};

export default initialValues;
