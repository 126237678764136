import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from 'components/SoftBadge';

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import SessionPlayers from './Players';
import SessionStaff from './Staff';
import SoftButton from 'components/SoftButton';
import SessionDashboard from '../Dashboard';
import SessionHouseTransactions from './House';
import { useCardroomContext } from 'features';

const Participants = ({ cardroom, session, onParticipantStateChanged, addPlayerHandler, addStaffHandler, addHouseTxHandler, reconcileHandler, summaryHandler, liveSummaryHandler }) => {


    const TabPanel = function (props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <SoftBox sx={{ p: 3 }}>
                        <SoftTypography>{children}</SoftTypography>
                    </SoftBox>
                )}
            </div>
        );
    }

    const a11yProps = function (index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [context, actions, features] = useCardroomContext(cardroom);

    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    function playerStateChanged() {
        if (onParticipantStateChanged) {
            onParticipantStateChanged("player");
        }
    }
    function staffStateChanged() {
        if (onParticipantStateChanged) {
            onParticipantStateChanged("staff");
        }
    }

    function houseTxStateChanged() {
        if (onParticipantStateChanged) {
            onParticipantStateChanged("house");
        }
    }

    function canAddParticipant() {
        if (!context) return false;
        return context.isAllowed(actions.casino.session.participant.add);
    }

    function canCreateTransaction() {
        if (!context) return false;
        return context.isAllowed(actions.casino.session.transaction.create);
    }

    let playerTabLabel = "Players";
    let staffTabLabel = "Staff";
    if (session) {
        //playerTabLabel = <SoftBox>Players<SoftBadge badgeContent={session.totalPlayers} size="xs" color="info" variant="contained"><Icon fontSize="small" color="inherit"></Icon></SoftBadge></SoftBox>;
        playerTabLabel = "Players (" + session.totalPlayers + ")";
        staffTabLabel = "Staff (" + session.totalStaff + ")";
    }

    return <SoftBox>
        <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
                <AppBar position="static">
                    <SoftBox sx={{ ml: 3 }}>
                        {tabValue === 0 && session && session.status === 4 ? <SoftButton variant="outlined" color="info" onClick={summaryHandler}><Icon size="lg">assessment</Icon>&nbsp;Summary</SoftButton> : null}
                        {false && tabValue === 0 && session && session.status === 3 ? <SoftButton variant="outlined" color="info" onClick={reconcileHandler}><Icon size="lg">balance</Icon>&nbsp;Balance Sheet</SoftButton> : null}

                        {false && tabValue === 0 && session && session.status !== 4 ? <SoftButton variant="outlined" color="info" onClick={liveSummaryHandler}><Icon size="lg">balance</Icon>&nbsp;Live Summary</SoftButton> : null}


                        {tabValue === 1 && canAddParticipant() && session && session.status < 3 ? <SoftButton variant="outlined" color="info" onClick={addPlayerHandler}><Icon size="lg">person_add_alt_sharp</Icon>&nbsp;Add Player</SoftButton> : null}
                        {tabValue === 2 && canAddParticipant() && session && session.status < 3 ? <SoftButton variant="outlined" color="info" onClick={addStaffHandler}><Icon size="lg">badge</Icon>&nbsp;Add Staff</SoftButton> : null}
                        {tabValue === 3 && canCreateTransaction() && session && (session.status < 4) ? <SoftButton variant="outlined" color="info" onClick={addHouseTxHandler}><Icon size="lg">receipt</Icon>&nbsp;Add Transaction</SoftButton> : null}
                    </SoftBox>
                </AppBar>
            </Grid>
            <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
                <AppBar position="static">
                    <Tabs
                        orientation="horizontal"
                        value={tabValue}
                        onChange={handleSetTabValue}
                        sx={{ background: "transparent" }}>
                        <Tab label="Dashboard" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">dashboard</Icon>} {...a11yProps(0)} />
                        <Tab label={playerTabLabel} style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">group</Icon>} {...a11yProps(1)} />
                        <Tab label={staffTabLabel} style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">badge</Icon>}  {...a11yProps(2)} />
                        <Tab label="House" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">house</Icon>}  {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
            </Grid>

        </Grid>
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>
                <SoftBox>
                    <TabPanel value={tabValue} index={0}>
                        <SessionDashboard cardroom={cardroom} session={session} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Card>
                            <SoftBox mt={3}>
                                {session ?
                                    <SessionPlayers cardroom={cardroom} session={session} onPlayerStateChanged={playerStateChanged} /> : null}
                            </SoftBox>

                        </Card>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <Card>
                            {session ?
                                <SessionStaff cardroom={cardroom} session={session} onStaffStateChanged={staffStateChanged} /> : null}
                        </Card>
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <Card>
                            {session ?
                                <SessionHouseTransactions cardroom={cardroom} session={session} onHouseTransactionStateChanged={houseTxStateChanged} /> : null}
                        </Card>
                    </TabPanel>
                </SoftBox>
            </Grid>
        </Grid>
    </SoftBox>;

}



export default Participants;