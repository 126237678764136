import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';


import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { BootstrapDialog } from "components/Elements/Dialog/common"


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import FormField from 'components/Elements/Forms/FormField';
import SoftAvatar from 'components/SoftAvatar';
import FormSwitch from 'components/Elements/Forms/FormSwitch';
import FormImageField from 'components/Elements/Forms/FormImageField';
import PhoneCell from 'components/Elements/DataTable/components/Cells/PhoneCell';
import PartnerSelector from 'components/Elements/Partner/PartnerSelector';
import { useCardroomContext } from 'features';

const PlayerEditorForm = ({ cardroom, player, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { candidateId, partnerId, name, nickName, phoneNumber, creditLimit, mustSign, mustNotify, notificationMask, photo, mask, isAnonymous } = formField;
    const {
        candidateId: candidateIdV,
        partnerId: partnerIdV,
        name: nameV,
        nickName: nickNameV,
        phoneNumber: phoneNumberV,
        creditLimit: creditLimitV,
        mustSign: mustSignV,
        mustNotify: mustNotifyV,
        notificationMask: notificationMaskV,
        photo: photoV,
        mask: maskV,
        isAnonymous: isAnonymousV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditPlayer() {
        if (!context) return false;
        return context.isAllowed(actions.participant.player.edit);
    }

    function canNotify() {
        if (!context) return false;
        return context.isEnabled(features.casino.smsNotifications);
    }

    function onResponsiblePartnerChanged(id) {
        setFieldValue(partnerId.name, id);
    }

    useEffect(() => {
        if (!formDataInitialized) {

            let pn = player.name;
            let phone = player.phoneNumber;
            let hasPhoto = player.hasPhoto;


            if (player.properties) {
                pn = player.properties.name ? player.properties.name : name;
                phone = player.properties.phoneNumber ? player.properties.phoneNumber : phone;
                hasPhoto = player.properties.hasPhoto ? player.properties.hasPhoto : hasPhoto;


                setFieldValue(nickName.name, player.properties.nickName ? player.properties.nickName : "");
                setFieldValue(creditLimit.name, player.properties.creditLimit);

                setFieldValue(mustSign.name, player.properties.mustSign);
                setFieldValue(mustNotify.name, player.properties.mustNotify);
                setFieldValue(notificationMask.name, player.properties.notificationMask);

                setFieldValue(mask.name, player.properties.participantType);
                setFieldValue(partnerId.name, player.properties.responsiblePartnerId);
            }

            if (hasPhoto)
                setFieldValue(photo.name, apiConfig.images.endpoint + "/player/" + player.id + ".jpg");


            if (false === canNotify()) {
                setFieldValue(mustNotify.name, false);
            }

            setFieldValue(candidateId.name, player.id);
            setFieldValue(name.name, pn);
            setFieldValue(phoneNumber.name, phone ? phone : "");
            setFieldValue(isAnonymous.name, player.isAnonymous);

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    function getPlayerImagePresenter({ src }) {

        return <SoftAvatar src={src} size="xxl" variant="rounded" bgColor="dark" />
    }

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        {canEditPlayer() ?
                            <FormImageField name={photo.name} caption="Drop player photo here" imgHeight={120} imagePresenter={getPlayerImagePresenter}  {...formData} />
                            : <SoftAvatar src={photoV} size="xxl" variant="rounded" bgColor="dark" />}
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {phoneNumber.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                {isAnonymousV ? <SoftTypography variant="caption" fontWeight="medium" color="text">Anonymous</SoftTypography> :
                                    <PhoneCell value={phoneNumberV} obfuscate={!canEditPlayer()} />}
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {name.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditPlayer()}
                                    type={name.type}
                                    name={name.name}
                                    value={nameV}
                                    placeholder={name.placeholder}
                                    error={errors.name && touched.name}
                                    success={nameV && nameV.length > 0 && !errors.name}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12} mb={1.5}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {partnerId.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <PartnerSelector readonly={!canEditPlayer()} cardroom={cardroom} label={partnerId.label} value={partnerIdV} onPartnerChanged={onResponsiblePartnerChanged} />

                                <SoftTypography component="div" variant="caption" color="error">
                                    <ErrorMessage name={partnerId.name} />
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {nickName.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditPlayer()}
                                    type={nickName.type}
                                    name={nickName.name}
                                    value={nickNameV}
                                    placeholder={nickName.placeholder}
                                    error={errors.nickName && touched.nickName}
                                    success={nickNameV && nickNameV.length > 0 && !errors.nickName}
                                />
                            </Grid>
                        </Grid>

                        {!isAnonymousV ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {creditLimit.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField disabled={!canEditPlayer()}
                                        type={creditLimit.type}
                                        name={creditLimit.name}
                                        value={creditLimitV}
                                        placeholder={creditLimit.placeholder}
                                        error={errors.creditLimit && touched.creditLimit}
                                        success={creditLimitV && creditLimitV.length > 0 && !errors.creditLimit}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid container xs={12}>
                                    <Grid item xs={4}>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SoftBox ml={0.5} mb={0.25}>
                                            <FormSwitch label={mustSign.label} readOnly={!canEditPlayer()}
                                                name={mustSign.name}
                                                value={mustSignV}
                                                {...formData} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12}>
                                    <Grid item xs={4}>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SoftBox ml={0.5} mb={0.25}>
                                            <FormSwitch label={mustNotify.label} readOnly={!canNotify() || !canEditPlayer()}
                                                name={mustNotify.name}
                                                value={mustNotifyV}
                                                {...formData} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {mustNotifyV ? <>
                                <Grid container xs={12}>
                                    <Grid item xs={12}>
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                Notification Events
                                            </SoftTypography>
                                            <SoftBox mt={0.75}>
                                                <SoftTypography component="div" variant="caption" color="error">
                                                    <ErrorMessage name={mask.name} />
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12}>
                                    <Grid item xs={4}>
                                        <SoftBox ml={0.5} mb={0.25}>
                                            <FormSwitch label="Marker Balance Changed" readOnly={!canEditPlayer()}
                                                name={notificationMask.name}
                                                value={notificationMaskV}
                                                mask={1}
                                                variant="bit"
                                                {...formData} />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SoftBox ml={0.5} mb={0.25}>
                                            <FormSwitch label="Chips Bought" readOnly={!canEditPlayer()}
                                                name={notificationMask.name}
                                                value={notificationMaskV}
                                                mask={2}
                                                variant="bit"
                                                {...formData} />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SoftBox ml={0.5} mb={0.25}>
                                            <FormSwitch label="Cashed Out" readOnly={!canEditPlayer()}
                                                name={notificationMask.name}
                                                value={notificationMaskV}
                                                mask={4}
                                                variant="bit"
                                                {...formData} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12}>
                                    <Grid item xs={4}>
                                        <SoftBox ml={0.5} mb={0.25}>
                                            <FormSwitch label="Promo Awarded" readOnly={!canEditPlayer()}
                                                name={notificationMask.name}
                                                value={notificationMaskV}
                                                mask={8}
                                                variant="bit"
                                                {...formData} />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SoftBox ml={0.5} mb={0.25}>
                                            <FormSwitch label="Game Scheduled" readOnly={!canEditPlayer()}
                                                name={notificationMask.name}
                                                value={notificationMaskV}
                                                mask={16}
                                                variant="bit"
                                                {...formData} />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SoftBox ml={0.5} mb={0.25}>
                                            <FormSwitch label="Signature Requested" readOnly={!canEditPlayer()}
                                                name={notificationMask.name}
                                                value={notificationMaskV}
                                                mask={64}
                                                variant="bit"
                                                {...formData} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>

                            </> : null}
                        </> : null}


                        {/*
                        <SoftTypography component="div" variant="caption" color="error">
                            <ErrorMessage name={candidateId.name} />
                        </SoftTypography>
                        <SoftTypography component="div" variant="caption" color="error">
                            <ErrorMessage name={name.name} />
                        </SoftTypography>
                        <SoftTypography component="div" variant="caption" color="error">
                            <ErrorMessage name={phoneNumber.name} />
                        </SoftTypography>
                        <SoftTypography component="div" variant="caption" color="error">
                            <ErrorMessage name={mask.name} />
                        </SoftTypography>
                        <SoftTypography component="div" variant="caption" color="error">
                            <ErrorMessage name={notificationMask.name} />
                        </SoftTypography>
                        <SoftTypography component="div" variant="caption" color="error">
                            <ErrorMessage name={creditLimit.name} />
                        </SoftTypography>
                        <SoftTypography component="div" variant="caption" color="error">
                            <ErrorMessage name={partnerId.name} />
                        </SoftTypography>
                            */}

                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;
};

const PlayerEditor = ({ cardroom, player, onClose }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;

    const [playerPhoto, setPlayerPhoto] = useState(null);
    const [playerRecord, setPlayerRecord] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditPlayer() {
        if (!context) return false;
        return context.isAllowed(actions.participant.player.edit);
    }

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving player...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.people.scopes.write,
    });

    const handleSubmit = (values, actions) => {

        if (false === canEditPlayer()) return;

        //construct participant object
        const np = Object.assign({}, player);

        //update player...

        np.properties.name = values.name;
        np.properties.nickName = values.nickName;

        //need to upload photo...
        if (values.photo) {
            np.properties.hasPhoto = true;

            setPlayerPhoto(values.photo);
        }

        np.properties.phoneNumber = values.phoneNumber;
        np.properties.creditLimit = values.creditLimit;
        np.properties.mustSign = values.mustSign;
        np.properties.mustNotify = values.mustNotify;
        np.properties.notificationMask = values.notificationMask;
        np.properties.responsiblePartnerId = values.partnerId;


        //post it to user service

        setPlayerRecord(np);
    };

    useEffect(() => {
        if (playerPhoto) {
            //[HttpPost("venue/{venueId:Guid}/participants/{participantId:Guid}/image", Name = "SaveParticipantImage")]
            execute("POST", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants/" + player.id + "/image", playerPhoto).then((response) => {
                if (response) {
                    //raiseOnTransactionCreated();
                }
                //clear payload and close dialog...
                // setTransactionSavePayload(null);
                // progressIndicatorProps.close();

                setPlayerPhoto(null);

            }).catch((e) => {
                //progressIndicatorProps.setMode("errorWithRetry");
                //progressIndicatorProps.setMessage("Unable to save transaction, please try again...")
            });
        }

    }, [playerPhoto, execute]);

    useEffect(() => {
        if (playerRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving player profile...")
            showProgress();

            execute("POST", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", playerRecord).then((response) => {
                if (response) {
                    //raiseOnTransactionCreated();
                    player = Object.assign(player, response);
                }

                setPlayerRecord(null);
                progressIndicatorProps.close();
                raiseOnClose();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save player profile, please try again...")
            });
        }

    }, [playerRecord, execute, retryCounter]);

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <PlayerEditorForm cardroom={cardroom} player={player} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canEditPlayer()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

PlayerEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,

};

const PlayerEditorDialog = ({ open, close, cardroom, player }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Player Properties: {player ? player.name : ""}
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox mt={3}>
                <PlayerEditor cardroom={cardroom} player={player} onClose={close} />
            </SoftBox>
        </DialogContent>
    </BootstrapDialog>;
}

PlayerEditorDialog.defaultProps = {

};

PlayerEditorDialog.propTypes = {
    cardroom: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,

};

export {
    PlayerEditor,
    PlayerEditorDialog
};
