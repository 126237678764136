import * as Yup from "yup";
import "yup-phone";
import buyin from "./form";

const {
    formField: { targetAccount, sourceAccount, type, amount, method, journalTxType, verified, note, promoType, markerBalance, availableCredit, provider, providerOther, providerRefMethod, providerRef, providerRefImage, participantSignatureImage, participantSignatureRequired },
} = buyin;



const validations = {
    receive: Yup.object().shape({
        [targetAccount.name]: Yup.string().required(targetAccount.errorMsg),
        [sourceAccount.name]: Yup.string().required(sourceAccount.errorMsg),
        [amount.name]: Yup.number().min(0.1, "Amount must be greater than 0").required(amount.errorMsg)
            .test("maxMarkerBalance", "Amount cannot exceed player current balance", function (value, ctx) {
                return Math.abs(ctx.parent.accountBalance) >= value;
            }),
        [method.name]: Yup.string().oneOf(["cash", "electronic"]).required(method.errorMsg),
        [provider.name]: Yup.string().when([method.name], {
            is: (methodV) => (methodV === "electronic"),
            then: Yup.string().required(provider.errorMsg)
        }),
        [providerOther.name]: Yup.string().when([method.name, provider.name], {
            is: (methodV, providerV) => (methodV === "electronic" && providerV === "other"),
            then: Yup.string().required(providerOther.errorMsg)
        }),
        [providerRefMethod.name]: Yup.string().oneOf(["number", "image"]).required(providerRefMethod.errorMessage),
        [providerRef.name]: Yup.string().when([method.name, providerRefMethod.name], {
            is: (methodV, refMethodV) => methodV === "electronic" && refMethodV === "number",
            then: Yup.string().required(providerRef.errorMsg)
        }),
        [providerRefImage.name]: Yup.string().when([method.name, providerRefMethod.name], {
            is: (methodV, refMethodV) => methodV === "electronic" && refMethodV === "image",
            then: Yup.string().required(providerRef.errorMsg)
        })
    }),
    pay: Yup.object().shape({
        [targetAccount.name]: Yup.string().required(targetAccount.errorMsg),
        [sourceAccount.name]: Yup.string().required(sourceAccount.errorMsg),
        [amount.name]: Yup.number().min(0.1, "Amount must be greater than 0").required(amount.errorMsg)
            .test("maxMarkerBalance", "Amount cannot exceed player current balance", function (value, ctx) {
                return Math.abs(ctx.parent.accountBalance) >= value;
            }),
        [method.name]: Yup.string().oneOf(["cash", "electronic"]).required(method.errorMsg),
        [provider.name]: Yup.string().when([method.name], {
            is: (methodV) => (methodV === "electronic"),
            then: Yup.string().required(provider.errorMsg)
        }),
        [providerOther.name]: Yup.string().when([method.name, provider.name], {
            is: (methodV, providerV) => (methodV === "electronic" && providerV === "other"),
            then: Yup.string().required(providerOther.errorMsg)
        }),
        [providerRefMethod.name]: Yup.string().oneOf(["number", "image"]).required(providerRefMethod.errorMessage),
        [providerRef.name]: Yup.string().when([method.name, providerRefMethod.name], {
            is: (methodV, refMethodV) => methodV === "electronic" && refMethodV === "number",
            then: Yup.string().required(providerRef.errorMsg)
        }),
        [providerRefImage.name]: Yup.string().when([method.name, providerRefMethod.name], {
            is: (methodV, refMethodV) => methodV === "electronic" && refMethodV === "image",
            then: Yup.string().required(providerRef.errorMsg)
        })
    }),
    journal: Yup.object().shape({
        [journalTxType.name]: Yup.string().oneOf(["credit", "debit"]).required(journalTxType.errorMessage),
        [targetAccount.name]: Yup.string().required(targetAccount.errorMsg),
        [amount.name]: Yup.number().min(0.1, "Amount must be greater than 0").required(amount.errorMsg),
        [note.name]: Yup.string().required(note.errorMsg)
    })
};

export default validations;