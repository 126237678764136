import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { type, amount, tipAmount, method, tableNo, taxRate, verified, markerBalance, note, provider, providerOther, providerRefMethod, providerRef, providerRefImage },
} = form;



const validations = {

    //add logic to account for markerBalance
    down: Yup.object().shape({
        [amount.name]: Yup.number().min(0),
        [tipAmount.name]: Yup.number().min(0),
        [tableNo.name]: Yup.number().min(1),
        [taxRate.name]: Yup.number().min(0),
    }),
    payout: Yup.object().shape({
        [amount.name]: Yup.number().min(1, "Amount must be greater than 0")
        .test("between 1 and balance", "Amount cannot exceed your current balance", function (value, ctx) {
            if (ctx.parent.markerBalance > 0) return true;

            return value <= Math.abs(ctx.parent.markerBalance);
        }),       
        [provider.name]: Yup.string().when([method.name], {
            is: (methodV) => (methodV === "electronic"),
            then: Yup.string().required(provider.errorMsg)
        }),
        [providerOther.name]: Yup.string().when([method.name, provider.name], {
            is: (methodV, providerV) => (methodV === "electronic" && providerV === "other"),
            then: Yup.string().required(providerOther.errorMsg)
        }),
        [providerRef.name]: Yup.string().when([method.name, providerRefMethod.name], {
            is: (methodV, refMethodV) => methodV === "electronic" && refMethodV === "number",
            then: Yup.string().required(providerRef.errorMsg)
        }),
        [providerRefImage.name]: Yup.string().when([method.name, providerRefMethod.name], {
            is: (methodV, refMethodV) => methodV === "electronic" && refMethodV === "image",
            then: Yup.string().required(providerRef.errorMsg)
        })
    }),
    tips: Yup.object().shape({
        [tipAmount.name]: Yup.number().min(1, "Tip amount must be greater than 0."),
        [taxRate.name]: Yup.number().min(0),
    }),
};

export default validations;