
const ReportUtilities = {
    navigatingEventName: "reportNavigating",
    subscribe: function (listener) {
        document.addEventListener(ReportUtilities.navigatingEventName, listener);
    },
    unsubscribe: function (listener) {
        document.removeEventListener(ReportUtilities.navigatingEventName, listener);
    },
    publish: function (path, state) {
        const event = new CustomEvent(ReportUtilities.navigatingEventName, {
            detail: {
                path: path,
                state: state
            }
        });

        document.dispatchEvent(event);
    }
};


export default ReportUtilities;