import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from "prop-types";

import SoftTypography from "components/SoftTypography";
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';



const ClubSelector = ({ value, readonly, emptyValue, placeholder, onSelectionChanged, clubs, excludedClubs, valueName, textName }) => {


    const [availableOptions, setAvailableOptions] = useState(null);

    const [selectedValue, setSelectedValue] = useState(null); //value ? value : emptyValue);
    const [selectedIndex, setSelectedIndex] = useState(null);

    function getSelectedIndex() {
        let dataSource = availableOptions; // getDataSource();

        if (!dataSource) return -1;
        if (!selectedValue) return -1;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == selectedValue.id || dataSource[i].id == selectedValue) {
                return i;
            }
        }

        return -1;
    }

    function findValueInDataSource(v) {
        let dataSource = getDataSource();

        if (!dataSource) return null;
        if (!v) return null;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == v.id || dataSource[i].id == v) {
                return dataSource[i];
            }
        }

        return null;
    }

    function getDataSource() {
        if (!clubs) return null;

        const exclusionMap = [];
        if (excludedClubs) {
            for (var i = 0; i < excludedClubs.length; i++) {
                if (!exclusionMap[excludedClubs[i].id]) {
                    exclusionMap[excludedClubs[i].id] = excludedClubs[i];
                }
            }
        }

        const rows = [];

        for (var i = 0; i < clubs.length; i++) {
            if (!exclusionMap[clubs[i].id]) {
                rows.push(clubs[i]);
            }
        }


        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            });
        }

        if (emptyValue) {
            rows.unshift(emptyValue);
        }

        return rows;
    }

    function handleScheduleChange({ item, index }) {
        setSelectedIndex(null);
        setSelectedValue(item);

        if (onSelectionChanged) {
            onSelectionChanged(item);
        }
    }


    useEffect(() => {
        if (availableOptions) {
            setSelectedIndex(getSelectedIndex());
        }

    }, [availableOptions, selectedIndex, selectedValue]);

    useEffect(() => {
        if (clubs) {
            setAvailableOptions(getDataSource());
        }

        setSelectedValue(findValueInDataSource(value));

    }, [clubs, excludedClubs, value]);

    return <>
        {readonly ?
            <SoftTypography
                variant="caption"
                fontWeight="regular"
                color="dark"
            > {selectedValue ? selectedValue.name : "[None]"}
            </SoftTypography> : <>{availableOptions ?

                <DataBoundSelect placeholder={placeholder} data={availableOptions} valueName={valueName} textName={textName}
                    onChange={handleScheduleChange} selectedIndex={selectedIndex} />

                : null}
            </>
        }
    </>;
};


ClubSelector.propTypes = {
    value: PropTypes.object,
    readonly: PropTypes.bool,
    emptyValue: PropTypes.object,
    placeholder: PropTypes.string,
    onSelectionChanged: PropTypes.func,
    clubs: PropTypes.array,
    excludedClubs: PropTypes.array,
    valueName: PropTypes.string,
    textName: PropTypes.string
};

ClubSelector.defaultProps = {
    valueName: "id",
    textName: "name"
};

export default ClubSelector;