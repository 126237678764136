import React, { useState, useEffect } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BootstrapDialog } from "components/Elements/Dialog/common"
import { useTheme } from '@emotion/react';
import { Grid, useMediaQuery } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import SettingsPanel from './components/SettingsPanel';
import CardroomProfile from './components/CardroomProfile';

const SettingsAndConfigurationsDialog = ({ open, close }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen


    function onCloseInitiated() {
        resetDialogState();

        close();
    }

    function resetDialogState() {
        // setSessionCheckComplete(!(session && session.status === 3));
        // setBlockingPlayerIdList(null);
        // setBlockingPlayers(null);
        // setBlockingPlayersTable(null);
    }


    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xxl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Settings
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox m={1}>
                <Grid container spacing={1} >
                    <SettingsPanel />
                </Grid>
            </SoftBox>
        </DialogContent>
        <DialogActions>
            <SoftButton onClick={close} variant="outlined" color="secondary">
                close
            </SoftButton>
        </DialogActions>
    </BootstrapDialog >
};

const CasinoSettingsDialog = ({ open, close, cardroom, club, union, agency, onCardroomChanged }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xxl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Cardroom Settings
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox m={1}>
                <Grid container spacing={1} >
                    <CardroomProfile cardroom={cardroom} union={union} club={club} agency={agency} onCardroomChanged={onCardroomChanged} />
                </Grid>
            </SoftBox>
        </DialogContent>
        <DialogActions>
            <SoftButton onClick={close} variant="outlined" color="secondary">
                close
            </SoftButton>
        </DialogActions>
    </BootstrapDialog >
};


export {
    SettingsAndConfigurationsDialog,
    CasinoSettingsDialog
};