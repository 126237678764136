
import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

// @mui material components
import Grid from "@mui/material/Grid";
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import DataTable from 'components/Elements/DataTable';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import PhoneCell from 'components/Elements/DataTable/components/Cells/PhoneCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import PartnerCell from 'components/Elements/DataTable/components/Cells/PartnerCell';


const ReconciliationWorksheet = ({ cardroom, session, onClose }) => {


    function convertSessionPlayersToTable(participants, table, cardroom, session) {
        var rows = [];

        if (participants && participants.length > 0) {
            for (var i = 0; i < participants.length; i++) {
                let name = participants[i].name;
                let phone = participants[i].phoneNumber;
                let hasPhoto = participants[i].hasPhoto || (participants[i].properties && participants[i].properties.hasPhoto)
                let ptype = 0, partnerId = null;

                if (participants[i].properties) {
                    ptype = participants[i].properties.participantType;
                    name = participants[i].properties.name ? participants[i].properties.name : name;
                    phone = participants[i].properties.phoneNumber ? participants[i].properties.phoneNumber : phone;
                    hasPhoto = participants[i].properties.hasPhoto ? participants[i].properties.hasPhoto : hasPhoto;
                    partnerId = participants[i].properties.responsiblePartnerId;

                    if (participants[i].properties.nickName) name += " (" + participants[i].properties.nickName + ")";
                }

                let staffImage = hasPhoto ? apiConfig.images.endpoint + "/player/" + participants[i].id + ".jpg" : name.substring(0, 1);

                let ptypeStr = "|";
                if ((ptype & 1) > 0) ptypeStr += "player|";
                if ((ptype & 2) > 0) ptypeStr += "host|";
                if ((ptype & 4) > 0) ptypeStr += "dealer|";
                if ((ptype & 8) > 0) ptypeStr += "chef|";
                if ((ptype & 16) > 0) ptypeStr += "valet|";
                if ((ptype & 32) > 0) ptypeStr += "security|";
                if ((ptype & 64) > 0) ptypeStr += "other|";

                let row = {
                    id: participants[i].id,
                    name: [name, { image: staffImage }], // format name from name & nickname
                    phone: phone,
                    hasImage: hasPhoto,
                    balance: (-1) * participants[i].borrowedAmount,
                    accountBalance: participants[i].account ? participants[i].account.accountsReceivable - participants[i].account.accountsPayable : 0,
                    roles: ptypeStr,
                    partner: partnerId,
                    details: <></>,
                    participant: participants[i]
                };
                rows.push(row);
            }
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                let aName = a.name && a.name[0] ? a.name[0].toLowerCase() : a.name;
                let bName = b.name && b.name[0] ? b.name[0].toLowerCase() : b.name;
    
                if (aName < bName) { return -1; }
                if (aName > bName) { return 1; }
    
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function mergeParticipantRecords(sparticipants, pparticipants, accounts) {
        var map = [];
        var accountMap = [];
        var result = [];

        if (accounts) {
            for (var i = 0; i < accounts.length; i++) {
                accountMap[accounts[i].accountHolderId] = accounts[i];
            }
        }

        if (pparticipants) {
            for (var i = 0; i < pparticipants.length; i++) {
                map[pparticipants[i].id] = pparticipants[i];
            }

            if (sparticipants) {
                for (var i = 0; i < sparticipants.length; i++) {
                    let pp = map[sparticipants[i].id];
                    if (pp) {
                        pp = Object.assign(pp, sparticipants[i]);
                        pp.account = accountMap[pp.id];
                        result.push(pp);
                    }
                }
            }

        }
        return result;
    }

    function GetParticipantTableSchema() {
        const schema = {
            columns: [
                {
                    Header: "name", accessor: "name", Cell: ({ value: [name, data] }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} />
                    ),
                },
                { Header: "phone", accessor: "phone", Cell: ({ value }) => <PhoneCell value={value} obfuscate={true} /> },
                { Header: "role(s)", accessor: "roles", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "marker balance", accessor: "balance", Cell: ({ value }) => <MoneyCell value={value} prefix="$" /> },
                { Header: "accnt balance", accessor: "accountBalance", Cell: ({ value }) => <MoneyCell value={value} prefix="$" /> },
                //until partner load is optimized in PartnerSelector (cached in casino.partners), do not display this column
                //{ Header: "resp. party", accessor: "partner", Cell: ({ value }) => <PartnerCell value={value} cardroom={cardroom} /> }
            ],
            collapsibleDetailAccessor:null, // "details",
            rows: []
        };

        return schema;
    }


    const [participants, setPartcipants] = useState(null);
    const [sessionParticipants, setSessionParticipants] = useState(null);
    const [participantAccounts, setParticipantAccounts] = useState(null);
    const [participantTable, setPartcipantTable] = useState(null);

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    const casinoReq = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read
    });

    const peopleReq = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read
    });

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading session participants...", handleProgressRetry)


    //load session participants
    useEffect(() => {
        if (!sessionParticipants) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session participants...")
            showProgress();

            //{casinoId:Guid}/sessions/{sessionId:Guid}/participants/{type:int?}
            casinoReq.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/participants/255").then((response) => {
                if (response) {
                    if (response.length === 0) {
                        progressIndicatorProps.close();
                    }

                    setSessionParticipants(response);
                }
            }).catch((ex) => {
                //setOverlayMessage("An error occured, please refresh");
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load session participants, plese try again...");
            });
        }
    }, [casinoReq.execute, sessionParticipants, retryCounter])


    useEffect(() => {
        if (!participants && sessionParticipants && sessionParticipants.length > 0) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session participant' details...")

            let list = [];
            for (var i = 0; i < sessionParticipants.length; i++) {
                if (sessionParticipants[i].borrowedAmount != 0) //only add users that have balances
                    list.push(sessionParticipants[i].id);
            }

            if (list.length > 0) {
                peopleReq.execute("PUT", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", list).then((response) => {
                    if (response) {
                        setPartcipants(response);
                        setParticipantAccounts(null);
                    }
                }).catch((ex) => {
                    //setOverlayMessage("An error occured, please refresh");
                    progressIndicatorProps.setMode("errorWithRetry");
                    progressIndicatorProps.setMessage("Unable to load session participants' details, plese try again...");
                });
            }
            else {
                progressIndicatorProps.close();
            }
        }
    }, [peopleReq.execute, sessionParticipants, participants, retryCounter])

    //load player accounts
    useEffect(() => {
        if (participants && !participantAccounts) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading participant accounts...")

            // [HttpGet("{entityId:Guid}/holders/accounts/{type:int}", Name = "GetEntityAccounts")]
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/accounts/1").then((response) => {
                if (response) {
                    setParticipantAccounts(response);

                    setPartcipantTable(convertSessionPlayersToTable(mergeParticipantRecords(sessionParticipants, participants, response), GetParticipantTableSchema(), cardroom, session));

                    progressIndicatorProps.close();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load participant accounts, please try again...")
            });
        }
    }, [accountRequest.execute, participantAccounts, participants, retryCounter])

    return <SoftBox width="100%"  >

        <SoftBox width="100%" display="flex" justifyContent="space-between">
            <Grid container xs={12}>
                <Grid item xs={12}>
                    <SoftBox mb={2} textAlign="center">
                        <ProgressIndicator {...progressIndicatorProps} />
                    </SoftBox>
                    {!progressIndicatorProps.visible ? <>
                        {!participants || participants.length === 0 ?
                            <SoftBox mb={2} textAlign="center">
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold">
                                    There are no participants with unpaid balances...
                                </SoftTypography>
                            </SoftBox>
                            : <SoftBox>
                                <DataTable table={participantTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} isHierarchical={false} />
                            </SoftBox>}
                        <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                            <div></div>
                            <SoftButton variant="gradient" color="dark" onClick={raiseOnClose}>
                                close
                            </SoftButton>
                            {/**
                            <SoftButton
                                variant="gradient"
                                color="dark"
                                type="submit"
                                disabled={!participants || participants.length === 0}
                            >
                                reconcile
                            </SoftButton> */}
                        </SoftBox>
                    </> : null}
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>
};

export default ReconciliationWorksheet;