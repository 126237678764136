import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { candidateId, partnerId, name, nickName, phoneNumber, creditLimit, mustSign, mustNotify, isAnonymous, notificationMask, photo, mask },
} = form;



const validations = {

    //add logic to account for markerBalance
    editor: Yup.object().shape({
        [candidateId.name]: Yup.string().required("Player context must be set"),
        [name.name]: Yup.string().required(name.errorMsg),
        [phoneNumber.name]: Yup.string().when([isAnonymous.name], {
            is: (isAnonymousV) => !isAnonymousV,
            then: Yup.string().phone("US", phoneNumber.errorMsg).length(10, phoneNumber.errorMsg).required(phoneNumber.errorMsg)
        }),
        [mask.name]: Yup.number().required(),
        [notificationMask.name]: Yup.number().required(),
        [creditLimit.name]: Yup.number().min(0, "Credit limit must be a positive number.").required(creditLimit.errorMsg),
        [partnerId.name]: Yup.string().required(partnerId.errorMsg),
    }),

};

export default validations;