
import React, { useState, useEffect } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BootstrapDialog } from "components/Elements/Dialog/common"
import { useTheme } from '@emotion/react';
import { Card, Grid, useMediaQuery } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';

import { styled } from '@mui/material/styles';

//import validations from "./schemas/validations";
//import form from "./schemas/form";
//import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useCardroomContext } from 'features';
import SoftDropzone from 'components/SoftDropzone';
import { apiConfig } from 'config/apiConfig';

import { InteractionType, PopupRequest } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { useMemo } from 'react';
import useFetchWithMsal from 'hooks/useFetchWithMsal';



const ReportUploadDialog = ({ open, close, cardroom, onFileUploaded }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen



    const [pendingFile, setPendingFile] = useState(null);
    // const [dropZone, setDropZone] = useState(null);

    const [authToken, setAuthToken] = useState(null);

    const [hasFiles, setHasFiles] = useState(true);

    let dropZone = null;


    const { error, execute, token } = useFetchWithMsal({
        scopes: apiConfig.ingest.scopes.write,
    });

    function initiateUpload() {
        if (!dropZone) return;
        if (dropZone.files.length == 0) return;

        dropZone.processQueue();
        /*
        const file = dropZone.files[0];

        const f = new FormData();
        f.append("name", file.name);
        f.append("file", file);
        // f.append("contenttype", f.contentType);
        // f.append("length", f.length);

        setPendingFile(f);
        */
    }

    useEffect(() => {
        if (!authToken) {
            token().then((t) => {
                setAuthToken(t);
            });
        }
    }, [authToken, token]);

    /*
    useEffect(() => {
        if (pendingFile) {

            //  [HttpPost("casino/{casinoId:Guid}", Name = "UploadFileForIngestion")]
            let url = apiConfig.ingest.endpoint + "/casino/" + cardroom.id;
            execute("POST", url, pendingFile, false, true).then((response) => {
                if (response) {
                    close();
                }
                setPendingFile(null);
            }).catch((ex) => {
                var e = ex;

            });
        }
    }, [execute, pendingFile]);

*/

    function onFileAdded(file) {

        if (dropZone.files.length > 1) {
            dropZone.removeFile(file);
        }

        //dropZone.processQueue();

        const hasFiles = dropZone.files.length > 0;
        setHasFiles(hasFiles);
    }

    function onFileRemoved(file) {

        const hasFiles = dropZone.files.length > 0;
        setHasFiles(hasFiles);
    }

    function onDropZoneCreated(zone) {
        // setDropZone(zone);
        dropZone = zone;

       // dropZone.on("addedfile", onFileAdded);

        //dropZone.on("removedfile", onFileRemoved);

        /*        
        
        dropZone.on("processing", (a1, a2, a3, a4) => {
        
                    //     setPendingFile(file);
                    var a = "";
                });
        
                dropZone.on("maxfilesreached", (a1, a2, a3, a4) => {
        
                    var a = "";
        
                });
        
                dropZone.on("maxfilesexceeded", (a1, a2, a3, a4) => {
        
                    var a = "";
        
                });*/
    }

    //const [canUpload, setCanUpload] = useState("123");

    //let dropzone = null;

    /*
    const { instance } = useMsal();

    const request = {
        scopes: apiConfig.ingest.scopes.write,
    };

    const { result, error: msalError } = useMsalAuthentication(InteractionType.Popup, {
        ...request,
        account: instance.getActiveAccount(),
        redirectUri: '/redirect.html'
    });

    const getToken = async () => {
        if (result) {
            return result.accessToken;
        }
    };


    //const authToken = result.accessToken;

    useEffect(() => {
        if (!authToken) {

            getToken().then((token) => {
                setAuthToken(token);
            });
        }
    }, [authToken, getToken]);

*/
    // setAuthToken(result.accessToken);

    /*
    
*/


    /*
     //events
        this.prototype.events = [
"drop",
"dragstart",
"dragend",
"dragenter",
"dragover",
"dragleave",
"addedfile",
"addedfiles",
"removedfile",
"thumbnail",
"error",
"errormultiple",
"processing",
"processingmultiple",
"uploadprogress",
"totaluploadprogress",
"sending",
"sendingmultiple",
"success",
"successmultiple",
"canceled",
"canceledmultiple",
"complete",
"completemultiple",
"reset",
"maxfilesexceeded",
"maxfilesreached",
"queuecomplete",
];
    */


    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Upload Report File
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox m={1}>
                <Grid container spacing={1}> {authToken ?
                    <SoftDropzone onDropZoneCreated={null} options={{
                        addRemoveLinks: true
                        , acceptedFiles: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
                        , maxFiles: 1
                        , autoProcessQueue: false
                        , autoQueue: true
                        , maxFilesize: 75 //100MB
                        , filesizeBase: 1024
                        , url: apiConfig.ingest.endpoint + "/casino/" + cardroom.id
                        , method: "POST"
                        //, disablePreviews: false
                        //, url: apiConfig.ingest.endpoint
                        , headers: { "Authorization": `Bearer ${authToken}` }
                    }} /> : null}
                </Grid>
            </SoftBox>
        </DialogContent>
        <DialogActions>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <SoftButton onClick={close} variant="outlined" color="secondary">
                    cancel
                </SoftButton>

                <SoftButton onClick={initiateUpload} variant="outlined" color="success" disabled={!hasFiles}>
                    Upload
                </SoftButton>
            </SoftBox>

        </DialogActions>
    </BootstrapDialog >
};


export default ReportUploadDialog;