import form from "./form";


const {
    formField: {
        amount,
        tableNo,
        note,
        provider,
        providerOther,
        providerRefMethod,
        reimburse,
        reimburseParticipantType,
        reimburseParticipant,
        reimburseParticipantMask,
        method
    },
} = form;

const initialValues = {
    [amount.name]: 0,
    [tableNo.name]: 1,
    [method.name]: "cash",
    [note.name]: "",
    [provider.name]: "",
    [providerOther.name]: "",
    [providerRefMethod.name]: "number",
    [reimburse]: false,
    [reimburseParticipantType]: "staff",
    [reimburseParticipant]: "",
    [reimburseParticipantMask.name]: 0
};

export default initialValues;
