/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import Breadcrumbs from "components/Elements/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
} from "components/Elements/Navbar/styles";

// Soft UI Dashboard PRO React context
import {
    useSoftUIController,
    setOpenConfigurator,
} from "context";


import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { b2cPolicies } from "config/authConfig";
import { SettingsAndConfigurationsDialog } from "../Dialog/settings";
import { useDialog } from "../Dialog/common";
import MessageCenter from "../Notifications/Message";
function Navbar({ absolute, light, isMini }) {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useSoftUIController();
    const { transparentNavbar, fixedNavbar, openConfigurator } = controller;
    const route = useLocation().pathname.split("/").slice(1);

    const { instance, inProgress } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const handleLogout = () => {
        instance.logoutRedirect();
    };

    useEffect(() => {

        setNavbarType("static");

        //return () => { };
    }, [dispatch, fixedNavbar]);

    /*
     const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
 
     const handleProfileEdit = () => {
         if (inProgress === InteractionStatus.None) {
             instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
         }
     };
 */

    const [openSettingsDialog, settingsDialogProps] = useDialog();

    const handleSettingsPageOpen = () => { openSettingsDialog(); };

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
                    <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
                </SoftBox>
                <SettingsAndConfigurationsDialog {...settingsDialogProps} />
                <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
                    <SoftBox color={light ? "white" : "inherit"}>
                        
                        <MessageCenter account={activeAccount} light={light} />

                        <IconButton
                            size="small"
                            color="inherit"
                            sx={navbarIconButton}
                            onClick={handleSettingsPageOpen}
                        >
                            <Icon>settings</Icon>
                            <SoftTypography
                                variant="button"
                                fontWeight="medium"
                                color={light ? "white" : "dark"}
                            >
                                {activeAccount && activeAccount.name ? activeAccount.name : 'Unknown'}

                            </SoftTypography>
                        </IconButton>

                        <IconButton sx={navbarIconButton} size="small" onClick={handleLogout}>
                            <Icon
                                sx={({ palette: { dark, white } }) => ({
                                    color: light ? white.main : dark.main,
                                })}
                            >
                                account_circle
                            </Icon>
                            <SoftTypography
                                variant="button"
                                fontWeight="medium"
                                color={light ? "white" : "dark"}
                            >
                                Sign out
                            </SoftTypography>
                        </IconButton>
                    </SoftBox>
                </SoftBox>

            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
Navbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
Navbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default Navbar;
