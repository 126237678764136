import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, Link, useLocation } from "react-router-dom";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig';

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import MiniActionCard from "components/Elements/MiniActionCard";

import wavesWhite from "assets/images/shapes/waves-white.svg";
import defaultCasino from "assets/graphics/logos/default-casino.png";

import { useDialog } from "components/Elements/Dialog/common";
import { AddPlayerDialog, AddStaffDialog, CloseSessionDialog, AddHouseTransactionDialog, ReconcileGameSessionDialog, SessionSummaryDialog } from "layouts/Cardroom/Session/components/Dialogs";
import Participants from "layouts/Cardroom/Session/components/Participants";
import { useCardroomContext } from 'features';
import { SessionExpenseReportDialog, SessionLiveSummaryDialog } from '../Dialogs';
import CardroomLogo from 'components/Elements/CardroomLogo';

const SessionClockWidget = (props) => {
    const session = props.session;
    const startTime = session ? session.startedOn + "Z" : new Date(Date.now());

    const [duration, setDuration] = useState(calcDuration(startTime, new Date(Date.now())));

    function calcDuration(start, end) {

        let startMs = Date.parse(start);
        let endMs = Date.parse(end);

        let ms = endMs - startMs;

        let seconds = Math.floor(ms / 1000);
        ms -= seconds * 1000;
        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        let hours = Math.floor(minutes / 60);
        minutes -= hours * 60;

        let days = Math.floor(hours / 24);
        hours -= days * 24

        let str = "";
        if (days > 0) str = days + ".";

        str += hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');

        return str;
        //return days + "." + hours + ":" + minutes + ":" + seconds + "." + ms;  
    }

    useEffect(() => {
        const id = setTimeout(() => {
            setDuration(calcDuration(startTime, new Date(Date.now())));
        }, 1000);
    }, [duration]);

    function onEndSessionClick() {
        if (props.onSessionEndRequest) {
            props.onSessionEndRequest();
        }
    }

    return <MiniActionCard
        bgColor="dark"
        title={{ text: "duration", fontWeight: "medium" }}
        count={{ text: duration, prefix: "" }}
        icon={{ component: "schedule" }}
        action={props.onSessionEndRequest ? onEndSessionClick : null}
    />;
}

function CardroomSessionDetails(props) {
    const cardroom = props.cardroom ? props.cardroom : null;

    const [session, setSession] = useState(props.session ? props.session : cardroom.currentSession);

    const [needInitialRefresh, setNeedInitialRefresh] = useState(true);

    const [openAddPlayerDialog, addPlayerDialogProps] = useDialog();

    const [openAddStaffDialog, addStaffDialogProps] = useDialog();

    const [openHouseTxDialog, houseTxDialogProps] = useDialog();

    const [openCloseSessionDialog, closeSessionDialogProps] = useDialog();

    const [openReconcileDialog, reconcileDialogProps] = useDialog();

    const [openSessionSummaryDialog, sessionSummaryDialogProps] = useDialog();

    const [openSessionLiveSummaryDialog, sessionLiveSummaryDialogProps] = useDialog();


    const [openSessionExpensesDialog, sessionExpensesDialogProps] = useDialog();

    function formatStartEndDates(status, start, end, ss) {
        let result = "";
        if (start) {
            let sdt = new Date(Date.parse(start + "Z"));
            result += sdt.toLocaleDateString();
        }

        if (end && status !== 1) {
            let edt = new Date(Date.parse(end + "Z"));
            if (result) result += " - ";
            result += edt.toLocaleDateString();
        }

        return result;
    }


    const [context, actions, features] = useCardroomContext(cardroom);

    const casinoRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const refreshSessionProperties = async () => {
        casinoRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id).then((response) => {
            if (response) {
                setSession(response);
                if (cardroom && cardroom.currentSession && cardroom.currentSession === response.id) {
                    cardroom.currentSession = response;
                }
            }

            //progressIndicatorProps.close();

        }).catch((e) => {
            //progressIndicatorProps.setMode("errorWithRetry");
            //progressIndicatorProps.setMessage("Search to load session details, please try again...")
        });
    };

    useEffect(() => {
        if (!session || needInitialRefresh) {
            refreshSessionProperties();
            setNeedInitialRefresh(false);
        }
        //
    }, [session, needInitialRefresh]);

    function participantStateChanged() {
        refreshSessionProperties();
    }

    function onCloseSessionDialogClosed() {
        refreshSessionProperties();
    }

    function getMoneySupply() {
        if (!session) return 0;

        //todo: add logic to add promos & subtract cashouts?
        return session.totalBuyIns - session.totalCashouts - session.totalTips - session.totalRake; // no need to include tax as that's in tips already getTotalWithholdings();
    }

    function getTotalWithholdings() {
        if (!session) return 0;

        return session.totalRake + session.totalTax - session.totalExpenses;
    }

    function canCloseSession() {
        if (!context) return false;
        if (!session) return false;
        if (session.status !== 3) return false;
        //session && (session.status === 3

        return context.isAllowed(actions.casino.session.close);
    }

    function canEndSession() {
        if (!context) return false;
        if (!session) return false;
        if (session.status !== 2) return false;
        return context.isAllowed(actions.casino.session.end);
    }

    function canStartSession() {
        if (!context) return false;
        if (!session) return false;
        if (session.status !== 1) return false;
        return context.isAllowed(actions.casino.session.start);
    }

    function canAddParticipant() {
        if (!context) return false;
        return context.isAllowed(actions.casino.session.participant.add);
    }

    function canCreateTransaction() {
        if (!context) return false;
        return context.isAllowed(actions.casino.session.transaction.create);
    }

    const navigate = useNavigate();
    function navigateToDetailPage() {
        navigate("/dashboards/cardroom", { state: { cardroom: cardroom } });
    }


    return <>

        <SoftBox mb={3} p={1}>
            <SoftBox
                position="relative"
                bgColor="secondary"
                py={3}
                px={{ xs: 3, sm: 6, md: 1 }}
                mt={3}
                borderRadius="xl"
                variant="gradient"
            >

                <SoftBox
                    component="img"
                    src={wavesWhite}
                    alt="pattern-line"
                    width="100%"
                    position="absolute"
                    left="0"
                    top="0"
                    opacity={0.4}
                />


                <Grid container alignItems="center" position="relative">

                    <CardroomLogo cardroom={cardroom} canOpenProperties={false} customAction={navigateToDetailPage} />

                    <Grid item xs={12} lg={11}>
                        <Grid container>
                            <Grid item xs={6} lg={3} p={1}>
                                <CloseSessionDialog  {...closeSessionDialogProps} cardroom={cardroom} session={session} onClose={onCloseSessionDialogClosed} />
                                {session && session.status === 2 ?
                                    <SessionClockWidget session={session} onSessionEndRequest={canEndSession() ? openCloseSessionDialog : null} /> :
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: session && session.status === 1 ? "scheduled for" : "start/end", fontWeight: "medium" }}
                                        count={{ text: session ? formatStartEndDates(session.status, session.startedOn, session.endedOn, session) : "" }}
                                        icon={{ component: "calendar_month_outlined" }}
                                        action={session && (canCloseSession() || canStartSession()) ? openCloseSessionDialog : null}
                                    />}
                            </Grid>
                            <Grid item xs={6} lg={3} p={1}>
                                <MiniActionCard
                                    bgColor="dark"
                                    title={{ text: "Chips Out", fontWeight: "medium" }}
                                    count={{ text: getMoneySupply(), prefix: "$", useNumberFormatter: true, color: "success" }}
                                    icon={{ component: "money" }}
                                />

                            </Grid>
                            <Grid item xs={6} lg={3} p={1}>
                                <MiniActionCard
                                    bgColor="dark"
                                    title={{ text: "revenue", fontWeight: "medium" }}
                                    count={{ text: getTotalWithholdings(), prefix: "$", useNumberFormatter: true, color: getTotalWithholdings() > 0 ? "success" : "error" }}
                                    action={session ? openSessionLiveSummaryDialog : null}
                                    icon={{ component: "business" }}
                                />

                            </Grid>
                            <Grid item xs={6} lg={3} p={1}>
                                <MiniActionCard
                                    bgColor="dark"
                                    title={{ text: "Markers", fontWeight: "medium" }}
                                    count={{ text: session ? session.amountOwedByPlayers : 0, prefix: "$", useNumberFormatter: true, color: "error" }}
                                    icon={{ color: "info", component: "credit_card" }}
                                    action={session ? openReconcileDialog : null}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </SoftBox>
        </SoftBox>


        <SoftBox mb={3}>
            <Participants cardroom={cardroom} session={session} onParticipantStateChanged={participantStateChanged} addPlayerHandler={openAddPlayerDialog} addStaffHandler={openAddStaffDialog} addHouseTxHandler={openHouseTxDialog} reconcileHandler={openReconcileDialog} summaryHandler={openSessionSummaryDialog} liveSummaryHandler={openSessionLiveSummaryDialog} />
        </SoftBox>

        {canAddParticipant() ? <>
            <AddStaffDialog {...addStaffDialogProps} cardroom={cardroom} session={session} mode="session" onAdded={refreshSessionProperties} />
            <AddPlayerDialog {...addPlayerDialogProps} cardroom={cardroom} session={session} mode="session" onAdded={refreshSessionProperties} />
        </> : null}
        {canCreateTransaction() ?
            <AddHouseTransactionDialog {...houseTxDialogProps} cardroom={cardroom} session={session} mode="session" onAdded={refreshSessionProperties} /> : null}
        <ReconcileGameSessionDialog {...reconcileDialogProps} cardroom={cardroom} session={session} title={(session && session.status < 3) ? "Outstanding debt" : null} />
        <SessionSummaryDialog {...sessionSummaryDialogProps} cardroom={cardroom} session={session} />
        <SessionLiveSummaryDialog {...sessionLiveSummaryDialogProps} cardroom={cardroom} session={session} />
        <SessionExpenseReportDialog {...sessionExpensesDialogProps} cardroom={cardroom} session={session} />
    </>;

}


export default CardroomSessionDetails;

