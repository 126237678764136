import form from "./form";


const {
    formField: {
        partnerId,
        withholdingSchedule
    },
} = form;

const initialValues = {
    [partnerId.name]: "",
    [withholdingSchedule.name]: "none"
};

export default initialValues;
