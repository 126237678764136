import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { code, key },
} = form;



const validations = {
    editor: Yup.object().shape({
        [code.name]: Yup.string().required(code.errorMsg),
        [key.name]: Yup.string().required(key.errorMsg)
    }),

};

export default validations;