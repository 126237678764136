// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";


const DateCell = ({ value, format, display, color }) => {

    let date = new Date(Date.parse(value));

    let dateText = "";
    if (!isNaN(date.valueOf())) {

        if (format === "date" || format === "dateAndTime") {
            if (display === "locale")
                dateText = date.toLocaleDateString();
            else
                dateText = date.toDateString();
        }

        if (format === "dateAndTime") {
            dateText += " ";
        }

        if (format === "time" || format === "dateAndTime") {

            if (display === "locale")
                dateText += date.toLocaleTimeString();
            else
                dateText += date.toTimeString();
        }
    }

    return (
        <SoftTypography variant="caption" fontWeight="medium" color={color}>
            {dateText}
        </SoftTypography>
    );
}

// Setting default values for the props of DefaultCell
DateCell.defaultProps = {
    value: "01/01/1900",
    format: "date",
    display: "default",
    color: "text"
};

// Typechecking props for the DefaultCell
DateCell.propTypes = {
    value: PropTypes.string,
    format: PropTypes.oneOf(["date", "time", "dateAndTime"]),
    display: PropTypes.oneOf(["default", "locale"]),
    color: PropTypes.string
};

export default DateCell;
