
const form = {
    formId: "find-participant-form",
    formField: {
        searchCriteria: {
            name: "searchCriteria",
            label: "",
            type: "text",
            placeholder: "phone number or name/nickname",
            errorMsg: "Search criteria is required.",
        },
        candidate: {
            name: "candidate",
            label: "",
            type: "hidden",
            placeHolder: "",
            errorMsg: "Candidate must be selected"
        },
        candidateId: {
            name: "candidateId",
            type: "hidden",
            errorMsg: "Candidate ID"
        },
        partnerId: {
            name: "partnerId",
            label: "Responsible partner",
            type: "hidden",
            errorMsg: "Responsible party must be selected"
        },
        candidateState: {
            name: "candidateState",
            type: "hidden",
            errorMsg: "Candidate state"
        },
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "name",
            errorMsg: "Name is required.",
        },
        nickName: {
            name: "nickName",
            label: "Nickname",
            type: "text",
            placeholder: "nickname",
            errorMsg: "",
        },
        phoneNumber: {
            name: "phoneNumber",
            label: "Phone Number",
            type: "number",
            placeholder: "phone number",
            errorMsg: "A 10 digit phone number is required",
        },
        creditLimit: {
            name: "creditLimit",
            label: "Credit Limit",
            type: "number",
            placeholder: "credit limit",
            errorMsg: "Credit limit requires a number >= 0",
        },
        withholdingRate: {
            name: "withholdingRate",
            label: "Withholding Rate",
            type: "number",
            placeholder: "withholding percentage",
            errorMsg: "Withholding rate must be between 0 and 100",
        },
        withholdingThreshold: {
            name: "withholdingThreshold",
            label: "Withholding exemption",
            type: "number",
            placeholder: "withholding exemption",
            errorMsg: "Exempt amount is required",
        },
        withholdingSchedule: {
            name: "withholdingSchedule",
            label: "Withholding Type",
            type: "text",
            placeholder: "withholding type",
            errorMsg: "Withholding type is required",
        },
        mustSign: {
            name: "mustSign",
            label: "Require marker signature",
            type: "hidden",
            placeholder: "Require marker signature",
            errorMsg: "Marker signature setting is required",
        },
        mustNotify: {
            name: "mustNotify",
            label: "Require notifications",
            type: "hidden",
            placeholder: "Require notifications",
            errorMsg: "Notification setting is required"
        },
        isAnonymous: {
            name: "isAnonymous",
            label: "Anonymous participant",
            type: "bool",
            placeholder: "Is anonymous",
            errorMsg: "Anonymous designation is required",
        },
        notificationMask: {
            name: "notificationMask",
            type: "hidden",
            errorMsg: "Notification mask"
        },
        photo: {
            name: "photo",
            label: "",
            type: "text",
            placeholder: "photo",
            errorMsg: "photo",
        },
        mask: {
            name: "mask",
            type: "hidden",
            errorMsg: "At least one staff role must be selected"
        },
        initialBalance: {
            name: "initialBalance",
            label: "Existing balance",
            type: "number",
            placeholder: "existing account balance",
            errorMsg: "Existing account balance is required"
        },
        initialBalanceType: {
            name: "initialBalanceType",
            label: "Does the player have an existing balance?",
            type: "string",
            placeHolder: "",
            errorMsg: "Initial balance type must be selected"
        }
    },

};

export default form;
