
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
const ImageCell = ({ value, img, width, height }) => {
    return (
        <SoftBox display="flex" alignItems="center">
            <SoftBox mr={1}>
                {img? <SoftBox component="img"
                    src={img}
                    alt={value}
                    width={width}
                    height={height} />: null}
            </SoftBox>
        </SoftBox >
    );
}
// Setting default values for the props of DefaultCell
ImageCell.defaultProps = {
    value: "",
    img: "",
    width: { lg: "82px", sm: "96px", xl: "96px" },
    height: { lg: "82px", sm: "96px", xl: "96px" },
};

// Typechecking props for the DefaultCell
ImageCell.propTypes = {
    value: PropTypes.string,
    img: PropTypes.string,
    width: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
    height: PropTypes.oneOfType(PropTypes.string, PropTypes.object)
};

export default ImageCell;
