
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { apiConfig } from 'config/apiConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import FormField from "components/Elements/Forms/FormField";

import playerAvatar from "assets/graphics/personas/player_256.png";
import staffAvatar from "assets/graphics/personas/masseuse_256.png";
import MoneyCell from "components/Elements/DataTable/components/Cells/MoneyCell";


const Account = ({ type, cardroom, formData }) => {
    const partipantType = type;
    let participantTypeName = "player";
    let avatar = playerAvatar;

    if (partipantType === "staff") {
        participantTypeName = "staff member";
        avatar = staffAvatar;
    }

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { candidate, candidateId, initialBalance, initialBalanceType, candidateState, name, nickName, phoneNumber, creditLimit,
        withholdingRate, withholdingThreshold, withholdingSchedule, photo, mask, staffRoles, partnerId, mustSign, mustNotify, notificationMask, isAnonymous } = formField;
    const {
        candidate: candidateV,
        candidateId: candidateIdV,
        candidateState: candidateStateV,
        name: nameV,
        nickName: nickNameV,
        phoneNumber: phoneNumberV,
        creditLimit: creditLimitV,
        withholdingRate: withholdingRateV,
        withholdingThreshold: withholdingThresholdV,
        withholdingSchedule: withholdingScheduleV,
        photo: photoV,
        mask: maskV,
        mustSign: mustSignV,
        mustNotify: mustNotifyV,
        notificationMask: notificationMaskV,
        staffRoles: staffRolesV,
        partnerId: partnerIdV,
        initialBalanceType: initialBalanceTypeV,
        initialBalance: initialBalanceV,
        isAnonymous: isAnonymousV
    } = values;

    const participantTypeLabel = maskV & 1 > 0 ? "player" : "staff";

    function isPlayer() {
        return (maskV & 1) > 0;
    }
    function isStaff() {
        return (maskV & 254) > 0;
    }

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={photoV} alt={nameV} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>

                <Grid item xs={12} sm={8}>
                    <Grid container xs={12}>
                        <Grid item xs={4} >
                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    {participantTypeLabel} Name
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={8}>
                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize" >
                                    {nameV}
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                    {isAnonymousV ?
                        <Grid container xs={12}>
                            <Grid item xs={12} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        color="error"   >
                                        This {participantTypeLabel} will be created as an anonymous {participantTypeLabel}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                        </Grid>
                        : null}
                    <Grid container xs={12}>
                        <Grid item xs={4} >
                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"                         >
                                    Status
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={8}>
                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize" >
                                    {candidateStateV === "local" ? "Returning " + participantTypeLabel : candidateStateV === "new" ? "New to Gambooler" : "New to " + cardroom.name}
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </Grid>

                    {isPlayer() ?
                        <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"                         >
                                            {creditLimit.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize" >
                                            <MoneyCell value={creditLimitV} prefix="$" useColorScheme={false} />
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                            </Grid>

                            {initialBalanceV != 0 ?
                                <>
                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"                         >
                                                    Existing Debt
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    textTransform="capitalize" >
                                                    {initialBalanceTypeV}
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"                         >
                                                    {initialBalance.label}
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    textTransform="capitalize" >
                                                    <MoneyCell value={initialBalanceV} prefix="$" useColorScheme={false} />
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </> : null}
                        </>
                        : null}

                    {isStaff() ? <>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"                         >
                                        {withholdingSchedule.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize" >
                                        {withholdingScheduleV}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                        </Grid>
                        {withholdingScheduleV !== "none" ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"                         >
                                            {withholdingRate.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize" >
                                            {withholdingRateV}%
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"                         >
                                            {withholdingThreshold.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize" >
                                            <MoneyCell value={withholdingThresholdV} prefix="$" useColorScheme={false} />
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </> : null}
                    </>
                        : null}
                </Grid>

            </Grid>
        </SoftBox>
    </SoftBox>;
}

Account.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Account;
