
const form = {
    formId: "agent-fee-schedule-editor-form",
    formField: {        
        scheduleId: {
            name: "scheduleId",
            label: "schedule id",
            type: "hidden"
        },
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "name",
            errorMsg: "Name is required.",
        },
        description: {
            name: "description",
            label: "Description",
            type: "text",
            placeholder: "description",
        },
        unionDuesRate: {
            name: "unionDuesRate",
            label: "Union Dues Rate",
            type: "number",
            placeholder: "union dues percentage",
            errorMsg: "Union dues rate is required",
        },
        xmttDuesRate: {
            name: "xmttDuesRate",
            label: "XMTT Dues Rate",
            type: "number",
            placeholder: "xmtt dues percentage",
            errorMsg: "XMTT dues rate is required",
        },
        chipOffsetRate: {
            name: "chipOffsetRate",
            label: "Chip Offset Rate",
            type: "number",
            placeholder: "chip offset percentage",
            errorMsg: "Chip offset rate is required",
        },
        taxRebateRate: {
            name: "taxRebateRate",
            label: "Tax Rebate Rate",
            type: "number",
            placeholder: "tax rebate percentage",
            errorMsg: "Tax rebate rate is required",
        },
        unionDuesBase: {
            name: "unionDuesBase",
            label: "Union Dues Base",
            type: "number",
            placeholder: "union dues base amount",
            errorMsg: "Union dues base is required",
        },
        winTaxRate: {
            name: "winTaxRate",
            label: "Win Tax Rate",
            type: "number",
            placeholder: "winner tax percentage",
            errorMsg: "Win tax rate is required",
        },
        loyaltyRebateRate: {
            name: "loyaltyRebateRate",
            label: "Loyalty Dividend Rate",
            type: "number",
            placeholder: "loyalty dividend percentage",
            errorMsg: "Loyalty dividend rate is required",
        }
    },

};

export default form;
