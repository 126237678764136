
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import PageLayout from "components/Elements/PageLayout";
import Navbar from "components/Elements/Navbar";
import Footer from "components/Elements/Footer";

import PlayerDetails from "./Player";
import StaffDetails from "./Staff";

function ParticipantPage({participantType}) {

    const location = useLocation();
  
    const [cardroom, setCardroom] = useState(location.state?.cardroom);
    const [participantId, setParticipantId] = useState(location.state?.id);
    //const [participantType, setParticipantType] = useState(location.state?.participantType);


    let isStaff = participantType === "staff";

    return (
      <PageLayout>
        <Navbar />

        <SoftBox pt="3px">

          {
            isStaff ? <StaffDetails cardroom={cardroom} id={participantId}/> : <PlayerDetails cardroom={cardroom} id={participantId}/>

          }
          
        </SoftBox>
      
      <Footer />
      </PageLayout>);
}

export default ParticipantPage;