import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";


import defaultTxAvatar from "assets/graphics/casino/tips_256.png";
import FormField from 'components/Elements/Forms/FormField';
import { NumericFormat } from 'react-number-format';
import { useCardroomContext } from 'features';



const RecordStaffTipsForm = ({ cardroom, session, staff, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, tipAmount, taxRate, taxAmount, method, note } = formField;
    const {
        type: typeV,
        method: methodV,
        tipAmount: tipAmountV,
        taxRate: taxRateV,
        taxAmount: taxAmountV,
        note: noteV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);
    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(type.name, "tips");

            setFieldValue(method.name, "credit");

            setFieldValue(taxRate.name, staff && staff.properties && staff.properties.withholdingRate ? staff.properties.withholdingRate : 0);

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);
    /*
        function onTipAmountChanged() {
            setFieldValue(taxAmount.name, Math.round(taxRateV * tipAmountV / 5) * 5);
        }
    */
    const [context, actions, features] = useCardroomContext(cardroom);

    function canOverrideTaxAmount() {
        if (!context) return false;
        return context.isAllowed(actions.casino.staff.payout.overrideWitheldAmount);
    }

    return <SoftBox minHeight="190px">
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={defaultTxAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {tipAmount.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                    type={tipAmount.type}
                                    name={tipAmount.name}
                                    value={tipAmountV}
                                    placeholder={tipAmount.placeholder}
                                    error={errors.tipAmount && touched.tipAmount}
                                    success={tipAmountV && tipAmountV.toString().length > 0 && !errors.tipAmount}
                                /></Grid>
                        </Grid>
                        {/*}
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Withholding
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                {canOverrideTaxAmount() ?
                                    <FormField onFocus={e => e.currentTarget.select()}
                                        type={taxAmount.type}
                                        name={taxAmount.name}
                                        value={taxAmountV}
                                        placeholder={taxAmount.placeholder}
                                        error={errors.taxAmount && touched.taxAmount}
                                        success={taxAmountV && taxAmountV.toString().length > 0 && !errors.taxAmount}
                                    />
                                    : <SoftTypography pl={1.5}
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize">
                                        <NumericFormat value={taxAmountV} displayType="text" thousandSeparator={true} prefix="$" /> (
                                        <NumericFormat value={taxRateV * 100} displayType="text" thousandSeparator={true} suffix="%" />)
                                    </SoftTypography>}
                            </Grid>
                        </Grid>
*/}
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {note.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()}
                                    type={note.type}
                                    name={note.name}
                                    value={noteV}
                                    placeholder={note.placeholder}
                                    error={errors.note && touched.note}
                                    success={noteV && noteV.length > 0 && !errors.note}
                                />
                            </Grid>
                        </Grid>
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>

};



export default RecordStaffTipsForm;