import { useState } from "react";
import PropTypes from "prop-types";

import SoftAlert from "components/SoftAlert";

const useAlert = () => {
    const [open, setOpen] = useState(false);
    const [color, setColor] = useState("info");
    const [children, setChildren] = useState(null);

    const openAlert = () => {
      setOpen(true);
    };
    const closeAlert = ()=>{
        setOpen(false);
    };

    const props = {
        color,
        open,
        children
    };

    return [openAlert, closeAlert, setColor, setChildren, props];
  };

const Alert = ({color, open, children}) => {
    if (open) {
        return <SoftAlert color={color} dismissible>{children}</SoftAlert>;
    }    
};


Alert.defaultProps = {
    color: "info",
    open: false    
};
  
  // Typechecking props for the MiniStatisticsCard
Alert.propTypes = {
    color: PropTypes.oneOf([
        "white",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
    ]),
    open: PropTypes.bool,
    children: PropTypes.node
};

export {useAlert};

export default Alert;