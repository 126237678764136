import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import Icon from '@mui/material/Icon';

import { styled } from '@mui/material/styles';
import SoftAlert from "components/SoftAlert";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from 'components/SoftButton';
import { CircularProgress } from '@mui/material';


const ProgressIndicator = ({ visible, mode, message, size, retryHandler }) => {
    if (!visible) return null;

    let color = "dark";
    let textColor = "white";
    let icon = null; //"hourglass_empty";
    let iconColor = "white";
    let canRetry = false;

    switch (mode) {
        case "warn":
            color = "warning";
            icon = "warning_amber";
            iconColor = "error";
            break;
        case "warnWithRetry":
            color = "warning";
            icon = "warning_amber";
            iconColor = "error";
            canRetry = true;
            break;
        case "error":
            color = "error";
            icon = "error_outline";
            iconColor = "white";
            break;
        case "errorWithRetry":
            color = "error";
            icon = "error_outline";
            iconColor = "white";
            canRetry = true;
            break;
    }
{/*}
    if (size === "xs") {
        return <SoftBox p={2} bgColor={color} color={textColor} variant="gradient" borderRadius={10} display="flex" justifyContent="space-between">
            <SoftTypography color={textColor} style={{display: "inline-flex"}}>
                {icon ? <Icon color={iconColor} fontSize="large">{icon}</Icon> :
                    <CircularProgress color="secondary" />}
                &nbsp;
                <SoftTypography variant="body2" color={textColor} textGradient={false}>
                    {message}
                </SoftTypography> </SoftTypography><SoftTypography>
                {canRetry ? <SoftButton style={{ zIndex: 5 }} iconOnly onClick={retryHandler} title="retry"><Icon>replay</Icon></SoftButton> : null}
            </SoftTypography>
        </SoftBox >;
    }
*/}
    return <SoftBox p={2}>
        <SoftAlert color={color} textGradient={true}>
            {icon ? <Icon color={iconColor} fontSize="large">{icon}</Icon> :
                <CircularProgress color="secondary" />}
            &nbsp;
            <SoftTypography variant="body2" color={textColor} textGradient={false}>
                {message}
            </SoftTypography>
            {canRetry ? <SoftButton style={{ position: "absolute", top: 12, right: 12, zIndex: 5 }} iconOnly onClick={retryHandler} title="retry"><Icon>replay</Icon></SoftButton> : null}
        </SoftAlert>
    </SoftBox >;
};

ProgressIndicator.propTypes = {
    visible: PropTypes.bool,
    mode: PropTypes.oneOf([
        "wait",
        "warn",
        "warnWithRetry",
        "error",
        "errorWithRetry"
    ]),
    message: PropTypes.string.isRequired,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"])
};


const useProgressIndicator = (initialMode, initialMessage, retryHandler) => {
    const [visible, setVisible] = useState(false);
    const [mode, setMode] = useState(initialMode);
    const [message, setMessage] = useState(initialMessage);

    const show = () => {
        setVisible(true);
    };

    const close = () => {
        setVisible(false);
    };


    const props = {
        visible,
        mode,
        message,
        close,
        setMode,
        setMessage,
        retryHandler
    };
    return [show, props];
};

export {
    useProgressIndicator,
    ProgressIndicator
};