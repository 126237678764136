import form from "./form";


const {
    formField: {
        amount,
        tipAmount,
        method,
        tableNo,
        taxRate,
        taxAmount,
        verified,
        provider,
        providerOther,
        providerRefMethod,
        providerRef,
        providerRefImage,
        note
    },
} = form;

const initialValues = {
    [amount.name]: 0,
    [tipAmount.name]: 0,
    [taxRate.name]: 0,
    [taxAmount.name]: 0,
    [method.name]: "cash",
    [verified.name]: false,
    [tableNo.name]: 1,
    [provider.name]: "",
    [providerOther.name]: "",
    [providerRefMethod.name]: "number",
    [providerRef.name]: "",
    [providerRefImage.name]: "",
    [note.name]: ""
};

export default initialValues;
