
import React, { useState, useEffect, useInsertionEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import Swal from "sweetalert2";

import verifyAvatar from "assets/graphics/actions/fingerprint_256.png";
import { Formik, Form, ErrorMessage } from "formik";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import SoftAvatar from 'components/SoftAvatar';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import IconCell from 'components/Elements/DataTable/components/Cells/IconCell';
import FormField from 'components/Elements/Forms/FormField';
import FormImageField from 'components/Elements/Forms/FormImageField';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import FormSwitch from 'components/Elements/Forms/FormSwitch';

import apple from "assets/graphics/logos/paynet/96px/apple.png";
import cashapp from "assets/graphics/logos/paynet/96px/cashapp.png";
import paypal from "assets/graphics/logos/paynet/96px/paypal.png";
import venmo from "assets/graphics/logos/paynet/96px/venmo.png";
import zelle from "assets/graphics/logos/paynet/96px/zelle.png";
import SignatureCollectionForm from 'layouts/Cardroom/Session/components/Forms/Signature';


function isSignatureCollectionNeeded(tx) {
    if (!tx) return false;

    if (tx.type === 1 && tx.paymentType === 3 && tx.status === 1 && tx.signatureRequired && !tx.participantSignature) {
        return true;
    }

    return false;
}

const TransactionEditorForm = ({ cardroom, participant, session, transaction, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { method, verified, switchToCredit, note, provider, providerOther, providerRefMethod, providerRef, providerRefImage, participantSignatureImage, participantSignatureRequired, requestSignature } = formField;
    const {
        method: methodV,
        verified: verifiedV,
        switchToCredit: switchToCreditV,
        provider: providerV,
        providerOther: providerOtherV,
        providerRefMethod: providerRefMethodV,
        providerRef: providerRefV,
        providerRefImage: providerRefImageV,
        participantSignatureImage: participantSignatureImageV,
        participantSignatureRequired: participantSignatureRequiredV,
        requestSignature: requestSignatureV,
        note: noteV
    } = values;

    function getTransactionTypeName(type) {
        if (type === 1) return "buyin";
        if (type === 2) return "cashout";
        if (type === 3) return "promo";
        if (type === 4) return "withholding";
        if (type === 5) return "rake";
        if (type === 7) return "expense";
        if (type === 8) return "other";
        return "unknown";
    }

    function getTransactionPaymentMethodName(type) {
        if (type === 1) return "cash";
        if (type === 2) return "electronic";
        if (type === 3) return "credit";
        if (type === 4) return "account";

        return "unknown";
    }

    function getEWalletVendor(vendor) {

        let valueText = vendor;
        let icon = "local_atm";
        let color = "text";

        switch (vendor) {
            case "zelle":
                valueText = "Zelle";
                //icon =zelle; // 
                icon = <SoftBox component="img" src={zelle} width="16px" />;
                break;
            case "venmo":
                valueText = "Venmo";
                //icon = venmo; //
                icon = <SoftBox component="img" src={venmo} width="16px" />;
                break;
            case "apple":
                valueText = "Apple Pay";
                //icon = apple; //
                icon = <SoftBox component="img" src={apple} width="16px" />;
                break;
            case "paypal":
                valueText = "PayPal";
                //icon = paypal; //
                icon = <SoftBox component="img" src={paypal} width="16px" />;
                break;
            case "cashapp":
                valueText = "Cash App";
                //icon = cashapp; //
                icon = <SoftBox component="img" src={cashapp} width="16px" />;
                break;
        }

        return <IconCell value={valueText} icon={icon} color={color} />;

    }

    function onVendorRefMethodRadioGroupChanged(e, newValue) {
        setFieldValue(providerRefMethod.name, newValue);
    }

    function onSwitchToCreditChanged(value) {
        if (value) {
            setFieldValue(method.name, "credit");
        }
        else {
            setFieldValue(method.name, getTransactionPaymentMethodName(transaction.paymentType));
        }
    }

    function onSignatureCollected(sigImg) {
        setFieldValue(participantSignatureImage.name, sigImg);
    }

    function onSignatureCleared() {
        setFieldValue(participantSignatureImage.name, "");
    }

    function getSignatureRequestStatusLabel(sr) {
        if (!sr) return "Not Found";

        if (sr.status === 1) return "Active";
        if (sr.status === 2) return "Completed";
        if (sr.status === 3) return "Expired";

        return "Unknown";
    }

    function getSignatureRequestStatusColor(sr) {

        if (!sr) return "warning";

        if (sr.status === 1) return "text";
        if (sr.status === 2) return "success";
        if (sr.status === 3) return "error";

        return "info";

    }

    const [signatureRequest, setSignatureRequest] = useState(null);
    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.signature.scopes.read,
    });

    const [formDataInitialized, setFormDataInitialized] = useState(false);
    useEffect(() => {
        if (!formDataInitialized) {

            if (transaction) {
                //setFieldValue(verified.name, true);

                if (transaction.status === 4) {
                    setFieldValue(verified.name, false);
                    setFieldValue(switchToCredit.name, true);
                }

                setFieldValue(participantSignatureRequired.name, transaction.signatureRequired);
                setFieldValue(participantSignatureImage.name, transaction.participantSignature ? transaction.participantSignature : "");

                if (transaction.type === 1 && transaction.paymentMethod === 3 && transaction.status === 2 && transaction.signatureRequired && !transaction.participantSignature) {
                    setFieldValue(verified.name, false);
                    //set flag to indicate we need to collect signature?
                }

                setFieldValue(note.name, transaction.note ? transaction.note : "");
                setFieldValue(method.name, getTransactionPaymentMethodName(transaction.paymentType));
                setFieldValue(providerRef.name, transaction.ePaymentVendorReferenceNo ? transaction.ePaymentVendorReferenceNo : "");
                setFieldValue(providerRefImage.name, transaction.ePaymentVendorReferenceImage ? transaction.ePaymentVendorReferenceImage : "");
            }

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    useEffect(() => {
        if (!signatureRequest && isSignatureCollectionNeeded(transaction)) {
            //[HttpGet("{casinoId:Guid}/{participantId:Guid}/{sessionId:Guid}/{transactionId:Guid}/signature-request",
            execute("GET", apiConfig.signature.endpoint + "/" + cardroom.id + "/" + participant.id + "/" + session.id + "/" + transaction.id + "/signature-request").then((response) => {
                if (response) {
                    setSignatureRequest(response);
                }

            }).catch((e) => {

            });
        }
    }, [execute, transaction, signatureRequest]);


    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={verifyAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>

                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Type
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography
                                    variant="caption"
                                    textTransform="capitalize">
                                    {transaction ? getTransactionTypeName(transaction.type) : null}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Amount
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography
                                    variant="caption"
                                    textTransform="capitalize">
                                    <MoneyCell value={transaction ? transaction.amount : 0} prefix="$" useColorScheme={false} />
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Payment Method
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography
                                    variant="caption"
                                    textTransform="capitalize">
                                    {transaction ? getTransactionPaymentMethodName(transaction.paymentType) : "Unkown"}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        {transaction && transaction.paymentType === 2 ?
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            Payment Vendor
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography
                                        variant="caption"
                                        textTransform="capitalize">
                                        {transaction ? getEWalletVendor(transaction.ePaymentVendor) : <>Unkown</>}
                                    </SoftTypography>
                                </Grid>
                            </Grid> : null}

                        {isSignatureCollectionNeeded(transaction) ?
                            <>
                                {signatureRequest && signatureRequest.status !== 0 ?
                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    Signature Request Code
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SoftTypography
                                                variant="caption"
                                                textTransform="capitalize"
                                                color={getSignatureRequestStatusColor(signatureRequest)}>
                                                {signatureRequest.signatureKey} / ({getSignatureRequestStatusLabel(signatureRequest)})
                                            </SoftTypography>
                                        </Grid>
                                    </Grid> : null
                                }
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {participantSignatureImage.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SignatureCollectionForm size="sm" onSignatureCollected={onSignatureCollected} onSignatureCleared={onSignatureCleared} />

                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            color="error">
                                            <ErrorMessage name={participantSignatureImage.name} />
                                        </SoftTypography>
                                    </Grid>
                                </Grid>
                            </> :
                            <>
                                {transaction && transaction.status !== 4 ?
                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    {verified.label}
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <FormSwitch mr="auto" readOnly={transaction && transaction.status === 4}
                                                name={verified.name}
                                                {...formData} />
                                        </Grid>
                                    </Grid> : <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    Status
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SoftTypography
                                                variant="caption"
                                                textTransform="capitalize" color="error">
                                                Declined
                                            </SoftTypography>
                                        </Grid>
                                    </Grid>}

                                {verifiedV ? <>
                                    {transaction && transaction.paymentType === 2 ? <>

                                        <Grid container xs={12}>
                                            <Grid item xs={4} >
                                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize">
                                                        {providerRefMethod.label}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <SoftTypography
                                                    variant="caption"
                                                    textTransform="capitalize">
                                                    <RadioGroup row={true} value={providerRefMethodV} onChange={onVendorRefMethodRadioGroupChanged} style={{ marginLeft: "0.75em" }}>
                                                        <FormControlLabel value="number" control={<Radio />} label="Number" />
                                                        <FormControlLabel value="image" control={<Radio />} label="Image" />
                                                    </RadioGroup>
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>

                                        {providerRefMethodV === "number" ?
                                            <Grid container xs={12}>
                                                <Grid item xs={4} >
                                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize">
                                                            {providerRef.label}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormField type={providerRef.type}
                                                        name={providerRef.name}
                                                        value={providerRefV}
                                                        placeholder={providerRef.placeholder}
                                                        error={errors.providerRef && touched.providerRef}
                                                        success={providerRefV && providerRefV.toString().length > 0 && !errors.providerRef}
                                                    />
                                                </Grid>
                                            </Grid> : null}

                                        {providerRefMethodV === "image" ?
                                            <Grid container xs={12}>
                                                <Grid item xs={4} >
                                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize">
                                                            {providerRefImage.label}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormImageField name={providerRefImage.name} imgWidth="120px" {...formData} />
                                                </Grid>
                                            </Grid> : null}
                                    </> : null}
                                </> : <>
                                    <Grid container xs={12}>
                                        <Grid item xs={12} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    color="error"
                                                >
                                                    {transaction && transaction.status === 4 ?
                                                        <>Your account balance pre-authorization failed. This transaction must be either converted to 'credit' or canceled.
                                                            <br />
                                                            If canceling, please ensure that any chips associated with this transaction are removed from play.
                                                        </> : <>
                                                            If payment was not recieved, you can either switch payment type to 'credit', or cancel the transaction.
                                                        </>}
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    color="error"
                                                    textTransform="capitalize"
                                                >
                                                    {switchToCredit.label}
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <FormSwitch mr="auto"
                                                name={switchToCredit.name}
                                                value={switchToCreditV}
                                                onChange={onSwitchToCreditChanged}
                                                {...formData} />
                                        </Grid>
                                    </Grid>
                                </>}
                            </>}

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {note.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField
                                    type={note.type}
                                    name={note.name}
                                    value={noteV}
                                    placeholder={note.placeholder}
                                    error={errors.note && touched.note}
                                    success={noteV && noteV.length > 0 && !errors.note}
                                />
                            </Grid>
                        </Grid>
                    </SoftBox>

                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >

};

const TransactionEditor = ({ cardroom, session, transaction, participant, onClose, onTransactionUpdated }) => {
    const { formId, formField } = form;
    const currentValidation = validations.editor;

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    function raiseOnTransactionUpdated(updatedTx) {
        if (onTransactionUpdated) {
            onTransactionUpdated(updatedTx);
        }
    }

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    const handleSubmit = (values, actions) => {
        //build tx object
        const tx = Object.assign({}, transaction);
        let delaySave = false;

        if (isSignatureCollectionNeeded(transaction)) {
            // [HttpPut("{casinoId:Guid}/sessions/{sessionId:Guid}/participants/{participantId:Guid}/transactions/{transactionId:Guid}/signature", Name = " UpdateSessionTransactionSignature")]
            //public async Task<bool> UpdateSessionTransactionSignatureAsync(Guid casinoId, Guid sessionId, Guid participantId, Guid transactionId, [FromBody] string signature)
            if (values.participantSignatureImage) {
                setUpdatedSignature({
                    signature: values.participantSignatureImage
                });
            }
            else {
                actions.validateForm();
            }
        }
        else {

            if (!values.verified && values.switchToCredit) {
                tx.status = 2;
                tx.paymentType = 3;
            }
            else if (!values.verified && !values.switchToCredit) {
                delaySave = true;

                const newSwal = Swal.mixin({
                    buttonsStyling: true,
                    cancelButtonColor: "#FBCF33",
                    denyButtonColor: "#EA0606"
                });

                const diagResult = newSwal
                    .fire({
                        inputAttributes: {
                            autocapitalize: "off",
                        },
                        focusCancel: true,
                        reverseButtons: true,
                        showCancelButton: true,
                        showDenyButton: true,
                        showConfirmButton: false,
                        confirmButtonText: "Yes, cancel transaction",
                        denyButtonText: "Yes, cancel transaction",
                        cancelButtonText: "No",
                        title: "Are you sure you want to cancel this transaction?",
                        text: "This operation cannot be reversed!",
                        icon: "warning",
                        allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                        //confirm button style didn't work, so using DENY button instead...
                        if (result.isDenied) {
                            tx.status = 3;
                            setPendingTransaction(tx);
                        }
                        else {
                            actions.setSubmitting(false);
                        }
                    });
            }
            else if (values.verified) {
                tx.status = 2;

                if (values.providerRefMethod === "image" && values.providerRefImage) {
                    tx.ePaymentVendorReferenceImage = values.providerRefImage;
                }
                else if (values.providerRefMethod === "number" && values.providerRef) {
                    tx.ePaymentVendorReferenceNo = values.providerRef;
                }
            }

            tx.note = values.note ? values.note : null;

            if (!delaySave) {
                setPendingTransaction(tx);
            }
        }
    };

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.write,
    });

    const [pendingTransaction, setPendingTransaction] = useState(null);
    const [updatedSignature, setUpdatedSignature] = useState(null);

    useEffect(() => {
        if (pendingTransaction) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Updating transaction...")
            showProgress();

            //[HttpPut("{casinoId:Guid}/sessions/{sessionId:Guid}/participants/{participantId:Guid}/transactions/{transactionId:Guid}", Name = "UpdateSessionTransaction")]
            execute("PUT", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/participants/" + participant.id + "/transactions/" + transaction.id, pendingTransaction).then((response) => {
                if (response) {
                    raiseOnTransactionUpdated(response);
                    //compare status? & id?
                }
                //clear payload and close dialog...
                setPendingTransaction(null);
                progressIndicatorProps.close();

                raiseOnClose();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to update transaction, please try again...")
            });

        }

    }, [execute, pendingTransaction, retryCounter]);

    useEffect(() => {
        if (updatedSignature) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Updating transaction signature...")
            showProgress();

            // [HttpPut("{casinoId:Guid}/sessions/{sessionId:Guid}/participants/{participantId:Guid}/transactions/{transactionId:Guid}/signature", Name = " UpdateSessionTransactionSignature")]
            execute("PUT", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/participants/" + participant.id + "/transactions/" + transaction.id + "/signature", updatedSignature.signature).then((response) => {
                if (response) {
                    raiseOnTransactionUpdated(response);
                }
                //clear payload and close dialog...
                setPendingTransaction(null);
                progressIndicatorProps.close();

                raiseOnClose();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to update transaction signature, please try again...")
            });

        }
    }, [execute, updatedSignature, retryCounter]);

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving transaction...", handleRetry)

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">
                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <TransactionEditorForm cardroom={cardroom} participant={participant} session={session} transaction={transaction} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="outlined" color="dark" onClick={raiseOnClose}>
                                    Close
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color={values.switchToCredit || values.verified ? "dark" : "error"}
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    {isSignatureCollectionNeeded(transaction) ? "Save" : values.switchToCredit ? "Update" : values.verified ? "Verify Payment" : "Cancel Transaction"}
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>
            </Form>
        }}


    </Formik >;

};

TransactionEditor.defaultProps = {

};

TransactionEditor.propTypes = {
    cardroom: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    transaction: PropTypes.object.isRequired,
    participant: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    onTransactionUpdated: PropTypes.func
};

export default TransactionEditor;