import React from 'react';
import PropTypes from "prop-types";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { BootstrapDialog } from "components/Elements/Dialog/common"
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import FeeScheduleEditor from "./Editor";


const FeeScheduleEditorDialog = ({ open, close, cardroom, club, schedule }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Agent Fee Schedule: {schedule ? schedule.name : "<new>"}
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox mt={3}>
                <FeeScheduleEditor cardroom={cardroom} club={club} schedule={schedule} onClose={close} />
            </SoftBox>
        </DialogContent>
    </BootstrapDialog>;
}

FeeScheduleEditorDialog.defaultProps = {

};

FeeScheduleEditorDialog.propTypes = {
    cardroom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,

};

export default FeeScheduleEditorDialog;
