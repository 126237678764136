import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';

import { ErrorMessage } from "formik";
import FormField from "components/Elements/Forms/FormField";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import journalAvatar from "assets/graphics/actions/journal_256.png";
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';

const AccountTransactionJournalForm = ({ cardroom, cardroomAccount, staff, players, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, sourceAccount, targetAccount, participant, amount, journalTxType, accountBalance, note } = formField;
    const {
        type: typeV,
        amount: amountV,
        journalTxType: journalTxTypeV,
        accountBalance: accountBalanceV,
        note: noteV,
        sourceAccount: sourceAccountV,
        targetAccount: targetAccountV
    } = values;


    const [formDataInitialized, setFormDataInitialized] = useState(false);
    const [fromAccountHolderName, setFromAccountHolderName] = useState(null);
    const [participantType, setParticipantType] = useState(null);
    const [targetAccountList, setTargetAccountList] = useState(null);
    const [selectedTargetAccountIndex, setSelectedTargetAccountIndex] = useState(-1);

    function onTransactionTypeChanged(e, newValue) {
        setFieldValue(journalTxType.name, newValue);
    }

    function onTargetAccountSelectionChanged({ item, index }) {
        if (item && item.account) {
            setFieldValue(targetAccount.name, item.account.id);
            setFieldValue(participant.name, item.displayName);
            setSelectedTargetAccountIndex(index);
            setFieldValue(accountBalance.name, item.account.accountsReceivable - item.account.accountsPayable);
        }
    }

    function onAmountChanging(value) {
        if (!formDataInitialized) return;
        //if (value <= markerBalanceV && markerBalanceV > 0 && methodV != "credit") {
        //    setFieldValue(method.name, "credit");
        //}
    }

    function onTargetTypeChanged(e, newValue) {

        setParticipantType(newValue);
        let ds = players;
        if (newValue === "staff") {
            ds = staff;
        }

        setSelectedTargetAccountIndex(-1);
        setTargetAccountList(filterParticipants(ds));
        setFieldValue(targetAccount.name, "");
        setFieldValue(participant.name, "");
        setFieldValue(accountBalance.name, 0);

    }

    function filterParticipants(ds) {
        if (!ds) return ds;
        const result = [];

        for (var i = 0; i < ds.length; i++) {
            //we only want accounts that have a credit, as we can only pay those that we owe $ to...

            if (ds[i].account) {
                result.push(ds[i]);
            }
            else {
                console.warn("account missing, holder id: " + ds[i].id);
            }
        }

        if (result.length > 0){
            //sort by displayName

            result.sort(function (a, b) {
                if (a.displayName < b.displayName) { return -1; }
                if (a.displayName > b.displayName) { return 1; }
                return 0;
            });
        }

        return result;
    }

    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(type.name, "journal");
            setFieldValue(journalTxType.name, "credit");

            setParticipantType("player");

            setFieldValue(sourceAccount.name, cardroomAccount.id)
            setFromAccountHolderName(cardroom.name);
            setFieldValue(targetAccount.name, "");
            setFieldValue(participant.name, "");


            setTargetAccountList(filterParticipants(players));

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={journalAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Source Account
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={.5}
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    {fromAccountHolderName}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {journalTxType.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row={true} value={journalTxTypeV} onChange={onTransactionTypeChanged}>
                                        <FormControlLabel value="credit" control={<Radio />} label="Credit" />
                                        <FormControlLabel value="debit" control={<Radio />} label="Debit" />
                                    </RadioGroup>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={journalTxType.name} />
                                    </SoftTypography>
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        {journalTxTypeV ?
                            <>
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                Participant Type
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SoftTypography pl={1.5}
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize">
                                            <RadioGroup row={true} value={participantType} onChange={onTargetTypeChanged}>
                                                <FormControlLabel value="player" control={<Radio />} label="Player" />
                                                <FormControlLabel value="staff" control={<Radio />} label="Staff" />
                                            </RadioGroup>
                                        </SoftTypography>
                                    </Grid>
                                </Grid>

                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                Target Account
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <DataBoundSelect placeholder="Target account" valueName="id" textName="displayName"
                                            data={targetAccountList} selectedIndex={selectedTargetAccountIndex} onChange={onTargetAccountSelectionChanged} />

                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            color="error">
                                            <ErrorMessage name={targetAccount.name} />
                                        </SoftTypography>
                                    </Grid>
                                </Grid>
                                {targetAccountV ?
                                    <>
                                        <Grid container xs={12}>
                                            <Grid item xs={4} >
                                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                    >
                                                        {amount.label}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                                    type={amount.type}
                                                    name={amount.name}
                                                    value={amountV}
                                                    validate={onAmountChanging}
                                                    placeholder={amount.placeholder}
                                                    error={errors.amount && touched.amount}
                                                    success={amountV && amountV.toString().length > 0 && !errors.amount}
                                                /></Grid>
                                        </Grid>

                                        <Grid container xs={12}>
                                            <Grid item xs={4} >
                                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize">
                                                        {note.label}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormField onFocus={e => e.currentTarget.select()}
                                                    type={note.type}
                                                    name={note.name}
                                                    value={noteV}
                                                    placeholder={note.placeholder}
                                                    error={errors.note && touched.note}
                                                    success={noteV && noteV.length > 0 && !errors.note}
                                                />
                                            </Grid>
                                        </Grid>
                                    </> : null}

                            </> : null}

                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>;
};


export default AccountTransactionJournalForm;