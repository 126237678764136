import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { method, verified, note, provider, providerOther, providerRefMethod, providerRef, providerRefImage, participantSignatureImage, participantSignatureRequired },
} = form;



const validations = {

    //add logic to account for markerBalance
    editor: Yup.object().shape({
        [method.name]: Yup.string().oneOf(["cash", "electronic", "credit", "account"]).required(method.errorMsg),
        [providerRefMethod.name]: Yup.string().oneOf(["number", "image"]).required(providerRefMethod.errorMessage),
        [providerRef.name]: Yup.string().when([method.name, verified.name, providerRefMethod.name], {
            is: (methodV, verifiedV, refMethodV) => methodV === "electronic" && verifiedV && refMethodV === "number",
            then: Yup.string().required(providerRef.errorMsg)
        }),
        [participantSignatureImage.name]: Yup.string().when([method.name, participantSignatureRequired.name], {
            is: (methodV, sigRequiredV) => (methodV === "credit" && sigRequiredV),
            then: Yup.string().required(participantSignatureImage.errorMsg)
        }),
        [providerRefImage.name]: Yup.string().when([method.name, verified.name, providerRefMethod.name], {
            is: (methodV, verifiedV, refMethodV) => methodV === "electronic" && verifiedV && refMethodV === "image",
            then: Yup.string().required(providerRef.errorMsg)
        })
    })
};

export default validations;