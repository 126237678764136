import * as Yup from "yup";
import "yup-phone";
import buyin from "./form";

const {
    formField: { type, amount, method, verified, note, promoType, markerBalance, availableCredit, provider, providerOther, providerRefMethod, providerRef, providerRefImage, participantSignatureImage, participantSignatureRequired, requestSignature },
} = buyin;



const validations = {

    //add logic to account for markerBalance
    buyin: Yup.object().shape({
        [amount.name]: Yup.number()
            .test("belowCreditLimit", availableCredit.errorMsg, function (value, ctx) {
                if (ctx.parent.method !== "credit") return true;

                return value <= ctx.parent.availableCredit;
            })
            .test("greaterThanZero", amount.errorMsg, function (value, ctx) {
                return value >= 0;
            }),
        [method.name]: Yup.string().oneOf(["cash", "electronic", "credit"]).required(method.errorMsg),
        [participantSignatureImage.name]: Yup.string().when([method.name, participantSignatureRequired.name, requestSignature.name], {
            is: (methodV, sigRequiredV, sigRequestV) => (methodV === "credit" && sigRequiredV && !sigRequestV),
            then: Yup.string().required(participantSignatureImage.errorMsg)
        }),
        [provider.name]: Yup.string().when([method.name], {
            is: (methodV) => (methodV === "electronic"),
            then: Yup.string().required(provider.errorMsg)
        }),
        [providerOther.name]: Yup.string().when([method.name, provider.name], {
            is: (methodV, providerV) => (methodV === "electronic" && providerV === "other"),
            then: Yup.string().required(providerOther.errorMsg)
        }),
        [providerRefMethod.name]: Yup.string().oneOf(["number", "image"]).required(providerRefMethod.errorMessage),
        [providerRef.name]: Yup.string().when([method.name, verified.name, providerRefMethod.name], {
            is: (methodV, verifiedV, refMethodV) => methodV === "electronic" && verifiedV && refMethodV === "number",
            then: Yup.string().required(providerRef.errorMsg)
        }),
        [providerRefImage.name]: Yup.string().when([method.name, verified.name, providerRefMethod.name], {
            is: (methodV, verifiedV, refMethodV) => methodV === "electronic" && verifiedV && refMethodV === "image",
            then: Yup.string().required(providerRef.errorMsg)
        })
        // [providerRefImage.name]: Yup.string().when([verified.name, providerRef.name], {
        //     is: (verifiedV, providerRefV) => verifiedV && !providerRefV,
        //     then: Yup.string().required(providerRefImage.errorMsg)
        // })


        //[participantSignatureImage.name]: Yup.string().nullable().required(participantSignatureImage.errorMsg),
        //todo: add custom validation for providerOther and provider if "eWallet" & "other" are chosen
        //add logic to ensure signature is collected if player sig is required
    }),
    cashout: Yup.object().shape({
        [amount.name]: Yup.number().min(0),
        [method.name]: Yup.string().oneOf(["cash", "electronic", "credit"]).when([markerBalance.name, amount.name], {
            is: (balanceV, amountV) => amountV <= balanceV,
            then: Yup.string().oneOf(["credit"], "'credit' must be selected")
        }),
        [provider.name]: Yup.string().when([method.name], {
            is: (methodV) => (methodV === "electronic"),
            then: Yup.string().required(provider.errorMsg)
        }),
        [providerOther.name]: Yup.string().when([method.name, provider.name], {
            is: (methodV, providerV) => (methodV === "electronic" && providerV === "other"),
            then: Yup.string().required(providerOther.errorMsg)
        }),
        [providerRefMethod.name]: Yup.string().oneOf(["number", "image"]).required(providerRefMethod.errorMessage),
        [providerRef.name]: Yup.string().when([method.name, verified.name, providerRefMethod.name], {
            is: (methodV, verifiedV, refMethodV) => methodV === "electronic" && verifiedV && refMethodV === "number",
            then: Yup.string().required(providerRef.errorMsg)
        }),
        [providerRefImage.name]: Yup.string().when([method.name, verified.name, providerRefMethod.name], {
            is: (methodV, verifiedV, refMethodV) => methodV === "electronic" && verifiedV && refMethodV === "image",
            then: Yup.string().required(providerRef.errorMsg)
        })
    }),
    promo: Yup.object().shape({
        [amount.name]: Yup.number().min(0).required(amount.errorMsg),
        [method.name]: Yup.string().oneOf(["cash", "electronic", "credit"]).required(method.errorMsg),
        [promoType.name]: Yup.string().oneOf(["chips", "credit"]).required(promoType.errorMsg),
        [note.name]: Yup.string().required()
    }),
    settle: Yup.object().shape({
        [amount.name]: Yup.number().min(0).required(amount.errorMsg)
            .test("maxMarkerBalance", "Amount cannot exceed player current balance", function (value, ctx) {
                return Math.abs(ctx.parent.markerBalance) >= value;
            }),
        [method.name]: Yup.string().oneOf(["cash", "electronic", "credit"]).required(method.errorMsg),
        [provider.name]: Yup.string().when([method.name], {
            is: (methodV) => (methodV === "electronic"),
            then: Yup.string().required(provider.errorMsg)
        }),
        [providerOther.name]: Yup.string().when([method.name, provider.name], {
            is: (methodV, providerV) => (methodV === "electronic" && providerV === "other"),
            then: Yup.string().required(providerOther.errorMsg)
        }),
        [providerRefMethod.name]: Yup.string().oneOf(["number", "image"]).required(providerRefMethod.errorMessage),
        [providerRef.name]: Yup.string().when([method.name, verified.name, providerRefMethod.name], {
            is: (methodV, verifiedV, refMethodV) => methodV === "electronic" && verifiedV && refMethodV === "number",
            then: Yup.string().required(providerRef.errorMsg)
        }),
        [providerRefImage.name]: Yup.string().when([method.name, verified.name, providerRefMethod.name], {
            is: (methodV, verifiedV, refMethodV) => methodV === "electronic" && verifiedV && refMethodV === "image",
            then: Yup.string().required(providerRef.errorMsg)
        })
    }),
    payout: Yup.object().shape({
        [amount.name]: Yup.number().min(1, "Amount must be greater than 0")
            .test("between 1 and balance", "Amount cannot exceed your current balance", function (value, ctx) {
                if (ctx.parent.markerBalance > 0) return true;

                return value <= Math.abs(ctx.parent.markerBalance);
            }),
        [provider.name]: Yup.string().when([method.name], {
            is: (methodV) => (methodV === "electronic"),
            then: Yup.string().required(provider.errorMsg)
        }),
        [providerOther.name]: Yup.string().when([method.name, provider.name], {
            is: (methodV, providerV) => (methodV === "electronic" && providerV === "other"),
            then: Yup.string().required(providerOther.errorMsg)
        }),
        [providerRef.name]: Yup.string().when([method.name, providerRefMethod.name], {
            is: (methodV, refMethodV) => methodV === "electronic" && refMethodV === "number",
            then: Yup.string().required(providerRef.errorMsg)
        }),
        [providerRefImage.name]: Yup.string().when([method.name, providerRefMethod.name], {
            is: (methodV, refMethodV) => methodV === "electronic" && refMethodV === "image",
            then: Yup.string().required(providerRef.errorMsg)
        })
    }),
};

export default validations;