/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Custom styles for the SoftDropzone
import SoftDropzoneRoot from "components/SoftDropzone/SoftDropzoneRoot";

function SoftDropzone({ options, onDropZoneCreated }) {
    const dropzoneRef = useRef();

    useEffect(() => {
        Dropzone.autoDiscover = false;

        function createDropzone() {
            return new Dropzone(dropzoneRef.current, { ...options });
        }

        function removeDropzone() {
            if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz) => dz.destroy());
        }

        const dz = createDropzone();
        
        if (onDropZoneCreated) {
            onDropZoneCreated(dz);
        }

        return () => removeDropzone();
    }, [options]);

    return (
        <SoftDropzoneRoot
            component="form"
            action="/file-upload"
            ref={dropzoneRef}
            className="form-control dropzone"
        >
            <SoftBox className="fallback">
                <SoftBox component="input" name="file" type="file" multiple />
            </SoftBox>
        </SoftDropzoneRoot>
    );
}

// Typechecking props for the SoftDropzone
SoftDropzone.propTypes = {
    options: PropTypes.objectOf(PropTypes.any).isRequired,
    onDropZoneCreated: PropTypes.func
};

export default SoftDropzone;
