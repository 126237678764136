
import React, { useState, useEffect } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BootstrapDialog } from "components/Elements/Dialog/common"

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { Grid } from '@mui/material';

const Privacy = ({ }) => {
    const [document, setDocument] = useState(null);
    const [retryCounter, setRetryCounter] = useState(0);

    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading privacy policy...", handleProgressRetry)

    const loadPrivacyData = async () => {
        progressIndicatorProps.setMode("wait");
        progressIndicatorProps.setMessage("Loading privacy policy...")
        showProgress();

        const response = await fetch("https://gambooler.z1.web.core.windows.net/b2c/docs/privacy.json"
        ).then((response) => response.json())
            .catch(error => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load privacy policy, please try again...")
            });
        if (response) {
            setDocument(response);
        }
        progressIndicatorProps.close();
    };

    useEffect(() => {
        if (!document) {
            loadPrivacyData();
        }
    }, [document, retryCounter])


    return <>
        <ProgressIndicator {...progressIndicatorProps} />
        {!progressIndicatorProps.visible ? <>
            {document ? <>

                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <SoftTypography variant="h5">
                            {document.heading}
                        </SoftTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <SoftBox component="ul" mt={3}>
                            {document.sections.map((section) => (
                                <SoftBox component="li" px={2} lineHeight={1}>

                                    <SoftTypography variant="button" fontWeight="regular" color="text">
                                        {section}
                                    </SoftTypography>

                                </SoftBox>
                            ))}
                        </SoftBox>
                    </Grid>
                </Grid>

            </> : null}
        </> : null}
    </>;
};

const PrivacyDialog = ({ open, close }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            onClose={close}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="xl"
        >
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
                <SoftBox>
                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        Privacy Policy
                    </SoftTypography>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>
                <SoftBox mt={3}>
                    <Privacy />
                </SoftBox>
            </DialogContent>
        </BootstrapDialog>
    );
};

export default PrivacyDialog;