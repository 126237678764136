import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import wavesWhite from "assets/images/shapes/waves-white.svg";

import { useDialog } from "components/Elements/Dialog/common";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import MiniActionCard from "components/Elements/MiniActionCard";


import { useCardroomContext } from 'features';
import ReportingPeriods from 'layouts/Club/components/Reports/History';
import FeeScheduleEditorDialog from 'layouts/Club/components/AgentFees';
import CardroomLogo from 'components/Elements/CardroomLogo';
import AgencyPlayers from '../AgencyPlayers';
import AgencyAgents from '../AgencyAgents';



function AgencyDetails({ cardroom }) {

    const TabPanel = function (props) {
        const { children, value, index, ...other } = props;
        return (
            <div role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}>
                {value === index && (
                    <SoftBox >
                        <SoftTypography>{children}</SoftTypography>
                    </SoftBox>
                )}
            </div>
        );
    }

    const a11yProps = function (index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    const [cardroomDetailsData, setCardroomDetailsData] = useState(null);
    const [agency, setAgency] = useState(null);

    const cardroomRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading agency details...", handleRetry)

    const [context, actions, features] = useCardroomContext(cardroom);

    useEffect(() => {
        if (!cardroomDetailsData || cardroomDetailsData.id != cardroom.id) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino details...")
            showProgress();
            cardroomRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id).then((response) => {
                setCardroomDetailsData(response);
                //setCardroomStats(null);
                //progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino details, please try again...")
            });
        }
    }, [cardroomRequest.execute, cardroomDetailsData, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (cardroomDetailsData && !agency) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading agency info...")
            showProgress();

            clubRequest.execute("GET", apiConfig.club.endpoint + "/agency/casino/" + cardroom.id).then((response) => {

                setAgency(response);

                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load agency info, please try again...")
            });
        }
    }, [clubRequest.execute, agency, cardroomDetailsData, retryCounter]) //cardroomListData 


    const [openFeeScheduleEditorDialog, openFeeScheduleEditorDialogProps] = useDialog();

    function canManagePlayerSchedules() {
        return true;
    }


    return <>
        {cardroomDetailsData && !progressIndicatorProps.visible ? <>
            {canManagePlayerSchedules() ? <FeeScheduleEditorDialog {...openFeeScheduleEditorDialogProps} cardroom={cardroom} club={agency} /> : null}
            <SoftBox mb={3} p={1}>
                <SoftBox
                    position="relative"
                    bgColor="secondary"
                    py={3}
                    px={{ xs: 3, sm: 6, md: 1 }}
                    mt={3}
                    borderRadius="xl"
                    variant="gradient" >

                    <SoftBox
                        component="img"
                        src={wavesWhite}
                        alt="pattern-line"
                        width="100%"
                        position="absolute"
                        left="0"
                        top="0"
                        opacity={0.4}
                    />

                    <Grid container alignItems="center" position="relative">

                        <CardroomLogo cardroom={cardroomDetailsData} agency={agency} canOpenProperties={true} />

                        <Grid item xs={12} lg={11}>
                            <Grid container>
                                <Grid item xs={6} lg={3} p={1} >
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Agent ID", fontWeight: "medium" }}
                                        count={{ text: agency ? agency.appAgentId : "", useNumberFormatter: false }}
                                        icon={{ component: "verified" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Clubs", fontWeight: "medium" }}
                                        count={{ text: agency ? agency.clubCount : 0, color: "success", useNumberFormatter: true }}
                                        icon={{ component: "credit_card" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Players", fontWeight: "medium" }}
                                        count={{ text: agency ? agency.playerCount : 0, color: "success", useNumberFormatter: true }}
                                        icon={{ component: "group" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Agents", fontWeight: "medium" }}
                                        count={{ text: agency ? agency.agentCount : 0, color: "success", useNumberFormatter: true }}
                                        icon={{ color: "info", component: "support_agent" }}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>

            <SoftBox mb={3} p={1}>
                <Grid container spacing={1.5} alignItems="center">
                    <Grid item xs={12} md={6} lg={4}>
                        <AppBar position="static">
                            <SoftBox sx={{ ml: 3 }}>

                                {false && tabValue === 2 && canManagePlayerSchedules() ? <SoftButton variant="outlined" color="info" onClick={openFeeScheduleEditorDialog}><Icon size="lg">percent</Icon>&nbsp;Manage Player Schedules</SoftButton> : null}
                            </SoftBox>
                        </AppBar>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
                        <AppBar position="static">
                            <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue} sx={{ background: "transparent" }}>
                                <Tab label="Reports" icon={<Icon fontSize="small" color="inherit">summarize</Icon>}  {...a11yProps(0)} />
                                <Tab label="Players" icon={<Icon fontSize="small" color="inherit">group</Icon>} {...a11yProps(1)} />
                                <Tab label="Agents" icon={<Icon fontSize="small" color="inherit">support_agent</Icon>} {...a11yProps(2)} />
                                {/*<Tab label="Debt Service" icon={<Icon fontSize="small" color="inherit">payments</Icon>}  {...a11yProps(3)} />*/}
                            </Tabs>
                        </AppBar>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>
                        <SoftBox>
                            <TabPanel value={tabValue} index={0}>
                                <ReportingPeriods cardroom={cardroom} agency={agency} audience="agency" />
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <AgencyPlayers cardroom={cardroom} agency={agency} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <AgencyAgents cardroom={cardroom} agency={agency} />
                            </TabPanel>

                        </SoftBox>
                    </Grid>
                </Grid>
            </SoftBox></> : <ProgressIndicator {...progressIndicatorProps} />}
    </>;
}

export default AgencyDetails;