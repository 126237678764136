import form from "./form";


const {
    formField: {
        agentId,
        feeSchedule
    },
} = form;

const initialValues = {
    [agentId.name]: "",
    [feeSchedule.name]: ""
};

export default initialValues;
