
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import { NumericFormat } from "react-number-format";

const MoneyCell = ({ value, prefix, suffix, useColorScheme, inheritColor }) => {

    let formattedPrefix = prefix;
    let formattedSuffix = suffix;
    let formattedValue = value;

    let color = inheritColor ? "" : "text";
    if (useColorScheme) {
        if (value > 0) {
            color = "success";
        }
        else if (value < 0) {
            color = "error";
            formattedPrefix = "(" + prefix;
            formattedSuffix = ")";
            formattedValue = -1 * formattedValue;
        }
    }
    function formatCurrency(value) {
        return <>{formattedPrefix}{value}{formattedSuffix}</>
    }
    //prefix={formattedPrefix} suffx={formattedSuffix}
    return (
        <SoftTypography variant="caption" fontWeight="medium" color={color}>
            <NumericFormat value={formattedValue} displayType="text" thousandSeparator={true} renderText={formatCurrency} />
        </SoftTypography>
    );
}

// Setting default values for the props of DefaultCell
MoneyCell.defaultProps = {
    prefix: "$",
    suffix: "",
    useColorScheme: true,
    inheritColor: false
};

// Typechecking props for the DefaultCell
MoneyCell.propTypes = {
    value: PropTypes.number.isRequired,
    useColorScheme: PropTypes.bool,
    prefix: PropTypes.string,
    suffix: PropTypes.string
};

export default MoneyCell;
