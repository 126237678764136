
const form = {
    formId: "invitation-editor-form",
    formField: {        
        invitationId: {
            name: "invitationId",
            label: "schedule id",
            type: "hidden"
        },
        sendTo: {
            name: "sendTo",
            label: "Address",
            type: "text",
            placeholder: "address",
            errorMsg: "Address is required.",
        },
        protocol: {
            name: "protocol",
            label: "Contact Method",
            type: "number",
            placeholder: "contact method",
            errorMsg: "Contact method is required.",
        },
        role: {
            name: "role",
            label: "User Role",
            type: "number",
            placeholder: "user role",
            errorMsg: "User role is required",
        }
    },

};

export default form;
