/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import search from "./form";

import newParticipant from "assets/graphics/icons/participant_add.svg";

const {
    formField: {
        searchCriteria,
        candidate,
        candidateId,
        partnerId,
        candidateState,
        name,
        nickName,
        phoneNumber,
        creditLimit,
        withholdingRate,
        withholdingSchedule,
        withholdingThreshold,
        photo,
        mask,
        mustSign,
        mustNotify,
        notificationMask,
        initialBalance,
        initialBalanceType,
        isAnonymous
    },
} = search;

const initialValues = {
    [searchCriteria.name]: "",
    [candidate.name]: null,
    [candidateId.name]: "",
    [partnerId.name]: "",
    [candidateState.name]: "new",
    [name.name]: "",
    [nickName.name]: "",
    [phoneNumber.name]: "",
    [creditLimit.name]: 0,
    [withholdingRate.name]: 0,
    [withholdingThreshold.name]: 0,
    [withholdingSchedule.name]: "none",
    [photo.name]: newParticipant,
    [mask.name]: 1,
    [mustSign.name]: false,
    [mustNotify.name]: false,
    [notificationMask.name]: 97,
    [initialBalance.name]: 0,
    [initialBalanceType.Name]: "none",
    [isAnonymous.name]: false
};

export default initialValues;
