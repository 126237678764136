import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import FormField from "components/Elements/Forms/FormField";

import defaultTxAvatar from "assets/graphics/casino/rake_256.png";


const RakeForm = ({ cardroom, session, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, amount, tableNo } = formField;
    const {
        type: typeV,
        amount: amountV,
        tableNo: tableNoV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);
    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(type.name, "rake");

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    return <SoftBox minHeight="190px">
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={defaultTxAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {tableNo.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()}
                                    type={tableNo.type}
                                    name={tableNo.name}
                                    value={tableNoV}
                                    placeholder={tableNo.placeholder}
                                    error={errors.tableNo && touched.tableNo}
                                    success={tableNoV && tableNoV.toString().length > 0 && !errors.tableNo}
                                /></Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Rake Collected
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                    type={amount.type}
                                    name={amount.name}
                                    value={amountV}
                                    placeholder={amount.placeholder}
                                    error={errors.amount && touched.amount}
                                    success={amountV && amountV.toString().length > 0 && !errors.amount}
                                /></Grid>
                        </Grid>
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>

};



export default RakeForm;