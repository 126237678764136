
const form = {
    formId: "agent-profile-editor-form",
    formField: {
        agentId: {
            name: "agentId",
            label: "Agent",
            type: "hidden",
            errorMsg: "an agent must be selected"
        },
        feeSchedule: {
            name: "feeSchedule",
            label: "Commission Schedule",
            type: "text",
            placeholder: "commission schedule",
            errorMsg: "Commission schedule is required",
        }
    },

};

export default form;
