/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import "yup-phone";
import search from "./form";

const {
    formField: { searchCriteria, candidate, candidateId, name, nickName, phoneNumber, creditLimit, initialBalance, withholdingRate, withholdingThreshold, withholdingSchedule, photo, mask, partnerId, isAnonymous },
} = search;

const validations = [
    Yup.object().shape({
        //[searchCriteria.name]: Yup.string().min(4).required(searchCriteria.errorMsg), //step one items
        [searchCriteria.name]: Yup.string().when([candidateId.name], {
            is: (candidateIdV) => !candidateIdV,
            then: Yup.string().min(4).required(searchCriteria.errorMsg)
        }),
        [candidate.name]: Yup.object().required(candidate.errorMsg)
    }),
    Yup.object().shape({
        //        [phoneNumber.name]: Yup.string().phone("US", phoneNumber.errorMsg).length(10, phoneNumber.errorMsg).required(phoneNumber.errorMsg),
        [phoneNumber.name]: Yup.string().when([isAnonymous.name], {
            is: (isAnonymousV) => !isAnonymousV,
            then: Yup.string().phone("US", phoneNumber.errorMsg).length(10, phoneNumber.errorMsg).required(phoneNumber.errorMsg)
        }),
        [name.name]: Yup.string().min(2, phoneNumber.errorMsg).required(name.errorMsg),
        [creditLimit.name]: Yup.number().min(0, "Credit limit must be a positive number.").required(creditLimit.errorMsg),
        [withholdingRate.name]: Yup.number().min(0, withholdingRate.errorMsg).max(100, withholdingRate.errorMsg).required(withholdingRate.errorMsg),
        [withholdingThreshold.name]: Yup.number().min(0, "Withholding exemption must be greater than or equal to 0.").required(withholdingThreshold.errorMsg),
        [withholdingSchedule.name]: Yup.string().oneOf(["none", "flat", "progressive"], "Withholding type must be 'none', 'flat', or 'progressive'").required(withholdingThreshold.errorMsg),
        [mask.name]: Yup.number().min(1, mask.errorMsg).required(mask.errorMsg),
        [partnerId.name]: Yup.string().required(partnerId.errorMsg),
        [initialBalance.name]: Yup.number().min(0, "Existing balance must be a non-negative number").required(initialBalance.errorMsg)
    }),
    Yup.object().shape({
        //step 3 items
    }),
];

export default validations;
