export const apiConfig = {
    casino: {
        endpoint: 'https://local.api.gambooler.com/casino/Casino',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/769bf3b7-e34c-4266-a85f-7c2a79c397ba/Casino.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/769bf3b7-e34c-4266-a85f-7c2a79c397ba/Casino.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/769bf3b7-e34c-4266-a85f-7c2a79c397ba/Casino.User.Manage']
        },
    },
    user: {
        endpoint: 'https://local.api.gambooler.com/user/User',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/73ae78a2-e9bc-43ee-9b67-0ac301e2f10a/User.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/73ae78a2-e9bc-43ee-9b67-0ac301e2f10a/User.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/73ae78a2-e9bc-43ee-9b67-0ac301e2f10a/User.User.Manage']
        }
    },
    account:{
        endpoint: 'https://local.api.gambooler.com/account/account',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/cdea3cc6-948a-48e7-9b55-f7b8244de706/Account.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/cdea3cc6-948a-48e7-9b55-f7b8244de706/Account.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/cdea3cc6-948a-48e7-9b55-f7b8244de706/Account.User.Manage']
        }
    },
    people: {
        endpoint: 'https://local.api.gambooler.com/people/People',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/e48665f2-534c-4523-b905-987849ebdd85/People.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/e48665f2-534c-4523-b905-987849ebdd85/People.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/e48665f2-534c-4523-b905-987849ebdd85/People.User.Manage']
        },
    },
    signature: {
        endpoint: 'https://gambooler-signature-api.wonderfulrock-496476de.westus3.azurecontainerapps.io/Signature',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/a428fb80-6844-461a-9ef6-42bea6ab1339/Signature.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/a428fb80-6844-461a-9ef6-42bea6ab1339/Signature.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/a428fb80-6844-461a-9ef6-42bea6ab1339/Signature.User.Manage']
        },
    },
    club:{
        endpoint: 'https://local.api.gambooler.com/club',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/47fe4f93-d59b-437b-b5c4-dda7616f59f3/Club.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/47fe4f93-d59b-437b-b5c4-dda7616f59f3/Club.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/47fe4f93-d59b-437b-b5c4-dda7616f59f3/Club.User.Manage']
        },
    },
    ingest: {
        endpoint: 'https://gambooler-club-ingestion.wonderfulrock-496476de.westus3.azurecontainerapps.io/Ingestion',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/46323466-aa8d-4cef-ac52-7313d6c23441/Club.Ingest.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/46323466-aa8d-4cef-ac52-7313d6c23441/Club.Ingest.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/46323466-aa8d-4cef-ac52-7313d6c23441/Club.Ingest.User.Manage']
        },
    },
    images: {
        endpoint: 'https://gambooler.blob.core.windows.net/public'
    }
};