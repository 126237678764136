import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { candidateId, partnerId, name, nickName, phoneNumber, withholdingRate, withholdingThreshold, withholdingSchedule, mustNotify, notificationMask, isAnonymous, photo, mask },
} = form;



const validations = {

    //add logic to account for markerBalance
    editor: Yup.object().shape({
        [candidateId.name]: Yup.string().required("Player context must be set"),
        [name.name]: Yup.string().required(name.errorMsg),
        [phoneNumber.name]: Yup.string().when([isAnonymous.name], {
            is: (isAnonymousV) => !isAnonymousV,
            then: Yup.string().phone("US", phoneNumber.errorMsg).length(10, phoneNumber.errorMsg).required(phoneNumber.errorMsg)
        }),
        [mask.name]: Yup.number().required(),
        [notificationMask.name]: Yup.number().required(),
        [withholdingRate.name]: Yup.number().min(0, "Withholding rate must be 0-100%.").max(100, "Withholding rate must be 0-100%.").required(withholdingRate.errorMsg),
        [withholdingThreshold.name]: Yup.number().min(0, "Withholding exemption must be greater than or equal to 0.").required(withholdingThreshold.errorMsg),
        [withholdingSchedule.name]: Yup.string().oneOf(["none", "flat", "progressive"], "Withholding type must be 'none', 'flat', or 'progressive'").required(withholdingThreshold.errorMsg),
        [partnerId.name]: Yup.string().required(partnerId.errorMsg),
    }),

};

export default validations;