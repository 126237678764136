
const form = {
    formId: "transaction-editor-form",
    formField: {
        verified: {
            name: "verified",
            label: "Payment received and verified",
            type: "bool",
            placeholder: "",
            errorMsg: "",
        },
        method: {
            name: "method",
            label: "Payment method",
            type: "text",
            placeholder: "payment method",
            errorMsg: "Payment method is required",
        },
        verified: {
            name: "verified",
            label: "Payment received and verified",
            type: "bool",
            placeholder: "",
            errorMsg: "",
        },
        switchToCredit:{
            name: "switchToCredit",
            label: "Switch to credit",
            type: "bool",
            placeholder: "",
            errorMsg: "",
        },
        provider: {
            name: "provider",
            label: "e-Wallet provider",
            type: "text",
            placeholder: "eWallet provider",
            errorMsg: "eWallet provider is required when using eWallet payment method"
        },
        providerOther: {
            name: "providerOther",
            label: "e-Wallet vendor name",
            type: "text",
            placeholder: "eWallet vendor name",
            errorMsg: "eWallet vendor name is required when using eWallet payment method with 'other' provider"
        },
        providerRefMethod:{
            name: "providerRefMethod",
            label: "Vendor reference type",
            type: "text",
            placeholder: "vendor reference type",
            errorMsg: "Vendor reference type is required when payment is verified"
        },
        providerRef: {
            name: "providerRef",
            label: "Vendor reference #",
            type: "text",
            placeholder: "vendor reference #",
            errorMsg: "Vendor reference is required when payment is verified"
        },
        providerRefImage: {
            name: "providerRefImage",
            label: "Vendor reference document",
            type: "hidden",
            placeholder: "vendor reference doc",
            errorMsg: "Vendor ref document is required when using eWallet"
        },
        participantSignatureImage: {
            name: "participantSignatureImage",
            label: "Participant signature",
            type: "hidden",
            placeholder: "participant signature",
            errorMsg: "Participant signature is required for this transaction"
        },
        participantSignatureRequired: {
            name: "participantSignatureRequired",
            type: "bool"
        },
        requestSignature:{
            name: "requestSignature",
            label: "Request player signature via SMS",
            type: "bool",
            placeholder: "",
            errorMsg: ""
        },
        note: {
            name: "note",
            label: "Note",
            type: "text",
            placeholder: "Note",
            errorMsg: "Note is required"
        }
    },

};

export default form;
