import React, { useEffect, useState } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { Routes, Route, Navigate, Link, useLocation } from "react-router-dom";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";

import Alert, { useAlert } from "components/Elements/Notifications/Alert";

import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftSnackbar from "components/SoftSnackbar";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftDatePicker from "components/SoftDatePicker";

import FormField from "components/Elements/Forms/FormField";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BootstrapDialog, useDialog } from "components/Elements/Dialog/common"

import { Formik, Form, ErrorMessage } from "formik";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import FormSwitch from 'components/Elements/Forms/FormSwitch';
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { useCardroomContext } from 'features';
import avatarLogo from 'assets/graphics/casino/AA-chips_256.png'

const NewSessionDataEntryForm = ({ cardroom, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;

    const { schedule, scheduleDate, scheduleTime, copyPlayers, copyPlayersFromSession } = formField;

    const {
        schedule: scheduleV,
        scheduleDate: scheduleDateV,
        scheduleTime: scheduleTimeV,
        copyPlayers: copyPlayersV,
        copyPlayersFromSession: copyPlayersFromSessionV
    } = values;

    const [recentSessions, setRecentSessions] = useState(null);

    function onSessionSelectionChanged({ item, index }) {
        setFieldValue(copyPlayersFromSession.name, item.id);
    }

    function onCopyPlayersOptionsChanged(e, newValue) {
        setFieldValue(copyPlayers.name, newValue);
    }

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    useEffect(() => {
        if (!recentSessions && copyPlayersV === "session") {
            execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/recent/10").then((response) => {
                if (response) {
                    const list = [];
                    for (var i = 0; i < response.length; i++) {
                        let name = new Date(Date.parse(response[i].startedOn + "Z")).toLocaleDateString();
                        if (response[i].endedOn) {
                            name += " - " + new Date(Date.parse(response[i].endedOn + "Z")).toLocaleDateString();
                        }
                        else {
                            name = "Scheduled for: " + name;
                        }
                        list.push({
                            id: response[i].id,
                            startDate: new Date(Date.parse(response[i].startedOn)),
                            name: name
                        });
                    }
                    list.sort(function (a, b) { return b.startDate - a.startDate });
                    setRecentSessions(list);
                }
            }).catch((ex) => {
                //progressIndicatorProps.setMode("errorWithRetry");
                //progressIndicatorProps.setMessage("Unable to load recent games, please try again...")
            });
        }

    }, [recentSessions, copyPlayersV, execute]);

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={avatarLogo} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">


                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {schedule.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>

                                <FormSwitch mr="auto"
                                    name={schedule.name}
                                    value={scheduleV}
                                    {...formData} />

                            </Grid>
                        </Grid>

                        {scheduleV ?
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {scheduleDate.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField
                                        type={scheduleDate.type}
                                        name={scheduleDate.name}
                                        value={scheduleDateV}
                                        placeholder={scheduleDate.placeholder}
                                        error={errors.scheduleDate && touched.scheduleDate}
                                        success={scheduleDateV && scheduleDateV.toString().length > 0 && !errors.scheduleDate}
                                    />
                                    <FormField
                                        type={scheduleTime.type}
                                        name={scheduleTime.name}
                                        value={scheduleTimeV}
                                        placeholder={scheduleTime.placeholder}
                                        error={errors.scheduleTime && touched.scheduleTime}
                                        success={scheduleTimeV && scheduleTimeV.toString().length > 0 && !errors.scheduleTime}
                                    />
                                </Grid>
                            </Grid> : null}

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {copyPlayers.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <RadioGroup row value={copyPlayersV} defaultValue="none" onChange={onCopyPlayersOptionsChanged} style={{ marginLeft: ".75em" }}>
                                    <FormControlLabel value="none" control={<Radio />} label="None" />
                                    <FormControlLabel value="session" control={<Radio />} label="Prior Session" />
                                    <FormControlLabel value="casino" control={<Radio />} label="Everyone" />
                                </RadioGroup>
                            </Grid>
                        </Grid>

                        {copyPlayersV === "session" ?
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {copyPlayersFromSession.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <DataBoundSelect placeholder={copyPlayersFromSession.placeholder} valueName="id" textName="name" data={recentSessions} onChange={onSessionSelectionChanged} />

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={copyPlayersFromSession.name} />
                                    </SoftTypography>
                                </Grid>
                            </Grid> : null}


                        <FormField type="hidden"
                            name={copyPlayersFromSession.name}
                            value={copyPlayersFromSessionV} />

                        <FormField type="hidden"
                            name={copyPlayers.name}
                            value={copyPlayersV} />
                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;

};

const NewSessionForm = ({ cardroom, onClose, onSessionCreated }) => {

    const { formId, formField } = form;
    const currentValidation = validations.newSession;

    const [retryCounter, setRetryCounter] = useState(0);
    const [newSession, setNewSession] = useState(null);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Creating session...", handleRetry)

    const handleSubmit = (values, actions) => {


        let d = null;
        if (values.schedule && values.scheduleDate && values.scheduleTime) {
            d = new Date(Date.parse(values.scheduleDate));
            d = new Date(Date.parse(d.toDateString() + " " + values.scheduleTime));
        }

        let inviteFlag = 1;
        if (values.copyPlayers === "session") inviteFlag = 2;
        if (values.copyPlayers === "casino") inviteFlag = 3;

        var session =
        {
            status: values.schedule ? 1 : 2,
            startedOn: values.schedule && d ? d.toISOString() : new Date(Date.now()).toISOString(),
            priorSessionId: inviteFlag === 2 ? values.copyPlayersFromSession : "00000000-0000-0000-0000-000000000000",
            autoInvite: inviteFlag
        };

        setNewSession(session);
    };


    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.write,
    });

    useEffect(() => {
        if (newSession) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Creating session...")
            showProgress();

            execute("POST", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions", newSession).then((response) => {

                if (response) {

                    setNewSession(null);
                    progressIndicatorProps.close();

                    if (onSessionCreated) onSessionCreated(response);
                    raiseOnClose();
                }

            }).catch((err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to create session, please try again...")
            });

        }
    }, [execute, newSession, retryCounter]);


    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <NewSessionDataEntryForm cardroom={cardroom} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    ok
                                </SoftButton>
                            </SoftBox>
                        </>
                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

const NewSessionDialog = ({ open, close, cardroom, onSessionCreated }) => {

    //TODOD: CLOSE IS NOT BEING SENT IN... WHY...

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen


    function onCloseRequested() {
        close();
    }


    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="md">
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
                <SoftBox>
                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        New Session
                    </SoftTypography>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>
                <SoftBox mt={3}>
                    <NewSessionForm cardroom={cardroom} onClose={onCloseRequested} onSessionCreated={onSessionCreated} />
                </SoftBox>
            </DialogContent>
        </BootstrapDialog>
    );
};

const NewSessionToolbar = ({ cardroom, onSessionCreated }) => {

    const [openNewSessionDialog, newSessionDialogProps] = useDialog();

    const [sessionCreatedSuccees, setSessionCreatedSuccess] = useState(false);
    const [currentSession, setCurrentSession] = useState(null);

    function handleSessionCreated(session) {
        if (session) {
            //cardroom.currentSession = session;
            setCurrentSession(session);
            setSessionCreatedSuccess(true);

            if (onSessionCreated) {
                onSessionCreated(session);
            }
        }
    }

    const [context, actions, features] = useCardroomContext(cardroom);

    function canStartNewSession() {
        if (!context) return false;
        return context.isAllowed(actions.casino.session.create);
    }

    return <>
        {sessionCreatedSuccees && currentSession && currentSession.status === 2 ? <Navigate to="/dashboards/cardroom/session" state={{ cardroom: cardroom, session: currentSession }} /> :
            canStartNewSession() ? <>
                <SoftButton variant="outlined" size="small" color="dark" onClick={openNewSessionDialog}>
                    <Icon size="large" color="inherit">games</Icon>&nbsp;New Game
                </SoftButton >
                <NewSessionDialog  {...newSessionDialogProps} cardroom={cardroom} onSessionCreated={handleSessionCreated} />
            </> : null
        }
    </>;

};


export default NewSessionToolbar;