
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';

import { ErrorMessage } from "formik";
import FormField from "components/Elements/Forms/FormField";
import FormSwitch from "components/Elements/Forms/FormSwitch";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import chipsAvatar from "assets/graphics/casino/cash_256.png";
import { NumericFormat } from 'react-number-format';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import FormImageField from 'components/Elements/Forms/FormImageField';
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';



const AccountTransactionReceiveForm = ({ cardroom, cardroomAccount, staff, players, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, sourceAccount, targetAccount, participant, amount, method, accountBalance, note, provider, providerOther, providerRef, providerRefMethod, providerRefImage, } = formField;
    const {
        type: typeV,
        amount: amountV,
        method: methodV,
        accountBalance: accountBalanceV,
        provider: providerV,
        providerOther: providerOtherV,
        providerRef: providerRefV,
        verified: verifiedV,
        providerRefImage: providerRefImageV,
        providerRefMethod: providerRefMethodV,
        note: noteV,
        sourceAccount: sourceAccountV,
        targetAccount: targetAccountV
    } = values;

    function onPaymentMethodRadioGroupChanged(e, newValue) {
        setFieldValue(method.name, newValue);
    }

    function onVendorRefMethodRadioGroupChanged(e, newValue) {
        setFieldValue(providerRefMethod.name, newValue);
    }

    function onAmountChanging(value) {
        if (!formDataInitialized) return;
        //if (value <= markerBalanceV && markerBalanceV > 0 && methodV != "credit") {
        //    setFieldValue(method.name, "credit");
        //}
    }

    function onEWalletProviderChanged(e, newValue) {
        setFieldValue(provider.name, newValue);
    }
    function onTargetTypeChanged(e, newValue) {
        setParticipantType(newValue);
        let ds = players;
        if (newValue === "staff") {
            ds = staff;
        }

        setSelectedSourceAccountIndex(-1);
        setSourceAccountList(filterParticipants(ds));
        setFieldValue(sourceAccount.name, "");
        setFieldValue(participant.name, "");
        setFieldValue(accountBalance.name, 0);
    }

    function filterParticipants(ds) {
        if (!ds) return ds;
        const result = [];

        for (var i = 0; i < ds.length; i++) {
            //we only want accounts that have a credit, as we can only pay those that we owe $ to...
            if (ds[i].account) {
                if ((ds[i].account.accountsReceivable - ds[i].account.accountsPayable) < 0) {
                    result.push(ds[i]);
                }
            }
            else {
                console.warn("account missing, holder id: " + ds[i].id);
            }
        }

        return result;
    }

    function onSourceAccountSelectionChanged({ item, index }) {
        if (item && item.account) {
            setFieldValue(sourceAccount.name, item.account.id);
            setFieldValue(participant.name, item.displayName);
            setSelectedSourceAccountIndex(index);
            setFieldValue(accountBalance.name, item.account.accountsReceivable - item.account.accountsPayable);
        }
    }

    const [formDataInitialized, setFormDataInitialized] = useState(false);
    const [toAccountHolderName, setToAccountHolderName] = useState(null);
    const [participantType, setParticipantType] = useState(null);
    const [sourceAccountList, setSourceAccountList] = useState(null);
    const [selectedSourceAccountIndex, setSelectedSourceAccountIndex] = useState(-1);

    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(type.name, "collect");

            setFieldValue(targetAccount.name, cardroomAccount.id)
            setToAccountHolderName(cardroom.name);
            setFieldValue(sourceAccount.name, "");
            setFieldValue(participant.name, "");

            setParticipantType("player");
            setSourceAccountList(filterParticipants(players));

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={chipsAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Participant Type
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row={true} value={participantType} onChange={onTargetTypeChanged}>
                                        <FormControlLabel value="player" control={<Radio />} label="Player" />
                                        <FormControlLabel value="staff" control={<Radio />} label="Staff" />
                                    </RadioGroup>
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {sourceAccount.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <DataBoundSelect placeholder={sourceAccount.placeholder} valueName="id" textName="displayName"
                                    data={sourceAccountList} selectedIndex={selectedSourceAccountIndex} onChange={onSourceAccountSelectionChanged} />

                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    color="error">
                                    <ErrorMessage name={sourceAccount.name} />
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {targetAccount.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={.5}
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    {toAccountHolderName}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        {sourceAccountV && accountBalanceV < 0 ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {amount.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                        type={amount.type}
                                        name={amount.name}
                                        value={amountV}
                                        validate={onAmountChanging}
                                        placeholder={amount.placeholder}
                                        error={errors.amount && touched.amount}
                                        success={amountV && amountV.toString().length > 0 && !errors.amount}
                                    /></Grid>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Outstanding Debt
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>

                                    <SoftTypography pl={1.5}
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize">
                                        <MoneyCell value={parseFloat(accountBalanceV)} prefix="$" useColorScheme={true} />
                                    </SoftTypography>

                                </Grid>
                            </Grid>


                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {method.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography pl={1.5}
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize">
                                        <RadioGroup row={false} value={methodV} onChange={onPaymentMethodRadioGroupChanged}>
                                            <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                                            <FormControlLabel value="electronic" control={<Radio />} label="e-Wallet" />
                                        </RadioGroup>

                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            color="error">
                                            <ErrorMessage name={method.name} />
                                        </SoftTypography>
                                    </SoftTypography>

                                </Grid>
                            </Grid>

                            {methodV === "electronic" ? <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {provider.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography pl={1.5}
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize">
                                        <RadioGroup row value={providerV} onChange={onEWalletProviderChanged}>
                                            <FormControlLabel value="zelle" control={<Radio />} label="Zelle" />
                                            <FormControlLabel value="paypal" control={<Radio />} label="PayPal" />
                                            <FormControlLabel value="venmo" control={<Radio />} label="Venmo" />
                                            <FormControlLabel value="cashapp" control={<Radio />} label="CashApp" />
                                            <FormControlLabel value="apple" control={<Radio />} label="ApplePay" />
                                            <FormControlLabel value="other" control={<Radio />} label="Other" />
                                        </RadioGroup>

                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            color="error">
                                            <ErrorMessage name={provider.name} />
                                        </SoftTypography>
                                    </SoftTypography>
                                </Grid>
                            </Grid> : null}

                            {methodV === "electronic" && providerV === "other" ? <Grid container xs={12}>
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                {providerOther.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormField type={providerOther.type} onFocus={e => e.currentTarget.select()}
                                            name={providerOther.name}
                                            value={providerOtherV}
                                            placeholder={providerOther.placeholder}
                                            error={errors.providerOther && touched.providerOther}
                                            success={providerOtherV && providerOtherV.length > 0 && !errors.providerOther}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid> : null}


                            {methodV === "electronic" ? <>
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                {providerRefMethod.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SoftTypography
                                            variant="caption"
                                            textTransform="capitalize">
                                            <RadioGroup row={true} value={providerRefMethodV} onChange={onVendorRefMethodRadioGroupChanged} style={{ marginLeft: "0.75em" }}>
                                                <FormControlLabel value="number" control={<Radio />} label="Number" />
                                                <FormControlLabel value="image" control={<Radio />} label="Image" />
                                            </RadioGroup>
                                        </SoftTypography>
                                    </Grid>
                                </Grid>

                                {providerRefMethodV === "number" ?
                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    {providerRef.label}
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <FormField type={providerRef.type} onFocus={e => e.currentTarget.select()}
                                                name={providerRef.name}
                                                value={providerRefV}
                                                placeholder={providerRef.placeholder}
                                                error={errors.providerRef && touched.providerRef}
                                                success={providerRefV && providerRefV.toString().length > 0 && !errors.providerRef}
                                            />

                                        </Grid>
                                    </Grid> : null}

                                {providerRefMethodV === "image" ?
                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize">
                                                    {providerRefImage.label}
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <FormImageField name={providerRefImage.name} imgWidth="120px" {...formData} />
                                        </Grid>
                                    </Grid> : null}
                            </> : null}

                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {note.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField onFocus={e => e.currentTarget.select()}
                                        type={note.type}
                                        name={note.name}
                                        value={noteV}
                                        placeholder={note.placeholder}
                                        error={errors.note && touched.note}
                                        success={noteV && noteV.length > 0 && !errors.note}
                                    />
                                </Grid>
                            </Grid>
                        </> : null}

                        {selectedSourceAccountIndex > -1 && sourceAccountV && accountBalanceV >= 0 ? <Grid container xs={12}>
                            <Grid item xs={12} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        color="error"
                                        textTransform="capitalize"
                                    >
                                        Account does not have an outstandng balance
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                        </Grid> : null}
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>;

};



export default AccountTransactionReceiveForm;