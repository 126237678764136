import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

import { Formik, Form } from "formik";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import AccountTransactionPayForm from './PayForm';
import AccountTransactionReceiveForm from './ReceiveForm';
import AccountTransactionJournalForm from './AdjustementForm';




function getFormContent(type, cardroom, account, staff, players, mode, formData) {

    if (!cardroom || !staff || !players || !formData) return null;

    switch (type) {
        case "pay":
            return <AccountTransactionPayForm cardroom={cardroom} cardroomAccount={account} staff={staff} players={players} formData={formData} mode={mode} />;
        case "receive":
            return <AccountTransactionReceiveForm cardroom={cardroom} cardroomAccount={account} staff={staff} players={players} formData={formData} mode={mode} />;
        case "journal":
            return <AccountTransactionJournalForm cardroom={cardroom} cardroomAccount={account} staff={staff} players={players} formData={formData} mode={mode} />;

    }
}

const AccountTransactionForm = ({ type, cardroom, cardroomAccount, mode, onClose, onTransactionCreated }) => {

    function mapParticipantAccounts(ppl, accounts) {
        const accountMap = [];
        if (accounts && accounts.length > 0) {
            for (var i = 0; i < accounts.length; i++) {
                accountMap[accounts[i].accountHolderId] = accounts[i];
            }
        }

        const staff = [], players = [];

        if (ppl && ppl.length > 0) {
            for (var i = 0; i < ppl.length; i++) {
                ppl[i].account = accountMap[ppl[i].id];
                let phone = ppl[i].phoneNumber, name = ppl[i].name, nickName = null;

                if (ppl[i].properties) {
                    if ((ppl[i].properties.participantType & 1) > 0) {
                        players.push(ppl[i]);
                    }

                    if ((ppl[i].properties.participantType & 254) > 0) {
                        staff.push(ppl[i]);
                    }

                    phone = ppl[i].properties.phoneNumber ? ppl[i].properties.phoneNumber : phone;
                    name = ppl[i].properties.name ? ppl[i].properties.name : name;
                    nickName = ppl[i].properties.nickName ? ppl[i].properties.nickName : nickName;
                }

                if (nickName) {
                    name = name + " \"" + nickName + "\"";
                }

                if (phone) {
                    //format phone
                    let formattedPhone = phone;
                    if (phone.length === 10) {
                        formattedPhone = "+1 (" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + " - ****";
                    }

                    name = name + " @ " + formattedPhone + "";
                }
                else if (ppl[i].isAnonymous) {
                    name = name + " (anonymous)";
                }

                ppl[i].displayName = name;

                //set displayName
                //let logo = ppl[i].hasPhoto || (ppl[i].properties && ppl[i].properties.hasPhoto) ? apiConfig.images.endpoint + "/player/" + ppl[i].id + ".jpg" : ppl[i].name.substring(0, 1).toUpperCase();

            }
        }

        setStaff(staff);
        setPlayers(players);
    }

    const { formId, formField } = form;
    const currentValidation = validations[type];

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving transaction...", handleRetry)


    const [participants, setParticipants] = useState(null);
    const [accounts, setAccounts] = useState(null);
    const [players, setPlayers] = useState(null);
    const [staff, setStaff] = useState(null);
    const [pendingTransaction, setPendingTransaction] = useState(null);

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    const accountRequest = useFetchWithMsal({
        scopes: [...apiConfig.account.scopes.read, ...apiConfig.account.scopes.write],
    });

    //load casino participants
    useEffect(() => {
        if (!participants) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading participant data...")
            showProgress();

            peopleRequest.execute("GET", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants/255").then((response) => {
                if (response) {
                    setParticipants(response);
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load participant data, please try again...")
            });
        }
    }, [peopleRequest.execute, participants, retryCounter]) //cardroomListData 

    //load casino accounts
    useEffect(() => {
        if (participants && !accounts) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading participant accounts...")
            showProgress();

            // [HttpGet("{entityId:Guid}/holders/accounts/{type:int}", Name = "GetEntityAccounts")]
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/accounts/1").then((response) => {
                if (response) {
                    setAccounts(response);
                    progressIndicatorProps.close();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load participant accounts, please try again...")
            });
        }
    }, [accountRequest.execute, accounts, participants, retryCounter])


    useEffect(() => {
        if (accounts && participants) {
            mapParticipantAccounts(participants, accounts);
        }
    }, [accounts, participants])


    const handleSubmit = (values, actions) => {
        var tx = null;
        function getPaymentMethod(m) {
            if (m === "cash") return 1;
            if (m === "electronic") return 2;
        }

        function getJournalTransactionType(t) {
            if (t === "credit") return 2; //credit to player, means debit to casino
            if (t === "debit") return 1; //debit player, means credit to casino
        }

        if (values.type === "pay") {
            tx = {
                accountId: values.sourceAccount,
                accountHolderId: cardroom.id,
                transaction: {
                    sourceAccount: {
                        id: values.sourceAccount, //casino account
                        casinoId: cardroom.id,
                        name: 'Casino account'
                    },
                    targetAccount: {
                        id: values.targetAccount, //player/staff account
                        casinoId: cardroom.id,
                        name: 'Participant account'
                    },
                    description: "Payment to " + values.participant,
                    paymentMethod: getPaymentMethod(values.method),
                    type: 2, //debit to casino
                    amount: values.amount
                }
            };
        }
        else if (values.type === "collect") {
            tx = {
                accountId: values.targetAccount,
                accountHolderId: cardroom.id,
                transaction: {
                    targetAccount: {
                        id: values.sourceAccount, //player/staff account
                        casinoId: cardroom.id,
                        name: 'Participant account'
                    },
                    sourceAccount: {
                        id: values.targetAccount, //casino account
                        casinoId: cardroom.id,
                        name: 'Casino account'
                    },
                    description: "Payment from " + values.participant,
                    paymentMethod: getPaymentMethod(values.method),
                    type: 1, //credit to casino
                    amount: values.amount
                }
            };
        }
        else if (values.type === "journal") {
            tx = {
                accountId: values.sourceAccount,
                accountHolderId: cardroom.id,
                transaction: {
                    sourceAccount: {
                        id: values.sourceAccount, //casino account
                        casinoId: cardroom.id,
                        name: 'Casino account'
                    },
                    targetAccount: {
                        id: values.targetAccount, //player/staff account
                        casinoId: cardroom.id,
                        name: 'Participant account'
                    },
                    description: "Journal " + values.journalTxType,
                    paymentMethod: 3, //credit
                    type: getJournalTransactionType(values.journalTxType),
                    amount: values.amount
                }
            };
        }

        if (values.note) {
            tx.transaction.description += " (" + values.note + ")";
        }

        if (tx) {
            //set state to be saved
            setPendingTransaction(tx);
        }
    };

    useEffect(() => {
        if (pendingTransaction) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving account transaction...")
            showProgress();

            //  [HttpPost("{entityId:Guid}/holders/{holderId:Guid}/accounts/{accountId:Guid}/transactions", Name = "ProcessTransaction")]
            accountRequest.execute("POST", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + pendingTransaction.accountHolderId + "/accounts/" + pendingTransaction.accountId + "/transactions", pendingTransaction.transaction).then((response) => {
                if (response) {
                    setPendingTransaction(null);
                    progressIndicatorProps.close();
                    raiseOnTransactionCreated();
                    raiseOnClose();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save account transaction, please try again...")
            });
        }
    }, [accountRequest.execute, pendingTransaction, retryCounter]);

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnTransactionCreated() {
        if (onTransactionCreated) {
            onTransactionCreated();
        }
    }

    return !progressIndicatorProps.visible ? <>
        <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
                return <Form id={formId} autoComplete="off">

                    <SoftBox p={2} >
                        <SoftBox >
                            <ProgressIndicator {...progressIndicatorProps} />

                            {progressIndicatorProps.visible ? <></> : <>
                                {getFormContent(type, cardroom, cardroomAccount, staff, players, mode, {
                                    values,
                                    touched,
                                    formField,
                                    errors,
                                    setFieldValue,
                                    setFieldError,
                                    setFieldTouched,
                                    validateForm,
                                    resetForm
                                })}

                                <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                    <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                        cancel
                                    </SoftButton>

                                    <SoftButton
                                        variant="gradient"
                                        color="dark"
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        save
                                    </SoftButton>
                                </SoftBox>
                            </>

                            }

                        </SoftBox>
                    </SoftBox>

                </Form>
            }}
        </Formik></> : <ProgressIndicator {...progressIndicatorProps} />;
};

AccountTransactionForm.defaultProps = {
    type: "receive",
};

AccountTransactionForm.propTypes = {
    type: PropTypes.oneOf([
        "pay",
        "receive"
    ]).isRequired,
    cardroom: PropTypes.object //.isRequired
};

export default AccountTransactionForm;