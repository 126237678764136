
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Routes, Route, Navigate, Link, useLocation } from "react-router-dom";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";

import SoftTypography from "components/SoftTypography";
import MiniActionCard from "components/Elements/MiniActionCard";

import wavesWhite from "assets/images/shapes/waves-white.svg";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useDialog } from "components/Elements/Dialog/common";
import PlayerGames from './components/PlayerGames';
import PlayerTransactions from './components/PlayerTransactions';
import { PlayerEditorDialog } from './components/PlayerEditor';
import AccountingCard from 'layouts/Cardroom/Details/components/Accounting';

const PlayerDetails = ({ cardroom, id }) => {

    const TabPanel = function (props) {
        const { children, value, index, ...other } = props;
        return (
            <div role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}>
                {value === index && (
                    <SoftBox >
                        <SoftTypography>{children}</SoftTypography>
                    </SoftBox>
                )}
            </div>
        );
    }

    const a11yProps = function (index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);


    const [player, setPlayer] = useState(null)

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading player details...", handleRetry)

    const [openPlayerEditorDialog, openPlayerEditorDialogProps] = useDialog();


    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    //load cardroom players
    useEffect(() => {
        if (!player) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading player details...")
            showProgress();

            execute("GET", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants/" + id).then((response) => {
                if (response) {
                    //build formatted player object

                    var p = {};
                    p = Object.assign(p, response);

                    let name = p.properties && p.properties.name ? p.properties.name : p.name;
                    let nickName = p.properties ? p.properties.nickName : null;
                    if (nickName) {
                        name += " \"" + nickName + "\"";
                    }

                    p.formattedName = name;

                    let phone = p.properties && p.properties.phoneNumber ? p.properties.phoneNumber : p.phoneNumber;
                    if (phone && phone.length === 10) {
                        phone = "+1 (" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + "-****";
                    }
                    p.formattedPhone = phone;

                    //net to get balance info from accounting service...

                    if (p) {

                        progressIndicatorProps.setMode("wait");
                        progressIndicatorProps.setMessage("Loading player account...");
                        // [HttpGet("{entityId:Guid}/holders/{holderId:Guid}/accounts/{type:int}", Name = "GetEntityAccount")]
                        accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + p.id + "/accounts/1").then((accnt) => {
                            if (accnt) {
                                p.account = accnt;
                            }
                            progressIndicatorProps.close();
                        }).catch((ex) => {
                            progressIndicatorProps.setMode("errorWithRetry");
                            progressIndicatorProps.setMessage("Unable to load player account, please try again...")
                        });
                    }

                    setPlayer(p);
                    //fetch player account
                    progressIndicatorProps.close();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load player details, please try again...")
            });
        }
    }, [execute, player, retryCounter]) //cardroomListData 


    return (<>
        <ProgressIndicator {...progressIndicatorProps} />
        {!progressIndicatorProps.visible ? <>

            <SoftBox mb={3} p={1}>
                <SoftBox
                    position="relative"
                    bgColor="secondary"
                    py={3}
                    px={{ xs: 3, sm: 6, md: 1 }}
                    mt={3}
                    borderRadius="xl"
                    variant="gradient"
                >

                    <SoftBox
                        component="img"
                        src={wavesWhite}
                        alt="pattern-line"
                        width="100%"
                        position="absolute"
                        left="0"
                        top="0"
                        opacity={0.4}
                    />

                    <Grid container alignItems="center" position="relative">
                        <Grid item xs={12} lg={1}>
                            <SoftBox textAlign="center" >
                                <SoftAvatar variant="rounded"
                                    bgColor="dark"
                                    src={player ? (player.properties && (player.properties.hasPhoto || player.hasPhoto) ? apiConfig.images.endpoint + "/player/" + player.id + ".jpg" : player.name.substring(0, 1)) : "G"}
                                    alt={player ? (player.properties && player.properties.name ? player.properties.name : player.name) : ""}
                                    display="flex"
                                    size="xl"
                                    style={{ marginLeft: "auto", "marginRight": "auto" }} />
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={11}>
                            <Grid container>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "name", fontWeight: "medium" }}
                                        count={{ text: player ? player.formattedName : "" }}
                                        icon={{ component: "person" }}
                                        action={openPlayerEditorDialog}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "phone", fontWeight: "medium" }}
                                        count={{ text: player ? player.formattedPhone : "" }}
                                        icon={{ component: "phone" }}
                                        action={openPlayerEditorDialog}
                                    />

                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "credit limit", fontWeight: "medium" }}
                                        count={{ text: player && player.properties ? player.properties.creditLimit : 0, prefix: "$", useNumberFormatter: true }}
                                        icon={{ component: "credit_card" }}
                                        action={openPlayerEditorDialog}
                                    />

                                </Grid>

                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "balance", fontWeight: "medium" }}
                                        count={{ text: player && player.account ? player.account.accountsPayable - player.account.accountsReceivable : 0, prefix: "$", useNumberFormatter: true, color: player && player.account && (player.account.accountsPayable - player.account.accountsReceivable) >= 0 ? "success" : "error" }}
                                        icon={{ color: "info", component: "paid" }}
                                    />
                                </Grid>
                            </Grid>

                            <PlayerEditorDialog {...openPlayerEditorDialogProps} cardroom={cardroom} player={player} />
                        </Grid>

                    </Grid>
                </SoftBox>
            </SoftBox>


            <SoftBox mb={3} p={1}>
                <Grid container spacing={1.5} alignItems="center">

                    <Grid item xs={12} md={4}>
                        <AppBar position="static">
                            <SoftBox sx={{ ml: 3 }}>
                                {tabValue === 1 ? <SoftButton variant="outlined" color="info"><Icon size="lg">person_add_alt_sharp</Icon>&nbsp;Add Player</SoftButton> : null}
                                {tabValue === 2 ? <SoftButton variant="outlined" color="info"><Icon size="lg">badge</Icon>&nbsp;Add Staff</SoftButton> : null}
                            </SoftBox>
                        </AppBar>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
                        <AppBar position="static">
                            <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue} sx={{ background: "transparent" }}>
                                <Tab label="Games" icon={<Icon fontSize="small" color="inherit">casino</Icon>}  {...a11yProps(0)} />
                                <Tab label="Account" icon={<Icon fontSize="small" color="inherit">account_balance_wallet</Icon>}  {...a11yProps(3)} />
                            </Tabs>
                        </AppBar>
                    </Grid>


                    <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>

                        <SoftBox>
                            <TabPanel value={tabValue} index={0}>
                                {player ?
                                    <PlayerGames cardroom={cardroom} player={player} /> : null}
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                {player && player.account ?
                                    <AccountingCard cardroom={cardroom} account={player.account} /> : null}
                            </TabPanel>

                        </SoftBox>

                    </Grid>
                </Grid>
            </SoftBox>
        </> : null}
    </>);

};

PlayerDetails.defaultProps = {

};

PlayerDetails.propTypes = {
    cardroom: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired
};


export default PlayerDetails;