import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { unionId, name, appUnionId, featuresFlag },
} = form;

const validations = {
    editor: Yup.object().shape({
        [name.name]: Yup.string().required(name.errorMsg),
        [featuresFlag.name]: Yup.number().min(0, "Features value must be greater than or equal to 0")
    }),

};

export default validations;