import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import SoftButton from "components/SoftButton";
import Icon from "@mui/material/Icon";

function ExistingSessionToolbar(props) {
    const navigate = useNavigate();

    const [cardroom, setCasino] = useState(props.cardroom);

   

    function onGotToCurrentSessionClicked() {
        navigate("/dashboards/cardroom/session", { state: { session: cardroom.currentSession, cardroom: cardroom } });
    }

    return <SoftButton variant="outlined" size="small" color="dark" onClick={onGotToCurrentSessionClicked}>
        <Icon size="large" color="inherit">launch</Icon>&nbsp;Continue Current Game
    </SoftButton >;
}


export default ExistingSessionToolbar;