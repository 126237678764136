import form from "./form";


const {
    formField: {
        unionId,
        name,
        appUnionId,
        featuresFlag
    },
} = form;

const initialValues = {
    [unionId.name]: "",
    [name.name]: "",
    [appUnionId.name]: "",
    [featuresFlag.name]: 0
};

export default initialValues;
