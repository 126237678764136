import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: {
        clubId,
        feeSchedule,
        referralSchedule,
        referredClub }
} = form;



const validations = {
    editor: Yup.object().shape({
        [clubId.name]: Yup.string().required(clubId.errorMsg),
        //[feeSchedule.name]: Yup.string().required(feeSchedule.errorMsg),
        [referralSchedule.name]: Yup.string().required(referralSchedule.errorMsg),
        [referredClub.name]: Yup.string().required(referredClub.errorMsg)
    }),

};

export default validations;