import buyin from "./form";


const {
    formField: {
        amount,
        method,
        provider,
        providerOther,
        providerRefMethod,
        providerRef,
        providerRefImage,
        accountBalance,
        note,
        journalTxType
    },
} = buyin;

const initialValues = {
    [amount.name]: 0,
    [method.name]: "cash",
    [provider.name]: "",
    [providerOther.name]: "",
    [providerRefMethod.name]: "number",
    [providerRef.name]: "",
    [providerRefImage.name]: "",
    [accountBalance.name]: 0,
    [note.name]: "",
    [journalTxType.name]: "credit"
};

export default initialValues;
