
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { apiConfig } from 'config/apiConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";


// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import FormField from "components/Elements/Forms/FormField";
import FormSwitch from "components/Elements/Forms/FormSwitch";
import { ErrorMessage } from "formik";

import playerAvatar from "assets/graphics/personas/player_256.png";
import staffAvatar from "assets/graphics/personas/masseuse_256.png";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import PartnerSelector from "components/Elements/Partner/PartnerSelector";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";


const Edit = ({ type, cardroom, formData }) => {
    const partipantType = type;
    let participantTypeName = "player";
    let avatar = playerAvatar;

    if (partipantType === "staff") {
        participantTypeName = "staff member";
        avatar = staffAvatar;
    }

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { candidate, candidateId, partnerId, candidateState, name, nickName, phoneNumber, creditLimit, initialBalance, initialBalanceType, withholdingRate,
        withholdingThreshold, withholdingSchedule, photo, mask, mustSign, mustNotify, notificationMask, isAnonymous } = formField;
    const {
        candidate: candidateV,
        candidateId: candidateIdV,
        partnerId: partnerIdV,
        candidateState: candidateStateV,
        name: nameV,
        nickName: nickNameV,
        phoneNumber: phoneNumberV,
        creditLimit: creditLimitV,
        withholdingRate: withholdingRateV,
        withholdingThreshold: withholdingThresholdV,
        withholdingSchedule: withholdingScheduleV,
        photo: photoV,
        mask: maskV,
        mustSign: mustSignV,
        mustNotify: mustNotifyV,
        notificationMask: notificationMaskV,
        initialBalance: initialBalanceV,
        initialBalanceType: initialBalanceTypeV,
        isAnonymous: isAnonymousV
    } = values;


    //extend logic to decide when it's appropriate to lock an edit field (currently works, mostly, until you go back from add to edit & then all is locked)
    //const [editFormLocked, setEditFormLocked] = useState(candidateIdV && candidateIdV != "new");

    const showOptionalProperties = () => {
        if (candidateStateV === "local") {
            if (type === "player" && (candidateV.type & 1) !== 0) {
                return false;
            }

            if (type !== "player" && (candidateV.type & 126) !== 0) {
                return false;
            }

            return true;
        }

        return true;
    }

    const areCorePropertiesLocked = () => {
        if (candidateStateV === "new") {
            return false;
        }

        if (candidateStateV === "local") {
            if (type === "player" && (candidateV.type & 1) !== 0) {
                return true;
            }

            if (type !== "player" && (candidateV.type & 126) !== 0) {
                return true;
            }

            return true;
        }

        return true;
    };

    const areExtendedPropertiesLocked = () => {
        if (candidateStateV === "new") {
            return false;
        }

        if (candidateStateV === "local") {
            if (type === "player" && (candidateV.type & 1) === 0) {
                return false;
            }

            if (type !== "player" && (candidateV.type & 126) === 0) {
                return false;
            }

            return true;
        }

        return false;
    };

    function onInitialBalanceTypeRadioGroupChanged(e, newValue) {
        setFieldValue(initialBalanceType.name, newValue);
    }

    const [participantRecord, setParticipantRecord] = useState(null);
    const [retryCounter, setRetryCounter] = useState(0);


    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "searching database...", handleRetry)

    const [phoneCheckDone, setPhoneCheckDone] = useState(areCorePropertiesLocked());
    const [phoneCheckInProgress, setPhoneCheckInProgress] = useState(false);

    function onResponsiblePartnerChanged(id) {
        setFieldValue(partnerId.name, id);
    }

    function onWithholdingSchedChanged(e, newValue) {
        setFieldValue(withholdingSchedule.name, newValue);
    }

    useEffect(() => {
        if (phoneNumberV && !isAnonymousV) {

            if (phoneNumberV.toString().length > 10) {
                setFieldValue(phoneNumber.name, phoneNumberV.toString().substring(0, 10));
            }

            if (phoneNumberV.toString().length < 10) {
                setParticipantRecord(null);
                setPhoneCheckDone(false);
            }
        }
    }, [phoneNumberV]);


    const [formDataInitialized, setFormDataInitialized] = useState(false);

    useEffect(() => {
        if (!formDataInitialized) {
            //set initial values
            setFieldValue(initialBalanceType.name, "none");

            //if (candidateStateV === "new") setFieldValue(isAnonymous.name, false);




            if (candidateStateV === "new" && phoneNumberV && phoneNumberV.toString().length === 10) {
                setFieldValue(phoneNumber.name, phoneNumberV + "0"); //force number search...
            }

            setFieldValue(isAnonymous.name, false);
            setFieldValue(mustNotify.name, false);
            setFieldValue(mustSign.name, false);
            setFieldValue(withholdingRate.name, 0);
            setFieldValue(withholdingThreshold.name, 0);
            setFieldValue(withholdingSchedule.name, "none");

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);



    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    useEffect(() => {

        //make sure mask reflects type of add we're doing
        setFieldValue(mask.name, type === "player" ? maskV | 1 : maskV);

        validateForm();

        if (isAnonymousV || (areCorePropertiesLocked() === true && !participantRecord)) {
            setParticipantRecord({});
            setPhoneCheckDone(true);
            return;
        }
        if (!participantRecord && !phoneCheckInProgress && phoneNumberV && phoneNumberV.toString().length === 10) {
            document.activeElement.blur();

            setPhoneCheckDone(false);

            setPhoneCheckInProgress(true); //force focus away somehow...

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Searching " + type + " database...")
            showProgress();

            let ptype = type === "player" ? 1 : 126;
            execute("GET", apiConfig.people.endpoint + "/venue/00000000-0000-0000-0000-000000000000/participants/" + ptype + "/search?term=" + encodeURIComponent(phoneNumberV)).then((response) => {

                if (response && response.length > 0) {
                    var pr = response[0];

                    setFieldValue(name.name, pr.name);
                    setFieldValue(candidateId.name, pr.id);
                    setFieldValue(candidateState.name, "global");
                    if (pr.hasPhoto) {
                        setFieldValue(photo.name, apiConfig.images.endpoint + "/player/" + pr.id + ".jpg");
                    }
                }
                else {
                    //no match found
                    setFieldValue(candidateState.name, "new");
                    setFieldValue(candidateId.name, "new_participant");
                    setFieldValue(initialBalance.name, 0);
                    //setFieldValue(name.name, ""); don't clear the name...
                }

                progressIndicatorProps.close();
                setPhoneCheckInProgress(false);
                setPhoneCheckDone(true);

                validateForm();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Search failed, please try again...")
            });

        }
    }, [phoneNumberV, participantRecord, retryCounter]);

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={photoV} alt={nameV} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Anonymous {type}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftBox ml={0.5} mb={0.25}>
                                    <FormSwitch readOnly={areCorePropertiesLocked()}
                                        name={isAnonymous.name}
                                        value={isAnonymousV}
                                        {...formData} />
                                </SoftBox>
                            </Grid>
                        </Grid>
                        {!isAnonymousV ?
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {phoneNumber.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField disabled={areCorePropertiesLocked()}
                                        maxLength={10}
                                        type={phoneNumber.type}
                                        name={phoneNumber.name}
                                        value={phoneNumberV}
                                        placeholder={phoneNumber.placeholder}
                                        error={errors.phoneNumber && touched.phoneNumber}
                                        success={phoneNumberV && phoneNumberV.length > 0 && !errors.phoneNumber}
                                    />
                                </Grid>
                            </Grid>
                            : null}
                        <ProgressIndicator {...progressIndicatorProps} />
                        {phoneCheckDone || isAnonymousV ?
                            <Grid xs={12}>
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {name.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormField disabled={areCorePropertiesLocked()}
                                            type={name.type}
                                            name={name.name}
                                            value={nameV}
                                            placeholder={name.placeholder}
                                            error={errors.name && touched.name}
                                            success={nameV && nameV.length > 0 && !errors.name}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {nickName.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormField disabled={areCorePropertiesLocked()}
                                            type={nickName.type}
                                            name={nickName.name}
                                            value={nickNameV}
                                            placeholder={nickName.placeholder}
                                            error={errors.nickName && touched.nickName}
                                            success={nickNameV && nickNameV.length > 0 && !errors.nickName}
                                        />
                                    </Grid>
                                </Grid>


                                <Grid container xs={12} mb={1.5}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {partnerId.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <PartnerSelector readonly={areExtendedPropertiesLocked()} cardroom={cardroom} label={partnerId.label} value={partnerIdV} onPartnerChanged={onResponsiblePartnerChanged} />
                                        {!areExtendedPropertiesLocked() ?
                                            <SoftTypography component="div" variant="caption" color="error">
                                                <ErrorMessage name={partnerId.name} />
                                            </SoftTypography> : null}
                                    </Grid>
                                </Grid>

                                {type === "player" ? <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {creditLimit.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormField disabled={areExtendedPropertiesLocked() || isAnonymousV}
                                            type={creditLimit.type}
                                            name={creditLimit.name}
                                            value={creditLimitV}
                                            placeholder={creditLimit.placeholder}
                                            error={errors.creditLimit && touched.creditLimit}
                                            success={creditLimitV && creditLimitV >= 0 && !errors.creditLimit}
                                        />
                                    </Grid>
                                </Grid> : null}

                                {showOptionalProperties() ? <>
                                    {type === "player" ? <>

                                        {!isAnonymousV ? <>
                                            {!areExtendedPropertiesLocked() ? <>

                                                <Grid container xs={12}>
                                                    <Grid item xs={4} >
                                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                fontWeight="bold"
                                                                textTransform="capitalize"
                                                            >
                                                                {initialBalanceType.label}
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <SoftTypography component="label" variant="caption" textTransform="capitalize">
                                                            <RadioGroup row={true} defaultValue="none" value={initialBalanceTypeV} onChange={onInitialBalanceTypeRadioGroupChanged} style={{ marginLeft: "1.25em" }}>
                                                                <FormControlLabel value="none" control={<Radio disabled={isAnonymousV} />} label="No" />
                                                                <FormControlLabel value="balance" control={<Radio disabled={isAnonymousV} />} label="Has Balance" />
                                                                <FormControlLabel value="credit" control={<Radio disabled={isAnonymousV} />} label="Has Credit" />
                                                            </RadioGroup>

                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                color="error">
                                                                <ErrorMessage name={initialBalanceType.name} />
                                                            </SoftTypography>
                                                        </SoftTypography>
                                                    </Grid>
                                                </Grid>


                                                {initialBalanceTypeV != "none" ?
                                                    <Grid container xs={12}>
                                                        <Grid item xs={4} >
                                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                                <SoftTypography
                                                                    component="label"
                                                                    variant="caption"
                                                                    fontWeight="bold"
                                                                    textTransform="capitalize"
                                                                >
                                                                    Current {initialBalanceTypeV == "credit" ? "credit" : "balance"}
                                                                </SoftTypography>
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <FormField disabled={areExtendedPropertiesLocked()}
                                                                type={initialBalance.type}
                                                                name={initialBalance.name}
                                                                value={initialBalanceV}
                                                                placeholder={initialBalance.placeholder}
                                                                error={errors.initialBalance && touched.initialBalance}
                                                                success={initialBalanceV && initialBalanceV >= 0 && !errors.initialBalance}
                                                            />
                                                        </Grid>
                                                    </Grid> : null}</> : null}

                                            <Grid container xs={12}>
                                                <Grid container xs={12}>
                                                    <Grid item xs={4}>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <SoftBox ml={0.5} mb={0.25}>
                                                            <FormSwitch label={mustSign.label} readOnly={isAnonymousV}
                                                                name={mustSign.name}
                                                                value={mustSignV}
                                                                {...formData} />
                                                        </SoftBox>
                                                    </Grid>
                                                </Grid>
                                                <Grid container xs={12}>
                                                    <Grid item xs={4}>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <SoftBox ml={0.5} mb={0.25}>
                                                            <FormSwitch label={mustNotify.label} readOnly={isAnonymousV}
                                                                name={mustNotify.name}
                                                                value={mustNotifyV}
                                                                {...formData} />
                                                        </SoftBox>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </> : null}
                                    </>
                                        :
                                        <Grid container xs={12}>

                                            <Grid container xs={12}>
                                                <Grid item xs={4} >
                                                    <SoftBox mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                        >
                                                            {withholdingSchedule.label}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <SoftTypography
                                                        variant="caption"
                                                        textTransform="capitalize">
                                                        <RadioGroup row={true} defaultValue={withholdingScheduleV} value={withholdingScheduleV} onChange={onWithholdingSchedChanged} style={{ marginLeft: "1.25em", marginTop: "3px" }}>
                                                            <FormControlLabel value="none" control={<Radio />} label="None" />
                                                            <FormControlLabel value="flat" control={<Radio />} label="Flat Rate" />
                                                            <FormControlLabel value="progressive" control={<Radio />} label="Progressive" />
                                                        </RadioGroup>
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            color="error">
                                                            <ErrorMessage name={withholdingSchedule.name} />
                                                        </SoftTypography>
                                                    </SoftTypography>
                                                </Grid>
                                            </Grid>

                                            {withholdingScheduleV != "none" ? <>

                                                <Grid container xs={12}>
                                                    <Grid item xs={4} >
                                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                fontWeight="bold"
                                                                textTransform="capitalize"
                                                            >
                                                                {withholdingRate.label}
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <FormField disabled={areExtendedPropertiesLocked()}
                                                            type={withholdingRate.type}
                                                            name={withholdingRate.name}
                                                            value={withholdingRateV}
                                                            min={0}
                                                            max={0}
                                                            placeholder={withholdingRate.placeholder}
                                                            error={errors.withholdingRate && touched.withholdingRate}
                                                            success={withholdingRateV && withholdingRateV.toString().length > 0 && !errors.withholdingRate}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container xs={12}>
                                                    <Grid item xs={4} >
                                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                fontWeight="bold"
                                                                textTransform="capitalize"
                                                            >
                                                                {withholdingThreshold.label}
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <FormField disabled={areExtendedPropertiesLocked()}
                                                            type={withholdingThreshold.type}
                                                            name={withholdingThreshold.name}
                                                            value={withholdingThresholdV}
                                                            placeholder={withholdingThreshold.placeholder}
                                                            error={errors.withholdingThreshold && touched.withholdingThreshold}
                                                            success={withholdingThresholdV && withholdingThresholdV.toString().length > 0 && !errors.withholdingThreshold}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </> : null}

                                            <Grid container xs={12}>
                                                <Grid item xs={12}>
                                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                        >
                                                            Staff Roles
                                                        </SoftTypography>
                                                        <SoftBox mt={0.75}>
                                                            <SoftTypography component="div" variant="caption" color="error">
                                                                <ErrorMessage name={mask.name} />
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                            <Grid container xs={12}>
                                                <Grid item xs={4}>
                                                    <SoftBox ml={0.5} mb={0.25}>
                                                        <FormSwitch label="Host"
                                                            name={mask.name}
                                                            value={maskV}
                                                            mask={2}
                                                            variant="bit"
                                                            {...formData} />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SoftBox ml={0.5} mb={0.25}>
                                                        <FormSwitch label="Dealer"
                                                            name={mask.name}
                                                            value={maskV}
                                                            mask={4}
                                                            variant="bit"
                                                            {...formData} />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SoftBox ml={0.5} mb={0.25}>
                                                        <FormSwitch label="Chef"
                                                            name={mask.name}
                                                            value={maskV}
                                                            mask={8}
                                                            variant="bit"
                                                            {...formData} />
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                            <Grid container xs={12}>
                                                <Grid item xs={4}>
                                                    <SoftBox ml={0.5} mb={0.25}>
                                                        <FormSwitch label="Valet"
                                                            name={mask.name}
                                                            value={maskV}
                                                            mask={16}
                                                            variant="bit"
                                                            {...formData} />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SoftBox ml={0.5} mb={0.25}>
                                                        <FormSwitch label="Security"
                                                            name={mask.name}
                                                            value={maskV}
                                                            mask={32}
                                                            variant="bit"
                                                            {...formData} />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SoftBox ml={0.5} mb={0.25}>
                                                        <FormSwitch label="Other"
                                                            name={mask.name}
                                                            value={maskV}
                                                            mask={64}
                                                            variant="bit"
                                                            {...formData} />
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                        </Grid>}</> : null}


                            </Grid>
                            : null
                        }


                    </SoftBox>
                </Grid>

            </Grid>
        </SoftBox>
    </SoftBox >;
}

Edit.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Edit;
