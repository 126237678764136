import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { scheduleId, name, unionDuesRate, xmttDuesRate, chipOffsetRate, taxRebateRate, unionDuesBase, winTaxRate, loyaltyRebateRate },
} = form;



const validations = {
    editor: Yup.object().shape({
        //[scheduleId.name]: Yup.string(),
        [name.name]: Yup.string().required(name.errorMsg),
        [unionDuesRate.name]: Yup.number().min(0, "Union dues rate must be between 0 and 100%.").max(100, "Union dues rate must be between 0 and 100%.").required(unionDuesRate.errorMsg),
        [xmttDuesRate.name]: Yup.number().min(0, "XMTT dues rate must be between 0 and 100%.").max(100, "XMTT dues rate must be between 0 and 100%.").required(xmttDuesRate.errorMsg),
        [chipOffsetRate.name]: Yup.number().min(0, "Chip offset rate must be between 0 and 100%.").max(100, "Chip offset rate must be between 0 and 100%.").required(chipOffsetRate.errorMsg),
        [taxRebateRate.name]: Yup.number().min(0, "Tax rebate rate must be between 0 and 100%.").max(100, "Tax rebate rate must be between 0 and 100%.").required(taxRebateRate.errorMsg),
        [winTaxRate.name]: Yup.number().min(0, "Winner tax rate must be between 0 and 100%.").max(100, "Winner tax rate must be between 0 and 100%.").required(winTaxRate.errorMsg),
        [unionDuesBase.name]: Yup.number().min(0, "Union dues base rate must be >= 0").required(unionDuesBase.errorMsg),
        [loyaltyRebateRate.name]: Yup.number().min(0, "Loyalty rebate rate must be between 0 and 100%.").max(100, "Loyalty rebate rate must be between 0 and 100%.").required(loyaltyRebateRate.errorMsg),
    }),

};

export default validations;