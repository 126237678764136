import form from "./form";


const {
    formField: {
        feeRate,
        feeThreshold,
        feeScheduleScheme
    },
} = form;

const initialValues = {
    [feeRate.name]: 0,
    [feeThreshold.name]: 0,
    [feeScheduleScheme.name]: 0
};

export default initialValues;
