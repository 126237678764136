import React, { useState, useEffect } from 'react';

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import wavesWhite from "assets/images/shapes/waves-white.svg";

import defaultCasino from "assets/graphics/logos/default-casino.png";

import { useDialog } from "components/Elements/Dialog/common";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import MiniActionCard from "components/Elements/MiniActionCard";


import { useCardroomContext } from 'features';
import { Link } from '@mui/material';

import UnionClubs from '../UnionClubs';
import UnionUnions from '../UnionUnions/inidex';
import UnionEditorDialog from '../SubUnion';
import ReportingPeriods from 'layouts/Club/components/Reports/History';
import { CasinoSettingsDialog } from 'components/Elements/Dialog/settings';
import CardroomLogo from 'components/Elements/CardroomLogo';
import ReportUploadDialog from 'layouts/Club/components/Reports/ReportUpload';


function UnionDetails({ cardroom }) {

    const TabPanel = function (props) {
        const { children, value, index, ...other } = props;
        return (
            <div role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}>
                {value === index && (
                    <SoftBox >
                        <SoftTypography>{children}</SoftTypography>
                    </SoftBox>
                )}
            </div>
        );
    }

    const a11yProps = function (index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    const [cardroomDetailsData, setCardroomDetailsData] = useState(null);
    const [union, setUnion] = useState(null);

    const cardroomRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading casino details...", handleRetry)

    const [context, actions, features] = useCardroomContext(cardroom);

    const [openCreateUnionDialog, createUnionDialogProps] = useDialog();
    const [openFileUploadDialog, fileUploadDialogProps] = useDialog();

    function canManageSubunion() {
        if (!union || !context) return false;
        //add permission check

        if (union.isVirtual) return false;

        if ((union.features & 16) === 0) return false;

        return context.isAllowed(actions.club.subunion.edit);
    }

    function canUploadReport() {
        if (!union || !context) return false;
        //add permission check

        if (union.isVirtual) return false;

        if (!context.isEnabled(features.club.reporting.selfUpload)) return false;

        return context.isAllowed(actions.club.reports.upload);
    }

    function onSubUnionsChanged() {

    }
    function onFileUploaded() { }

    useEffect(() => {
        if (!cardroomDetailsData || cardroomDetailsData.id != cardroom.id) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino details...")
            showProgress();
            cardroomRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id).then((response) => {
                setCardroomDetailsData(response);
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino details, please try again...")
            });
        }
    }, [cardroomRequest.execute, cardroomDetailsData, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (cardroomDetailsData && !union) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading union info...")
            showProgress();

            clubRequest.execute("GET", apiConfig.club.endpoint + "/union/casino/" + cardroom.id).then((response) => {
                setUnion(response);

                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load union info, please try again...")
            });
        }
    }, [clubRequest.execute, union, cardroomDetailsData, retryCounter]) //cardroomListData 

    return <>
        {cardroomDetailsData && !progressIndicatorProps.visible ? <>

            <SoftBox mb={3} p={1}>
                <SoftBox
                    position="relative"
                    bgColor="secondary"
                    py={3}
                    px={{ xs: 3, sm: 6, md: 1 }}
                    mt={3}
                    borderRadius="xl"
                    variant="gradient" >

                    <SoftBox
                        component="img"
                        src={wavesWhite}
                        alt="pattern-line"
                        width="100%"
                        position="absolute"
                        left="0"
                        top="0"
                        opacity={0.4}
                    />

                    <Grid container alignItems="center" position="relative">

                        <CardroomLogo cardroom={cardroomDetailsData} union={union} canOpenProperties={true} />

                        <Grid item xs={12} lg={11}>
                            <Grid container>
                                <Grid item xs={6} lg={3} p={1} >
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Unions", fontWeight: "medium" }}
                                        count={{ text: union ? union.unionCount : 0, color: "success", useNumberFormatter: true }}
                                        icon={{ component: "groups" }}
                                    />

                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Clubs", fontWeight: "medium" }}
                                        count={{ text: union ? union.clubCount : 0, color: "success", useNumberFormatter: true }}
                                        icon={{ component: "nightlife" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Players", fontWeight: "medium" }}
                                        count={{ text: union ? union.playerCount : 0, color: "success", useNumberFormatter: true }}
                                        icon={{ component: "person" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Agents", fontWeight: "medium" }}
                                        count={{ text: union ? union.agentCount : 0, color: "success", useNumberFormatter: true }}
                                        icon={{ component: "support_agent" }}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </SoftBox>
            </SoftBox>

            <SoftBox mb={3} p={1}>
                <Grid container spacing={1.5} alignItems="center">
                    <Grid item xs={12} md={6} lg={4}>
                        <AppBar position="static">
                            <SoftBox sx={{ ml: 3 }}>
                                {tabValue === 0 && canUploadReport() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openFileUploadDialog}><Icon size="lg">cloud_upload</Icon>&nbsp;Upload File</SoftButton> : null}
                                {tabValue === "unions" && canManageSubunion() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openCreateUnionDialog}><Icon size="lg">group_add</Icon>&nbsp;Create Union</SoftButton> : null}
                            </SoftBox>
                        </AppBar>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
                        <AppBar position="static">
                            <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue} sx={{ background: "transparent" }}>
                                <Tab label="Reports" icon={<Icon fontSize="small" color="inherit">summarize</Icon>}  {...a11yProps(0)} />
                                {canManageSubunion() ? <Tab label="Unions" icon={<Icon fontSize="small" color="inherit">beach_access</Icon>} {...a11yProps(1)} value="unions" /> : null}
                                <Tab label="Clubs" icon={<Icon fontSize="small" color="inherit">nightlife</Icon>} {...a11yProps(2)} value="clubs" />
                                {/*<Tab label="Debt Service" icon={<Icon fontSize="small" color="inherit">payments</Icon>}  {...a11yProps(3)} />*/}
                            </Tabs>
                        </AppBar>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>
                        <SoftBox>
                            <TabPanel value={tabValue} index={0}>
                                {canUploadReport() ? <ReportUploadDialog {...fileUploadDialogProps} cardroom={cardroom} onFileUploaded={onFileUploaded} /> : null}
                                <ReportingPeriods cardroom={cardroom} union={union} audience="union" />
                            </TabPanel>
                            <TabPanel value={tabValue} index={"unions"}>
                                <UnionEditorDialog {...createUnionDialogProps} cardroom={cardroom} union={union} subunion={null} onUnionChanges={onSubUnionsChanged} />
                                <UnionUnions cardroom={cardroom} union={union} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={"clubs"}>
                                <UnionClubs cardroom={cardroom} union={union} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                TAB 4
                            </TabPanel>
                        </SoftBox>
                    </Grid>
                </Grid>
            </SoftBox></> : <ProgressIndicator {...progressIndicatorProps} />
        }
    </>;
}

export default UnionDetails;