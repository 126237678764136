
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { useMsal } from '@azure/msal-react';
import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

import Swal from "sweetalert2";

import PhoneCell from 'components/Elements/DataTable/components/Cells/PhoneCell';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

import defaultPerson from "assets/graphics/icons/person.svg";
import defaultCasino from "assets/graphics/logos/default-casino.png";
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import { Divider } from '@mui/material';
import { useCardroomContext } from 'features';

const TransactionReceipt = ({ cardroom, session, transaction, participant, onClose, onTransactionUpdated }) => {

    const { borderWidth } = borders;
    const { light } = colors;
    const borderBottom = `${borderWidth[1]} solid ${light.main}`;

    const cardroomLogo = cardroom && cardroom.hasLogo ? apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png" : defaultCasino;

    const [context, actions, features] = useCardroomContext(cardroom);

    function getTransactionType(tx) {
        if (!tx) return "N/A";

        /**Purchase = 1,
                Redemption = 2,
                Promotion = 3,
                Withholding = 4,
                Rake = 5,
                Init = 6,
                Expense = 7,
                Other = 8 */

        switch (tx.type) {
            case 1:
                return "Buy In";
            case 2:
                return "Cashout";
            case 3:
                return "Promo";
            case 4:
                return "Withholding";
            case 5:
                return "Rake";
            case 7:
                return "Expense";
            case 8:
                return "Other"
        }

        return "UKNOWN";
    }

    function getTransactionAction(tx) {
        if (!tx) return "N/A";

        switch (tx.type) {
            case 1:
            case 4:
                return "Billed";
            case 2:
            case 3:
            case 7:
                return "Paid";

            //case 5:
            //    return "Rake";
            //case 8:
            //    return "Other"
        }

        return "UKNOWN";
    }

    function getTransactionPayMethod(tx) {
        if (!tx) return "N/A";

        /** Cash = 1,
        Electronic = 2,
        Credit = 3
        Account = 4*/

        switch (tx.paymentType) {
            case 1:
                return "Cash";
            case 2:
                return "e-Wallet";
            case 3:
                return "Credit";
            case 4:
                return "Account";
        }

        return "UKNOWN";
    }

    function getTransactionStatus(tx) {
        if (!tx) return "N/A";


        switch (tx.status) {
            case 1:
                return "Pending";
            case 2:
                return "Committed";
            case 3:
                return "Canceled";
            case 4:
                return "Declined";
        }

        return "UKNOWN";
    }

    function getParticipantName(p) {
        if (!p) return null;

        let name = p.name;
        if (p.properties) {
            name = p.properties.name ? p.properties.name : name;

            if (p.properties.nickName) name += " (" + p.properties.nickName + ")";
        }

        return name;

    }
    function getParticipantPhone(p) {
        if (!p) return null;

        let phone = p.phoneNumber;

        if (p.properties) {
            phone = p.properties.phoneNumber ? p.properties.phoneNumber : phone;
        }

        return phone;
    }

    function getParticipantImage(p) {
        if (!p) return null;

        let hasPhoto = p.hasPhoto;

        if (p.properties) {
            hasPhoto = p.properties.hasPhoto ? p.properties.hasPhoto : hasPhoto;
        }

        return hasPhoto ? apiConfig.images.endpoint + "/player/" + p.id + ".jpg" : defaultPerson;
    }

    function getPaymentVendor(vendor) {
        if (vendor === "apple") return "Apple Pay";
        if (vendor === "cashapp") return "CashApp";
        if (vendor === "venmo") return "Venmo";
        if (vendor === "zelle") return "Zelle";
        if (vendor === "paypal") return "PayPal";

        return vendor;
    }

    const [createdByUser, setCreatedByUser] = useState(null);
    const [verifiedByUser, setVerifiedByUser] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnTransactionUpdated(updatedTx) {
        if (onTransactionUpdated) {
            onTransactionUpdated(updatedTx);
        }
    }

    //const inputRef = useRef();

    function onRequestTransactionCancelation() {

        const newSwal = Swal.mixin({
            customClass: {
                //confirmButton: "btn btn-error",
                //    cancelButton: "MuiButton-outlined MuiButton-outlinedError MuiButton-sizeMedium MuiButton-outlinedSizeMedium",
            },
            buttonsStyling: true,
            //confirmButtonColor: "#009999",
            cancelButtonColor: "#FBCF33",
            denyButtonColor: "#EA0606"
        });

        const { value: note } = newSwal
            .fire({
                //input: "textarea",
                inputAttributes: {
                    autocapitalize: "off",
                },
                focusCancel: true,
                reverseButtons: true,
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: false,
                confirmButtonText: "Yes, cancel",
                denyButtonText: "Yes, cancel",
                cancelButtonText: "No",
                title: "Are you sure you want to cancel this transaction?",
                text: "This operation cannot be reversed!",
                icon: "warning",
                allowOutsideClick: () => !Swal.isLoading(),

                /*
                didOpen: (args) => {
                    //let container =  newSwal.getHtmlContainer();//.focus();
                    let el = newSwal.getFocusableElements();
                    
                    //MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium css-19pq5ar-MuiButtonBase-root-MuiButton-root

                    for (var i = 0; i < el.length; i++) {
                        switch (el[i].className) {
                            case "swal2-cancel swal2-styled":
                                el[i].className = "swal2-cancel swal2-styled MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium css-19pq5ar-MuiButtonBase-root-MuiButton-root";
                                break;
                            case "swal2-deny swal2-styled":
                                break;
                            case 'swal2-confirm swal2-styled':
                                break;
                        }
                    }
                },
               
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if (value) {
                            resolve()
                        } else {
                            resolve('A note is required.')
                        }
                    })
                },
                */

            }).then((result) => {
                //confirm button style didn't work, so using DENY button instead...
                if (result.isDenied) {
                    cancelTransaction("User requested cancelation.");
                }
            });
    }

    function cancelTransaction(note) {
        if (false === canCancelTransaction()) return;

        const tx = Object.assign({}, transaction);

        tx.status = 3;
        tx.note = note;

        setPendingTransaction(tx);
    }

    function canCancelTransaction() {
        if (!context) return false;
        return context.isAllowed(actions.casino.session.transaction.cancel);
    }

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.user.scopes.read,
    });

    const casinoRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.write,
    });

    const [pendingTransaction, setPendingTransaction] = useState(null);

    useEffect(() => {
        if (!createdByUser) {

            //progressIndicatorProps.setMode("wait");
            //progressIndicatorProps.setMessage("Loading game history...")
            //showProgress();

            execute("GET", apiConfig.user.endpoint + "/" + transaction.createdByUserId).then((response) => {
                if (response) {
                    setCreatedByUser(response);
                    //progressIndicatorProps.close();
                }
            }).catch((ex) => {
                //progressIndicatorProps.setMode("errorWithRetry");
                //progressIndicatorProps.setMessage("Unable to load game history, please try again...")
            });
        }
    }, [execute, createdByUser, retryCounter])

    useEffect(() => {
        if (!verifiedByUser) {

            //progressIndicatorProps.setMode("wait");
            //progressIndicatorProps.setMessage("Loading game history...")
            //showProgress();

            execute("GET", apiConfig.user.endpoint + "/" + transaction.verifiedByuserId).then((response) => {
                if (response) {
                    setVerifiedByUser(response);
                    //progressIndicatorProps.close();
                }
            }).catch((ex) => {
                //progressIndicatorProps.setMode("errorWithRetry");
                //progressIndicatorProps.setMessage("Unable to load game history, please try again...")
            });
        }
    }, [execute, verifiedByUser, retryCounter])

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "canceling transaction...", handleRetry)

    function getCardroomSmsNumber(cr) {
        if (!cr) return "";

        if (cr.vanitySmsNumber) return cr.vanitySmsNumber;
        if (cr.smsNumber) return cr.smsNumber;
    }

    useEffect(() => {
        if (pendingTransaction) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Canceling transaction...")
            showProgress();

            //[HttpPut("{casinoId:Guid}/sessions/{sessionId:Guid}/participants/{participantId:Guid}/transactions/{transactionId:Guid}", Name = "UpdateSessionTransaction")]
            casinoRequest.execute("PUT", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/participants/" + transaction.participantId + "/transactions/" + transaction.id, pendingTransaction).then((response) => {
                if (response) {
                    raiseOnTransactionUpdated(response);
                    //compare status? & id?
                }
                //clear payload and close dialog...
                setPendingTransaction(null);
                progressIndicatorProps.close();

                raiseOnClose();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to cancel transaction, please try again...")
            });

        }

    }, [casinoRequest.execute, pendingTransaction, retryCounter]);

    return <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} md={8}>
            <ProgressIndicator {...progressIndicatorProps} />

            {progressIndicatorProps.visible ? <></> :
                <Card>
                    {/* Invoice header */}
                    <SoftBox p={3}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={12} md={4}>
                                <SoftBox width="100%" textAlign={{ xs: "left", md: "left" }} mt={2}>
                                    <SoftBox component="img" src={cardroomLogo} width="25%" style={{ maxHeight: "50px" }} p={1} mb={1} />
                                    <SoftTypography variant="h6" fontWeight="medium">
                                        {cardroom ? cardroom.name : null}
                                    </SoftTypography>
                                    <SoftBox mt={1} mb={2}>
                                        <SoftTypography display="block" variant="body2" color="secondary">
                                            {cardroom ? <>
                                                {cardroom.phoneNumber ? <>tel: <PhoneCell value={cardroom.phoneNumber} obfuscate={false} /> <br /></> : null}
                                                {cardroom.vanitySmsNumber ? <>sms:&nbsp;<SoftTypography variant="caption" fontWeight="medium" color="text">
                                                    {cardroom.vanitySmsNumber} </SoftTypography><br /></> :
                                                    cardroom.vanitySmsNumber ? <>sms: <PhoneCell value={cardroom.smsNumber} obfuscate={false} /> <br /></> : null}
                                            </> : null}
                                        </SoftTypography>
                                    </SoftBox>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} md={7} lg={3}>
                                {participant ?
                                    <SoftBox width="100%" textAlign={{ xs: "left", md: "right" }} mt={2}>
                                        <SoftBox component="img" src={getParticipantImage(participant)} width="25%" style={{ maxHeight: "50px" }} p={1} mb={1} />
                                        <SoftTypography variant="h6" fontWeight="medium">
                                            {getTransactionAction(transaction)} to: {getParticipantName(participant)}
                                        </SoftTypography>

                                        <SoftBox mt={1} mb={2}>
                                            <SoftTypography display="block" variant="body2" color="secondary">
                                                tel: <PhoneCell value={getParticipantPhone(participant)} obfuscate={true} /><br />
                                            </SoftTypography>
                                        </SoftBox>
                                    </SoftBox> : null}
                            </Grid>
                        </Grid>
                        <SoftBox mt={{ xs: 5, md: 5 }}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={12} md={6}>
                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                        Record Id
                                    </SoftTypography>
                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                        {transaction.id}
                                    </SoftTypography>

                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                        Status
                                    </SoftTypography>
                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                        {getTransactionStatus(transaction)}
                                    </SoftTypography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <SoftBox
                                        width="100%"
                                        display="flex"
                                        flexDirection={{ xs: "column", md: "row" }}
                                        alignItems={{ xs: "flex-start", md: "center" }}
                                        textAlign={{ xs: "left", md: "right" }}
                                        mt={{ xs: 3, md: 0 }}
                                    >
                                        <SoftBox width="50%">
                                            <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                                Session date:
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox width="50%">
                                            <SoftTypography variant="caption" fontWeight="medium">
                                                {new Date(Date.parse(session.startedOn + "Z")).toLocaleDateString()}
                                            </SoftTypography>
                                        </SoftBox>
                                    </SoftBox>
                                    <SoftBox
                                        width="100%"
                                        display="flex"
                                        flexDirection={{ xs: "column", md: "row" }}
                                        alignItems={{ xs: "flex-start", md: "center" }}
                                        textAlign={{ xs: "left", md: "right" }}
                                    >
                                        <SoftBox width="50%">
                                            <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                                Tx date:
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox width="50%">
                                            <SoftTypography variant="caption" fontWeight="medium">
                                                {new Date(Date.parse(transaction.transactionDate + "Z")).toLocaleDateString()}
                                            </SoftTypography>
                                        </SoftBox>
                                    </SoftBox>
                                    <SoftBox
                                        width="100%"
                                        display="flex"
                                        flexDirection={{ xs: "column", md: "row" }}
                                        alignItems={{ xs: "flex-start", md: "center" }}
                                        textAlign={{ xs: "left", md: "right" }}
                                    >
                                        <SoftBox width="50%">
                                            <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                                Tx time:
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox width="50%">
                                            <SoftTypography variant="caption" fontWeight="medium">
                                                {new Date(Date.parse(transaction.transactionDate + "Z")).toLocaleTimeString()}
                                            </SoftTypography>
                                        </SoftBox>
                                    </SoftBox>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="space-between" mt={2}>
                                <Grid item xs={12} md={4}>
                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                        Created By
                                    </SoftTypography>
                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }} ml={0.5}>
                                        {createdByUser ? createdByUser.displayName : "..."}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} md={4} textAlign="right">
                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                        Verified By
                                    </SoftTypography>
                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }} mr={0.5}>
                                        {verifiedByUser ? verifiedByUser.displayName : "..."}
                                    </SoftTypography>
                                </Grid>
                            </Grid>

                        </SoftBox>
                    </SoftBox>

                    {/* Invoice table */}
                    <SoftBox p={3}>
                        <SoftBox width="100%" overflow="auto">
                            <Table sx={{ minWidth: "32rem" }}>
                                <SoftBox component="thead">
                                    <TableRow>
                                        <SoftBox
                                            component="th"
                                            width={{ xs: "25%", md: "25%" }}
                                            py={1.5}
                                            px={1}
                                            textAlign="left"
                                            borderBottom={borderBottom}
                                        >
                                            <SoftTypography variant="caption" color="text" fontWeight="medium">
                                                Transaction Type
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox
                                            component="th"
                                            py={1.5}
                                            pl={3}
                                            pr={1}
                                            textAlign="left"
                                            borderBottom={borderBottom}
                                        >
                                            <SoftTypography variant="caption" color="text" fontWeight="medium">
                                                Payment Method
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox
                                            component="th"
                                            py={1.5}
                                            pl={3}
                                            pr={1}
                                            textAlign="left"
                                            borderBottom={borderBottom}
                                        >
                                            <SoftTypography variant="caption" color="text" fontWeight="medium">
                                                Payment Vendor
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox
                                            component="th"
                                            py={1.5}
                                            pl={3}
                                            pr={1}
                                            textAlign="left"
                                            borderBottom={borderBottom}
                                        >
                                            <SoftTypography variant="caption" color="text" fontWeight="medium">
                                                Amount
                                            </SoftTypography>
                                        </SoftBox>
                                    </TableRow>
                                </SoftBox>
                                <TableBody>
                                    <TableRow>
                                        <SoftBox component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                            <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                {getTransactionType(transaction)}
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox
                                            component="td"
                                            textAlign="left"
                                            py={1}
                                            pr={1}
                                            pl={3}
                                            borderBottom={borderBottom}
                                        >
                                            <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                {getTransactionPayMethod(transaction)}
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox
                                            component="td"
                                            textAlign="left"
                                            py={1}
                                            pr={1}
                                            pl={3}
                                            borderBottom={borderBottom}
                                        >
                                            <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                {getPaymentVendor(transaction.ePaymentVendor)}
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox
                                            component="td"
                                            textAlign="left"
                                            py={1}
                                            pr={1}
                                            pl={3}
                                            borderBottom={borderBottom}
                                        >
                                            <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                <MoneyCell value={transaction.amount} prefix="$" useColorScheme={false} />
                                            </SoftTypography>
                                        </SoftBox>
                                    </TableRow>
                                    {transaction.paymentType == 2 ?
                                        <TableRow>
                                            <SoftBox component="td" colSpan="4" textAlign="left" p={1} pr={3} borderBottom={borderBottom}>
                                                <Grid container xs={12}>
                                                    <Grid item xs={4}>
                                                        <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                            Vendor Reference:
                                                        </SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={8} textAlign="right">
                                                        <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                            {transaction.ePaymentVendorReferenceNo}

                                                            {transaction.ePaymentVendorReferenceImage ? <SoftBox component="img"
                                                                src={transaction.ePaymentVendorReferenceImage}
                                                                width={{ lg: "82px", sm: "96px", xl: "96px" }}
                                                            /> : null}
                                                        </SoftTypography>
                                                    </Grid>
                                                </Grid>

                                            </SoftBox>
                                        </TableRow> : null}
                                    {transaction.participantSignature ?
                                        <TableRow>
                                            <SoftBox component="td" colSpan="4" textAlign="right" p={1} pr={3} borderBottom={borderBottom}>
                                                <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                    <SoftBox component="img" src={transaction.participantSignature} />
                                                </SoftTypography>
                                            </SoftBox>
                                        </TableRow> : null}


                                    {transaction.note ?
                                        <TableRow>
                                            <SoftBox component="td" colSpan="4" textAlign="right" p={1} pr={3} borderBottom={borderBottom}>
                                                <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                    NOTE: </SoftTypography><SoftTypography variant="caption" fontWeight="medium" color="text">{transaction.note}</SoftTypography>
                                            </SoftBox>
                                        </TableRow> : null}
                                </TableBody>
                            </Table>
                        </SoftBox>
                    </SoftBox>

                    {/* Invoice footer */}
                    <SoftBox p={3} mt={1}>
                        <Grid container>
                            <Grid item xs={12} lg={5}>
                                {canCancelTransaction() && session && (session.status === 2 || session.status === 3) && transaction && transaction.status != 3 ?
                                    <SoftBox
                                        width="100%"
                                        height={{ xs: "auto", md: "100%" }}
                                        display="flex"
                                        justifyContent={{ xs: "flex-start", md: "flex-start" }}
                                        alignItems="flex-start"
                                        mt={{ xs: 2, md: 0 }}
                                    >
                                        <SoftButton
                                            variant="outlined"
                                            color="error"
                                            onClick={onRequestTransactionCancelation}
                                        >
                                            cancel transaction
                                        </SoftButton>
                                    </SoftBox> : null}
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <SoftBox
                                    width="100%"
                                    height={{ xs: "auto", md: "100%" }}
                                    display="flex"
                                    justifyContent={{ xs: "flex-start", md: "flex-end" }}
                                    alignItems="flex-end"
                                    mt={{ xs: 2, md: 0 }}
                                >
                                    <SoftButton
                                        variant="outlined"
                                        color="dark"
                                        onClick={raiseOnClose}
                                    >
                                        close
                                    </SoftButton>
                                </SoftBox>
                            </Grid>
                        </Grid>
                    </SoftBox>

                </Card>}
        </Grid>
    </Grid >;

};

TransactionReceipt.defaultProps = {

};

TransactionReceipt.propTypes = {
    cardroom: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    transaction: PropTypes.object.isRequired,
    participant: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    onTransactionUpdated: PropTypes.func
};

export default TransactionReceipt;