import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BootstrapDialog } from "components/Elements/Dialog/common"

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import ParticipantCell from "components/Elements/DataTable/components/Cells/ParticipantCell";
import PhoneCell from "components/Elements/DataTable/components/Cells/PhoneCell";

import Swal from "sweetalert2";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import ParticipantFinder from "layouts/Cardroom/Session/components/Wizards/FindParticipant";

import PlayerTransactionForm, { PlayerTransactionInlineForm } from 'layouts/Cardroom/Session/components/Forms/PlayerTransactionForm';

import StaffTransactionForm from 'layouts/Cardroom/Session/components/Forms/StaffTransactionForm';



import DataTable from "components/Elements/DataTable";
import { Card, CardActionArea, CardContent, CardHeader, CardMedia, Grid, Icon, Switch } from '@mui/material';
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';
import SoftAvatar from 'components/SoftAvatar';

import warningIcon from "assets/graphics/icons/warning_256.png";
import stopWatch from "assets/graphics/actions/stopwatch_256.png";
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import HouseTransactionForm from '../Forms/HouseTransactionForm';
import { NumericFormat } from 'react-number-format';
import ReconciliationWorksheet from '../Forms/ReconciliationWorksheet';
import SessionSummaryReport from '../SummaryReport';
import ExpensesWorksheet from '../Forms/ExpensesWorksheet';
import SessionLiveSummaryReport from '../LiveSummaryReport';


const TabPanel = function (props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <SoftBox sx={{ p: 3 }}>
                    <SoftTypography>{children}</SoftTypography>
                </SoftBox>
            )}
        </div>
    );
}

const a11yProps = function (index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const AddStaffDialog = ({ open, close, cardroom, session, mode, onAdded }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    function onStaffAdded() {
        if (onAdded) {
            onAdded();
        }
        close();
    }

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            onClose={close}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="xl"
        >
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
                <SoftBox>
                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        Staff
                    </SoftTypography>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>
                <SoftBox mt={3}>
                    <ParticipantFinder type="staff" mode={mode} cardroom={cardroom} session={session} onAdded={onStaffAdded} />
                </SoftBox>
            </DialogContent>
        </BootstrapDialog>
    );
};

const AddPlayerDialog = ({ open, close, cardroom, session, mode, onAdded }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    function onPlayerAdded() {
        if (onAdded) {
            onAdded();
        }
        close();
    }

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            onClose={close}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="xl">
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
                <SoftBox>
                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        Players
                    </SoftTypography>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>
                <SoftBox mt={3}>
                    <ParticipantFinder type="player" mode={mode} cardroom={cardroom} session={session} onAdded={onPlayerAdded} />
                </SoftBox>
            </DialogContent>
        </BootstrapDialog>
    );
};

const AddPlayerTransactionDialog = ({ open, close, cardroom, session, player, onAdded }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    function getPlayerFormattedName() {
        if (!player) return "Unknown";

        let name = player.name;

        if (player.properties) {
            name = player.properties.name ? player.properties.name : name;

            if (player.properties.nickName) name += " (" + player.properties.nickName + ")";
        }

        return name;
    }

    function onDialogClosureRequested() {
        close();
    }

    function onSessionTransactionAdded() {
        onDialogClosureRequested();

        if (onAdded) {
            onAdded();
        }
    }

    const [selectedTxTypeIndex, setSelectedTxTypeIndex] = useState(-1);
    const [selectedTxType, setSelectedTxType] = useState(null);

    useEffect(() => {
        //clear whenever dialog is opened...
        setSelectedTxType(dropDownData[0]);
        setSelectedTxTypeIndex(0);
    }, [open]);



    const dropDownData = [

    ];

    if (session) {
        if (session.status === 2) {
            dropDownData.push({ id: 1, key: "buyin", text: "Buy In" });
        }

        dropDownData.push({ id: 2, key: "cashout", text: "Cash In Chips" });
        dropDownData.push({ id: 3, key: "promo", text: "Promotion" });
    }


    if (player && player.borrowedAmount > 0)
        dropDownData.push({ id: 4, key: "settle", text: "Settle Balance" });

    if (player && player.borrowedAmount < 0)
        dropDownData.push({ id: 5, key: "payout", text: "Pay Out" });


    const dropDownSelectionChanged = (e) => {
        const item = e.item;
        const index = e.index;

        setSelectedTxTypeIndex(index);
        setSelectedTxType(item);
    }

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="xl">
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
                <SoftBox>
                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        New Player Transaction: {getPlayerFormattedName()}
                    </SoftTypography>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>
                <Card style={{ minHeight: 200 }} mt={3}>
                    <Grid container xs={12}>
                        <Grid item xs={12} md={4} lg={3} sx={{ ml: "auto" }} >
                            <SoftBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                                <DataBoundSelect placeholder="Select transaction type" valueName="id" textName="text"
                                    data={dropDownData} onChange={dropDownSelectionChanged} size="medium" selectedIndex={selectedTxTypeIndex} />
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <SoftBox pb={0} px={2} textAlign="center" lineHeight={1.25}>
                                <PlayerTransactionForm type={selectedTxType ? selectedTxType.key : null} cardroom={cardroom} session={session} player={player} onClose={onDialogClosureRequested} onTransactionCreated={onSessionTransactionAdded} />
                            </SoftBox></Grid>
                    </Grid>
                </Card>
            </DialogContent>
            {/*
            <DialogActions>
                <SoftButton onClick={close} variant="outlined" color="warning">
                    cancel
                </SoftButton>
                <SoftButton onClick={onAddAndClose} variant="outlined" color="success" disabled={!canAddTransaction}>
                    add
                </SoftButton>
            </DialogActions>
    */}
        </BootstrapDialog >
    );
};


const AddPlayerInlineTransactionDialog = ({ open, close, cardroom, session, onAdded }) => {
    const theme = useTheme();

    function onDialogClosureRequested() {
        close();
    }

    function onSessionTransactionAdded() {
        onDialogClosureRequested();

        if (onAdded) {
            onAdded();
        }
    }

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            fullScreen={true}
            fullWidth={true}
            maxWidth="xxl">
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
                <SoftBox width="100%" display="flex" justifyContent="space-between">

                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        New Player Transaction
                    </SoftTypography>

                    <SoftButton onClick={close} variant="outlined" size="small" color="error" title="clear selection" iconOnly>
                        <Icon >clear</Icon>
                    </SoftButton>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>
                <Card style={{ minHeight: 200 }} mt={3}>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <SoftBox pb={0} px={2} textAlign="center" lineHeight={1.25}>
                                <PlayerTransactionInlineForm cardroom={cardroom} session={session} onClose={onDialogClosureRequested} onTransactionCreated={onSessionTransactionAdded} />
                            </SoftBox>
                        </Grid>
                    </Grid>
                </Card>
            </DialogContent>
        </BootstrapDialog >
    );
};



const AddStaffTransactionDialog = ({ open, close, cardroom, session, staff, onAdded }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    function getStaffFormattedName() {
        if (!staff) return "Unknown";

        let name = staff.name;

        if (staff.properties) {
            name = staff.properties.name ? staff.properties.name : name;

            if (staff.properties.nickName) name += " (" + staff.properties.nickName + ")";
        }

        return name;
    }

    function onDialogClosureRequested() {
        close();
    }

    function onSessionTransactionAdded() {
        onDialogClosureRequested();

        if (onAdded) {
            onAdded();
        }
    }

    const [selectedTxTypeIndex, setSelectedTxTypeIndex] = useState(-1);
    const [selectedTxType, setSelectedTxType] = useState(null);

    useEffect(() => {
        //clear whenever dialog is opened...
        setSelectedTxType(dropDownData[0]);
        setSelectedTxTypeIndex(0);
    }, [open]);


    //if dealer, report downs / rake?
    //if server, deposit tips

    const dropDownData = [

    ];

    if (staff && staff.properties) {

        let mask = staff.properties.participantType;
        if (staff.type) mask = staff.type;

        if ((mask & 4) > 0) //dealer
        {
            dropDownData.push({ id: 1, key: "down", text: "End of Down" });
        }

        dropDownData.push({ id: 2, key: "tips", text: "Record Tips" })

        if (Math.abs(staff.borrowedAmount) > 0)
            dropDownData.push({ id: 3, key: "payout", text: "Pay Out" });
    }

    const dropDownSelectionChanged = (e) => {
        const item = e.item;
        const index = e.index;

        setSelectedTxTypeIndex(index);
        setSelectedTxType(item);
    }



    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="xl">
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
                <SoftBox>
                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        New Staff Transaction: {getStaffFormattedName()}
                    </SoftTypography>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>
                <Card style={{ minHeight: 200 }} mt={3}>
                    <Grid container xs={12}>
                        <Grid item xs={12} md={4} lg={3} sx={{ ml: "auto" }} >
                            <SoftBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                                <DataBoundSelect placeholder="Select transaction type" valueName="id" textName="text"
                                    data={dropDownData} onChange={dropDownSelectionChanged} size="medium" selectedIndex={selectedTxTypeIndex} />
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <SoftBox pb={0} px={2} textAlign="center" lineHeight={1.25}>
                                <StaffTransactionForm type={selectedTxType ? selectedTxType.key : null} cardroom={cardroom} session={session} staff={staff} onClose={onDialogClosureRequested} onTransactionCreated={onSessionTransactionAdded} />
                            </SoftBox></Grid>
                    </Grid>
                </Card>
            </DialogContent>
            {/* 
            <DialogActions>
                <SoftButton onClick={close} variant="outlined" color="info">
                    close
                </SoftButton>
            </DialogActions>
            */}
        </BootstrapDialog>
    );
};

const CloseSessionDialog = ({ open, close, cardroom, session, onClose }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "updating session...", handleRetry)

    const [retryCounter, setRetryCounter] = useState(0);

    const [isProcessing, setIsProcessing] = useState(false);

    const [sessionUpdateState, setSessionUpdateState] = useState(null);

    const [cancelSessionFlag, setCancelSessionFlag] = useState(false);

    const [sessionCheckComplete, setSessionCheckComplete] = useState(!(session && session.status === 3));
    const [sessionCheckInProgress, setSessionCheckInProgress] = useState(false);
    const [blockingPlayerIdList, setBlockingPlayerIdList] = useState(null);
    const [blockingPlayers, setBlockingPlayers] = useState(null);
    const [blockingPlayersTable, setBlockingPlayersTable] = useState(null);

    function onCancelSessionSwitchChanged() {
        setCancelSessionFlag(!cancelSessionFlag);
    }

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const getDialogTitle = () => {
        if (!session) return "[null] session";

        if (session.status === 1) return "Scheduled Session";
        if (session.status === 2) return "End Session";
        if (session.status === 3) return "Finalize Session";

        return "Invalid Session State";
    };

    const getActionTitle = () => {
        if (!session) return null;

        if (session.status === 1) return "start";
        if (session.status === 2) return "end";
        if (session.status === 3) return "finalize";

        return null;
    };

    const getYesButtonCaption = () => {
        if (!session) return "Yes";

        if (session.status === 1) {
            if (cancelSessionFlag) return "Cancel Session";

            return "Begin Session";
        }
        if (session.status === 2) return "End Session";
        if (session.status === 3) return "Finalize Session";

        return "Yes";
    };

    const getActionWarning = () => {
        if (!session) return null;

        if (session.status === 1) {
            if (cancelSessionFlag) return "Canceling the session will close and remove it from the schedule.";

            return "Starting the session will begin the clock and enable you to add participant transactions.";
        }

        if (session.status === 2) {
            const chipsInPlay = session.totalBuyIns - session.totalCashouts - session.totalTips - session.totalRake;

            return "Doing this will result in you being able to view, but not edit most aspects of this session, including, but not limited to adding participants and most types of transactions, such as 'buy-in'.";

        }
        if (session.status === 3) return "Doing this will result in the session becoming readonly. You will not be able to make any changes to the session.";

        return null;
    };

    function process() {
        let newState = 0;

        if (session.status === 1) {
            if (cancelSessionFlag) newState = 5;
            else newState = 2;
        }
        else if (session.status === 2) {

            //double confirm...
            const newSwal = Swal.mixin({
                buttonsStyling: true,
                cancelButtonColor: "#FBCF33",
                denyButtonColor: "#EA0606"
            });

            const { value: note } = newSwal
                .fire({
                    //input: "textarea",
                    inputAttributes: {
                        autocapitalize: "off",
                    },
                    focusCancel: true,
                    reverseButtons: true,
                    showCancelButton: true,
                    showDenyButton: true,
                    showConfirmButton: false,
                    confirmButtonText: "Yes, end session",
                    denyButtonText: "Yes, end session",
                    cancelButtonText: "No",
                    title: "Are you really sure you want to end this game session?",
                    text: "This operation cannot be reversed!",
                    icon: "warning",
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    //confirm button style didn't work, so using DENY button instead...
                    if (result.isDenied) {
                        setSessionUpdateState({ state: 3 });
                    }
                });


        }
        else if (session.status === 3) {
            newState = 4;
        }

        if (newState !== 0) {
            setSessionUpdateState({ state: newState });
        }
    }

    function cancel() {
        onCloseInitiated();

        if (close) {
            close();
        }
    }

    function onSuccessfullStateUpdate(status) {
        //do whatever notification is required to parent object...
        session.status = status;
        if (status === 2) {
            session.startedOn = new Date(Date.now()).toISOString();
        }
        else if (status === 3) {
            session.endedOn = new Date(Date.now()).toISOString();
        }

        onCloseInitiated();

        if (close) {
            close();
        }
    }

    function onCloseInitiated() {
        resetDialogState();

        if (onClose) {
            onClose();
        }
    }

    function mapParticipantList(idList, detailedList) {
        let players = [];
        let staff = [];

        let detailedMap = [];
        for (var i = 0; i < detailedList.length; i++) {
            detailedMap[detailedList[i].id] = detailedList[i];
        }

        for (var i = 0; i < idList.length; i++) {
            if ((idList[i].type & 1) > 0) {
                players.push(detailedMap[idList[i].id]);
            }
            else {
                staff.push(detailedMap[idList[i].id]);
            }
        }

        return {
            staff: staff,
            players: players
        };
    }

    function prepAndStoreBlockingParticipantsTable(blockers) {
        const getRows = (list, type) => {
            const rows = [];
            if (list) {
                for (var i = 0; i < list.length; i++) {
                    let name = list[i].name;
                    let phone = list[i].phoneNumber;
                    let hasPhoto = list[i].hasPhoto;

                    if (list[i].properties) {
                        name = list[i].properties.name ? list[i].properties.name : name;
                        phone = list[i].properties.phoneNumber ? list[i].properties.phoneNumber : phone;
                        hasPhoto = list[i].properties.playerHasPhoto ? list[i].properties.playerHasPhoto : hasPhoto;

                        if (list[i].properties.nickName) name += " (" + list[i].properties.nickName + ")";
                    }

                    let image = hasPhoto ? apiConfig.images.endpoint + "/player/" + list[i].id + ".jpg" : name.substring(0, 1);

                    rows.push({
                        id: list[i].id,
                        name: [name, { image: image }], // format name from name & nickname
                        phone: phone,
                        type: type
                    });

                }
            }
            return rows;
        };

        if (blockers) {
            const table = {
                columns: [
                    {
                        Header: "name", accessor: "name", Cell: ({ value: [name, data] }) => (
                            <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} />
                        ),
                    },
                    { Header: "phone", accessor: "phone", Cell: ({ value }) => <PhoneCell value={value} obfuscate={true} /> },
                    { Header: "type", accessor: "type", Cell: ({ value }) => <TextCell value={value} /> }
                ],
                rows: [
                    ...getRows(blockers.players, "player"),
                    ...getRows(blockers.staff, "staff")
                ]
            };

            //sort rows here

            if (table.rows.length > 0) {
                table.rows.sort(function (a, b) {
                    let aName = a.name && a.name[0] ? a.name[0].toLowerCase() : a.name;
                    let bName = b.name && b.name[0] ? b.name[0].toLowerCase() : b.name;

                    if (aName < bName) { return -1; }
                    if (aName > bName) { return 1; }

                    return 0;
                });
            }

            setBlockingPlayersTable(table);
        }
    }

    function resetDialogState() {
        setSessionCheckComplete(!(session && session.status === 3));
        setBlockingPlayerIdList(null);
        setBlockingPlayers(null);
        setBlockingPlayersTable(null);
    }

    function isYesButtonDisabled() {
        if (isProcessing) return true;
        if (blockingPlayers) return true;
        if (sessionCheckInProgress) return true;
        if (!sessionCheckComplete) return true;

        return false;
    }

    function isNoButtonDisabled() {
        if (isProcessing) return true;

        return false;
    }

    const { error, execute } = useFetchWithMsal({
        scopes: [...apiConfig.casino.scopes.read, ...apiConfig.casino.scopes.write]
    });

    useEffect(() => {
        if (!sessionCheckComplete && open && !sessionCheckInProgress) {
            setSessionCheckInProgress(true);

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Checking session data...")
            showProgress();


            //[HttpGet("{casinoId:Guid}/sessions/{sessionId:Guid}/participants/blocking-closure", Name = "GetCasinoSessionParticipantsWithClosureBlockingTransactions")]
            execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/participants/blocking-closure").then((response) => {
                if (response && response.length > 0) {
                    //we have bound blocking transactions, lets resolve user names, etc...
                    const list = [];
                    for (var i = 0; i < response.length; i++) {
                        list.push({
                            id: response[i].id,
                            type: response[i].type
                        });
                    }
                    setBlockingPlayerIdList(list);
                }
                else {
                    //nothing found, nothing is blocking
                    setSessionCheckInProgress(false);
                    setSessionCheckComplete(true);

                    progressIndicatorProps.close();
                }
            }).catch((e) => {
                setSessionCheckInProgress(false);
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to check session data, please try again...")
            });
        }
    }, [execute, open, sessionCheckComplete, retryCounter]);


    const peopleReq = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read
    });

    useEffect(() => {

        if (blockingPlayerIdList && blockingPlayerIdList.length > 0 && !blockingPlayers) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading participant details...")


            const list = [];
            for (var i = 0; i < blockingPlayerIdList.length; i++) {
                list.push(blockingPlayerIdList[i].id);
            }

            peopleReq.execute("PUT", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", list).then((response) => {
                if (response) {

                    const blockers = mapParticipantList(blockingPlayerIdList, response);
                    setBlockingPlayers(blockers);

                    prepAndStoreBlockingParticipantsTable(blockers);

                    setSessionCheckInProgress(false);
                    setSessionCheckComplete(true);

                    progressIndicatorProps.close();
                }
            }).catch((ex) => {
                //setOverlayMessage("An error occured, please refresh");
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load session players' details, plese try again...");
            });
        }
    }, [peopleReq.execute, blockingPlayerIdList, blockingPlayers, retryCounter])

    useEffect(() => {
        if (sessionCheckComplete && sessionUpdateState) {

            setIsProcessing(true);
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Updating session status...")
            showProgress();


            //  [HttpPost("{casinoId:Guid}/sessions/{sessionId:Guid}/{status}", Name = "UpdateCasinoSessionStatus")]
            execute("POST", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/" + sessionUpdateState.state).then((response) => {
                if (response) {
                    onSuccessfullStateUpdate(sessionUpdateState.state);
                    setSessionUpdateState(null);
                    progressIndicatorProps.close();
                }
                else {
                    progressIndicatorProps.setMode("errorWithRetry");
                    progressIndicatorProps.setMessage("Unable to update session status, please try again...")
                }

                setIsProcessing(false);
            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to update session status, please try again...")
            });
        }
    }, [execute, sessionUpdateState, sessionCheckComplete, retryCounter]);

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="md">
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
                <SoftBox>
                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        {getDialogTitle()}
                    </SoftTypography>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>
                <SoftBox mt={3}>
                    <ProgressIndicator {...progressIndicatorProps} />
                    <Card>
                        {progressIndicatorProps.visible ? <></> : <>
                            <CardContent>
                                <SoftBox>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={4} container justifyContent="center">
                                            <SoftBox position="relative" height="max-content" mx="auto">
                                                <SoftAvatar src={session && session.status === 1 ? stopWatch : warningIcon} size="xxl" variant="rounded" bgColor="transparent" />
                                            </SoftBox>
                                        </Grid>
                                        {blockingPlayers ? <Grid item xs={12} sm={8} style={{ verticalAlign: "middle" }}>
                                            <SoftBox >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    color="dark"
                                                    fontWeight="bold">
                                                    Following participants have unverified transaction that are preventing you from finalzing this session. Please verify or cancel these participants' pending transactions before proceeding.
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid> :
                                            <Grid item xs={12} sm={8}>
                                                <SoftBox style={{ verticalAlign: "top" }}>
                                                    <SoftTypography
                                                        component="label"
                                                        variant="h5"
                                                        color="dark"
                                                        fontWeight="bold">
                                                        {session && session.status === 1 ? "What would you like to do with this scheduled session?" : "Are you sure you want to " + getActionTitle() + " this session?"}

                                                    </SoftTypography>
                                                </SoftBox>
                                                {session && session.status === 1 ?
                                                    <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                                                        <SoftTypography mt={1}
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold">
                                                            Cancel session?
                                                        </SoftTypography>

                                                        <Switch checked={cancelSessionFlag} onChange={onCancelSessionSwitchChanged} mr={1} />
                                                    </SoftBox> : null}

                                                <SoftBox mt={1}>
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold">
                                                        {getActionWarning()}
                                                    </SoftTypography>
                                                    {(session.totalBuyIns - session.totalCashouts - session.totalTips - session.totalRake) > 0 ? <SoftTypography variant="caption" fontWeight="medium" color="error">
                                                        <NumericFormat value={session.totalBuyIns - session.totalCashouts - session.totalTips - session.totalRake} prefix="$" displayType="text" thousandSeparator={true} />&nbsp;
                                                        in chips appear to have not yet been cashed in. Please ensure that all of the chips have been cashed in before closing out the game session.
                                                    </SoftTypography> : null}
                                                </SoftBox>
                                                <SoftBox textAlign="center" mt={5}>
                                                    <SoftTypography
                                                        component="label"
                                                        variant="h6"
                                                        color="error"
                                                        fontWeight="bold">
                                                        This action cannot be undone!
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>}
                                    </Grid>
                                    {blockingPlayersTable ?
                                        <Grid container>
                                            <DataTable table={blockingPlayersTable} entriesPerPage={{ defaultValue: 5, visible: false }} />
                                        </Grid> : null}
                                </SoftBox>
                            </CardContent>
                            <CardActionArea>
                                <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                    {blockingPlayers ? <SoftBox m={3} ml="auto">
                                        <SoftButton variant="outlined" color="error" onClick={cancel}>
                                            Cancel
                                        </SoftButton></SoftBox> : <>
                                        <SoftBox m={3}>
                                            <SoftButton variant="outlined" color="error" onClick={cancel} disabled={isNoButtonDisabled()}>
                                                Close Dialog
                                            </SoftButton>
                                        </SoftBox>
                                        <SoftBox m={3}>
                                            <SoftButton m={3}

                                                color="dark"
                                                disabled={isYesButtonDisabled()}
                                                onClick={process}
                                            >
                                                {getYesButtonCaption()}
                                            </SoftButton>
                                        </SoftBox></>}
                                </SoftBox>
                            </CardActionArea>  </>}
                    </Card>
                </SoftBox>
            </DialogContent>
        </BootstrapDialog >
    );
};


const SessionExpenseReportDialog = ({ open, close, cardroom, session }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen


    function onCloseInitiated() {
        close();
    }


    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xxl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Game Expenses
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox m={1}>
                <Grid container spacing={1} >
                    <ExpensesWorksheet cardroom={cardroom} session={session} onClose={onCloseInitiated} />
                </Grid>
            </SoftBox>
        </DialogContent>
    </BootstrapDialog >
};

const ReconcileGameSessionDialog = ({ open, close, cardroom, session, title }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    title = title || "Game Balance Sheet";

    function onCloseInitiated() {
        resetDialogState();

        close();
    }

    function resetDialogState() {
        // setSessionCheckComplete(!(session && session.status === 3));
        // setBlockingPlayerIdList(null);
        // setBlockingPlayers(null);
        // setBlockingPlayersTable(null);
    }


    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xxl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    {title}
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox m={1}>
                <Grid container spacing={1} >
                    <ReconciliationWorksheet cardroom={cardroom} session={session} onClose={onCloseInitiated} />
                </Grid>
            </SoftBox>
        </DialogContent>
    </BootstrapDialog >
};

const SessionSummaryDialog = ({ open, close, cardroom, session }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen


    function onCloseInitiated() {
        resetDialogState();

        close();
    }

    function resetDialogState() {
        // setSessionCheckComplete(!(session && session.status === 3));
        // setBlockingPlayerIdList(null);
        // setBlockingPlayers(null);
        // setBlockingPlayersTable(null);
    }


    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xxl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Session Summary
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox m={1}>
                <Grid container spacing={1} >
                    <SessionSummaryReport cardroom={cardroom} session={session} onClose={onCloseInitiated} />
                </Grid>
            </SoftBox>
        </DialogContent>
        <DialogActions>
            <SoftButton onClick={close} variant="outlined" color="secondary">
                close
            </SoftButton>
        </DialogActions>
    </BootstrapDialog >
};


const SessionLiveSummaryDialog = ({ open, close, cardroom, session }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl')); //size at which dialog goes full screen


    function onCloseInitiated() {
        resetDialogState();

        close();
    }

    function resetDialogState() {
    }


    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xxl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Live Summary
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox m={1}>
                <Grid container spacing={1} >
                    <SessionLiveSummaryReport cardroom={cardroom} session={session} onClose={onCloseInitiated} />
                </Grid>
            </SoftBox>
        </DialogContent>
        <DialogActions>
            <SoftButton onClick={close} variant="outlined" color="secondary">
                close
            </SoftButton>
        </DialogActions>
    </BootstrapDialog >
};


const AddHouseTransactionDialog = ({ open, close, cardroom, session, onAdded }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    function onDialogClosureRequested() {
        close();
    }

    function onHouseTransactionAdded() {
        onDialogClosureRequested();

        if (onAdded) {
            onAdded();
        }
    }

    const [selectedTxTypeIndex, setSelectedTxTypeIndex] = useState(-1);
    const [selectedTxType, setSelectedTxType] = useState(null);

    useEffect(() => {
        //clear whenever dialog is opened...
        setSelectedTxType(dropDownData[0]);
        setSelectedTxTypeIndex(0);
    }, [open]);



    const dropDownData = [
        { id: 5, key: "rake", text: "Rake" },
        { id: 7, key: "expenses", text: "Expenses" }
    ];


    const dropDownSelectionChanged = (e) => {
        const item = e.item;
        const index = e.index;

        setSelectedTxTypeIndex(index);
        setSelectedTxType(item);
    }

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="xl">
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
                <SoftBox>
                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        New House Transaction
                    </SoftTypography>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>
                <Card style={{ minHeight: 200 }} mt={3}>
                    <Grid container xs={12}>
                        <Grid item xs={12} md={4} lg={3} sx={{ ml: "auto" }} >
                            <SoftBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                                <DataBoundSelect placeholder="Select transaction type" valueName="id" textName="text"
                                    data={dropDownData} onChange={dropDownSelectionChanged} size="medium" selectedIndex={selectedTxTypeIndex} />
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <SoftBox pb={0} px={2} textAlign="center" lineHeight={1.25}>
                                <HouseTransactionForm type={selectedTxType ? selectedTxType.key : null} cardroom={cardroom} session={session} onClose={onDialogClosureRequested} onTransactionCreated={onHouseTransactionAdded} />
                            </SoftBox></Grid>
                    </Grid>
                </Card>
            </DialogContent>
        </BootstrapDialog >
    );

};

export {
    AddPlayerDialog,
    AddStaffDialog,
    AddPlayerTransactionDialog,
    AddPlayerInlineTransactionDialog,
    AddStaffTransactionDialog,
    CloseSessionDialog,
    SessionExpenseReportDialog,
    SessionSummaryDialog,
    SessionLiveSummaryDialog,
    ReconcileGameSessionDialog,
    AddHouseTransactionDialog
};