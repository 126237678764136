
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import SoftButton from 'components/SoftButton';


const ButtonCell = ({ id, onButtonClicked, color, icon }) => {

    const handleAddButtonClick = (e)=>{
        if (onButtonClicked)
        {
            onButtonClicked(id);
        }
    };

    return (
        <SoftTypography variant="caption" fontWeight="medium" color={color}>
            <IconButton
                size="small"
                color="inherit"
                onClick={handleAddButtonClick}>
                <Icon fontSize="small" color="inherit">
                    {icon}
                </Icon>
            </IconButton>
        </SoftTypography>
    );
}

// Setting default values for the props of DefaultCell
ButtonCell.defaultProps = {
    color: "text",
    icon: "add"
};

// Typechecking props for the DefaultCell
ButtonCell.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  color: PropTypes.string,
  icon: PropTypes.string,
  onActionClicked: PropTypes.func
};

export default ButtonCell;
