
import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { Card, Tooltip } from '@mui/material';

import DataTable from 'components/Elements/DataTable';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import ButtonCell from 'components/Elements/DataTable/components/Cells/ButtonCell';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';

import { useDialog } from "components/Elements/Dialog/common";
import FeeScheduleEditorDialog from 'layouts/Club/components/AgentFees';
import AgentEditorDialog from 'layouts/Club/components/Agent';
import IconCell from 'components/Elements/DataTable/components/Cells/IconCell';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';

const AgencyAgents = ({ cardroom, agency }) => {

    function prepareAgentsForPresentation(agents, table) {
        let rows = [];

        for (var i = 0; i < agents.length; i++) {
            let agentImage = agents[i].name.substring(0, 1);

            rows.push({
                name: [agents[i].name, { image: agentImage }],
                displayName: agents[i].name,
                displayNameNoCase: agents[i].name.toLowerCase(),
                appAgentId: agents[i].appAgentId,
                playerCount: agents[i].playerCount,
                agentCount: agents[i].agentCount,
                superAgentName: agents[i].superAgent ? agents[i].superAgent.name : "None",
                superAgentId: agents[i].superAgent ? agents[i].superAgent.name : "None",
                sourceAppName: agents[i].app ? agents[i].app.name : "None",
                agent: agents[i]
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetEmptySchema(handler) {
        return {
            columns: [
                {
                    Header: "Agent",
                    accessor: "name",
                    Cell: ({ value: [name, data], row }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} participant={row && row.original && row.original.agent ? row.original.agent : null} onClick={handler} />
                    ),
                },
                { Header: "Agent Id", accessor: "appAgentId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "# Players", accessor: "playerCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "# Agents", accessor: "agentCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },

                {
                    Header: "Commission", accessor: "agent", Cell: ({ value }) => {

                        let icon = "done", color = "success";
                        let tooltip = "agent properties are properly set", valueText = "configured";

                        if (value.feeSchedule) {
                            return <TextCell value={value.feeSchedule.name} />;
                        }

                        if (!value.feeSchedule) {
                            icon = "error_outline";
                            color = "error";
                            tooltip = "fee schedule not set";
                            valueText = "not configured";
                        }


                        return <Tooltip title={tooltip}><span><IconCell value={valueText} icon={icon} color={color} /></span></Tooltip>;
                    }
                },
                { Header: "Source", accessor: "sourceAppName", Cell: ({ value }) => <TextCell value={value} /> },
            ],
            rows: []
        };
    }

    function openAgentEditor(agent) {
        if (!canEditAgentProfile()) return;

        if (agent) {
            setCurrentAgent(agent);
            openAgentEditorDialog();
        }
    }

    const [openAgentEditorDialog, openAgentEditorDialogProps] = useDialog();

    const [currentAgent, setCurrentAgent] = useState(null);

    const [agents, setAgents] = useState(null);

    const [agentsTable, setAgentsTable] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading club agents...", handleRetry)


    //
    const [context, actions, features] = useCardroomContext(cardroom);

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (!agents) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading agents...")
            showProgress();
            // [HttpGet("{clubId:Guid}/casino/{casinoId:Guid}/players", Name = "GetClubPlayers")]
            clubRequest.execute("GET", apiConfig.club.endpoint + "/agency/" + agency.id + "/casino/" + cardroom.id + "/agents").then((response) => {
                setAgents(response);
                setAgentsTable(null);
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load agents, please try again...")
            });
        }
    }, [clubRequest.execute, agents, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (agents && !agentsTable) {
            setAgentsTable(prepareAgentsForPresentation(agents, GetEmptySchema(canEditAgentProfile() ? openAgentEditor : null)));
            progressIndicatorProps.close();
        }
    }, [agents, agentsTable]);

    function canEditAgentProfile() {
        return true;
    }

    return <Card>
        <ProgressIndicator {...progressIndicatorProps} />
        {(cardroom && agency) && !progressIndicatorProps.visible ? <>
            {canEditAgentProfile() ?
                <AgentEditorDialog {...openAgentEditorDialogProps} cardroom={cardroom} club={agency} agent={currentAgent} /> : null}
            {agentsTable ?
                <DataTable table={agentsTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} pagination={{ color: "dark" }} /> : null}
        </> : null}
    </Card>;
};

export default AgencyAgents