import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import IconButton from "@mui/material/IconButton";
import SoftButton from "components/SoftButton";

import { NumericFormat } from "react-number-format";

function MiniActionCard({ bgColor, title, count, percentage, icon, direction, action }) {

    const theme = useTheme();

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };


    return (
        <Card>
            <SoftBox bgColor={bgColor} variant="gradient">
                <SoftBox p={2}>
                    <Grid container alignItems="center">
                        <Grid item xs={8}>
                            <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                                <SoftTypography style={{ whiteSpace: "nowrap" }}
                                    variant="button"
                                    color={bgColor === "white" ? "text" : "white"}
                                    opacity={bgColor === "white" ? 1 : 0.7}
                                    textTransform="capitalize"
                                    fontWeight={title.fontWeight}
                                >
                                    {title.text}
                                </SoftTypography>
                                <SoftTypography style={{ whiteSpace: "nowrap" }}
                                    variant="h6"
                                    fontWeight="bold"
                                    color={count.color ? count.color : (bgColor === "white" ? "dark" : "white")}>
                                    {count ? (count.useNumberFormatter ? <NumericFormat value={count.text} displayType="text" thousandSeparator={true} prefix={count.prefix} /> : (count.prefix ? count.prefix : "") + count.text) : null}{" "}
                                    <SoftTypography variant="button" color={percentage.color} fontWeight="bold">
                                        {percentage.text}
                                    </SoftTypography>
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={4}>
                            <SoftBox sx={{ display: { xs: action ? 'grid' : 'none', sm: 'grid' } }}
                                variant="gradient"
                                bgColor={bgColor === "white" ? icon.color : "white"}
                                color={bgColor === "white" ? "white" : "dark"}
                                width="3rem"
                                height="3rem"
                                marginLeft="auto"
                                borderRadius="md"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                shadow="md">
                                {action ?
                                    <IconButton
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        style={
                                            {
                                                border: "2px solid",
                                                borderColor: isHovering ? theme.palette.info.main : theme.palette.secondary.main
                                            }
                                        }
                                        size="small"
                                        color="inherit"
                                        variant="outlined"
                                        onClick={action}>
                                        <Icon fontSize="small" color="inherit" >
                                            {icon.component}
                                        </Icon>
                                    </IconButton> : <Icon fontSize="small" color="inherit" >
                                        {icon.component}
                                    </Icon>}
                            </SoftBox>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

// Setting default values for the props of MiniStatisticsCard
MiniActionCard.defaultProps = {
    bgColor: "white",
    title: {
        fontWeight: "medium",
        text: "",
    },
    percentage: {
        color: "success",
        text: ""
    },
    count: {
        text: "",
        useNumberFormatter: false,
        prefix: "",
        color: "white"
    },
    direction: "right",
};

// Typechecking props for the MiniStatisticsCard
MiniActionCard.propTypes = {
    bgColor: PropTypes.oneOf([
        "white",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
    ]),
    title: PropTypes.PropTypes.shape({
        fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
        text: PropTypes.string
    }),
    count: PropTypes.PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        useNumberFormatter: PropTypes.bool,
        prefix: PropTypes.string,
        color: PropTypes.oneOf([
            "white",
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
        ])
    }),
    percentage: PropTypes.shape({
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "white",
        ]),
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    icon: PropTypes.shape({
        color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
        component: PropTypes.node.isRequired,
        //click: PropTypes.func
    }).isRequired,
    direction: PropTypes.oneOf(["right", "left"]),
    action: PropTypes.func
};

export default MiniActionCard;
