

import React, { useState, useEffect } from 'react';

import { Grid } from '@mui/material';

import { Formik, Form, ErrorMessage } from "formik";
import FormField from "components/Elements/Forms/FormField";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";


import chipsAvatar from "assets/graphics/casino/promo_256.png";



const PromotionTransactionForm = ({ cardroom, player, session, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, amount, method, note, verified, promoType } = formField;
    const {
        type: typeV,
        amount: amountV,
        method: methodV,
        promoType: promoTypeV,
        verified: verifiedV,
        note: noteV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);
    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(type.name, 3);
            setFieldValue(method.name, "credit");

            if (session && session.status === 3) {
                setFieldValue(promoType.name, "credit");
            }
            else {
                setFieldValue(promoType.name, "chips");
            }

            setFieldValue(verified.name, true);

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    function onPromoTypeRadioGroupChanged(e, newValue) {
        setFieldValue(promoType.name, newValue);
    }

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={chipsAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {amount.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                    type={amount.type}
                                    name={amount.name}
                                    value={amountV}
                                    placeholder={amount.placeholder}
                                    error={errors.amount && touched.amount}
                                    success={amountV && amountV.toString().length > 0 && !errors.amount}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {promoType.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row={true} value={promoTypeV} onChange={onPromoTypeRadioGroupChanged}>
                                        {session && session.status !== 3 ?
                                            <FormControlLabel value="chips" control={<Radio />} label="Free Chips" /> : null}
                                        <FormControlLabel value="credit" control={<Radio />} label="Marker Credit" />
                                    </RadioGroup>
                                </SoftTypography>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    color="error">
                                    <ErrorMessage name={promoType.name} />
                                </SoftTypography>

                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {note.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()}
                                    type={note.type}
                                    name={note.name}
                                    value={noteV}
                                    placeholder={note.placeholder}
                                    error={errors.note && touched.note}
                                    success={noteV && noteV.length > 0 && !errors.note}
                                />
                            </Grid>
                        </Grid>

                        <FormField type="hidden"
                            name={verified.name}
                            value={verifiedV} />

                        <FormField type="hidden"
                            name={promoType.name}
                            value={promoTypeV} />
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>;

};




export default PromotionTransactionForm;