import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import defaultTxAvatar from "assets/graphics/personas/masseuse_256.png";

const DefaultTransactionForm = ({ cardroom, formData }) => {

    return <SoftBox minHeight="190px">
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={defaultTxAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold">
                                    Please select the transaction type you'd like to create from the dropdown located in the top right corner of this dialog.
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>

};



export default DefaultTransactionForm;