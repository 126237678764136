import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormImageField from 'components/Elements/Forms/FormImageField';

import FormField from "components/Elements/Forms/FormField";

import { ErrorMessage } from "formik";

import defaultTxAvatar from "assets/graphics/casino/expenses_256.png";
import FormSwitch from 'components/Elements/Forms/FormSwitch';
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';


const ExpenseForm = ({ cardroom, session, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, amount, note, method, provider, providerOther, providerRefMethod, providerRef, providerRefImage, reimburse, reimburseParticipantType,
        reimburseParticipant, reimburseParticipantMask } = formField;
    const {
        type: typeV,
        amount: amountV,
        tableNo: tableNoV,
        method: methodV,
        provider: providerV,
        providerOther: providerOtherV,
        providerRefMethod: providerRefMethodV,
        providerRef: providerRefV,
        providerRefImage: providerRefImageV,
        reimburse: reimburseV,
        reimburseParticipantType: reimburseParticipantTypeV,
        reimburseParticipant: reimburseParticipantV,
        reimburseParticipantMask: reimburseParticipantMaskV,
        note: noteV
    } = values;


    const [selectedParticipantIndex, setSelectedParticipantIndex] = useState(-1);
    const [participantList, setParticipantList] = useState(null);

    const [needParticipants, setNeedParticipants] = useState(false);

    const [participants, setParticipants] = useState(null);
    const [staff, setStaff] = useState(null);
    const [players, setPlayers] = useState(null);

    function onPaymentMethodRadioGroupChanged(e, newValue) {
        setFieldValue(method.name, newValue);
    }

    function onReimbubrseParticipantTypeChanged(e, newValue) {
        setFieldValue(reimburseParticipantType.name, newValue);

        setFieldValue(reimburseParticipant.name, "");
        setFieldValue(reimburseParticipantMask.name, 0);
        setSelectedParticipantIndex(-1);

        if (newValue === "staff") {
            setParticipantList(staff);
        }
        else if (newValue === "player") {
            setParticipantList(players);
        }
    }

    function onReimburseParticipantSelectionChanged({ item, index }) {
        if (item) {
            setFieldValue(reimburseParticipant.name, item.id);

            if (reimburseParticipantTypeV === "player")
                setFieldValue(reimburseParticipantMask.name, 1);
            else
                setFieldValue(reimburseParticipantMask.name, item.type & ~1);

            setSelectedParticipantIndex(index);

        }
    }

    function getParticipantLabel() {
        if (reimburseParticipantTypeV === "staff") return "Staff Member";
        return "Player";
    }


    function onEWalletProviderChanged(e, newValue) {
        setFieldValue(provider.name, newValue);
    }

    function onVendorRefMethodRadioGroupChanged(e, newValue) {
        setFieldValue(providerRefMethod.name, newValue);
    }

    const casinoReq = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read
    });

    const peopleReq = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    function onReimburseSwitchChanged(newValue) {
        if (newValue) {
            setNeedParticipants(true);
        }
        else {
            if (methodV === "credit") {
                setFieldValue(method.name, "cash");
            }
        }
    }
    function mergeAndSeparateParticipants(sessionParticipants, participantDetails) {

        const map = [];

        const aStaff = [];
        const aPlayers = []

        function sort(rows) {
            if (rows.length > 0) {
                rows.sort(function (a, b) {
                    let aName = a.displayName.toLowerCase();
                    let bName = b.displayName.toLowerCase();

                    if (aName < bName) { return -1; }
                    if (aName > bName) { return 1; }

                    return 0;
                });
            }
        }
        //it's possible for splayers to contain duped players that have different masks, i.e mask 4 (dealer) and mask 5 (dealer & player)
        //we should merge splayers into pplayers & if dupes are found, & mask

        if (sessionParticipants) {
            for (var i = 0; i < sessionParticipants.length; i++) {
                map[sessionParticipants[i].id] = sessionParticipants[i];
            }

            if (participantDetails) {
                const fmap = [];

                for (var i = 0; i < participantDetails.length; i++) {
                    let pp = map[participantDetails[i].id];
                    if (pp) {
                        pp = Object.assign(pp, participantDetails[i]);

                        if (!fmap[pp.id]) {
                            fmap[pp.id] = pp;

                            if ((pp.type & 1) > 0) {
                                aPlayers.push(pp);
                            }
                            if ((pp.type & 254) > 0) {
                                aStaff.push(pp);
                            }
                        }
                        else {
                            fmap[pp.id].type |= pp.type;
                        }


                        let phone = pp.phoneNumber, name = pp.name, nickName = null;

                        if (pp.properties) {

                            phone = pp.properties.phoneNumber ? pp.properties.phoneNumber : phone;
                            name = pp.properties.name ? pp.properties.name : name;
                            nickName = pp.properties.nickName ? pp.properties.nickName : nickName;
                        }

                        if (nickName) {
                            name = name + " \"" + nickName + "\"";
                        }

                        if (phone) {
                            //format phone
                            let formattedPhone = phone;
                            if (phone.length === 10) {
                                formattedPhone = "+1 (" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + " - ****";
                            }

                            name = name + " @ " + formattedPhone + "";
                        }

                        fmap[pp.id].displayName = name;
                    }
                }

                sort(aPlayers);
                sort(aStaff);

                setPlayers(aPlayers);
                setStaff(aStaff);
            }

        }

        //onReimbubrseParticipantTypeChanged(null, reimburseParticipantTypeV);
        if (reimburseParticipantTypeV === "staff") {
            setParticipantList(aStaff);
        }
        else {
            setParticipantList(aPlayers);
        }
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading session participants...", handleProgressRetry)
    useEffect(() => {
        if (needParticipants && !participants) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session participants...")
            showProgress();

            //{casinoId:Guid}/sessions/{sessionId:Guid}/participants/{type:int?}
            casinoReq.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/participants/255").then((response) => {
                if (response) {
                    if (response.length === 0) {
                        progressIndicatorProps.close();
                    }
                    setParticipants(response);
                    setNeedParticipants(false);
                }
            }).catch((ex) => {
                //setOverlayMessage("An error occured, please refresh");
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load session participants, plese try again...");
            });
        }
    }, [participants, needParticipants, casinoReq.execute, retryCounter]);

    useEffect(() => {
        if (participants && (!staff || !players)) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session participants' details...")

            let list = [];
            for (var i = 0; i < participants.length; i++) {
                list.push(participants[i].id);
            }

            if (list.length > 0) {
                peopleReq.execute("PUT", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", list).then((response) => {
                    if (response) {
                        mergeAndSeparateParticipants(participants, response);
                    }
                    progressIndicatorProps.close();
                }).catch((ex) => {
                    //setOverlayMessage("An error occured, please refresh");
                    progressIndicatorProps.setMode("errorWithRetry");
                    progressIndicatorProps.setMessage("Unable to load session participants' details, plese try again...");
                });
            }
        }
    }, [participants, staff, players, peopleReq.execute, retryCounter]);


    const [formDataInitialized, setFormDataInitialized] = useState(false);
    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(type.name, "expenses");
            setFieldValue(reimburse.name, false);
            setFieldValue(reimburseParticipantType.name, "staff");
            setFieldValue(reimburseParticipant.name, "");

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    return <SoftBox minHeight="190px">
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={defaultTxAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Amount
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                    type={amount.type}
                                    name={amount.name}
                                    value={amountV}
                                    placeholder={amount.placeholder}
                                    error={errors.amount && touched.amount}
                                    success={amountV && amountV.toString().length > 0 && !errors.amount}
                                /></Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {reimburse.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormSwitch mr="auto" onChange={onReimburseSwitchChanged}
                                    name={reimburse.name}
                                    value={reimburseV}
                                    {...formData} />
                            </Grid>
                        </Grid>

                        {reimburseV ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {reimburseParticipantType.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography pl={1.5}
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize">
                                        <RadioGroup row={true} value={reimburseParticipantTypeV} onChange={onReimbubrseParticipantTypeChanged}>
                                            <FormControlLabel value="staff" control={<Radio />} label="Staff" />
                                            <FormControlLabel value="player" control={<Radio />} label="Player" />
                                        </RadioGroup>

                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            color="error">
                                            <ErrorMessage name={reimburseParticipantType.name} />
                                        </SoftTypography>
                                    </SoftTypography>
                                </Grid>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {getParticipantLabel()}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    {progressIndicatorProps.visible ?
                                        <ProgressIndicator {...progressIndicatorProps} /> : <>
                                            <DataBoundSelect placeholder={reimburseParticipantTypeV} valueName="id" textName="displayName"
                                                data={participantList} selectedIndex={selectedParticipantIndex} onChange={onReimburseParticipantSelectionChanged} />

                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                color="error">
                                                <ErrorMessage name={reimburseParticipant.name} />
                                            </SoftTypography>
                                        </>}
                                </Grid>
                            </Grid>
                        </> : null}


                        <Grid container xs={12} mt={.25}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {method.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row={false} value={methodV} onChange={onPaymentMethodRadioGroupChanged}>
                                        <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                                        <FormControlLabel value="electronic" control={<Radio />} label="e-Wallet" />
                                        {reimburseV ?
                                            <FormControlLabel value="credit" control={<Radio />} label="Credit" /> : null}
                                    </RadioGroup>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={method.name} />
                                    </SoftTypography>
                                </SoftTypography>

                            </Grid>
                        </Grid>

                        {methodV === "electronic" ? <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {provider.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row value={providerV} onChange={onEWalletProviderChanged}>
                                        <FormControlLabel value="zelle" control={<Radio />} label="Zelle" />
                                        <FormControlLabel value="paypal" control={<Radio />} label="PayPal" />
                                        <FormControlLabel value="venmo" control={<Radio />} label="Venmo" />
                                        <FormControlLabel value="cashapp" control={<Radio />} label="CashApp" />
                                        <FormControlLabel value="apple" control={<Radio />} label="ApplePay" />
                                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                                    </RadioGroup>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={provider.name} />
                                    </SoftTypography>
                                </SoftTypography>
                            </Grid>
                        </Grid> : null}

                        {methodV === "electronic" && providerV === "other" ? <Grid container xs={12}>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {providerOther.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField type={providerOther.type}
                                        name={providerOther.name}
                                        value={providerOtherV}
                                        placeholder={providerOther.placeholder}
                                        error={errors.providerOther && touched.providerOther}
                                        success={providerOtherV && providerOtherV.length > 0 && !errors.providerOther}
                                    />
                                </Grid>
                            </Grid>
                        </Grid> : null}

                        {methodV === "electronic" ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {providerRefMethod.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography
                                        variant="caption"
                                        textTransform="capitalize">
                                        <RadioGroup row={true} value={providerRefMethodV} onChange={onVendorRefMethodRadioGroupChanged} style={{ marginLeft: "0.75em" }}>
                                            <FormControlLabel value="number" control={<Radio />} label="Number" />
                                            <FormControlLabel value="image" control={<Radio />} label="Image" />
                                        </RadioGroup>
                                    </SoftTypography>
                                </Grid>
                            </Grid>

                            {providerRefMethodV === "number" ?
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {providerRef.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormField type={providerRef.type}
                                            name={providerRef.name}
                                            value={providerRefV}
                                            placeholder={providerRef.placeholder}
                                            error={errors.providerRef && touched.providerRef}
                                            success={providerRefV && providerRefV.toString().length > 0 && !errors.providerRef}
                                        />

                                    </Grid>
                                </Grid> : null}

                            {providerRefMethodV === "image" ?
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                {providerRefImage.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormImageField name={providerRefImage.name} imgWidth="120px" {...formData} />
                                    </Grid>
                                </Grid> : null}
                        </> : null}

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {note.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()}
                                    type={note.type}
                                    name={note.name}
                                    value={noteV}
                                    placeholder={note.placeholder}
                                    error={errors.note && touched.note}
                                    success={noteV && noteV.length > 0 && !errors.note}
                                /></Grid>
                        </Grid>
                    </SoftBox>
                </Grid>

            </Grid>
        </SoftBox>
    </SoftBox>

};

export default ExpenseForm;