import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';


import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { BootstrapDialog } from "components/Elements/Dialog/common"


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import FormField from 'components/Elements/Forms/FormField';
import SoftAvatar from 'components/SoftAvatar';
import FormSwitch from 'components/Elements/Forms/FormSwitch';
import FormImageField from 'components/Elements/Forms/FormImageField';
import PhoneCell from 'components/Elements/DataTable/components/Cells/PhoneCell';
import PartnerSelector from 'components/Elements/Partner/PartnerSelector';
import { useCardroomContext } from 'features';

const StaffEditorForm = ({ cardroom, staff, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { candidateId, partnerId, name, nickName, phoneNumber, withholdingRate, withholdingThreshold, withholdingSchedule, mustNotify, notificationMask, isAnonymous, photo, mask } = formField;
    const {
        candidateId: candidateIdV,
        partnerId: partnerIdV,
        name: nameV,
        nickName: nickNameV,
        phoneNumber: phoneNumberV,
        withholdingRate: withholdingRateV,
        withholdingThreshold: withholdingThresholdV,
        withholdingSchedule: withholdingScheduleV,
        photo: photoV,
        mask: maskV,
        isAnonymous: isAnonymousV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    function onResponsiblePartnerChanged(id) {
        setFieldValue(partnerId.name, id);
    }

    function onWithholdingSchedChanged(e, newValue) {
        setFieldValue(withholdingSchedule.name, newValue);
    }

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditStaff() {
        if (!context) return false;
        return context.isAllowed(actions.participant.staff.edit);
    }

    function mapWithholdingSchedule(v) {
        if (v === 1) return "flat";
        if (v === 2) return "progressive";
        return "none";
    }
    useEffect(() => {
        if (!formDataInitialized) {

            let sn = staff.name;
            let phone = staff.phoneNumber;
            let hasPhoto = staff.hasPhoto;

            setFieldValue(withholdingSchedule.name, mapWithholdingSchedule(0));

            if (staff.properties) {
                sn = staff.properties.name ? staff.properties.name : name;
                phone = staff.properties.phoneNumber ? staff.properties.phoneNumber : phone;
                hasPhoto = staff.properties.hasPhoto ? staff.properties.hasPhoto : hasPhoto;

                setFieldValue(nickName.name, staff.properties.nickName ? staff.properties.nickName : "");
                setFieldValue(withholdingRate.name, staff.properties.withholdingRate * 100);

                setFieldValue(withholdingThreshold.name, staff.properties.withholdingThreshold);
                setFieldValue(withholdingSchedule.name, mapWithholdingSchedule(staff.properties.withholdingSchedule));

                setFieldValue(mustNotify.name, staff.properties.mustNotify);
                setFieldValue(notificationMask.name, staff.properties.notificationMask);

                setFieldValue(mask.name, staff.properties.participantType);
                setFieldValue(partnerId.name, staff.properties.responsiblePartnerId);
            }

            if (hasPhoto)
                setFieldValue(photo.name, apiConfig.images.endpoint + "/player/" + staff.id + ".jpg");


            setFieldValue(candidateId.name, staff.id);
            setFieldValue(name.name, sn);
            setFieldValue(phoneNumber.name, phone ? phone : "");
            setFieldValue(isAnonymous.name, staff.isAnonymous);


            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    function getStaffImagePresenter({ src }) {

        return <SoftAvatar src={src} size="xxl" variant="rounded" bgColor="dark" />
    }

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        {canEditStaff() ?
                            <FormImageField name={photo.name} caption="Drop staff photo here" imgHeight={120} imagePresenter={getStaffImagePresenter}  {...formData} />
                            : <SoftAvatar src={photoV} size="xxl" variant="rounded" bgColor="dark" />}
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {phoneNumber.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                {isAnonymousV ? <SoftTypography variant="caption" fontWeight="medium" color="text">Anonymous</SoftTypography> :
                                    <PhoneCell value={phoneNumberV} obfuscate={!canEditStaff()} />}
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {name.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditStaff()}
                                    type={name.type}
                                    name={name.name}
                                    value={nameV}
                                    placeholder={name.placeholder}
                                    error={errors.name && touched.name}
                                    success={nameV && nameV.length > 0 && !errors.name}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12} mb={1.5}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {partnerId.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <PartnerSelector readonly={!canEditStaff()} cardroom={cardroom} label={partnerId.label} value={partnerIdV} onPartnerChanged={onResponsiblePartnerChanged} />

                                <SoftTypography component="div" variant="caption" color="error">
                                    <ErrorMessage name={partnerId.name} />
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {nickName.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditStaff()}
                                    type={nickName.type}
                                    name={nickName.name}
                                    value={nickNameV}
                                    placeholder={nickName.placeholder}
                                    error={errors.nickName && touched.nickName}
                                    success={nickNameV && nickNameV.length > 0 && !errors.nickName}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {withholdingSchedule.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row={true} defaultValue={withholdingScheduleV} value={withholdingScheduleV} onChange={onWithholdingSchedChanged} style={{ marginLeft: "1.25em", marginTop: "3px" }}>
                                        <FormControlLabel value="none" control={<Radio />} label="None" />
                                        <FormControlLabel value="flat" control={<Radio />} label="Flat Rate" />
                                        <FormControlLabel value="progressive" control={<Radio />} label="Progressive" />
                                    </RadioGroup>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={withholdingSchedule.name} />
                                    </SoftTypography>
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        {withholdingScheduleV != "none" ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {withholdingRate.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField disabled={!canEditStaff()}
                                        type={withholdingRate.type}
                                        name={withholdingRate.name}
                                        value={withholdingRateV}
                                        placeholder={withholdingRate.placeholder}
                                        error={errors.withholdingRate && touched.withholdingRate}
                                        success={withholdingRateV && withholdingRateV.toString().length > 0 && !errors.withholdingRate}
                                    />
                                </Grid>
                            </Grid>


                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {withholdingThreshold.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField disabled={!canEditStaff()}
                                        type={withholdingThreshold.type}
                                        name={withholdingThreshold.name}
                                        value={withholdingThresholdV}
                                        placeholder={withholdingThreshold.placeholder}
                                        error={errors.withholdingThreshold && touched.withholdingThreshold}
                                        success={withholdingThresholdV && withholdingThresholdV.toString().length > 0 && !errors.withholdingThreshold}
                                    />
                                </Grid>
                            </Grid>


                        </>
                            : null}
                        <Grid container xs={12}>
                            <Grid container xs={12}>
                                <Grid item xs={12}>
                                    <SoftBox mb={1} mt={0} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Staff Roles
                                        </SoftTypography>
                                        <SoftBox mt={0.75}>
                                            <SoftTypography component="div" variant="caption" color="error">
                                                <ErrorMessage name={mask.name} />
                                            </SoftTypography>
                                        </SoftBox>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container xs={12}>
                                <Grid item xs={4}>
                                    <SoftBox ml={0.5} mb={0.25}>
                                        <FormSwitch label="Host" readOnly={!canEditStaff()}
                                            name={mask.name}
                                            value={maskV}
                                            mask={2}
                                            variant="bit"
                                            {...formData} />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={4}>
                                    <SoftBox ml={0.5} mb={0.25}>
                                        <FormSwitch label="Dealer" readOnly={!canEditStaff()}
                                            name={mask.name}
                                            value={maskV}
                                            mask={4}
                                            variant="bit"
                                            {...formData} />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={4}>
                                    <SoftBox ml={0.5} mb={0.25}>
                                        <FormSwitch label="Chef" readOnly={!canEditStaff()}
                                            name={mask.name}
                                            value={maskV}
                                            mask={8}
                                            variant="bit"
                                            {...formData} />
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container xs={12}>
                                <Grid item xs={4}>
                                    <SoftBox ml={0.5} mb={0.25}>
                                        <FormSwitch label="Valet" readOnly={!canEditStaff()}
                                            name={mask.name}
                                            value={maskV}
                                            mask={16}
                                            variant="bit"
                                            {...formData} />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={4}>
                                    <SoftBox ml={0.5} mb={0.25}>
                                        <FormSwitch label="Security" readOnly={!canEditStaff()}
                                            name={mask.name}
                                            value={maskV}
                                            mask={32}
                                            variant="bit"
                                            {...formData} />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={4}>
                                    <SoftBox ml={0.5} mb={0.25}>
                                        <FormSwitch label="Other" readOnly={!canEditStaff()}
                                            name={mask.name}
                                            value={maskV}
                                            mask={64}
                                            variant="bit"
                                            {...formData} />
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Hidden Fields */}

                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;
};

const StaffEditor = ({ cardroom, staff, onClose }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;

    const [staffPhoto, setStaffPhoto] = useState(null);
    const [staffRecord, setStaffRecord] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving staff...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.people.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditStaff() {
        if (!context) return false;
        return context.isAllowed(actions.participant.staff.edit);
    }

    const handleSubmit = (values, actions) => {

        if (false === canEditStaff()) return;

        function mapWithholdingSchedule(sched, rate) {
            if (sched === "flat") return 1;
            if (sched === "progressive") return 2;

            if (rate > 0) return "flat";

            return "none";

        }
        //construct participant object
        const ns = Object.assign({}, staff);

        //update staff...

        ns.properties.name = values.name;
        ns.properties.nickName = values.nickName;

        //need to upload photo...
        if (values.photo) {
            ns.properties.hasPhoto = true;

            setStaffPhoto(values.photo);
        }

        let withhold = true;
        if (values.withholdingSchedule === "none") {
            withhold = false;
        }
        if (values.withholdingRate === 0) {
            withhold = false;
        }

        ns.properties.phoneNumber = values.phoneNumber;
        //ns.properties.creditLimit = values.creditLimit;
        ns.properties.withholdingRate = withhold ? values.withholdingRate / 100 : 0;
        ns.properties.withholdingThreshold = withhold ? values.withholdingThreshold : 0;
        ns.properties.withholdingSchedule = withhold ? mapWithholdingSchedule(values.withholdingSchedule, ns.properties.withholdingRate) : 0;
        ns.properties.mustSign = values.mustSign;
        ns.properties.mustNotify = values.mustNotify;
        ns.properties.notificationMask = values.notificationMask;
        ns.properties.responsiblePartnerId = values.partnerId;
        ns.properties.participantType = values.mask;

        //add other properties

        //post it to user service

        setStaffRecord(ns);
    };

    useEffect(() => {
        if (staffPhoto) {
            //[HttpPost("venue/{venueId:Guid}/participants/{participantId:Guid}/image", Name = "SaveParticipantImage")]
            execute("POST", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants/" + staff.id + "/image", staffPhoto).then((response) => {
                if (response) {
                    //raiseOnTransactionCreated();
                }
                //clear payload and close dialog...
                // setTransactionSavePayload(null);
                // progressIndicatorProps.close();

                setStaffPhoto(null);

            }).catch((e) => {
                //progressIndicatorProps.setMode("errorWithRetry");
                //progressIndicatorProps.setMessage("Unable to save transaction, please try again...")
            });
        }

    }, [staffPhoto, execute]);

    useEffect(() => {
        if (staffRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving staff profile...")
            showProgress();

            execute("POST", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", staffRecord).then((response) => {
                if (response) {
                    //raiseOnTransactionCreated();
                    staff = Object.assign(staff, response);
                }

                setStaffRecord(null);
                progressIndicatorProps.close();
                raiseOnClose();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save staff profile, please try again...")
            });
        }

    }, [staffRecord, execute, retryCounter]);

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <StaffEditorForm cardroom={cardroom} staff={staff} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canEditStaff()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

StaffEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    staff: PropTypes.object.isRequired,

};

const StaffEditorDialog = ({ open, close, cardroom, staff }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Staff Properties: {staff ? staff.name : ""}
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox mt={3}>
                <StaffEditor cardroom={cardroom} staff={staff} onClose={close} />
            </SoftBox>
        </DialogContent>
    </BootstrapDialog>;
}

StaffEditorDialog.defaultProps = {

};

StaffEditorDialog.propTypes = {
    cardroom: PropTypes.object.isRequired,
    staff: PropTypes.object.isRequired,

};

export {
    StaffEditor,
    StaffEditorDialog
};
