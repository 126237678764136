
const form = {
    formId: "staff-buy-in-transaction-form",
    formField: {
        type: {
            name: "type",
            type: "hidden"
        },
        amount: {
            name: "amount",
            label: "Amount",
            type: "number",
            placeholder: "amount",
            errorMsg: "Amount must be greater than 0.",
        },
        tableNo: {
            name: "tableNo",
            label: "Table Number",
            type: "number",
            placeholder: "table number",
            errorMsg: "table number is required",
        },
        method: {
            name: "method",
            label: "Payment method",
            type: "text",
            placeholder: "payment method",
            errorMsg: "Payment method is required",
        },
        provider: {
            name: "provider",
            label: "e-Wallet vendor",
            type: "text",
            placeholder: "eWallet vendor",
            errorMsg: "eWallet vendor is required when using eWallet payment method"
        },
        providerOther: {
            name: "providerOther",
            label: "e-Wallet vendor name",
            type: "text",
            placeholder: "eWallet vendor name",
            errorMsg: "eWallet vendor name is required when using eWallet payment method with 'other' provider"
        },
        providerRefMethod: {
            name: "providerRefMethod",
            label: "Vendor reference type",
            type: "text",
            placeholder: "vendor reference type",
            errorMsg: "Vendor reference type is required when payment is verified"
        },
        providerRef: {
            name: "providerRef",
            label: "Vendor reference #",
            type: "text",
            placeholder: "vendor reference #",
            errorMsg: "Vendor reference is required when payment is verified"
        },
        providerRefImage: {
            name: "providerRefImage",
            label: "Vendor reference document",
            type: "hidden",
            placeholder: "vendor reference doc",
            errorMsg: "Vendor ref document is required when using eWallet"
        },
        reimburse: {
            name: "reimburse",
            label: "Reimburse participant",
            type: "bool",
            placeholder: "reimburse participant",
            errorMsg: "Participant reimbursement must be selected"
        },
        reimburseParticipantType: {
            name: "reimburseParticipantType",
            label: "Participant type",
            type: "string",
            placeholder: "participant type",
            errorMsg: "Participant type must be selected"
        },
        reimburseParticipant: {
            name: "reimburseParticipant",
            label: "Participant",
            type: "string",
            placeholder: "participant",
            errorMsg: "Participant must be selected"
        },
        reimburseParticipantMask: {
            name: "reimburseParticipantMask",
            label: "Participant mask",
            type: "hidden"
        },
        note: {
            name: "note",
            label: "Description",
            placeholder: "expense description",
            type: "text",
            placeholder: "Note",
            errorMsg: "Description is required"
        }
    },

};

export default form;
