
const form = {
    formId: "agent-profile-editor-form",
    formField: {
        clubId: {
            name: "clubId",
            label: "Club",
            type: "hidden",
            errorMsg: "a club must be selected"
        },
        feeSchedule: {
            name: "feeSchedule",
            label: "Fee Schedule",
            type: "text",
            placeholder: "fee schedule",
            errorMsg: "fee schedule is required",
        },
        referralSchedule: {
            name: "referralSchedule",
            label: "Referral Schedule",
            type: "text",
            placeholder: "referral schedule",
            errorMsg: "referral schedule is required",
        },
        referredClub: {
            name: "referredClub",
            label: "Referred Club",
            type: "text",
            placeholder: "referred club",
            errorMsg: "referred club is required",
        }
    },

};

export default form;
