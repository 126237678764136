import React, { useState, useEffect } from 'react';

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import wavesWhite from "assets/images/shapes/waves-white.svg";

import defaultCasino from "assets/graphics/logos/default-casino.png";

import { useDialog } from "components/Elements/Dialog/common";
import { AddPlayerDialog, AddStaffDialog } from "layouts/Cardroom/Session/components/Dialogs";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import MiniActionCard from "components/Elements/MiniActionCard";

import AccountingCard from '../Accounting';
import CasinoPlayersCard from '../CasinoPlayers';
import GameHistoryCard from '../GameHistory';
import CasinoStaffCard from '../CasinoStaff';
import RecordAccountTransactionDialog from '../Accounting/Dialogs';
import NewSessionToolbar from '../Session/NewSession';
import ExistingSessionToolbar from '../Session/ExistingSession';
import { useCardroomContext } from 'features';
import { Link } from '@mui/material';
import { CasinoSettingsDialog } from 'components/Elements/Dialog/settings';
import { DebtServiceDialog } from '../Dialogs';
import CardroomLogo from 'components/Elements/CardroomLogo';

//import { hasAccess } from 'state/security/securitySlice';
//import CasinoSecurityContext from 'components/Elements/Security';
//import { useDispatch } from 'react-redux';


function CardroomDetailsDetails({ cardroom }) {

    const TabPanel = function (props) {
        const { children, value, index, ...other } = props;
        return (
            <div role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}>
                {value === index && (
                    <SoftBox >
                        <SoftTypography>{children}</SoftTypography>
                    </SoftBox>
                )}
            </div>
        );
    }

    const a11yProps = function (index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    const [openAddPlayerDialog, addPlayerDialogProps] = useDialog();

    const [openAddStaffDialog, addStaffDialogProps] = useDialog();

    const [openRecordTxDialog, recordTxDialogProps] = useDialog();


    const [openDebtServiceDialog, debtServiceDialogProps] = useDialog();

    //const cardroom = props.cardroom ? props.cardroom : null;

    const [cardroomDetailsData, setCardroomDetailsData] = useState(null);
    const [cardroomStats, setCardroomStats] = useState({});
    const [cardroomAccount, setCardroomAccount] = useState({});

    const cardroomRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading cardroom details...", handleRetry)

    function refreshCardroomProperties() {
        setCardroomStats(null);
        //setCardroomAccount(null);
    }

    const [context, actions, features] = useCardroomContext(cardroom);

    useEffect(() => {
        if (!cardroomDetailsData || cardroomDetailsData.id != cardroom.id) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading cardroom details...")
            showProgress();
            cardroomRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id).then((response) => {
                setCardroomDetailsData(response);
                setCardroomStats(null);
                //progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino details, please try again...")
            });
        }
    }, [cardroomRequest.execute, cardroomDetailsData, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (cardroomDetailsData && !cardroomStats) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino stats...")
            showProgress();
            peopleRequest.execute("GET", apiConfig.people.endpoint + "/venue/" + cardroom.id).then((response) => {
                setCardroomStats(response);
                setCardroomAccount(null);
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino stats, please try again...")
            });
        }
    }, [peopleRequest.execute, cardroomStats, cardroomDetailsData, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (cardroomDetailsData && cardroomStats && (!cardroomAccount || cardroomAccount.accountHolderId != cardroom.id)) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino account...")
            showProgress();
            // [HttpGet("{entityId:Guid}/holders/{holderId:Guid}/accounts/{type:int}", Name = "GetEntityAccount")]
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + cardroom.id + "/accounts/2").then((response) => {
                setCardroomAccount(response);
                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino account, please try again...")
            });
        }
    }, [accountRequest.execute, cardroomAccount, cardroomStats, cardroomDetailsData, retryCounter]) //cardroomListData 

    function canAddParticipants() {
        if (!context) return false;
        return context.isAllowed(actions.casino.participant.add);
    }
    function canAddAccountTransaction() {
        if (!context) return false;
        return context.isAllowed(actions.casino.account.transaction.add);
    }

    {/* Accounts receivable (AR), Accounts payable (AP)*/ }
    return <>
        {cardroomDetailsData && !progressIndicatorProps.visible ? <>

            <DebtServiceDialog {...debtServiceDialogProps} cardroom={cardroom} />
            {canAddParticipants() ? <>
                <AddStaffDialog {...addStaffDialogProps} cardroom={cardroom} mode="venue" onAdded={refreshCardroomProperties} />
                <AddPlayerDialog {...addPlayerDialogProps} cardroom={cardroom} mode="venue" onAdded={refreshCardroomProperties} />
            </> : null}
            {canAddAccountTransaction() ?
                <RecordAccountTransactionDialog {...recordTxDialogProps} cardroom={cardroom} account={cardroomAccount} onAdded={refreshCardroomProperties} mode="venue" />
                : null}

            <SoftBox mb={3} p={1}>
                <SoftBox
                    position="relative"
                    bgColor="secondary"
                    py={3}
                    px={{ xs: 3, sm: 6, md: 1 }}
                    mt={3}
                    borderRadius="xl"
                    variant="gradient" >

                    <SoftBox
                        component="img"
                        src={wavesWhite}
                        alt="pattern-line"
                        width="100%"
                        position="absolute"
                        left="0"
                        top="0"
                        opacity={0.4}
                    />

                    <Grid container alignItems="center" position="relative">

                        <CardroomLogo cardroom={cardroomDetailsData} canOpenProperties={true} />

                        <Grid item xs={12} lg={11}>
                            <Grid container>
                                <Grid item xs={6} lg={3} p={1} >
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "AR", fontWeight: "medium" }}
                                        count={{ text: cardroomAccount ? cardroomAccount.accountsReceivable : 0, prefix: "$", color: "warning", useNumberFormatter: true }}
                                        icon={{ component: "credit_card" }}
                                        action={openDebtServiceDialog} />

                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "AP", fontWeight: "medium" }}
                                        count={{ text: cardroomAccount ? cardroomAccount.accountsPayable : 0, prefix: "$", color: "error", useNumberFormatter: true }}
                                        icon={{ component: "money" }}
                                        action={openDebtServiceDialog} />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "# of players / staff", fontWeight: "medium" }}
                                        count={{ text: cardroomStats ? cardroomStats.venuePlayers + " / " + cardroomStats.venueStaff : "0 / 0" }}
                                        icon={{ component: "group" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "# of games", fontWeight: "medium" }}
                                        count={{ text: cardroomDetailsData ? cardroomDetailsData.totalSessions : 0, useNumberFormatter: true }}
                                        icon={{ color: "info", component: "casino" }}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </SoftBox>
            </SoftBox>

            <SoftBox mb={3} p={1}>
                <Grid container spacing={1.5} alignItems="center">
                    <Grid item xs={12} md={6} lg={4}>
                        <AppBar position="static">
                            <SoftBox sx={{ ml: 3 }}>
                                {tabValue === 0 ? cardroomDetailsData && cardroomDetailsData.currentSession && cardroomDetailsData.currentSession.status < 3 ? <ExistingSessionToolbar cardroom={cardroomDetailsData} /> : <NewSessionToolbar cardroom={cardroomDetailsData} /> : null}
                                {tabValue === 1 && canAddParticipants() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openAddPlayerDialog}><Icon size="lg">person_add_alt_sharp</Icon>&nbsp;Add Player</SoftButton> : null}
                                {tabValue === 2 && canAddParticipants() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openAddStaffDialog}><Icon size="lg">badge</Icon>&nbsp;Add Staff</SoftButton> : null}
                                {tabValue === 3 && canAddAccountTransaction() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openRecordTxDialog}><Icon size="lg">receipt_long</Icon>&nbsp;Record Transaction</SoftButton> : null}
                            </SoftBox>
                        </AppBar>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
                        <AppBar position="static">
                            <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue} sx={{ background: "transparent" }}>
                                <Tab label="Games" icon={<Icon fontSize="small" color="inherit">casino</Icon>}  {...a11yProps(0)} />
                                <Tab label="Players" icon={<Icon fontSize="small" color="inherit">group</Icon>} {...a11yProps(1)} />
                                <Tab label="Staff" icon={<Icon fontSize="small" color="inherit">local_bar</Icon>} {...a11yProps(2)} />
                                <Tab label="Debt Service" icon={<Icon fontSize="small" color="inherit">payments</Icon>}  {...a11yProps(3)} />
                            </Tabs>
                        </AppBar>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>
                        <SoftBox>
                            <TabPanel value={tabValue} index={0}>
                                <GameHistoryCard cardroom={cardroomDetailsData} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <CasinoPlayersCard cardroom={cardroomDetailsData} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <CasinoStaffCard cardroom={cardroomDetailsData} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                <AccountingCard cardroom={cardroomDetailsData} account={cardroomAccount} />
                            </TabPanel>
                        </SoftBox>
                    </Grid>
                </Grid>
            </SoftBox></> : <ProgressIndicator {...progressIndicatorProps} />}
    </>;
}

export default CardroomDetailsDetails;