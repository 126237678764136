import React, { useState, useEffect } from 'react';
import PropTypes, { number } from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import DataTable from 'components/Elements/DataTable';
import SoftBox from 'components/SoftBox';
import { AppBar, Grid, Icon, Tab, Tabs } from '@mui/material';
import PremiumServiceSubscriptionRequired from 'components/Elements/PremiumServiceRequried';
import SoftTypography from 'components/SoftTypography';

const ReportAgents = ({ cardroom, agency, reportingPeriod, summary }) => {

    return "AGENCY REPORT AGENTS";
}

ReportAgents.propTypes = {
    cardroom: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired
};

const ReportPlayers = ({ cardroom, agency, reportingPeriod, summary }) => {

    return "AGENCY REPORT PLAYERS";
}

ReportPlayers.propTypes = {
    cardroom: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired
};

const ReportSummary = ({ cardroom, agency, reportingPeriod, summary }) => {

    return "AGENCY REPORT SUMMARY";
}

ReportSummary.propTypes = {
    cardroom: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired
};

const AgencyReport = ({ cardroom, agency, reportingPeriod, summary }) => {

    const TabPanel = function (props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <SoftBox sx={{ p: 3 }}>
                        <SoftTypography>{children}</SoftTypography>
                    </SoftBox>
                )}
            </div>
        );
    }

    const a11yProps = function (index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    const [context, actions, features] = useCardroomContext(cardroom);

    function isAgentReportEnabled() {
        if (!context) return false;
        return context.isEnabled(features.club.reporting.agentReport);
    }

    function isPlayerReportEnabled() {
        if (!context) return false;
        return context.isEnabled(features.club.reporting.playerReport);
    }

    return <SoftBox>
        <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
                <AppBar position="static">
                </AppBar>
            </Grid>
            <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
                <AppBar position="static">
                    <Tabs
                        orientation="horizontal"
                        value={tabValue}
                        onChange={handleSetTabValue}
                        sx={{ background: "transparent" }}>
                        <Tab label="Summary" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">dashboard</Icon>} {...a11yProps(0)} />
                        <Tab label="Agents" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">support_agent</Icon>} {...a11yProps(1)} />
                        <Tab label="Players" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">person</Icon>} {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
            </Grid>

        </Grid>
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>

                <SoftBox>
                    <TabPanel value={tabValue} index={0}>
                        <ReportSummary cardroom={cardroom} agency={agency} reportingPeriod={reportingPeriod} summary={summary} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        {isAgentReportEnabled() ?
                            <ReportAgents cardroom={cardroom} agency={agency} reportingPeriod={reportingPeriod} summary={summary} />
                            : <PremiumServiceSubscriptionRequired service="detailed agent reports" />}
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        {isPlayerReportEnabled() ?
                            <ReportPlayers cardroom={cardroom} agency={agency} reportingPeriod={reportingPeriod} summary={summary} />
                            : <PremiumServiceSubscriptionRequired service="detailed player reports" />}
                    </TabPanel>

                </SoftBox>
            </Grid>
        </Grid>
    </SoftBox>;
};

AgencyReport.defaultProps = {

};

AgencyReport.propTypes = {
    cardroom: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired
};

export default AgencyReport;