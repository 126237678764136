import React from 'react';
import PropTypes from "prop-types";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { BootstrapDialog } from "components/Elements/Dialog/common"
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import UnionEditor from './Editor';

const UnionEditorDialog = ({ open, close, cardroom, union, subunion, onUnionChanges }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Union: {subunion ? subunion.name : "<new>"}
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox mt={3}>
                <UnionEditor cardroom={cardroom} union={union} subunion={subunion} onClose={close} onUnionAdded={onUnionChanges}/>
            </SoftBox>
        </DialogContent>
    </BootstrapDialog>;
}

UnionEditorDialog.defaultProps = {

};

UnionEditorDialog.propTypes = {
    cardroom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    subunion: PropTypes.object.isRequired

};

export default UnionEditorDialog;
