import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { apiConfig } from 'config/apiConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

import { Formik, Form } from "formik";

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import RakeForm from './RakeForm';
import ExpenseForm from './ExpenseForm';

function getFormContent(type, cardroom, session, formData) {
    switch (type) {
        case "rake":
            return <RakeForm cardroom={cardroom} session={session} formData={formData} />;
        case "expenses":
            return <ExpenseForm cardroom={cardroom} session={session} formData={formData} />;
    }

}


const HouseTransactionForm = ({ type, cardroom, session, onClose, onTransactionCreated }) => {

    const { formId, formField } = form;
    const currentValidation = validations[type];

    const [transactionSavePayload, setTransactionSavePayload] = useState(null);
    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving transaction...", handleRetry)

    const handleSubmit = (values, actions) => {

        function getPaymentType(method) {
            if (method === "cash") return 1;
            if (method === "electronic") return 2;
            if (method === "credit") return 3;
            return 0;
        }

        let list = [];

        if (values.type === "rake") {
            //build down transactions, there should be 2, 1 for rake, 1 for tips

            //rake
            list.push({
                sessionId: session.id,
                paymentType: getPaymentType("credit"),
                type: 5,
                status: 2, //for cashouts, we should verify if cash or marker, pending if ewallt?
                amount: values.amount,
                signatureRequired: false,
                participantId: cardroom.id,
                particpantType: 32768, //house
                note: "Table " + values.tableNo
            });
        }
        else if (values.type === "expenses") {
            //should this be a partner-specific expense?

            if (values.reimburse) {
                list.push({
                    sessionId: session.id,
                    paymentType: getPaymentType(values.method),
                    type: 7, //exepense
                    status: 2, //verified
                    amount: values.amount,
                    signatureRequired: false,
                    participantId: values.reimburseParticipant,
                    particpantType: values.reimburseParticipantMask,
                    ePaymentVendor: values.provider === "other" ? values.providerOther : values.provider,
                    ePaymentVendorReferenceNo: values.providerRef ? values.providerRef : null,
                    ePaymentVendorReferenceImage: values.providerRefImage ? values.providerRefImage : null,
                    note: values.note
                });
            }
            else {
                list.push({
                    sessionId: session.id,
                    paymentType: getPaymentType(values.method),
                    type: 7, //exepense
                    status: 2, //verified
                    amount: values.amount,
                    signatureRequired: false,
                    participantId: cardroom.id,
                    particpantType: 32768,
                    ePaymentVendor: values.provider === "other" ? values.providerOther : values.provider,
                    ePaymentVendorReferenceNo: values.providerRef ? values.providerRef : null,
                    ePaymentVendorReferenceImage: values.providerRefImage ? values.providerRefImage : null,
                    note: values.note
                });
            }
        }


        if (list.length > 0) {
            setTransactionSavePayload(list);
        }

    };

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnTransactionCreated() {
        if (onTransactionCreated) {
            onTransactionCreated();
        }
    }

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.write,
    });

    useEffect(() => {

        if (transactionSavePayload) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving transaction...")
            showProgress();

            //[HttpPost("{casinoId:Guid}/sessions/{sessionId:Guid}/transactions", Name = "CreateSessionTransaction")]
            execute("POST", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/transactions", transactionSavePayload).then((response) => {
                if (response) {
                    raiseOnTransactionCreated();
                }
                //clear payload and close dialog...
                setTransactionSavePayload(null);
                progressIndicatorProps.close();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save transaction, please try again...")
            });

        }

    }, [execute, transactionSavePayload, retryCounter]);

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            {getFormContent(type, cardroom, session, {
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            })}

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

HouseTransactionForm.defaultProps = {
    type: "rake",
};

HouseTransactionForm.propTypes = {
    type: PropTypes.oneOf([
        "rake",
        "expenses"
    ]).isRequired,
    cardroom: PropTypes.object //.isRequired
};

export default HouseTransactionForm;