
const form = {
    formId: "staff-buy-in-transaction-form",
    formField: {
        type: {
            name: "type",
            type: "hidden"
        },
        amount: {
            name: "amount",
            label: "Amount",
            type: "number",
            placeholder: "amount",
            errorMsg: "Amount must be greater than 0.",
        },
        tipAmount: {
            name: "tipAmount",
            label: "Tips",
            type: "number",
            placeholder: "tips",
            errorMsg: "Tip amount cannot be negative.",
        },
        taxRate: {
            name: "taxRate",
            label: "Tax Rate",
            type: "number",
            placeholder: "tax rate",
            errorMsg: "Tax rate cannot be negative.",
        },
        taxScheme: {
            name: "taxScheme",
            label: "Tax Scheme",
            type: "number",
            placeholder: "tax sceme",
            errorMsg: "Tax scheme cannot be blank."
        },
        taxExempt: {
            name: "taxExempt",
            label: "Exemption",
            type: "number",
            placeholder: "tax exemption",
            errorMsg: "Tax exemption cannot be blank."
        },
        taxAmount: {
            name: "taxAmount",
            label: "Tax Amount",
            type: "number",
            placeholder: "tax amount",
            errorMsg: "",
        },
        method: {
            name: "method",
            label: "Payment method",
            type: "text",
            placeholder: "payment method",
            errorMsg: "Payment method is required",
        },
        tableNo: {
            name: "tableNo",
            label: "Table Number",
            type: "number",
            placeholder: "table number",
            errorMsg: "table number is required",
        },
        verified: {
            name: "verified",
            label: "Payment received and verified",
            type: "bool",
            placeholder: "",
            errorMsg: "",
        },
        markerBalance: {
            name: "markerBalance",
            type: "hidden"
        },
        tipsEarned: {
            name: "tipsEarned",
            type: "hidden"
        },
        taxesPaid: {
            name: "taxesPaid",
            type: "hidden"
        },
        taxableAmount: {
            name: "taxableAmount",
            type: "hidden"
        },
        provider: {
            name: "provider",
            label: "e-Wallet vendor",
            type: "text",
            placeholder: "eWallet vendor",
            errorMsg: "eWallet vendor is required when using eWallet payment method"
        },
        providerOther: {
            name: "providerOther",
            label: "e-Wallet vendor name",
            type: "text",
            placeholder: "eWallet vendor name",
            errorMsg: "eWallet vendor name is required when using eWallet payment method with 'other' provider"
        },
        providerRefMethod: {
            name: "providerRefMethod",
            label: "Vendor reference type",
            type: "text",
            placeholder: "vendor reference type",
            errorMsg: "Vendor reference type is required when payment is verified"
        },
        providerRef: {
            name: "providerRef",
            label: "Vendor reference #",
            type: "text",
            placeholder: "vendor reference #",
            errorMsg: "Vendor reference is required when payment is verified"
        },
        providerRefImage: {
            name: "providerRefImage",
            label: "Vendor reference document",
            type: "hidden",
            placeholder: "vendor reference doc",
            errorMsg: "Vendor ref document is required when using eWallet"
        },
        note: {
            name: "note",
            label: "Note",
            type: "text",
            placeholder: "Note",
            errorMsg: "Note is required"
        }
    },

};

export default form;
