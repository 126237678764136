
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
//import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import PageLayout from "components/Elements/PageLayout";
import Navbar from "components/Elements/Navbar";
import Footer from "components/Elements/Footer";

import CardroomSessionDetails from "layouts/Cardroom/Session/components/SessionDetails";

function CardroomPage() {

    const location = useLocation();

    const [cardroom, setCardroom] = useState(null); //(location.state?.cardroom);
    const [session, setSession] = useState(null); //(location.state?.session);
    

    useEffect(() => {
        if (location && location.state) {
            if (location.state.cardroom && !cardroom) {
                setCardroom(location.state.cardroom);
            }
            if (location.state.session && !session) {
                setSession(location.state.session);
            }
        }
    }, [session, cardroom])

    return (
        <PageLayout>
            <Navbar />

            <SoftBox pt="3px">

                {(cardroom && session) ? <SoftBox mb={3}>
                    <CardroomSessionDetails cardroom={cardroom} session={session} />
                </SoftBox> : null}
            </SoftBox>

            <Footer />
        </PageLayout>);
}

export default CardroomPage;