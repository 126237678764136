
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { apiConfig } from 'config/apiConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import DataBoundSelect from "components/Elements/DataBound/Dropdown";


const PartnerSelector = ({ cardroom, value, label, readonly, onPartnerChanged }) => {

    const [selectedResponsiblePartner, setSelectedResponsiblePartner] = useState(value);
    const [partnerIdList, setPartnerIdList] = useState(null);
    const [partnerList, setPartnerList] = useState(null);
    const [selectedPartnerIndex, setSelectedPartnerIndex] = useState(-1);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "searching database...", handleRetry)

    const onPartnerSelectionChanged = (args) => {

        setSelectedResponsiblePartner(args.item);

        if (onPartnerChanged) {
            onPartnerChanged(args.item.id);
        }

    }

    useEffect(() => {
        setSelectedResponsiblePartner(value);
    }, [value]);


    useEffect(() => {
        if (partnerList) {
            for (var i = 0; i < partnerList.length; i++) {
                if (value == partnerList[i].id || value.id == partnerList[i].id) {
                    setSelectedResponsiblePartner(partnerList[i]);
                    setSelectedPartnerIndex(i);
                    break;
                }
            }
        }
    }, [selectedResponsiblePartner]);

    const casinoRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const userRequest = useFetchWithMsal({
        scopes: apiConfig.user.scopes.read,
    });

    useEffect(() => {
        if (!partnerIdList) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading partner list...")
            showProgress();

            //("{casinoId:Guid}/users/{role:long}"]
            casinoRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/users/32").then((response) => {
                if (response && response.length > 0) {
                    setPartnerIdList(response);
                }
                else {
                    progressIndicatorProps.close();
                }
            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Failed to load partner list, please try again...")
            });
        }
    }, [partnerIdList, casinoRequest.execute, retryCounter]);

    useEffect(() => {
        if (partnerIdList && !partnerList) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Resolving partner list...")


            userRequest.execute("PUT", apiConfig.user.endpoint + "/", partnerIdList).then((response) => {
                if (response && response.length > 0) {
                    if (value) {
                        for (var i = 0; i < response.length; i++) {
                            if (value == response[i].id) {
                                setSelectedResponsiblePartner(response[i]);
                                setSelectedPartnerIndex(i);
                                break;
                            }
                        }
                    }

                    setPartnerList(response);

                    progressIndicatorProps.close();
                }
            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Failed to resolve partner list, please try again...")
            });
        }
    }, [partnerIdList, partnerList, userRequest.execute, retryCounter]);

    return <>
        <ProgressIndicator {...progressIndicatorProps} size="xs" />
        {progressIndicatorProps.visible ? null : <>
            {readonly ? <SoftBox mb={1} mt={1.25} ml={0.5} width="100%" textAlign="left" lineHeight={0} display="inline-block" >
                <SoftTypography
                    component="label"
                    variant="caption"
                >
                    {selectedResponsiblePartner ? selectedResponsiblePartner.displayName : ""}
                </SoftTypography>
            </SoftBox> : partnerList ? <DataBoundSelect placeholder={label} valueName="id" textName="displayName" data={partnerList} onChange={onPartnerSelectionChanged} selectedIndex={selectedPartnerIndex} /> : null}
        </>}
    </>;

};

PartnerSelector.propTypes = {
    cardroom: PropTypes.object.isRequired,
    value: PropTypes.string,
    onPartnerChanged: PropTypes.func,
    readonly: PropTypes.bool,
    label: PropTypes.string
};

export default PartnerSelector;