
import React, { useState, useEffect } from 'react';
import PropTypes, { number } from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import DataTable from 'components/Elements/DataTable';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import { AppBar, Card, Divider, Grid, Icon, Switch, Tab, Tabs, Tooltip } from '@mui/material';
import IconCell from 'components/Elements/DataTable/components/Cells/IconCell';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import defaultCasino from "assets/graphics/logos/default-casino.png";
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import { useLayoutEffect } from 'react';
import AgentEditorDialog from '../../Agent';
import { useDialog } from 'components/Elements/Dialog/common';
import PremiumServiceSubscriptionRequired from 'components/Elements/PremiumServiceRequried';


const utilities = {
    player: {
        prepareForPresentation: function (players, table, filterPlayersWithNoHands, cardroom, club, summary, reportingPeriod) {
            let rows = [];

            for (var i = 0; i < players.length; i++) {

                if (filterPlayersWithNoHands === true && players[i].hands < 1) continue;

                let playerImage = players[i].name.substring(0, 1);

                rows.push({
                    name: [players[i].name, { image: playerImage }],
                    displayName: players[i].name,
                    displayNameNoCase: players[i].name.toLowerCase(),
                    nickname: players[i].nickname,
                    appPlayerId: players[i].appPlayerId,
                    agentName: players[i].agent ? players[i].agent.name : "None",
                    agentId: players[i].agent ? players[i].agent.appAgentId : "None",
                    superAgentName: players[i].agent && players[i].agent.superAgent ? players[i].agent.superAgent.name : "None",
                    superAgentId: players[i].agent && players[i].agent.superAgent ? players[i].agent.superAgent.appAgentId : "None",
                    sourceAppName: players[i].app ? players[i].app.name : "None",
                    result: players[i].result,
                    fees: players[i].fees,
                    insurance: players[i].insurance,
                    spinOffset: players[i].spinOffset,
                    jackpotFee: players[i].jackpotFee,
                    jackpotPayout: players[i].jackpotPayout,
                    jackpotPnL: players[i].jackpotFee + players[i].jackpotPayout,
                    xmttFee: players[i].xmttFee,
                    xmttResult: players[i].xmttResult,
                    xmttPnL: players[i].xmttFee + players[i].xmttResult,
                    hands: players[i].hands,
                    chipsSendOut: players[i].chipsSendout,
                    chipsClaimBack: players[i].chipsClaimback,
                    details: <ReportPlayerDetails cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} summary={summary} player={players[i]} />,
                    player: players[i]
                });
            }

            if (rows.length > 0) {
                rows.sort(function (a, b) {
                    if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                    if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                    return 0;
                });
            }

            table.rows = rows;

            return table;
        },
        getTableSchema: function (showAgent) {
            let schema = {
                columns: [
                    {
                        Header: "Player",
                        accessor: "name",
                        Cell: ({ value: [name, data], row }) => (
                            <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} participant={row && row.original && row.original.player ? row.original.player : null} />
                        ),
                    },
                    { Header: "Player Id", accessor: "appPlayerId", Cell: ({ value }) => <TextCell value={value} /> },
                    { Header: "Hands Played", accessor: "hands", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                    { Header: "Result", accessor: "result", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                    { Header: "Fees", accessor: "fees", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                    { Header: "Insurance", accessor: "insurance", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                    { Header: "Spin Offset", accessor: "spinOffset", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                    { Header: "Jackpot P&L", accessor: "jackpotPnL", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                    { Header: "XMTT P&L", accessor: "xmttPnL", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                    { Header: "Chips Send Out", accessor: "chipsSendOut", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="" useColorScheme={false} /> },
                    { Header: "Chips Claim Back", accessor: "chipsClaimBack", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="" useColorScheme={false} /> },
                ],
                collapsibleDetailAccessor: "details",
                rows: []
            };

            if (showAgent === true) {
                schema.columns.push({ Header: "Agent", accessor: "agentName", Cell: ({ value }) => <TextCell value={value} /> });
            }

            return schema;
        }
    },
    agent: {
        prepareForPresentation: function (players, table, filterAgentsWithNoFees, cardroom, club, summary, reportingPeriod) {

            function calculatePlayerNumbers(agent) {
                const numbers = {
                    results: 0,
                    fees: 0,
                    jackpotFee: 0,
                    jackpotPayout: 0,
                    xmttFee: 0,
                    xmttResult: 0
                };

                if (!agent) return numbers;

                let players = agent.effPlayers || agent.players;

                if (!players) return numbers;



                for (var i = 0; i < players.length; i++) {
                    numbers.fees += players[i].fees;
                    numbers.results += players[i].result;
                    numbers.jackpotFee += players[i].jackpotFee;
                    numbers.jackpotPayout += players[i].jackpotPayout;
                    numbers.xmttFee += players[i].xmttFee;
                    numbers.xmttResult += players[i].xmttResult;
                }

                return numbers;
            }

            function calculatePlayerFees(agent) {
                const numbers = calculatePlayerNumbers(agent);

                return numbers.fees;
            }

            function calculateAgentFees(agent) {
                return 0;//no need to calc now, but if we start supporting agent/superagent commission then we want this
            }

            function calculateCommission(amount, scheme, threshold, rate) {
                if (scheme === 0) return 0;

                //determine adjusted fee
                let agi = amount - threshold;
                //determing fee
                if (agi <= 0) return 0;
                switch (scheme) {
                    case 1: //Flat
                        return amount * rate;
                    case 2: //Progressive
                        return agi * rate;
                }

                return 0;
            }

            function calculatePlayerComission(agent) {
                //check scheme being > -1 to ensure we account for null/undefined vs 0
                if (agent && agent.feeSchedule) {

                    //this could occur when report is being presented to someone navigating down, that doesn't have actual permission to club itself
                    //union navigating to club level report w/o explicit permissions
                    if (isNaN(parseInt(agent.feeSchedule.scheme))) return null;

                    //None = 0,
                    if (agent.feeSchedule.scheme === 0) return 0;

                    if (agent.feeSchedule.aggregate === true) {
                        return calculateCommission(calculatePlayerFees(agent), agent.feeSchedule.scheme, agent.feeSchedule.threshold, agent.feeSchedule.rate);
                    }
                    else {
                        let commision = 0;
                        let players = agent.effPlayers || agent.players;

                        if (!players) return 0;

                        for (var i = 0; i < players.length; i++) {
                            commision += calculateCommission(players[i].fees, agent.feeSchedule.scheme, agent.feeSchedule.threshold, agent.feeSchedule.rate);
                        }

                        return commision; //.toFixed(2);
                    }
                }
                return null;
            }

            function calculateAgentComission(agent) {
                return 0; //no need to calc now, but if we start supporting agent/superagent commission then we want this
            }

            function calculateAgentChipOffset(agent, numbers) {
                if (!agent || !numbers) return 0;

                if (!agent.feeSchedule) return 0;

                return agent.feeSchedule.chipoffsetRate * (numbers.fees + numbers.results) * -1;
            }

            function calculateAgentTaxRebate(agent, numbers) {
                if (!agent || !numbers) return 0;
                if (!agent.feeSchedule) return 0;

                if (agent.feeSchedule.taxRebateRate <= 0) return 0;

                const earnings = numbers.fees + numbers.results;
                const xmtt = numbers.xmttResult;
                const jackpotPnL = numbers.jackpotFee + numbers.jackpotPayout;
                const adjustedEarnings = earnings - xmtt; // + jackpotPnL;

                return agent.feeSchedule.taxRebateRate * adjustedEarnings * -1;
            }

            let agents = [];
            let agentMap = [];

            for (var i = 0; i < players.length; i++) {
                let agent = players[i].agent;
                if (!agent) continue;

                let effAgent = agent.superAgent || agent;

                if (!agentMap[effAgent.id]) {
                    agentMap[effAgent.id] = effAgent;
                    agents.push(effAgent);
                } else {
                    effAgent = agentMap[effAgent.id];
                }

                if (!effAgent.players) {
                    effAgent.players = [];
                    effAgent.playerMap = [];
                }

                if (!effAgent.effPlayers) {
                    effAgent.effPlayers = [];
                    effAgent.effPlayerMap = [];
                }

                if (!effAgent.playerMap[players[i].id]) {
                    effAgent.players.push(players[i]);
                    effAgent.playerMap[players[i].id] = players[i];
                }

                if (!effAgent.effPlayerMap[players[i].id]) {
                    effAgent.effPlayers.push(players[i]);
                    effAgent.effPlayerMap[players[i].id] = players[i];
                }
            }

            let rows = [];

            for (var i = 0; i < agents.length; i++) {

                let agentImage = agents[i].name.substring(0, 1);

                let numbers = calculatePlayerNumbers(agents[i]);

                let agentCommission = calculateAgentComission(agents[i]);
                let playerCommission = calculatePlayerComission(agents[i]);

                let chipOffset = calculateAgentChipOffset(agents[i], numbers);
                let taxRebate = calculateAgentTaxRebate(agents[i], numbers);

                let row = {
                    name: [agents[i].name, { image: agentImage }],
                    agentId: agents[i].appAgentId,
                    displayName: agents[i].name,
                    displayNameNoCase: agents[i].name.toLowerCase(),
                    agentCount: agents[i].agents ? agents[i].agents.length : 0,
                    playerCount: agents[i].effPlayers ? agents[i].effPlayers.length : 0,
                    playerFees: numbers.fees,
                    playerResults: numbers.results,
                    playerXmtt: numbers.xmttFee + numbers.xmttResult,
                    playerXmttFee: numbers.xmttFee,
                    playerXmttResult: numbers.xmttResult,
                    playerJackpot: numbers.jackpotFee - numbers.jackpotPayout,
                    agentFees: calculateAgentFees(agents[i]),
                    playerCommision: playerCommission,
                    agentCommission: agentCommission,
                    agentSettlement: playerCommission + agentCommission + numbers.results + chipOffset + taxRebate,
                    totalComission: 0,
                    chipOffset: chipOffset,
                    taxRebate: taxRebate,
                    players: <ReportAgentPlayers cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} summary={summary} agent={agents[i]} />,
                    agent: agents[i]
                };

                row.totalComission = row.agentCommission + row.playerCommision;


                if (filterAgentsWithNoFees && row.playerFees == 0 && row.agentFees == 0 && row.totalComission == 0) {
                    continue;
                }

                rows.push(row);
            }



            if (rows.length > 0) {
                rows.sort(function (a, b) {
                    if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                    if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                    return 0;
                });
            }

            table.rows = rows;

            return table;
        },
        getTableSchema: function (onAgentClick, cardroom) {
            //do we know if we're in "root" report or child report? if child report, we shouldn't display comission as that's club level detail, not union
            let schema = {
                columns: [
                    {
                        Header: "Agent",
                        accessor: "name",
                        Cell: ({ value: [name, data], row }) => (
                            <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} onClick={onAgentClick} participant={row && row.original && row.original.agent ? row.original.agent : null} />
                        ),
                    },
                    { Header: "Agent Id", accessor: "agentId", Cell: ({ value }) => <TextCell value={value} /> },
                    { Header: "Fees", accessor: "playerFees", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                ],
                collapsibleDetailAccessor: "players",
                rows: []
            };

            if (cardroom.type === 2) {
                //show commission only when viewing from CLUB pov, when union's viewing, no commission to display as that's not UNIONs concern
                schema.columns.push({
                    Header: "Commission", accessor: "playerCommision", Cell: ({ value }) => {
                        if (value || value === 0) return <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} />;

                        return <IconCell value="invalid config" icon="manage_accounts" color="error" />
                    }
                });
            }

            schema.columns.push({ Header: "Results", accessor: "playerResults", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> });
            schema.columns.push({ Header: "Xmtt Result", accessor: "playerXmttResult", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> });
            schema.columns.push({ Header: "Jackpot P&L", accessor: "playerJackpot", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> });
            schema.columns.push({ Header: "Players", accessor: "playerCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> });

            if (cardroom.type === 2) {
                //show commission only when viewing from CLUB pov, when union's viewing, no commission to display as that's not UNIONs concern

                schema.columns.push({
                    Header: "Chip Offset", accessor: "chipOffset", Cell: ({ value }) => {
                        return <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} />;
                    }
                });

                schema.columns.push({
                    Header: "Tax / Rebate", accessor: "taxRebate", Cell: ({ value }) => {
                        return <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} />;
                    }
                });

                schema.columns.push({
                    Header: "Settlement", accessor: "agentSettlement", Cell: ({ value }) => {
                        return <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} />;
                    }
                });
            }

            return schema;
        }
    }
};

const ReportAgentPlayers = ({ cardroom, club, reportingPeriod, summary, agent }) => {

    const [playerTable, setPlayerTable] = useState(null);
    const [players, setPlayers] = useState(agent.effPlayers ? agent.effPlayers : agent.players);

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading players...", null);

    useLayoutEffect(() => {
        if (players && !playerTable) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading players...")
            showProgress();
            setPlayerTable(utilities.player.prepareForPresentation(players, utilities.player.getTableSchema(true), false, cardroom, club, summary, reportingPeriod));
            progressIndicatorProps.close();

        }
    }, [players, playerTable]);

    return (<Card p={1}>
        <ProgressIndicator {...progressIndicatorProps} />
        {playerTable ? <DataTable table={playerTable} entriesPerPage={{ defaultValue: 5000, visible: false }} showTotalEntries={false} isHierarchical={false} /> : null}
    </Card>);
}

const ReportAgents = ({ cardroom, club, reportingPeriod, summary }) => {

    const [agentTable, setAgentTable] = useState(null);
    const [players, setPlayers] = useState(null);


    const [currentAgent, setCurrentAgent] = useState(null);
    const [openAgentEditorDialog, openAgentEditorDialogProps] = useDialog();

    function openAgentEditor(agent) {
        if (!canEditAgent()) return;

        if (agent) {
            setCurrentAgent(agent);
            openAgentEditorDialog();
        }
    }

    function canEditAgent() {
        //edit only if reportingPeriod casinoId owns this specific club???
        //summary.club must be owed by cardroom?
        //        return cardroom.id === reportingPeriod.casinoId;
        //TODO: Add a way to check for current context to make sure we're inside the owner casino
        //add permissions/features check via useContext(cardroom);

        if (cardroom.type !== 2) return false;

        return true;
    }

    const [retryCounter, setRetryCounter] = useState(0);
    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading agents...", handleProgressRetry);

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (!players) {
            if (summary.players) {
                setPlayers(summary.players);
                setAgentTable(null);
                return;
            }

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading agents...")
            showProgress();

            //[HttpGet("casino/{casinoId:Guid}/reports/{reportingPeriodId:Guid}/players", Name = "GetReportingPeriodPlayers")]
            clubRequest.execute("GET", apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/players?clubId=" + club.id).then((response) => {
                if (response) {
                    summary.players = response;
                    setPlayers(response);
                    setAgentTable(null);
                }
            }).catch((ex) => {
                //setOverlayMessage("An error occured, please refresh");
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load agents, plese try again...");
            });
        }
    }, [clubRequest.execute, players, retryCounter])

    useEffect(() => {
        if (players && !agentTable) {
            setAgentTable(utilities.agent.prepareForPresentation(players, utilities.agent.getTableSchema(canEditAgent() ? openAgentEditor : null, cardroom), agentsWithFeesOnly, cardroom, club, summary, reportingPeriod));
            progressIndicatorProps.close();
        }
    }, [players, agentTable])


    const [agentsWithFeesOnly, setAgentsWithFeesOnly] = useState(true);
    const [tableRefreshNeeded, setTableRefreshNeeded] = useState(false);

    function onAgentsWithFeesOnlySwitchToggle() {
        let newValue = !agentsWithFeesOnly;
        setAgentsWithFeesOnly(newValue);
        setTableRefreshNeeded(true);
    }

    function onAgentPropertiesChanged(agent) {

        //setAgentTable(null);
        setTableRefreshNeeded(true);
    }

    useLayoutEffect(() => {
        if (players && agentTable && tableRefreshNeeded) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Filtering agents...")
            showProgress();

            setAgentTable(null);
            setTableRefreshNeeded(false);

            //progressIndicatorProps.close();
        }
    }, [agentsWithFeesOnly, tableRefreshNeeded, players, agentTable])

    const filter = <Grid container xs={12} justifyContent="center" ml={5}>
        <Grid item>
            <SoftBox mt={0.25} >
                <Switch checked={agentsWithFeesOnly} onChange={onAgentsWithFeesOnlySwitchToggle} />
            </SoftBox>
        </Grid>
        <Grid item>
            <SoftBox ml={1} display="inlineBox">
                <SoftTypography component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    Show only agents that have earned fees
                </SoftTypography>
            </SoftBox></Grid>
    </Grid >;

    return (<SoftBox>
        <ProgressIndicator {...progressIndicatorProps} />
        <AgentEditorDialog {...openAgentEditorDialogProps} cardroom={cardroom} club={club} agent={currentAgent} onAgentUpdated={onAgentPropertiesChanged} />
        {agentTable ? <DataTable table={agentTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} isHierarchical={true} widget={filter} /> : null}
    </SoftBox>);

};

ReportAgents.propTypes = {
    cardroom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired
};


const ReportPlayerDetails = ({ cardroom, club, reportingPeriod, summary, player }) => {

    function prepareForPresentation(details, table) {
        let rows = [];

        if (details && details.details) {
            details = details.details;
        }

        if (!details) return null;



        function createItem(groups, key) {

            function getKeyName(k) {
                switch (k) {
                    case "sixPlus":
                        return "6+";
                    case "leaderboard":
                        return "Leaderboard";
                    case "spin":
                        return "Spin"
                    case "ploHi":
                        return "PLO Hi";
                    case "ploHiLo":
                        return "PLO Hi/Lo";
                    case "floHi":
                        return "FLO Hi";
                    case "floHiLo":
                        return "FLO Hi/Lo";
                }

                if (k) return k.toUpperCase();

                return "";
            }

            function getGroupKeyValue(group, key, groups) {

                if (group == "SpinOffset" && key != "nlh" && key != "ploHi") {
                    return null;
                }

                if (group == "Insurance" && key != "nlh" && key != "ploHi" && key != "flh" && key != "sixPlus") {
                    return null;
                }

                if ((group == "Hands" || group == "Fees") && key == "leaderboard") {
                    return null;
                }

                return groups[group] ? groups[group][key] : null;

            }

            return {
                key: key,
                name: getKeyName(key),
                hands: getGroupKeyValue("Hands", key, groups), //groups["Hands"] ? groups["Hands"][key] : null,
                wins: getGroupKeyValue("Wins", key, groups), //groups["Wins"] ? groups["Wins"][key] : null,
                fees: getGroupKeyValue("Fees", key, groups), //groups["Fees"] ? groups["Fees"][key] : null,
                insurance: getGroupKeyValue("Insurance", key, groups), //groups["Insurance"] ? groups["Insurance"][key] : null,
                spin: getGroupKeyValue("SpinOffset", key, groups) //groups["SpinOffset"] ? groups["SpinOffset"][key] : null
            };
        }

        var map = [];
        for (var i = 0; i < details.length; i++) {
            map[details[i].groupName] = details[i].data;
        }

        let keys = ["nlh", "flh", "sixPlus", "ploHi", "ploHiLo", "floHi", "floHiLo", "mixed", "ofc", "sng", "mtt", "xmtt", "spin", "leaderboard"];
        for (var i = 0; i < keys.length; i++) {
            rows.push(createItem(map, keys[i]));
        }

        table.rows = rows;

        return table;
    }

    function getTableSchema() {
        return {
            columns: [
                { Header: "", accessor: "name", Cell: ({ value }) => <TextCell value={value} /> },
                {
                    Header: "Hands Played", accessor: "hands", Cell: ({ value }) => {

                        if (value || value === 0)
                            return <MoneyCell value={value} prefix="" useColorScheme={false} />;

                        return <IconCell icon="block" color="info" />;
                    }
                },
                {
                    Header: "Winnings", accessor: "wins", Cell: ({ value }) => {

                        if (value || value === 0)
                            return <MoneyCell value={value} prefix="$" useColorScheme={true} />;

                        return <IconCell icon="block" color="info" />;
                    }
                },
                {
                    Header: "Fees", accessor: "fees", Cell: ({ value }) => {

                        if (value || value === 0)
                            return <MoneyCell value={value} prefix="$" useColorScheme={true} />;

                        return <IconCell icon="block" color="info" />;
                    }
                },
                {
                    Header: "Insurance", accessor: "insurance", Cell: ({ value }) => {

                        if (value || value === 0)
                            return <MoneyCell value={value} prefix="$" useColorScheme={true} />;

                        return <IconCell icon="block" color="info" />;
                    }
                },
                {
                    Header: "Spin Offset", accessor: "spin", Cell: ({ value }) => {

                        if (value || value === 0)
                            return <MoneyCell value={value} prefix="$" useColorScheme={true} />;

                        return <IconCell icon="block" color="info" />;
                    }
                }
            ],
            rows: []
        };
    }


    const [playerDetailsTable, setPlayerDetailsTable] = useState(null);
    const [playerDetails, setPlayerDetails] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading player details...", handleProgressRetry);
    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (!playerDetails) {


            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading player details...")
            showProgress();

            //[HttpGet("casino/{casinoId:Guid}/reports/{reportingPeriodId:Guid}/players/{playerId:Guid}", Name = "GetReportingPeriodPlayerDetails")]
            clubRequest.execute("GET", apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/players/" + player.id + (club ? "?clubId=" + club.id : "")).then((response) => {
                if (response) {

                    setPlayerDetails(response);
                    setPlayerDetailsTable(null);
                }
            }).catch((ex) => {
                //setOverlayMessage("An error occured, please refresh");
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load player details, plese try again...");
            });

        }
    }, [clubRequest.execute, playerDetails, retryCounter]);

    useEffect(() => {
        if (playerDetails && !playerDetailsTable) {
            //load tables
            setPlayerDetailsTable(prepareForPresentation(playerDetails, getTableSchema()));
            progressIndicatorProps.close();
        }

    }, [playerDetails, playerDetailsTable]);


    return (<Card p={1}>
        <ProgressIndicator {...progressIndicatorProps} />
        {playerDetailsTable ? <DataTable table={playerDetailsTable} entriesPerPage={{ defaultValue: 5000, visible: false }} showTotalEntries={false} isHierarchical={false} /> : null}
    </Card>);
}

const ReportPlayers = ({ cardroom, club, reportingPeriod, summary }) => {

    const [playerTable, setPlayerTable] = useState(null);
    const [players, setPlayers] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading players...", handleProgressRetry);

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (!players) {
            if (summary.players) {
                setPlayers(summary.players);
                setPlayerTable(null);
                return;
            }

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading players...")
            showProgress();

            //[HttpGet("casino/{casinoId:Guid}/reports/{reportingPeriodId:Guid}/players", Name = "GetReportingPeriodPlayers")]
            clubRequest.execute("GET", apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/players?clubId=" + club.id).then((response) => {
                if (response) {
                    summary.players = response;
                    setPlayers(response);
                    setPlayerTable(null);
                }
            }).catch((ex) => {
                //setOverlayMessage("An error occured, please refresh");
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load players, plese try again...");
            });
        }
    }, [clubRequest.execute, players, retryCounter])

    useEffect(() => {
        if (players && !playerTable) {
            //merge
            setPlayerTable(utilities.player.prepareForPresentation(players, utilities.player.getTableSchema(), playersWithHandsOnly, cardroom, club, summary, reportingPeriod));
            progressIndicatorProps.close();
        }
    }, [players, playerTable])

    const [playersWithHandsOnly, setPlayersWitHandsOnly] = useState(true);
    const [tableRefreshNeeded, setTableRefreshNeeded] = useState(false);

    function onPlayersWithHandsOnlySwitchToggle() {
        let newValue = !playersWithHandsOnly;
        setPlayersWitHandsOnly(newValue);
        setTableRefreshNeeded(true);
    }

    useLayoutEffect(() => {
        if (players && playerTable && tableRefreshNeeded) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Filtering players...")
            showProgress();

            setPlayerTable(null);
            //setPlayerTable(preparePlayersForPresentation(players, GetPlayerTableSchema()));
            setTableRefreshNeeded(false);

            //progressIndicatorProps.close();
        }
    }, [playersWithHandsOnly, tableRefreshNeeded, players, playerTable])

    const filter = <Grid container xs={12} justifyContent="center" ml={5}>
        <Grid item>
            <SoftBox mt={0.25} >
                <Switch checked={playersWithHandsOnly} onChange={onPlayersWithHandsOnlySwitchToggle} />
            </SoftBox>
        </Grid>
        <Grid item>
            <SoftBox ml={1} display="inlineBox">
                <SoftTypography component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    Show only players with hand count > 0
                </SoftTypography>
            </SoftBox></Grid>
    </Grid >;

    return (<SoftBox>
        <ProgressIndicator {...progressIndicatorProps} />
        {playerTable ? <DataTable table={playerTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} isHierarchical={true} widget={filter} /> : null}
    </SoftBox>);
};

ReportPlayers.propTypes = {
    cardroom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired
};

const ReportSummary = ({ cardroom, club, reportingPeriod, summary }) => {
    const cardroomLogo = cardroom && cardroom.hasLogo ? apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png" : defaultCasino;
    const appLogo = club && club.app ? apiConfig.images.endpoint + "/app/" + club.app.id + ".png" : null;

    const [retryCounter, setRetryCounter] = useState(0);
    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report details...", handleRetry)

    function zero() {
        return parseFloat(0).toFixed(2);
    }

    function calcEarnings() {
        if (!summary) return zero();
        return (summary.winnings + summary.fees).toFixed(2);
    }
    function calcUnionDues() {
        if (!summary) return zero();
        return ((summary.fees * summary.unionDuesRate * -1) - summary.unionDuesBase).toFixed(2);
    }
    function calcXMTTDues() {
        if (!summary) return zero();
        return (summary.xmttFees * summary.xmttDuesRate * -1).toFixed(2);
    }
    function calcChipOffset() {
        if (!summary) return zero();
        return (calcEarnings() * summary.chipOffsetRate * -1).toFixed(2);
    }
    function calcTaxRebate() {
        if (!summary) return zero();

        let taxableAmount = calcEarnings() - summary.xmttPnL + summary.jackpotPnL;
        let winnerTax = taxableAmount > 0 ? taxableAmount * summary.winTaxRate : 0;

        return ((taxableAmount * summary.taxRebateRate * -1) - winnerTax).toFixed(2);
    }

    function getDividend() {
        if (!summary) return zero();
        return summary.loyaltyRebate.toFixed(2);
    }

    function formatDecimalValue(v) {
        if (!v) return zero();

        return v.toFixed(2);
    }

    //TODO: add tooltips to each heading explaing how it was put together
    return <Card>
        {!progressIndicatorProps.visible && club && summary ? <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8} >
                    <Card>
                        {/* Report header */}
                        <SoftBox p={3}>
                            <Grid container xs={12} justifyContent="space-between">
                                {club.app ? <>
                                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        <SoftBox width="100%" textAlign="left" mt={2}>
                                            <SoftBox textAlign="center" width="max-content">
                                                <SoftBox component="img" src={appLogo} width="96px" style={{}} p={1} mb={1} />
                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {club.app.name}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item sm={4} xs={12} >
                                        <SoftBox width="100%" textAlign={{ xs: "center", md: "center" }} mt={2}>
                                            <Tooltip title="Total Earnings - Union Dues + XMTT Dues + Chip Offset + Tax / Rebate + Referral Bonus" placement="top">
                                                <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                    Settelment
                                                </SoftTypography>
                                            </Tooltip>
                                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                <MoneyCell value={summary ? formatDecimalValue(summary.settlementAmount) : 0} prefix="$" />
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }} >
                                        <SoftBox justifyContent="right" textAlign="right" mt={2}>
                                            <SoftBox ml="auto" textAlign="center" width="max-content">
                                                <SoftBox component="img" src={cardroomLogo} width="96px" style={{}} p={1} mb={1} />
                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {club ? club.name : null}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid></> : null}
                            </Grid>
                            <Grid container justifyContent="space-between" mt={2}>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                            <SoftBox mt={3}>
                                {/* Row 1 */}
                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"left"}>
                                                <Tooltip title="Players net result" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Winnings
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"left"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.winnings) : 0} prefix="$" />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"center"}>
                                                <Tooltip title="Fees generated by club" placement="top">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Fees
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"center"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.fees) : 0} prefix="$" />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"right"}>
                                                <Tooltip title="Corss Union MTT Fees generated by club" placement="right-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        XMTT Fee
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"right"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.xmttFees) : 0} prefix="$" />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-between" >
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                {/* Row 2 */}
                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"left"}>
                                                <Tooltip title="Corss Union MTT profit & loss" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        XMTT P&L
                                                    </SoftTypography>
                                                </Tooltip>

                                            </Grid>
                                            <Grid item sm={12} textAlign={"left"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.xmttPnL) : 0} prefix="$" />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"center"}>
                                                <Tooltip title="Bad beat jackpot profit & loss" placement="top">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Jackpot P&L
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"center"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.jackpotPnL) : 0} prefix="$" />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"right"}>
                                                <Tooltip title="Spin & Go Offset" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Spin N Go Offset
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"right"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.spinNGoOffset) : 0} prefix="$" />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                {/* Row 3 */}
                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"left"}>
                                                <Tooltip title="Winnings + Fees" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Total Earnings
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"left"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={calcEarnings()} prefix="$" />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"center"}>
                                                <Tooltip title="Union Fee Rate * Total Fees" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Union Dues
                                                    </SoftTypography>
                                                </Tooltip>

                                            </Grid>
                                            <Grid item sm={12} textAlign={"center"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={calcUnionDues()} prefix="$" />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"right"}>
                                                <Tooltip title="Tax / Rebate Rate * Total Earnings" placement="top">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Tax / Rebate
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"right"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={calcTaxRebate()} prefix="$" />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid container justifyContent="space-between">
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                                {/* Row 4 */}
                                <Grid container xs={12} justifyContent="space-between">

                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"left"}>
                                                <Tooltip title="XMTT Dues Rate * XMTT Fee" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        XMTT Dues
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"left"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={calcXMTTDues()} prefix="$" />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"center"}>
                                                <Tooltip title="Chip Offset Rate * Adjusted Earnings" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Chip Offset
                                                    </SoftTypography>
                                                </Tooltip>

                                            </Grid>
                                            <Grid item sm={12} textAlign={"center"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={calcChipOffset()} prefix="$" />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"right"}>
                                                <Tooltip title="Referral fees based on referred clubs' fees" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Referral Bonus
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"right"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.referralBonus) : 0} prefix="$" />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-between" >
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                {/* Row 5 */}
                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12} sm={12}>
                                        {summary && summary.loyaltyRebate > 0 ?
                                            <Grid container xs={12} justifyContent="space-between">
                                                <Grid item sm={12} textAlign={"center"}>
                                                    <Tooltip title="Total Union Fees * Loyalty Dividend Rate" placement="top">
                                                        <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                            Loyalty Dividend
                                                        </SoftTypography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item sm={12} textAlign={"center"}>
                                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                        <MoneyCell value={getDividend()} prefix="$" />
                                                    </SoftTypography>
                                                </Grid>
                                            </Grid> : null}
                                    </Grid>
                                </Grid>
                            </SoftBox>

                        </SoftBox>

                    </Card>
                </Grid>
            </Grid>
        </> : <ProgressIndicator {...progressIndicatorProps} />}
        <Divider />
    </Card>;
};

ReportSummary.propTypes = {
    cardroom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired
};

const ClubReport = ({ cardroom, club, reportingPeriod, summary }) => {

    const TabPanel = function (props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <SoftBox sx={{ p: 3 }}>
                        <SoftTypography>{children}</SoftTypography>
                    </SoftBox>
                )}
            </div>
        );
    }

    const a11yProps = function (index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    const [context, actions, features] = useCardroomContext(cardroom);

    function isAgentReportEnabled() {
        if (!context) return false;
        return context.isEnabled(features.club.reporting.agentReport);
    }

    function isPlayerReportEnabled() {
        if (!context) return false;
        return context.isEnabled(features.club.reporting.playerReport);
    }

    return <SoftBox>
        <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
                <AppBar position="static">
                </AppBar>
            </Grid>
            <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
                <AppBar position="static">
                    <Tabs
                        orientation="horizontal"
                        value={tabValue}
                        onChange={handleSetTabValue}
                        sx={{ background: "transparent" }}>
                        <Tab label="Summary" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">dashboard</Icon>} {...a11yProps(0)} />
                        <Tab label="Agents" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">support_agent</Icon>} {...a11yProps(1)} />
                        <Tab label="Players" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">person</Icon>} {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
            </Grid>

        </Grid>
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>

                <SoftBox>
                    <TabPanel value={tabValue} index={0}>
                        <ReportSummary cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} summary={summary} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        {isAgentReportEnabled() ?
                            <ReportAgents cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} summary={summary} />
                            : <PremiumServiceSubscriptionRequired service="detailed agent reports" />}
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        {isPlayerReportEnabled() ?
                            <ReportPlayers cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} summary={summary} />
                            : <PremiumServiceSubscriptionRequired service="detailed player reports" />}
                    </TabPanel>

                </SoftBox>
            </Grid>
        </Grid>
    </SoftBox>;
};

ClubReport.defaultProps = {

};

ClubReport.propTypes = {
    cardroom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired
};

export default ClubReport;