import { Grid, Icon } from "@mui/material";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useCardroomContext } from "features";
import { Formik, Form, ErrorMessage } from 'formik';
import { useState } from "react";

import form from "./schemas/form";
import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";
import { useEffect } from "react";
import FormField from "components/Elements/Forms/FormField";
import CardroomSecurityEditor from "../CardroomSecurity";
import CardroomSubscriptionEditor from "../CardroomSubscription";
import UnionFeesEditor from "../UnionFees";
import ClubFeesEditor from "../ClubFees";
import UnionDefaultsEditor from "../UnionDefaults";
import UnionReferralFeesEditor from "../UnionReferrals";
import SoftAvatar from "components/SoftAvatar";

import { apiConfig } from 'config/apiConfig'
import defaultCasino from "assets/graphics/logos/default-casino.png";
import FormImageField from "components/Elements/Forms/FormImageField";
import SoftButton from "components/SoftButton";
import { useProgressIndicator } from "components/Elements/ProgressIndicator";
import { ProgressIndicator } from "components/Elements/ProgressIndicator";
import useFetchWithMsal from "hooks/useFetchWithMsal";

const TabPanel = function (props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <SoftBox >
                    <SoftTypography>{children}</SoftTypography>
                </SoftBox>
            )}
        </div>
    );
}

const a11yProps = function (index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const CardroomEditorForm = ({ cardroom, club, union, agency, readonly, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { cardroomId, name, logo, phoneNumber, smsNumber, smsVanityNumber, type, emailAddress } = formField;

    const { cardroomId: cardroomIdV,
        name: nameV,
        logo: logoV,
        phoneNumber: phoneNumberV,
        smsNumber: smsNumberV,
        smsVanityNumber: smsVanityNumberV,
        type: typeV,
        emailAddress: emailAddressV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [context, actions, features] = useCardroomContext(cardroom);

    useEffect(() => {
        setFormDataInitialized(false);
    }, [cardroom]);

    useEffect(() => {
        if (!formDataInitialized) {

            resetForm();

            setFieldValue(cardroomId.name, cardroom.id);
            setFieldValue(name.name, cardroom.name);
            // setFieldValue(name.name, pn);
            // setFieldValue(phoneNumber.name, phone);
            setFieldValue(type.name, cardroom.type);
            setFieldValue(emailAddress.name, cardroom.emailAddress ? cardroom.emailAddress : "");
            setFieldValue(phoneNumber.name, cardroom.phoneNumber ? cardroom.phoneNumber : "");
            setFieldValue(smsNumber.name, cardroom.smsNumber ? cardroom.smsNumber : "");
            setFieldValue(smsVanityNumber.name, cardroom.vanitySmsNumber ? cardroom.vanitySmsNumber : "");

            if (cardroom && cardroom.hasLogo) {
                setFieldValue(logo.name, apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png");
            }

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    function getCasinoTypeName() {
        if (!cardroom) return null;
        switch (cardroom.type) {
            case 1:
                return "Home Game";
            case 2:
                return "Online Club";
            case 3:
                return "Online Union";
            case 4:
                return "Stacking Stable";
            case 5:
                return "Online Agency";
        }

        return "N/A";
    }
    function getOnlineObjectType() {
        if (!cardroom || cardroom.type === 1 || cardroom.type === 4) return null;

        switch (cardroom.type) {
            case 2:
                return "Club";
            case 3:
                return "Union";
            case 5:
                return "Agency";
        }

        return "N/A";
    }

    function getOnlinePlatformName() {
        if (!cardroom || cardroom.type === 1 || cardroom.type === 4) return null;

        let app = null;
        if (cardroom.type === 2 && club != null) {
            app = club.app;
        }
        else if (cardroom.type === 3 && union != null) {
            app = union.app;
        }
        else if (cardroom.type === 5 && agency != null) {
            app = agency.app;
        }

        if (app) return app.name;

        return "Unknown";
    }

    function getOnlineObjectId() {
        if (!cardroom || cardroom.type === 1 || cardroom.type === 4) return null;

        if (cardroom.type === 2 && club != null) {
            return club.appClubId;
        }
        else if (cardroom.type === 3 && union != null) {
            return union.appUnionId;
        }
        else if (cardroom.type === 5 && agency != null) {
            return agency.appAgentId;
        }

        return "Unknown";
    }

    function canEditEmail() {
        if (false === canEditCardroom()) return false;

        return !(cardroom.type === 2 || cardroom.type === 3 || cardroom.type === 5);
    }

    function canEditCardroom() {

        if (!cardroom) return false;
        if (!context) return false;

        return context.isAllowed(actions.cardroom.properties.edit);
    }



    function getPlayerImagePresenter({ src }) {

        return <SoftAvatar src={src} size="xxl" variant="rounded" bgColor="light" />
    }

    return <Grid container spacing={1}>
        <Grid item xs={12} sm={4} container justifyContent="center">
            <SoftBox position="relative" height="max-content" mx="auto">
                {canEditCardroom() ?
                    <FormImageField name={logo.name} caption="Drop casino logo here" imgHeight={120} imagePresenter={getPlayerImagePresenter}  {...formData} />
                    : <SoftAvatar src={logoV} size="xxl" variant="rounded" bgColor="dark" />}
            </SoftBox>
        </Grid>
        <Grid xs={12} sm={8}>
            <Grid xs={12} container >
                <Grid item xs={4} >
                    <SoftBox mb={1} mt={0} ml={0.5} lineHeight={0} display="inline-block" >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize">
                            Type
                        </SoftTypography>
                    </SoftBox>
                </Grid>
                <Grid item xs={8}>
                    <SoftBox mb={1} mt={0} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            {getCasinoTypeName()}
                        </SoftTypography>
                    </SoftBox>
                </Grid>
            </Grid>

            <Grid xs={12} container>
                <Grid item xs={4} >
                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize">
                            {name.label}
                        </SoftTypography>
                    </SoftBox>
                </Grid>
                <Grid item xs={8}>
                    <FormField disabled={readonly || !canEditCardroom()}
                        type={name.type}
                        name={name.name}
                        value={nameV}
                        placeholder={name.placeholder}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
            </Grid>

            {typeV === 1 ? <>
                <Grid xs={12} container>
                    <Grid item xs={4} >
                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize">
                                {phoneNumber.label}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={8}>
                        <FormField disabled={readonly}
                            type={phoneNumber.type}
                            name={phoneNumber.name}
                            value={phoneNumberV}
                            placeholder={phoneNumber.placeholder}
                            error={errors.phoneNumber && touched.phoneNumber}
                            success={phoneNumberV && phoneNumberV.length > 0 && !errors.phoneNumber}
                        />
                    </Grid>
                </Grid>

                <Grid xs={12} container>
                    <Grid item xs={4} >
                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize">
                                {smsNumber.label}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={8}>
                        <FormField disabled={readonly}
                            type={smsNumber.type}
                            name={smsNumber.name}
                            value={smsNumberV}
                            placeholder={smsNumber.placeholder}
                            error={errors.smsNumber && touched.smsNumber}
                            success={smsNumberV && smsNumberV.length > 0 && !errors.smsNumber}
                        />
                    </Grid>
                </Grid>

                <Grid xs={12} container>
                    <Grid item xs={4} >
                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize">
                                {smsVanityNumber.label}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={8}>
                        <FormField disabled={readonly}
                            type={smsVanityNumber.type}
                            name={smsVanityNumber.name}
                            value={smsVanityNumberV}
                            placeholder={smsVanityNumber.placeholder}
                            error={errors.smsVanityNumber && touched.smsVanityNumber}
                            success={smsVanityNumberV && smsVanityNumberV.length > 0 && !errors.smsVanityNumber}
                        />
                    </Grid>
                </Grid></> : null}

            {typeV === 2 || typeV === 3 || typeV === 5 ? <>
                <Grid xs={12} container>
                    <Grid item xs={4} >
                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize">
                                Online Platform
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={8}>
                        <SoftBox mb={1} mt={0} ml={0.5} lineHeight={0} display="inline-block">
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                {getOnlinePlatformName()}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                </Grid>
                <Grid xs={12} container>
                    <Grid item xs={4} >
                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize">
                                {getOnlinePlatformName()} {getOnlineObjectType()} ID
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={8}>
                        <SoftBox mb={1} mt={0} ml={0.5} lineHeight={0} display="inline-block">
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                {getOnlineObjectId()}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                </Grid>
            </> : null}

            <Grid xs={12} container>
                <Grid item xs={4} >
                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize">
                            {emailAddress.label}
                        </SoftTypography>
                    </SoftBox>
                </Grid>
                <Grid item xs={8}>
                    <FormField disabled={readonly || !canEditEmail()}
                        type={emailAddress.type}
                        name={emailAddress.name}
                        value={emailAddressV}
                        placeholder={emailAddress.placeholder}
                        error={errors.emailAddress && touched.emailAddress}
                        success={emailAddressV && emailAddressV.length > 0 && !errors.emailAddress}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Grid>;
};

const CardroomProfileEditor = ({ cardroom, club, union, agency, onCardroomChanged }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;

    const [pendingCasinoLogo, setPendingCasinoLogo] = useState(null);
    const [pendingCasino, setPendingCasino] = useState(null);


    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving cardroom...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.write,
    });

    function raiseOnChanged() {
        if (onCardroomChanged) {
            onCardroomChanged();
        }
    }

    const handleSubmit = (values, actions) => {
        var updatedCardroom = {};
        updatedCardroom.id = cardroom.id;
        updatedCardroom.type = cardroom.type;
        updatedCardroom.hasLogo = cardroom.hasLogo;

        updatedCardroom.name = values.name;
        updatedCardroom.phoneNumber = values.phoneNumber;
        updatedCardroom.smsNumber = values.smsNumber;
        updatedCardroom.vanitySmsNumber = values.smsVanityNumber;

        if (!(cardroom.type === 2 || cardroom.type === 3)) {
            updatedCardroom.emailAddress = values.emailAddress;
        }

        if (values.logo) {
            //check if log is pointing at url or data:image/
            //if so, set update logo request
            if (values.logo.startsWith("data:image/")) {
                updatedCardroom.hasLogo = true;
                setPendingCasinoLogo(values.logo);
            }
        }
        else {
            updatedCardroom.hasLogo = false;
        }

        setPendingCasino(updatedCardroom);
    };


    useEffect(() => {
        if (pendingCasinoLogo) {
            // [HttpPut("{casinoId:Guid}/logo", Name = "SetCasinoLogo")]
            execute("PUT", apiConfig.casino.endpoint + "/" + cardroom.id + "/logo", pendingCasinoLogo).then((response) => {
                if (response) {
                    cardroom.hasLogo = false;
                    cardroom.hasLogo = true;
                }
                //clear payload and close dialog...
                // setTransactionSavePayload(null);
                // progressIndicatorProps.close();

                setPendingCasinoLogo(null);

            }).catch((e) => {
                //progressIndicatorProps.setMode("errorWithRetry");
                //progressIndicatorProps.setMessage("Unable to save transaction, please try again...")
            });
        }

    }, [pendingCasinoLogo, execute]);

    useEffect(() => {
        if (pendingCasino && !pendingCasinoLogo) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving cardroom profile...")
            showProgress();

            // [HttpPost("", Name = "SaveCasino")]
            execute("POST", apiConfig.casino.endpoint + "/", pendingCasino).then((response) => {
                if (response) {
                    //raiseOnTransactionCreated();
                    cardroom = Object.assign(cardroom, response);
                }

                raiseOnChanged();
                setPendingCasino(null);
                progressIndicatorProps.close();
                //raiseOnClose();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save cardroom profile, please try again...")
            });
        }

    }, [pendingCasino, pendingCasinoLogo, execute, retryCounter]);


    const cancelEdit = () => { };

    const [context, actions, features] = useCardroomContext(cardroom);
    function canEditCardroom() {
        if (!cardroom) return false;
        if (!context) return false;

        return context.isAllowed(actions.cardroom.properties.edit);
    }

    return (<Card id="basic-info" sx={{ overflow: "visible" }}>
        {cardroom ?
            <SoftBox p={3}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={currentValidation}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
                        return <Form id={formId} autoComplete="off">

                            <SoftBox p={2} >
                                <SoftBox >
                                    <ProgressIndicator {...progressIndicatorProps} />

                                    {progressIndicatorProps.visible ? <></> : <>

                                        <CardroomEditorForm cardroom={cardroom} club={club} union={union} agency={agency} readonly={!canEditCardroom()} formData={{
                                            values,
                                            touched,
                                            formField,
                                            errors,
                                            setFieldValue,
                                            setFieldError,
                                            setFieldTouched,
                                            validateForm,
                                            resetForm
                                        }} />


                                        <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                            <SoftButton variant="gradient" color="light" onClick={cancelEdit}>
                                                cancel
                                            </SoftButton>


                                            <SoftButton
                                                variant="gradient"
                                                color="dark"
                                                disabled={isSubmitting || !canEditCardroom()}
                                                type="submit"
                                            >
                                                save
                                            </SoftButton>
                                        </SoftBox>
                                    </>}
                                </SoftBox>
                            </SoftBox>
                        </Form>
                    }}
                </Formik>
            </SoftBox>
            : null}

    </Card>);
};

const OnlineAgencyCardroomProfile = ({ cardroom, agency, onCardroomChanged }) => {
    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);


    const [context, actions, features] = useCardroomContext(cardroom);


    return cardroom ? <Grid container spacing={1.5} alignItems="center">

        <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
            <AppBar position="static">
                <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue} sx={{ background: "transparent" }}>
                    <Tab label="Properties" icon={<Icon fontSize="small" color="inherit">contact_phone</Icon>}  {...a11yProps(0)} />
                    <Tab label="Security" icon={<Icon fontSize="small" color="inherit">security</Icon>} {...a11yProps(2)} value="security" />
                    <Tab label="Subscription" icon={<Icon fontSize="small" color="inherit">subscriptions</Icon>} {...a11yProps(3)} value="subscriptions" />
                </Tabs>
            </AppBar>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>
            <SoftBox>
                <TabPanel value={tabValue} index={0}>
                    <CardroomProfileEditor cardroom={cardroom} club={null} union={null} agency={agency} onCardroomChanged={onCardroomChanged} />
                </TabPanel>
                <TabPanel value={tabValue} index={"security"}>
                    <CardroomSecurityEditor cardroom={cardroom} />
                </TabPanel>
                <TabPanel value={tabValue} index={"subscriptions"}>
                    <CardroomSubscriptionEditor cardroom={cardroom} />
                </TabPanel>
            </SoftBox>
        </Grid>

    </Grid> : null;
}


const OnlineClubCardroomProfile = ({ cardroom, club, onCardroomChanged }) => {
    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);


    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditAgentFeeSchedules() {
        if (!context) return false;
        if (!club) return false;

        if (!context.isEnabled(features.club.reporting.agentReport)) return false;

        return context.isAllowed(actions.cardroom.fees.agent.edit);
    }

    return cardroom ? <Grid container spacing={1.5} alignItems="center">

        <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
            <AppBar position="static">
                <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue} sx={{ background: "transparent" }}>
                    <Tab label="Properties" icon={<Icon fontSize="small" color="inherit">contact_phone</Icon>}  {...a11yProps(0)} />
                    {canEditAgentFeeSchedules() ? <Tab label="Agent Fees" icon={<Icon fontSize="small" color="inherit">percent</Icon>}  {...a11yProps(1)} value="agentfees" /> : null}
                    <Tab label="Security" icon={<Icon fontSize="small" color="inherit">security</Icon>} {...a11yProps(2)} value="security" />
                    <Tab label="Subscription" icon={<Icon fontSize="small" color="inherit">subscriptions</Icon>} {...a11yProps(3)} value="subscriptions" />
                </Tabs>
            </AppBar>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>
            <SoftBox>
                <TabPanel value={tabValue} index={0}>
                    <CardroomProfileEditor cardroom={cardroom} club={club} union={null} onCardroomChanged={onCardroomChanged} />
                </TabPanel>
                <TabPanel value={tabValue} index={"agentfees"}>
                    <ClubFeesEditor cardroom={cardroom} club={club} />
                </TabPanel>
                <TabPanel value={tabValue} index={"security"}>
                    <CardroomSecurityEditor cardroom={cardroom} />
                </TabPanel>
                <TabPanel value={tabValue} index={"subscriptions"}>
                    <CardroomSubscriptionEditor cardroom={cardroom} />
                </TabPanel>
            </SoftBox>
        </Grid>

    </Grid> : null;
}
const OnlineUnionCardroomProfile = ({ cardroom, union, onCardroomChanged }) => {
    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);


    function canEditReferralSchedules() {
        //if (!context) return false;

        if (!union) return false;

        // if (union.isVirtual) return false;

        // ReferralScheduleEdit = 4,
        if ((union.features & 4) === 0) return false;

        return true;
    }

    function canEditFeeSchedules() {
        //if (!context) return false;

        if (!union) return false;

        //if (union.isVirtual) return false;

        //  ClubFeeScheduleEdit = 1,
        if ((union.features & 1) === 0) return false;

        return true;
    }

    function canSetDefaults() {
        if (!union) return false;

        //if (union.isVirtual) return false;

        return true;
    }

    return cardroom ? <Grid container spacing={1.5} alignItems="center">

        <Grid item xs={12} md={8} sx={{ ml: "auto" }}>
            <AppBar position="static">
                <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue} sx={{ background: "transparent" }}>
                    <Tab label="Properties" icon={<Icon fontSize="small" color="inherit">contact_phone</Icon>}  {...a11yProps(0)} />
                    {canEditFeeSchedules() ? <Tab label="Club Fees" icon={<Icon fontSize="small" color="inherit">currency_exchange</Icon>}  {...a11yProps(1)} value="feeSchedule" /> : null}
                    {canEditReferralSchedules() ? <Tab label="Referral Fees" icon={<Icon fontSize="small" color="inherit">recommend</Icon>}  {...a11yProps(2)} value="referralSchedule" /> : null}
                    {canSetDefaults() ? <Tab label="Defaults" icon={<Icon fontSize="small" color="inherit">settings_suggest</Icon>}  {...a11yProps(3)} value="defaults" /> : null}
                    <Tab label="Security" icon={<Icon fontSize="small" color="inherit">security</Icon>} {...a11yProps(4)} value="security" />
                    <Tab label="Subscription" icon={<Icon fontSize="small" color="inherit">subscriptions</Icon>} {...a11yProps(5)} value="subscriptions" />
                </Tabs>
            </AppBar>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>
            <SoftBox>
                <TabPanel value={tabValue} index={0}>
                    <CardroomProfileEditor cardroom={cardroom} club={null} union={union} onCardroomChanged={onCardroomChanged} />
                </TabPanel>
                <TabPanel value={tabValue} index={"feeSchedule"} >
                    <UnionFeesEditor cardroom={cardroom} union={union} />
                </TabPanel>
                <TabPanel value={tabValue} index={"referralSchedule"}>
                    <UnionReferralFeesEditor cardroom={cardroom} union={union} />
                </TabPanel>
                <TabPanel value={tabValue} index={"defaults"}>
                    <UnionDefaultsEditor cardroom={cardroom} union={union} dense={true} />
                </TabPanel>
                <TabPanel value={tabValue} index={"security"}>
                    <CardroomSecurityEditor cardroom={cardroom} />
                </TabPanel>
                <TabPanel value={tabValue} index={"subscriptions"}>
                    <CardroomSubscriptionEditor cardroom={cardroom} />
                </TabPanel>
            </SoftBox>
        </Grid>

    </Grid> : null;
}
const BNMCardroomProfile = ({ cardroom, onCardroomChanged }) => {

    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);


    return cardroom ? <Grid container spacing={1.5} alignItems="center">
        < Grid item xs={12} md={6} sx={{ ml: "auto" }}>
            <AppBar position="static">
                <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue} sx={{ background: "transparent" }}>
                    <Tab label="Properties" icon={<Icon fontSize="small" color="inherit">contact_phone</Icon>}  {...a11yProps(0)} />
                    <Tab label="Security" icon={<Icon fontSize="small" color="inherit">security</Icon>} {...a11yProps(3)} />
                    <Tab label="Subscription" icon={<Icon fontSize="small" color="inherit">subscriptions</Icon>} {...a11yProps(4)} />
                </Tabs>
            </AppBar>
        </Grid >
        <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>
            <SoftBox>
                <TabPanel value={tabValue} index={0}>
                    <CardroomProfileEditor cardroom={cardroom} club={null} union={null} onCardroomChanged={onCardroomChanged} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <CardroomSecurityEditor cardroom={cardroom} />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <CardroomSubscriptionEditor cardroom={cardroom} />
                </TabPanel>
            </SoftBox>
        </Grid>

    </Grid > : null;
};

const CardroomProfile = ({ cardroom, club, union, agency, onCardroomChanged }) => {

    return <Grid sx={{ overflow: "visible" }} width="100%" minHeight={375}>
        {cardroom ? <>
            {cardroom.type === 1 ? <BNMCardroomProfile cardroom={cardroom} onCardroomChanged={onCardroomChanged} /> : null}
            {cardroom.type === 2 ? <OnlineClubCardroomProfile cardroom={cardroom} club={club} onCardroomChanged={onCardroomChanged} /> : null}
            {cardroom.type === 3 ? <OnlineUnionCardroomProfile cardroom={cardroom} union={union} onCardroomChanged={onCardroomChanged} /> : null}
            {cardroom.type === 5 ? <OnlineAgencyCardroomProfile cardroom={cardroom} agency={agency} onCardroomChanged={onCardroomChanged} /> : null}
        </> : null}
    </Grid>

};

export default CardroomProfile;