import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { invitationId, sendTo, protocol, role },
} = form;



const validations = {
    editor: Yup.object().shape({
        [invitationId.name]: Yup.string(),
        [sendTo.name]: Yup.string().required(sendTo.errorMsg).when([protocol.name], {
            is: (protocolV) => protocolV === 1,
            then: Yup.string().phone("US", true, "Phone number must be a valid US phone number").length(10, "Phone number must be 10 characters long.").required(sendTo.errorMsg) // validate SMS
        }).when([protocol.name], {
            is: (protocolV) => protocolV === 2,
            then: Yup.string().email("Valid email address is required").required(sendTo.errorMsg) // validate email
        }).when([protocol.name], {
            is: (protocolV) => protocolV === 3,
            then: Yup.string().required(sendTo.errorMsg) // validate Telegram
        }).when([protocol.name], {
            is: (protocolV) => protocolV === 4,
            then: Yup.string().required(sendTo.errorMsg) // validate Signal
        }),
        [protocol.name]: Yup.number().oneOf([1, 2, 3, 4]).required(protocol.errorMsg),
        [role.name]: Yup.number().min(1, role.errorMsg).required(role.errorMsg),
    }),

};

export default validations;