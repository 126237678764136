/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
//import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import PageLayout from "components/Elements/PageLayout";
import Navbar from "components/Elements/Navbar";
import Footer from "components/Elements/Footer";



import { useCardroomContext } from "features";
import UnionDetails from "./components/UnionDetails";



function UnionPage() {

  const location = useLocation();

  const [cardroom, setCardroom] = useState(location.state?.cardroom);

  const [securityContext, actions, features] = useCardroomContext(cardroom);

  return (
    <PageLayout>
      <Navbar />

      <SoftBox pt="3px">

        {(cardroom) ? <SoftBox mb={3} pt={0}>
          <UnionDetails cardroom={cardroom} />
        </SoftBox> : null}

      </SoftBox>

      <Footer />
    </PageLayout>
  );
}

export default UnionPage;
