
import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { Divider } from "@mui/material";
import Card from "@mui/material/Card";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";

import FormField from "components/Elements/Forms/FormField";
import { PatternFormat } from "react-number-format";

import playerAvatar from "assets/graphics/personas/player_256.png";
import staffAvatar from "assets/graphics/personas/masseuse_256.png";
import newParticipant from "assets/graphics/icons/participant_add.svg";

const SearchResultList = ({ items, onItemSelected }) => {

    const updatedListItems = items.map((item) => {
        function handleListItemSelection() {
            if (onItemSelected) {
                onItemSelected(item);
            }
        }
        return (<>
            <ListItemButton onClick={handleListItemSelection}>
                <ListItemAvatar>
                    <SoftAvatar bgColor="secondary" variant="rounded" src={item.img} alt={item.name} size="sm" />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <React.Fragment>
                            <SoftTypography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary">
                                {item.name}
                            </SoftTypography>
                        </React.Fragment>
                    }
                    secondary={
                        <React.Fragment>
                            <SoftTypography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary">
                                {/*<PatternFormat value={item.phoneNumber} displayType="text" format="+1 (###) ###-####" mask="*" /> */}
                                {item.phoneNumber}
                            </SoftTypography>
                        </React.Fragment>
                    }
                />

                <ListItemAvatar>
                    <SoftAvatar bgColor="transparent" alt={item.name} size="md" variant="rounded">
                        <Icon color="secondary" fontSize="large">{(item.type & 1) > 0 ? "casino" : "local_bar"}</Icon>
                    </SoftAvatar>
                </ListItemAvatar>
            </ListItemButton>
            <Divider />
        </>
        );
    });

    return (
        <List dense={true} sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 250,
            minHeight: 250,
            '& ul': { padding: 0 },
        }}>
            {updatedListItems}
        </List>
    );
}

SearchResultList.propTypes = {
    items: PropTypes.array.isRequired,
    onItemSelected: PropTypes.func
};

const SelectedParticipantPanel = ({ participant, onDeselect }) => {
    if (!participant) return null;



    return <SoftBox height={250}>

        <Card style={{ border: "1px solid silver" }} bgColor="light">
            <SoftBox m={1} mt={3} p={1} >
                <Grid container>
                    <Grid item xs={2}><SoftAvatar src={participant.img} bgColor="secondary" alt={participant.name} size="md" variant="rounded" /></Grid>
                    <Grid item xs={8} textAlign="left">
                        <SoftTypography
                            variant="body2"
                            color="text.primary">
                            {participant.name}
                        </SoftTypography>
                        <SoftTypography
                            variant="body2"
                            color="text.primary">
                            {participant.phoneNumber}
                        </SoftTypography>
                    </Grid>
                    <Grid item xs={2}>
                        <SoftBox style={{ position: "absolute", top: 0, right: 0 }} m={1}>
                            <SoftButton onClick={onDeselect} variant="outlined" size="small" color="error" title="clear selection" iconOnly>
                                x
                            </SoftButton>
                        </SoftBox>
                    </Grid>
                </Grid>


            </SoftBox>
        </Card>

    </SoftBox>;
};

SelectedParticipantPanel.propTypes = {
    participant: PropTypes.object.isRequired,
    onDeselect: PropTypes.func
};

const Search = ({ type, cardroom, formData }) => {
    const partipantType = type;
    let participantTypeName = "player";
    let avatar = playerAvatar;

    if (partipantType === "staff") {
        participantTypeName = "staff member";
        avatar = staffAvatar;
    }

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { searchCriteria, candidate, candidateId, partnerId, candidateState, name, nickName, phoneNumber, creditLimit, photo, mask, mustSign,
        mustNotify, notificationMask, withholdingRate, isAnonymous } = formField;
    const {
        searchCriteria: searchCriteriaV,
        candidate: candidateV,
        partnerId: partnerIdV
    } = values;

    const [searchResults, setSearchResults] = useState([]);
    const [selectedParticipant, setSelectedParticipant] = useState(candidateV);

    function handleSearchResultSelection(item) {
        setSelectedParticipant(item);

        resetForm();

        setFieldValue(searchCriteria.name, item.name);
        setFieldValue(candidate.name, item);

        if (item.id == "new") {
            if (false == item.name.startsWith("[new ")) {
                let tName = item.name;
                if (tName.endsWith(" (create)")) {
                    tName = tName.substring(0, tName.length - " (create)".length);
                }
                setFieldValue(name.name, tName);
            }
        }
        else {
            setFieldValue(candidateId.name, item.id);
            setFieldValue(partnerId.name, item.partnerId);
            setFieldValue(candidateState.name, "local");
            setFieldValue(name.name, item.name);

            setFieldValue(mustSign.name, item.mustSign);
            setFieldValue(mustNotify.name, item.mustNotify);
            setFieldValue(notificationMask.name, item.notificationMask);
        }

        setFieldValue(isAnonymous.name, item.isAnonymous);
        setFieldValue(nickName.name, item.nickName);
        setFieldValue(phoneNumber.name, item.rawPhoneNumber ? item.rawPhoneNumber.replace(/#/g, "") : "");
        setFieldValue(creditLimit.name, item.creditLimit);
        setFieldValue(withholdingRate.name, item.withholdingRate);
        setFieldValue(photo.name, item.img);
        setFieldValue(mask.name, item.type);

    }

    function handleSelectedParticipantDeSelection() {
        setSelectedParticipant(null);
        resetForm();
    }

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    useEffect(() => {
        if (!selectedParticipant && searchCriteriaV && searchCriteriaV.length > 3) {
            //let ptype = type === "player" ? 1 : 126;
            execute("GET", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants/127/search?term=" + encodeURIComponent(searchCriteriaV)).then((response) => {
                if (response) {
                    let rows = [];
                    for (let i = 0; i < response.length; i++) {
                        let name = response[i].name, nickName = "";
                        let phone = response[i].phoneNumber;
                        let hasPhoto = response[i].hasPhoto;
                        let creditLimit = 0, requireSig = false, requireNotification = false, notifyMask = 0, withholdingRate = 0;
                        let ptype = 1, responsiblePartyId = null;

                        if (response[i].properties) {
                            name = response[i].properties.name ? response[i].properties.name : name;
                            nickName = response[i].properties.nickName ? response[i].properties.nickName : nickName;
                            phone = response[i].properties.phoneNumber ? response[i].properties.phoneNumber : phone;
                            hasPhoto = response[i].properties.hasPhoto ? response[i].properties.hasPhoto : hasPhoto;
                            creditLimit = response[i].properties.creditLimit ? response[i].properties.creditLimit : creditLimit;
                            withholdingRate = response[i].properties.withholdingRate ? response[i].properties.withholdingRate : withholdingRate;
                            ptype = response[i].properties.participantType ? response[i].properties.participantType : type;
                            requireSig = response[i].properties.mustSign;
                            requireNotification = response[i].properties.mustNotify;
                            notifyMask = response[i].notificationMask;
                            responsiblePartyId = response[i].properties.responsiblePartnerId;
                        }

                        let rawPhone = phone;

                        if (phone && phone.length === 10) {
                            phone = "+1 (" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + " - ****";
                        }
                        else if (response[i].isAnonymous) {
                            phone = "(Anonymous)";
                        }

                        //for now, we search ONLY within our own cardroom
                        let row = {
                            id: response[i].id,
                            name: name,
                            nickName: nickName,
                            phoneNumber: phone,
                            rawPhoneNumber: rawPhone,
                            img: hasPhoto ? apiConfig.images.endpoint + "/player/" + response[i].id + ".jpg" : name.substring(0, 1),
                            creditLimit: creditLimit,
                            withholdingRate: withholdingRate * 100,
                            mustSign: requireSig,
                            mustNotify: requireNotification,
                            notificationMask: notifyMask,
                            type: ptype,
                            cardroom: {
                                id: cardroom.id,
                                hasLogo: false,
                                name: cardroom.name
                            },
                            partnerId: responsiblePartyId ? responsiblePartyId : "",
                            isAnonymous: response[i].isAnonymous
                        };

                        rows.push(row);
                    }

                    if (rows.length === 0) {
                        //no results -- see if we can infer phone number or at least partial from searchCriteriaV?
                        let nupn = "##########";
                        let nun = (type === "player") ? "[new player]" : "[new staff memeber]";
                        if (false == isNaN(searchCriteriaV)) {
                            nupn = searchCriteriaV.padEnd(10, "#");
                        }
                        else {
                            nun = searchCriteriaV + " (create)";
                        }

                        let nurpn = nupn;
                        if (nupn.length === 10) {
                            nupn = "+1 (" + nupn.substring(0, 3) + ") " + nupn.substring(3, 6) + "-****";
                        }
                        rows.push({
                            id: "new",
                            name: nun,
                            nickName: null,
                            phoneNumber: nupn,
                            rawPhoneNumber: nurpn,
                            creditLimit: 0,
                            img: newParticipant,
                            type: (type === "player") ? 1 : 4,
                            cardroom: {
                                id: cardroom.id,
                                hasLogo: false,
                                name: cardroom.name
                            },
                            partnerId: null,
                            isAnonymous: false
                        });
                    }
                    setSearchResults(rows);
                }
            }).catch((e) => {
                console.warn(e.message);
            });
        }
        else {
            setSearchResults([])
        }
    }, [searchCriteriaV]);

    return <SoftBox>
        <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
            <SoftBox mb={1}>
                <SoftTypography variant="h5" fontWeight="regular">
                    Let&apos;s see if we can find your {participantTypeName}
                </SoftTypography>
            </SoftBox>
            <SoftTypography variant="body2" fontWeight="regular" color="text">
                Enter their phone number or name in the search bar
            </SoftTypography>
        </SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={avatar} alt="avatar" size="xxl" variant="rounded" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="center">

                        <FormField disabled={selectedParticipant} onFocus={e => e.currentTarget.select()} autoFocus
                            type={searchCriteria.type}
                            label={searchCriteria.label}
                            name={searchCriteria.name}
                            value={searchCriteriaV}
                            placeholder={searchCriteria.placeholder}
                            error={errors.candidate && touched.candidate}
                            success={candidateV > 0 && !errors.candidate}
                        />
                        {selectedParticipant ? <SelectedParticipantPanel participant={selectedParticipant} onDeselect={handleSelectedParticipantDeSelection} /> :
                            <SearchResultList items={searchResults} onItemSelected={handleSearchResultSelection} />}


                        <FormField disabled={selectedParticipant} style={{ display: "hidden" }}
                            type={candidate.type}
                            name={candidate.name}
                            value={candidateV}
                        />

                        <FormField style={{ display: "hidden" }}
                            type={partnerId.type}
                            name={partnerId.name}
                            value={partnerIdV}
                        />
                    </SoftBox>
                </Grid>

            </Grid>
        </SoftBox>
    </SoftBox>;
}

Search.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Search;
