
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import PartnerSelector from "components/Elements/Partner/PartnerSelector";


const PartnerCell = ({ value, cardroom, color }) => {

    return (
        <SoftTypography variant="caption" fontWeight="medium" color={color}>
            <PartnerSelector value={value} cardroom={cardroom} readonly={true} />
        </SoftTypography>
    );
}

// Setting default values for the props of DefaultCell
PartnerCell.defaultProps = {
    value: "",
    color: "text"
};

// Typechecking props for the DefaultCell
PartnerCell.propTypes = {
    value: PropTypes.string
};

export default PartnerCell;
