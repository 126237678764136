import { Grid, Icon, ListItemIcon, ListItemText } from "@mui/material";


import SoftTypography from "components/SoftTypography";
import { useCardroomContext } from "features";

import { useState } from "react";

import ClubFeeSelector from "layouts/Club/components/ClubFees/Selector";
import ClubFeeScheduleEditor from "layouts/Club/components/ClubFees/Editor";
import Card from "@mui/material/Card";



const UnionFeesEditor = ({ cardroom, union }) => {

    const [context, actions, features] = useCardroomContext(cardroom);

    function canManageFeeSchedule() {
        if (!union) return false;

        if ((union.features & 4) === 0) return false;

        return true;
    }

    function canEditFeeSchedule() {
        if (!union || !cardroom || !context) return false;

        // ClubFeeScheduleEdit = 1,
        if ((union.features & 1) === 0) return false;

        return context.isAllowed(actions.cardroom.fees.club.edit);
    }

    const addSchedulePlaceHolder = canEditFeeSchedule() ? canManageFeeSchedule() ? { id: null, name: "New Schedule" } : { id: "403", name: "Managed by parent union" } : null;

    const [currentFeeSchedule, setCurrentFeeSchedule] = useState(null);
    const [reloadSchedulesNeeded, setReloadSchedulesNeeded] = useState(false);

    function onFeeScheduleChanged(item) {
        if (item && item.id === "403") {
            item = null;
        }

        if (item) {
            setReloadSchedulesNeeded(false);
        }

        setCurrentFeeSchedule(item);

    }

    function onScheduleEditDone(canceled) {
        setCurrentFeeSchedule(null);
        setReloadSchedulesNeeded(true);
    }

    function buildListItem(item) {
        if (item.id) {
            if (item.id === "403") {
                return <>
                    <ListItemIcon style={{ minWidth: "32px" }}>
                        <Icon color={"error"}>block</Icon>
                    </ListItemIcon>
                    <ListItemText><SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize">
                        {item.name}
                    </SoftTypography></ListItemText></>;
            }
            else {
                return <ListItemText><SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {item.name}
                </SoftTypography></ListItemText>;
            }
        }
        else {
            return <>
                <ListItemIcon style={{ minWidth: "32px" }}>
                    <Icon>add</Icon>
                </ListItemIcon>
                <ListItemText><SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {item.name}
                </SoftTypography></ListItemText></>;
        }
    }

    return <Grid width="100%" xs={12}>
        <Grid container xs={12}>
            <Grid item xs={4} p={2}>
                <ClubFeeSelector cardroom={cardroom} union={union} emptyValue={addSchedulePlaceHolder}
                    onSelectionChanged={onFeeScheduleChanged} onBuildListItem={buildListItem} type="list" reload={reloadSchedulesNeeded} maxHeight="375px" />
            </Grid>
            <Grid item xs={8}> <Card style={{ overflow: "auto" }}>
                {currentFeeSchedule ?
                    <ClubFeeScheduleEditor cardroom={cardroom} union={union} schedule={currentFeeSchedule} dense={true} onClose={onScheduleEditDone} />
                    :
                    <Card sx={{ height: 375 }}>
                        <Grid container xs={12} justifyContent="center" alignItems="center">
                            <Grid item xs={12} textAlign={"center"}>

                                <SoftTypography
                                    component="h5"
                                    variant="caption"
                                    color="info">
                                    Select club fee schedule from the list or tap "add new" to create a new schedule
                                </SoftTypography>

                            </Grid>
                        </Grid>
                    </Card>
                }</Card>
            </Grid>
        </Grid>
    </Grid>;
};

export default UnionFeesEditor;