import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { agentId, feeSchedule }
} = form;



const validations = {
    editor: Yup.object().shape({
        [agentId.name]: Yup.string().required(agentId.errorMsg),
        [feeSchedule.name]: Yup.string().required(feeSchedule.errorMsg)
    }),

};

export default validations;