import { useMsal } from "@azure/msal-react";
import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Card } from "react-bootstrap";


const UserProfile = () => {
    const { instance, inProgress } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }


    return <Card id="basic-info" sx={{ overflow: "visible" }}>
        <SoftBox p={3}>
            <SoftTypography variant="h5">{activeAccount && activeAccount.name ? activeAccount.name : 'Unknown'}</SoftTypography>
        </SoftBox>
        <SoftBox pb={3} px={3}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    {activeAccount ? <>
                        Username: {activeAccount.username}<br />
                        Account Id: {activeAccount.localAccountId}<br />
                    </> : null}
                </Grid>
                <Grid item xs={12} sm={6}>

                </Grid>

                <Grid item xs={12} md={6}>

                </Grid>
                <Grid item xs={12} md={6}>
                    <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">

                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </Card>;
};


export default UserProfile;