import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'
import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { Divider, Grid } from '@mui/material';

import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';


import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import TransactionByTypeDoughnutChart from 'components/Elements/Charts/DoughnutCharts/TransactionByTypeDoughnutChart';

import VerticalBarChart from 'components/Elements/Charts/BarCharts/VerticalBarChart';


const BuyInChart = ({ chart }) => {
    if (!chart) return null;

    const total = chart.total;
    const chartData = chart.data;

    return <TransactionByTypeDoughnutChart title="Buy-Ins" width="100%"
        count={{ number: total, text: "total buyins", color: "success", isMoney: true }}
        chart={chartData}
        tooltip=""
    />;
};

const CashoutChart = ({ chart }) => {
    if (!chart) return null;

    const total = chart.total;
    const chartData = chart.data;

    return <TransactionByTypeDoughnutChart title="Cashouts" width="100%"
        count={{ number: total, text: "total cashouts", color: "success", isMoney: true }}
        chart={chartData}
        tooltip=""
    />;
};

const ChipsInPlayChart = ({ chart }) => {
    if (!chart) return null;

    const total = chart.total;
    const chartData = chart.data;

    return <TransactionByTypeDoughnutChart title="Chips In Play" width="100%"
        count={{ number: total, text: "chips in play", color: "success", isMoney: true }}
        chart={chartData}
        tooltip=""
    />;
};

const HouseTransactionsChart = ({ chart }) => {
    if (!chart) return null;

    const total = chart.total;
    const chartData = chart.data;

    return <TransactionByTypeDoughnutChart title="House Transactions" width="100%"
        count={{ number: total, text: "net income", color: total >= 0 ? "success" : "error", isMoney: true }}
        chart={chartData}
        tooltip=""
    />;
};

const StaffChart = ({ chart }) => {
    if (!chart) return null;

    const total = chart.total;
    const chartData = chart.data;

    return <TransactionByTypeDoughnutChart title="Staff Transactions" width="100%"
        count={{ number: total, text: "balance owed", color: "error", isMoney: true }}
        chart={chartData}
        tooltip=""
    />;
};

const DownsByDealerChart = ({ chart }) => {
    if (!chart) return null;

    //const total = chart.total;
    //const chartData = chart.data;

    return <VerticalBarChart
        title="Downs by dealer"
        chart={chart}
    />;
    //VerticalBarChart
    /*
        return <TransactionByTypeDoughnutChart title="Dealer Downs" width="100%"
            count={{ number: total, text: "total downs", color: "info", isMoney: false }}
            chart={chartData}
            tooltip=""
        />;*/
};

const SessionSummaryChart = ({ cardroom, session }) => {

    const [sessionStats, setSessionStats] = useState(null);

    const [buyInChartData, setBuyInChartData] = useState(null);
    const [cashoutChartData, setCashoutChartData] = useState(null);
    const [chipsInPlayChartData, setChipsInPlayChartData] = useState(null);
    const [houseChartData, setHouseChartData] = useState(null);
    const [staffChartData, setStaffChartData] = useState(null);
    const [dealerDownsChartData, setDealerDownsChartData] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading session statistics...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    function mapSessionStats(stats) {

        //process and create BuyIn, Cashout & ChipsNPlay data sets

        function mapActivityName(name) {
            if (name === "Purchase - player") return "Buy In - Player";
            if (name === "Redemption - player") return "Cashout - Player";
            if (name === "Promotion - player") return "Promo - Player";
            if (name === "Withholding - player") return "Tax - Player";
            if (name === "Rake - player") return "Rake - Player";
            if (name === "Expense - player") return "Expenses - Player";

            if (name === "Purchase - staff") return "Buy In - Staff";
            if (name === "Redemption - staff") return "Cashout - Staff";
            if (name === "Promotion - staff") return "Promo - Staff";
            if (name === "Withholding - staff") return "Tax - Staff";
            if (name === "Rake - staff") return "Rake - Staff";
            if (name === "Expense - staff") return "Expenses - Staff";
            if (name === "Gratuity - staff") return "Tips - Staff";
            if (name === "BalanceTransfer - staff") return "Transfer - Staff";

            if (name === "Purchase - house") return "Buy In - House";
            if (name === "Redemption - house") return "Cashout - House";
            if (name === "Promotion - house") return "Promo - House";
            if (name === "Withholding - house") return "Tax - House";
            if (name === "Rake - house") return "Rake - House";
            if (name === "Expense - house") return "Expenses - House";

            return name;
        }

        function createEmptyRow(name) {
            return {
                activity: name,
                cash: 0,
                electronic: 0,
                credit: 0,
                total: 0
            };
        }

        function convertToChartData(item) {
            return {
                total: item.total,
                data: {
                    labels: ["Cash", "e-Wallet", "Credit"],
                    datasets: {
                        backgroundColors: ["success", "warning", "error"],
                        data: [item.cash, item.electronic, item.credit]
                    },
                }
            };
        }

        function createBuyInChartData(map) {
            // const names = ["Buy In", "Cashout", "Promo", "Tax", "Rake", "Exepenses"];

            let totalBought = 0, boughtInCash = 0, boughtInEWallet = 0, boughtOnCredit = 0, boughtOnAccount = 0, totalPromo = 0;
            const mapKey = "Buy In - Player";
            if (map[mapKey]) {
                totalBought = map[mapKey].total;
                boughtInCash = map[mapKey].cash;
                boughtInEWallet = map[mapKey].electronic;
                boughtOnCredit = map[mapKey].credit;
                boughtOnAccount = map[mapKey].account
            }

            if (map["Promo - Player"]) totalPromo = map["Promo - Player"].total;

            return {
                total: totalBought,
                data: {
                    labels: ["Cash", "E-Wallet", "Credit", "Account"],
                    datasets: {
                        backgroundColors: ["success", "warning", "error", "info"],
                        data: [boughtInCash, boughtInEWallet, boughtOnCredit, boughtOnAccount]
                    },
                }
            };
        }

        function createCashOutChartData(map) {

            let totalCashoutPlayer = 0, totalCashoutStaff = 0, totalBalance = 0;
            let mapKey = "Cashout - Player";
            if (map[mapKey]) {
                totalCashoutPlayer = map[mapKey].total;
                totalBalance = map[mapKey].credit;
            }

            mapKey = "Cashout - Staff";
            if (map[mapKey]) {
                totalCashoutStaff = map[mapKey].total;
                totalBalance += map[mapKey].credit;
            }

            mapKey = "Transfer - Staff";
            if (map[mapKey]) {
                totalCashoutStaff += map[mapKey].total;
                //totalBalance += map[mapKey].credit;
            }

            return {
                total: totalCashoutPlayer + totalCashoutStaff,
                data: {
                    labels: ["Players", "Staff", "Balance Owed"],
                    datasets: {
                        backgroundColors: ["success", "secondary", "warning"],
                        data: [totalCashoutPlayer, totalCashoutStaff, totalBalance]
                    },
                }
            };
        }

        function createChipsInPlayData(map) {
            // const names = ["Buy In", "Cashout", "Promo", "Tax", "Rake", "Exepenses"];

            let totalBought = 0, totalCashedIn = 0, totalPromo = 0, totalTax = 0, totalRake = 0, totalTips = 0;
            //let boughtInCash = 0, boughtEWallet = 0, boughtCredit = 0;
            if (map["Buy In - Player"]) {
                let m = map["Buy In - Player"];

                totalBought = m.total;
                //boughtInCash = m.cash;
                //boughtEWallet = m.electronic;
                //boughtCredit = m.credit;
            }
            if (map["Cashout - Player"]) totalCashedIn = map["Cashout - Player"].total;
            if (map["Promo - Player"]) totalPromo = map["Promo - Player"].total;
            if (map["Tax - Player"]) totalTax = map["Tax - Player"].total;
            if (map["Rake - Player"]) totalRake = map["Rake - Player"].total;

            if (map["Buy In - Staff"]) totalBought += map["Buy In - Staff"].total;
            if (map["Cashout - Staff"]) totalCashedIn += map["Cashout - Staff"].total;
            if (map["Promo - Staff"]) totalPromo += map["Promo - Staff"].total;
            if (map["Tax - Staff"]) totalTax += map["Tax - Staff"].total;
            if (map["Rake - Staff"]) totalRake += map["Rake - Staff"].total;
            if (map["Tips - Staff"]) totalTips += map["Tips - Staff"].total;

            if (map["Rake - House"]) totalRake += map["Rake - House"].total;


            return {
                total: totalBought - totalCashedIn - totalRake - totalTips,  //tax already included in tip amount
                data: {
                    labels: ["Bought", "Cashed In", "Tips", "Rake"],
                    datasets: {
                        backgroundColors: ["info", "success", "warning", "secondary"],
                        data: [totalBought, totalCashedIn, totalTips, totalRake]
                    },
                }
            };
        }

        function createHouseChartData(map) {
            // const names = ["Buy In", "Cashout", "Promo", "Tax", "Rake", "Exepenses"];

            let totalExpenses = 0, totalPromo = 0, totalTax = 0, totalRake = 0;
            if (map["Exepenses - House"]) totalExpenses = map["Exepenses - House"].total;
            if (map["Exepenses - Staff"]) totalExpenses += map["Exepenses - Staff"].total;
            if (map["Exepenses - Player"]) totalExpenses += map["Exepenses - Player"].total;
            if (map["Promo - Player"]) totalPromo = map["Promo - Player"].total;
            if (map["Promo - Staff"]) totalPromo += map["Promo - Staff"].total;
            if (map["Tax - Player"]) totalTax = map["Tax - Player"].total;
            if (map["Tax - Staff"]) totalTax += map["Tax - Staff"].total;
            if (map["Rake - Player"]) totalRake += map["Rake - Player"].total;
            if (map["Rake - Staff"]) totalRake += map["Rake - Staff"].total;
            if (map["Rake - House"]) totalRake += map["Rake - House"].total;
            if (map["Expenses - Staff"]) totalExpenses += map["Expenses - Staff"].total;
            if (map["Expenses - Player"]) totalExpenses += map["Expenses - Player"].total;

            return {
                total: totalTax + totalRake - totalExpenses - totalPromo,
                data: {
                    labels: ["Rake", "Taxes", "Expenses", "Promos"],
                    datasets: {
                        backgroundColors: ["success", "info", "error", "warning"],
                        data: [totalRake, totalTax, totalExpenses, totalPromo]
                    },
                }
            };
        }

        function createStaffChart(map) {

            // const names = ["Staff Balance", "Staff Cashed Out", "Tax", ];

            let totalTax = 0, staffCreditBalance = 0, staffCashOut = 0, tipsCredit = 0, tipsTotal = 0, staffExpensesCredit = 0, staffExpensesTotal = 0;

            if (map["Cashout - Staff"]) staffCreditBalance = map["Cashout - Staff"].credit;
            if (map["Cashout - Staff"]) staffCashOut = map["Cashout - Staff"].total;
            if (map["Tax - Staff"]) totalTax = map["Tax - Staff"].total;
            if (map["Tips - Staff"]) {
                tipsTotal = map["Tips - Staff"].total;
                tipsCredit = map["Tips - Staff"].credit;
            }
            if (map["Transfer - Staff"]) {
                staffCashOut += map["Transfer - Staff"].total;
                staffCreditBalance -= map["Transfer - Staff"].cash + map["Transfer - Staff"].electronic;
            }

            if (map["Expenses - Staff"]) {
                staffExpensesCredit = map["Expenses - Staff"].credit;
                staffExpensesTotal = map["Expenses - Staff"].total;
                //staffCreditBalance += map["Expenses - Staff"].credit;
            }

            return {
                total: staffCreditBalance + tipsCredit + staffExpensesCredit - totalTax,
                data: {
                    labels: ["Tips", "Taxes", "Cashed Out"],
                    datasets: {
                        backgroundColors: ["success", "secondary", "info"],
                        data: [tipsTotal, totalTax, staffCashOut]
                    },
                }
            };
        }

        function createDealerDownsChart(map) {
            /*
            return {
                total: 17,
                data: {
                    labels: ["Chris", "Doug", "Jennifer"],
                    datasets: {
                        backgroundColors: ["info", "success", "warning"],
                        data: [5, 4, 8]
                    },
                }
            };
*/
            return {
                labels: ["Chris", "Doug", "Jennifer"],
                datasets: [{
                    label: "Dealer Downs",
                    color: "dark",
                    data: [5, 4, 8],
                }],
            };


        }

        if (stats) {

            // we want row for each, even if values are zero
            const map = [];
            const names = ["Buy In - Player", "Cashout - Player", "Promo - Player", "Tax - Player", "Rake - Player", "Expenses - Player",
                "Buy In - Staff", "Cashout - Staff", "Promo - Staff", "Tax - Staff", "Rake - Staff", "Expenses - Staff", "Tips - Staff", "Transfer - Staff",
                "Buy In - House", "Cashout - House", "Promo - House", "Tax - House", "Rake - House", "Expenses - House"];
            for (var i = 0; i < names.length; i++) {
                let r = createEmptyRow(names[i]);
                map[names[i]] = r;
            }

            for (var i = 0; i < stats.length; i++) {

                let mappedName = mapActivityName(stats[i].name);
                let row = map[mappedName];
                if (!row) {
                    row = createEmptyRow(mappedName);
                    map[mappedName] = row;
                }
                row.cash = stats[i].cash.total;
                row.electronic = stats[i].electronic.total;
                row.credit = stats[i].credit.total;
                row.account = stats[i].account.total;
                row.total = row.cash + row.electronic + row.credit + row.account;

            }

            if (map["Buy In - Player"]) {
                setBuyInChartData(createBuyInChartData(map));
                //setBuyInChartData(convertToChartData(map["Buy In"]));
            }
            if (map["Cashout - Player"] || map["Cashout - Staff"]) {
                setCashoutChartData(createCashOutChartData(map));
            }

            setChipsInPlayChartData(createChipsInPlayData(map));
            setHouseChartData(createHouseChartData(map));
            setStaffChartData(createStaffChart(map));
            setDealerDownsChartData(createDealerDownsChart(map));
        }

    }

    useEffect(() => {
        if (!sessionStats) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session statistics...")
            showProgress();

            //  [HttpGet("{casinoId:Guid}/sessions/{sessionId:Guid}/stats", Name = "GetSessionStatistics")]
            execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/stats").then((response) => {
                if (response) {
                    setSessionStats(response);
                    mapSessionStats(response);
                    progressIndicatorProps.close();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load session statistics, please try again...")
            });
        }
    }, [execute, sessionStats, retryCounter])


    return <Grid container spacing={3} justifyContent="space-between" alignItems="center">


        {!progressIndicatorProps.visible ? <>
            <Grid item xs={12} md={6}>
                <ChipsInPlayChart chart={chipsInPlayChartData} />
            </Grid>
            <Grid item xs={12} md={6}>
                <BuyInChart chart={buyInChartData} />
            </Grid>
            <Grid item xs={12} md={6}>
                <CashoutChart chart={cashoutChartData} />
            </Grid>
            <Grid item xs={12} md={6}>
                <HouseTransactionsChart chart={houseChartData} />
            </Grid>
            <Grid item xs={12} md={6}>
                <StaffChart chart={staffChartData} /></Grid>
            {/*
            <Grid item xs={12} md={6}>
                <DownsByDealerChart chart={dealerDownsChartData} />
            </Grid>
*/}

        </> : <Grid item xs={12}> <ProgressIndicator {...progressIndicatorProps} /></Grid>}

    </Grid>;

};

const SessionClockDisplay = ({ cardroom, session }) => {

    //session.endedOn = session.startedOn;
    const startTime = session ? session.startedOn + "Z" : new Date(Date.now());

    const [duration, setDuration] = useState(setDurationDisplay(startTime, new Date(Date.now())));

    function setDurationDisplay(start, end) {

        if (session && session.status !== 2) {
            if (session.status === 3 || session.status === 4) {
                return "Ended On: " + new Date(Date.parse(session.endedOn)).toLocaleDateString();
            }


            if (session.status === 1) {
                return "Scheduled for: " + new Date(Date.parse(start)).toLocaleDateString();
            }
            return "";
        }

        return "Duration: " + calcDuration(start, end);
    }

    function calcDuration(start, end) {
        let startMs = Date.parse(start);
        let endMs = Date.parse(end);

        let ms = endMs - startMs;

        let seconds = Math.floor(ms / 1000);
        ms -= seconds * 1000;
        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        let hours = Math.floor(minutes / 60);
        minutes -= hours * 60;

        let days = Math.floor(hours / 24);
        hours -= days * 24

        let str = "";
        if (days > 0) str = days + ".";

        str += hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');

        return str;
        //return days + "." + hours + ":" + minutes + ":" + seconds + "." + ms;  
    }

    useEffect(() => {
        if (session.status === 2) {
            const id = setTimeout(() => {
                setDuration("Duration: " + calcDuration(startTime, new Date(Date.now())));
            }, 1000);
        }
    }, [duration, session]);

    const mapSessionStatus = (status) => {
        switch (status) {
            case 1:
                return "Pending";
            case 2:
                return "Active";
            case 3:
                return "Completed";
            case 4:
                return "Closed";
            case 5:
                return "Canceled";
        }

        return "Other";
    };

    const getSessionStatusColor = (status) => {
        switch (status) {
            case 1:
                return "primary";
            case 2:
                return "info";
            case 3:
                return "warning";
            case 4:
                return "success";
            case 5:
                return "light";
        }

        return "error";
    };
    //startedOn //endedOn //status
    return (session ? <>
        <Grid item xs={4} textAlign="left" alignItems="left" style={{ paddingLeft: "5px" }}>
            <SoftTypography style={{ whiteSpace: "nowrap" }}
                variant="h6"
                fontWeight="bold" color="light">
                Started On: {new Date(Date.parse(session.startedOn)).toLocaleDateString()}
            </SoftTypography>
        </Grid>
        <Grid item xs={4} textAlign="center" alignItems="center">
            <SoftTypography style={{ whiteSpace: "nowrap" }}
                variant="h6"
                fontWeight="bold" color="light">
                Status: <SoftTypography variant="h6"
                    fontWeight="bold" color={getSessionStatusColor(session.status)} style={{ display: "inline" }}>
                    {mapSessionStatus(session.status)}
                </SoftTypography>
            </SoftTypography>
        </Grid>
        <Grid item xs={4} textAlign="right" alignItems="right" style={{ paddingRight: "5px" }}>

            <SoftTypography style={{ whiteSpace: "nowrap" }}
                variant="h6"
                fontWeight="bold" color="light">
                {duration}
            </SoftTypography>

        </Grid>
    </> : null
    );
}

const SessionDashboard = ({ cardroom, session }) => {

    return <SoftBox bgColor="dark" borderRadius="xl" position="relative" variant="gradient">
        <SoftBox p={3}>
            <Grid container spacing={3} pl={3} pb={1} justifyContent="flex-center" alignItems="center">
                <SessionClockDisplay cardroom={cardroom} session={session} />
            </Grid>
            <Divider light />

            <Grid container spacing={3} pl={3} justifyContent="space-between" alignItems="center">
                <SessionSummaryChart cardroom={cardroom} session={session} />
            </Grid>

            <Divider light />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <SoftBox display="flex" alignItems="center" position="relative">
                        <SoftBox width="100%" p={1.5}>

                        </SoftBox>
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>

};

SessionDashboard.defaultProps = {

};

SessionDashboard.propTypes = {
    cardroom: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired
};

export default SessionDashboard;