
const form = {
    formId: "player-editor-form",
    formField: {
        candidateId: {
            name: "candidateId",
            type: "hidden"
        },
        partnerId: {
            name: "partnerId",
            label: "Responsible partner",
            type: "hidden",
            errorMsg: "Responsible party must be selected"
        },
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "name",
            errorMsg: "Name is required.",
        },
        nickName: {
            name: "nickName",
            label: "Nickname",
            type: "text",
            placeholder: "nickname",
            errorMsg: "",
        },
        phoneNumber: {
            name: "phoneNumber",
            label: "Phone Number",
            type: "number",
            placeholder: "phone number",
            errorMsg: "A 10 digit phone number is required",
        },
        withholdingRate: {
            name: "withholdingRate",
            label: "Withholding Rate",
            type: "number",
            placeholder: "withholding percentage",
            errorMsg: "Withholding rate must be between 0 and 100",
        },
        withholdingThreshold: {
            name: "withholdingThreshold",
            label: "Withholding exemption",
            type: "number",
            placeholder: "withholding exemption",
            errorMsg: "Exempt amount is required",
        },
        withholdingSchedule: {
            name: "withholdingSchedule",
            label: "Withholding Type",
            type: "text",
            placeholder: "withholding type",
            errorMsg: "Withholding type is required",
        },
        mustNotify: {
            name: "mustNotify",
            label: "Require notifications",
            type: "hidden",
            placeholder: "Require notifications",
            errorMsg: "Notification setting is required"
        },
        notificationMask: {
            name: "notificationMask",
            type: "hidden"
        },
        photo: {
            name: "photo",
            label: "",
            type: "text",
            placeholder: "photo",
            errorMsg: "",
        },
        mask: {
            name: "mask",
            type: "hidden",
            errorMsg: "At least one staff role must be selected"
        },
        isAnonymous: {
            name: "isAnonymous",
            label: "Anonymous participant",
            type: "bool",
            placeholder: "Is anonymous",
            errorMsg: "Anonymous designation is required",
        }
    },

};

export default form;
