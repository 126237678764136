import buyin from "./form";


const {
    formField: {
        amount,
        method,
        verified,
        provider,
        providerOther,
        providerRefMethod,
        providerRef,
        providerRefImage,
        participantSignatureImage,
        markerBalance,
        availableCredit,
        requestSignature,
        note,
        promoType
    },
} = buyin;

const initialValues = {
    [amount.name]: 0,
    [method.name]: "cash",
    [verified.name]: false,
    [provider.name]: "",
    [providerOther.name]: "",
    [providerRefMethod.name]: "number",
    [providerRef.name]: "",
    [providerRefImage.name]: "",
    [participantSignatureImage.name]: "",
    [markerBalance.name]: 0,
    [availableCredit.name]: 0,
    [note.name]: "",
    [promoType.name]: "chips",
    [requestSignature.name]: true
};

export default initialValues;
