
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";



// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { BootstrapDialog } from "components/Elements/Dialog/common"

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import TransactionReceipt from './Receipt';
import TransactionEditor from './Editor';
import { useCardroomContext } from 'features';



const TransactionDialog = ({ open, close, cardroom, session, transaction, participant, onTransactionUpdated }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditTransaction() {
        if (!context) return false;
        return context.isAllowed(actions.casino.session.transaction.create) || context.isAllowed(actions.casino.session.transaction.update) || context.isAllowed(actions.casino.session.transaction.cancel);
    }

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        onClose={close}
        maxWidth="lg">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Transaction {transaction ? (transaction.status === 2) ? "Receipt" : "Editor" : null}
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox>{transaction ? (transaction.status === 2 || transaction.status === 3 || false === canEditTransaction()) ?
                <TransactionReceipt cardroom={cardroom} session={session} transaction={transaction} participant={participant} onClose={close} onTransactionUpdated={onTransactionUpdated} /> :
                <TransactionEditor cardroom={cardroom} session={session} transaction={transaction} participant={participant} onClose={close} onTransactionUpdated={onTransactionUpdated} /> : null}
            </SoftBox>
        </DialogContent>
    </BootstrapDialog>;
}


TransactionDialog.defaultProps = {

};

TransactionDialog.propTypes = {
    cardoom: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    transaction: PropTypes.object.isRequired,
    onTransactionUpdated: PropTypes.func
};

export default TransactionDialog;