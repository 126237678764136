import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";
import { useEffect, useState } from "react";
import SoftDropzone from "components/SoftDropzone";
import { Grid, Icon } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";


const FormImageField = ({ name, imgHeight, imgWidth, caption, imagePresenter, ...formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { field } = formField[name];

    const fieldV = values[name];

    const clear = () => {
        setFieldValue(name, "");
    };

    return (fieldV ? <Grid container xs={12} spacing={1}>
        <Grid item xs={11}> {imagePresenter ? imagePresenter({ src: fieldV }) :
            <img src={fieldV} height={imgHeight} width={imgWidth} />
        }</Grid>
        <Grid item xs={1}>
            <SoftBox style={{ position: "relative", top: 0, right: 0 }} m={1}>
                <SoftButton onClick={clear} variant="outlined" size="small" color="error" title="clear selection" iconOnly>
                    <Icon >clear</Icon>
                </SoftButton>
            </SoftBox>
        </Grid>
    </Grid> : <>
        <SoftDropzone mt={0.5} mb={0.5} options={{
            addRemoveLinks: true
            , maxFiles: 1
            , autoProcessQueue: false
            , autoQueue: false
            , dictDefaultMessage: caption ? caption : "Drop files here to upload"
            , init: function () {
                this.on("addedfile", file => {
                    //onVendorRefImageAdded(file, this);
                    var reader = new FileReader();
                    var url = reader.readAsDataURL(file);

                    reader.onloadend = function (e) {
                        var imgUrl = reader.result;
                        if (imgUrl && imgUrl.startsWith("data:image")) {
                            setFieldValue(name, reader.result);
                        }
                        else {
                            this.removeFile(file);
                        }
                    }.bind(this);
                })
            }
        }} />
        <SoftTypography
            component="label"
            variant="caption"
            color="error">
            <ErrorMessage name={name} />
        </SoftTypography>
    </>
    );
};

FormImageField.propTypes = {
    name: PropTypes.string.isRequired,
    imgHeight: PropTypes.string,
    imgHeight: PropTypes.string,
    formData: PropTypes.object.isRequired
};

export default FormImageField;