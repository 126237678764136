// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import {PatternFormat} from "react-number-format";

const PhoneCell = ({ value, obfuscate }) => {
    let maskedValue = value;
    if (obfuscate && maskedValue && maskedValue.length > 6){
        maskedValue = maskedValue.substring(0, 6);
    }

    return (
        <SoftTypography variant="caption" fontWeight="medium" color="text">
            <PatternFormat value={maskedValue} displayType="text" format="+1 (###) ###-####" mask="*" /> 
        </SoftTypography>
    );
}

// Setting default values for the props of DefaultCell
PhoneCell.defaultProps = {
    obfuscate: false    
};

// Typechecking props for the DefaultCell
PhoneCell.propTypes = {
  value: PropTypes.string.isRequired,
  obfuscate: PropTypes.bool
};

export default PhoneCell;
