
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { BootstrapDialog } from "components/Elements/Dialog/common"


import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import DataTable from 'components/Elements/DataTable';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import { Card, DialogActions, Icon, Menu, MenuItem, MenuList } from '@mui/material';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import { useDialog } from 'components/Elements/Dialog/common';
import SoftButton from 'components/SoftButton';
import ClubEditorDialog from 'layouts/Club/components/Club';
import { InvitationEditorDialog } from 'components/Elements/Invitation';


const EditableCell = ({ cardroom, union, parentUnion, unions, club }) => {

    const [unionMenu, setUnionMenu] = useState(null);
    const openSetUnionMenu = (event) => setUnionMenu(event.currentTarget);
    const closeSetUnionMenu = () => setUnionMenu(null);

    const [openMoveClubToUnionDialog, openMoveClubToUnionDialogProps] = useDialog();
    const [openInviteUserDialog, openInviteUserDialogProps] = useDialog();

    const [eligibleUnions, setEligibleUnions] = useState(null);
    const [moveToUnion, setMoveToUnion] = useState(null);
    const [parentUnionLocalCopy, setParentUnionLocalCopy] = useState(parentUnion);

    useEffect(() => {
        if (unions && parentUnionLocalCopy && !eligibleUnions) {
            //build list of eligible unions

            let rows = []; //[union, ...unions];

            for (var i = 0; i < unions.length; i++) {
                if (unions[i].id != parentUnionLocalCopy.id) {
                    rows.push(unions[i]);
                }
            }

            rows.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            });

            if (union.id != parentUnionLocalCopy.id) {
                rows.unshift(union);
            }

            //parentUnionId

            setEligibleUnions(rows);
        }
    }, [unions, parentUnionLocalCopy, eligibleUnions]);

    function onMenuItemClicked(targetUnion) {
        //alert("move" + club.name + "->" + targetUnion.name);
        //open move club dialog
        setMoveToUnion(targetUnion);
        closeSetUnionMenu();
        openMoveClubToUnionDialog();
    }

    function onClubMovedToNewUnion() {
        //this may need to trigger reloading of table??? is there a way to force just cell update?
        //updating parentUnion should do he trick?
        setParentUnionLocalCopy(moveToUnion);
        setMoveToUnion(null);
        setEligibleUnions(null); //force menu refresh

        for (var i = 0; i < unions.length; i++) {
            if (unions[i].clubs) unions[i].clubs = null;
            if (unions[i].unions) unions[i].unions = null;
        }
        if (union.clubs) union.clubs = null;
        if (union.unions) union.unions = null;
    }

    function onInviteUserClicked() {
        closeSetUnionMenu();
        openInviteUserDialog();
    }

    return <SoftBox display="flex" justifyContent="space-between" alignItems="center">
        <MoveClubToUnionDialog {...openMoveClubToUnionDialogProps} cardroom={cardroom} union={union} club={club} targetUnion={moveToUnion} onMoved={onClubMovedToNewUnion} />
        <InvitationEditorDialog {...openInviteUserDialogProps} cardroom={cardroom} union={union} club={club} />
        <TextCell value={parentUnionLocalCopy ? parentUnionLocalCopy.name : null} />
        <SoftTypography
            color="secondary"
            onClick={openSetUnionMenu}
            sx={{
                width: "16px",
                cursor: "pointer",
            }}
        >
            <Icon fontSize="default">more_vert</Icon> {/*edit */}
        </SoftTypography>

        <Menu anchorEl={unionMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(unionMenu)}
            onClose={closeSetUnionMenu}
            keepMounted
        >
            <MenuItem onClick={onInviteUserClicked}>Invite User</MenuItem>
            {eligibleUnions && eligibleUnions.length > 0 ? <>
                <MenuItem divider={true}></MenuItem>
                <MenuItem disabled={true}>Move to</MenuItem>
                <MenuItem divider={true}></MenuItem>
                {eligibleUnions ? eligibleUnions.map((m) => {
                    return <MenuItem onClick={() => onMenuItemClicked(m)}>{m.name}</MenuItem>
                }) : null}
            </> : null}


        </Menu>
    </SoftBox>;
};

const MoveClubToUnionDialog = ({ open, close, cardroom, union, club, targetUnion, onMoved }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Saving your changes...", handleRetry)
    const [readyToSave, setReadyToSave] = useState(false);


    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    function canMoveClub() {
        return true;
    }

    function moveClubToNewUnion() {
        //perform the move

        //once service calls succeeds, raise close & onMoved events
        setReadyToSave(true);

    }

    useEffect(() => {
        if (readyToSave) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving your changes...")
            showProgress();
            //[HttpPut("{unionId:Guid}/casino/{casinoId:Guid}/clubs/{clubId:Guid}/parent/{parentUnionId:Guid}", Name = " UpdateClubsParentUnion")]
            clubRequest.execute("PUT", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/clubs/" + club.id + "/parent/" + targetUnion.id).then((response) => {
                // setCardroomDetailsData(response);
                if (response) {
                    club = Object.assign(club, response);

                    if (onMoved) {
                        onMoved();
                    }
                    close();
                }
                setReadyToSave(false);
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save changes, please try again...")
            });
        }
    }, [clubRequest.execute, readyToSave, retryCounter]);

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={close}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Change union affiliation?
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox mt={3}>
                <ProgressIndicator {...progressIndicatorProps} />

                {!progressIndicatorProps.visible && club && targetUnion ? <>
                    <SoftTypography textAlign="center">
                        <SoftTypography color="dark" variant="h6" alignItems="center">
                            Moving a club from one union to another could change previously generated reports.
                        </SoftTypography>
                        <SoftTypography color="dark" variant="h6" alignItems="center">
                            Are you sure you want to move club "{club.name}" to union "{targetUnion.name}"?
                        </SoftTypography>

                    </SoftTypography>
                </> : null}
            </SoftBox>
        </DialogContent>
        <DialogActions>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <SoftButton onClick={close} variant="outlined" color="warning">
                    cancel
                </SoftButton>

                <SoftButton onClick={moveClubToNewUnion} variant="outlined" color="success" disabled={!canMoveClub()}>
                    move
                </SoftButton>
            </SoftBox>
        </DialogActions>
    </BootstrapDialog>;
};

MoveClubToUnionDialog.defaultProps = {

};

MoveClubToUnionDialog.propTypes = {
    cardroom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    targetUnion: PropTypes.object.isRequired
};

export { MoveClubToUnionDialog };

const UnionClubs = ({ cardroom, union }) => {

    function prepareClubsForPresentation(clubs, unions, table) {
        let unionMap = [];
        for (var i = 0; i < unions.length; i++) {
            if (!unionMap[unions[i].id]) {
                unionMap[unions[i].id] = unions[i];
            }
        }

        if (!unionMap[union.id]) {
            unionMap[union.id] = union;
        }

        let rows = [];

        for (var i = 0; i < clubs.length; i++) {
            let clubImage = clubs[i].name.substring(0, 1);

            let parentUnion = unionMap[clubs[i].parentUnionId];

            rows.push({
                name: [clubs[i].name, { image: clubImage }],
                displayName: clubs[i].name,
                displayNameNoCase: clubs[i].name.toLowerCase(),
                appClubId: clubs[i].appClubId,
                sourceAppName: clubs[i].app ? clubs[i].app.name : "None",
                playerCount: clubs[i].playerCount,
                agentCount: clubs[i].agentCount,
                union: parentUnion,
                club: clubs[i]
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetEmptySchema(handler) {
        return {
            columns: [
                {
                    Header: "Club",
                    accessor: "name",
                    Cell: ({ value: [name, data], row }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} participant={row && row.original && row.original.club ? row.original.club : null} onClick={handler} />
                    ),
                },
                { Header: "Club Id", accessor: "appClubId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "# Players", accessor: "playerCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "# Agents", accessor: "agentCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "Source", accessor: "sourceAppName", Cell: ({ value }) => <TextCell value={value} /> },
                {
                    Header: "Union", accessor: "union", Cell: ({ value, row }) => {
                        //if value, then we want to paint vertical ... & open context menu
                        return <EditableCell cardroom={cardroom} union={union} unions={unions} parentUnion={value} club={row && row.original ? row.original.club : null} />;
                    }
                },
            ],
            rows: []
        };
    }

    const [unions, setUnions] = useState(null);

    const [clubs, setClubs] = useState(null);

    const [clubsTable, setClubsTable] = useState(null);

    const [currentClub, setCurrentClub] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading union clubs...", handleRetry)

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditClub() {

        return true;
    }

    function openClubEditor(club) {
        if (!canEditClub()) return;

        if (club) {
            setCurrentClub(club);
            openClubEditorDialog();
        }
    }

    const [openClubEditorDialog, openClubEditorDialogProps] = useDialog();


    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (!clubs) {

            if (union.clubs) {
                setClubs(union.clubs);
                setClubsTable(null);
                return;
            }

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading union clubs...")
            showProgress();
            //[HttpGet("{unionId:Guid}/casino/{casinoId:Guid}/clubs", Name = "GetUnionClubs")]
            clubRequest.execute("GET", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/clubs").then((response) => {
                if (response) {
                    union.clubs = clubs;
                    setClubs(response);
                    setClubsTable(null);
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load union clubs, please try again...")
            });
        }
    }, [clubRequest.execute, clubs, retryCounter]) //cardroomListData 


    useEffect(() => {
        if (!unions) {

            if (union.unions) {
                setUnions(union.unions);
                return;
            }

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading unions...")
            showProgress();
            //[HttpGet("{unionId:Guid}/casino/{casinoId:Guid}/unions", Name = "GetUnionUnions")]
            clubRequest.execute("GET", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/unions").then((response) => {
                if (response) {
                    union.unions = response;
                    setUnions(response);
                }

            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load unions, please try again...")
            });
        }
    }, [clubRequest.execute, unions, retryCounter]) //cardroomListData 


    useEffect(() => {
        if (clubs && unions && !clubsTable) {
            setClubsTable(prepareClubsForPresentation(clubs, unions, GetEmptySchema(openClubEditor)));
            progressIndicatorProps.close();
        }
    }, [clubs, unions, clubsTable]);

    return <Card>
        {canEditClub() ?
            <ClubEditorDialog cardroom={cardroom} union={union} club={currentClub} clubs={clubs} {...openClubEditorDialogProps} /> : null}
        {(cardroom && clubs) && !progressIndicatorProps.visible ? <>
            {clubsTable ?
                <DataTable table={clubsTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} pagination={{ color: "dark" }} />
                : null}
        </> : <ProgressIndicator {...progressIndicatorProps} />}
    </Card>;
};

export default UnionClubs