import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';


import Card from "@mui/material/Card";

import MoneyCell from "components/Elements/DataTable/components/Cells/MoneyCell";
import TextCell from "components/Elements/DataTable/components/Cells/TextCell";



import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { Grid } from '@mui/material';
import DataTable from 'components/Elements/DataTable';
import DateCell from 'components/Elements/DataTable/components/Cells/DateCell';


function convertTransactionsToTable(transactions, holders, cardroom, table) {

    const holderMap = [];
    if (holders) {
        for (var i = 0; i < holders.length; i++) {
            if (!holderMap[holders[i].id]) {
                holderMap[holders[i].id] = holders[i];
            }
        }
    }

    function getTxType(v) {
        switch (v) {
            case 1:
                return "Credit";
            case 2:
                return "Debit";
            case 3:
                return "Debt Cancelation";
            case 4:
                return "Initial Balance";
        }

        return "Unknown";
    }
    function getSignedAmount(v, amount) {
        amount = Math.abs(amount);

        switch (v) {
            case 1:
            case 4:
                return amount;;
            case 2:
            case 3:
                return -1 * amount;
        }

        return amount;
    }

    function getParticipantName(p) {
        if (!p) return "[Unknown]";

        let name = p.name;

        if (p.properties) {
            name = p.properties.name ? p.properties.name : name;
            if (p.properties.nickName) {
                name += "(" + p.properties.nickName + ")";
            }
        }

        return name;
    }

    function getDescription(tx) {
        if (!tx) return "";

        function getCreditDescription(t) {

            return getDebitCreditDescription(t, "credited", "debited");
        }

        function getDebitDescription(t) {
            return getDebitCreditDescription(t, "debited", "credited");
        }

        function getDebitCreditDescription(t, srcAction, trgAction) {

            let descr = "";

            if (t.sourceAccount.accountHolderId == t.sourceAccount.casinoId) {
                descr = cardroom.name;
            }
            else {
                descr = getParticipantName(holderMap[t.sourceAccount.accountHolderId]);
            }

            descr += "'s account was " + srcAction + " and ";

            if (t.targetAccount.accountHolderId == t.targetAccount.casinoId) {
                descr += cardroom.name;
            }
            else {
                descr += getParticipantName(holderMap[t.targetAccount.accountHolderId]);
            }

            descr += "'s account " + trgAction + " " + formatAmount(t.amount);

            return descr;
        }

        function getDebtForgivenDescription(t) {
            return getDebitCreditDescription(t, "debited", "credited") + " as debt forgiveness";
        }
        function getInitDescription(t) {
            return getDebitCreditDescription(t, "")
            //return getDebitCreditDescription(t, "credited", "credited") + " as debt forgiveness";
        }

        let d = '';
        switch (tx.type) {
            case 1:
                d = getCreditDescription(tx);
                break;
            case 2:
                d = getDebitDescription(tx);
                break;
            case 3:
                d = getDebtForgivenDescription(tx);
                break;
            case 4:
                d = getInitDescription(tx);
                break;
        }

        if (tx.description) {
            d += " (" + tx.description + ")";
        }
        return d;
    }

    function formatAmount(amount) {
        var delimiter = ","; // replace comma if desired
        var a = amount.toString().split('.', 2)
        var d = a[1];
        var i = parseInt(a[0]);
        if (isNaN(i)) { return ''; }
        var minus = '';
        if (i < 0) { minus = '-'; }
        i = Math.abs(i);
        var n = new String(i);
        var a = [];
        while (n.length > 3) {
            var nn = n.substring(n.length - 3);
            a.unshift(nn);
            n = n.substring(0, n.length - 3);
        }
        if (n.length > 0) { a.unshift(n); }
        n = a.join(delimiter);
        if (!d || d.length < 1) { amount = n; }
        else { amount = n + '.' + d; }
        amount = minus + "$" + amount;
        return amount;
    }

    var rows = [];

    if (transactions && transactions.length > 0) {
        for (var i = 0; i < transactions.length; i++) {
            var row = {
                id: transactions[i].id,
                occuredOn: transactions[i].occuredOn + "Z",
                type: getTxType(transactions[i].type),
                method: transactions[i].paymentMethod,
                description: getDescription(transactions[i]),
                amount: getSignedAmount(transactions[i].type, transactions[i].amount),
                from: transactions[i].sourceAccount,
                to: transactions[i].targetAccount
            };

            rows.push(row);
        }
    }

    table.rows = rows;

    return table;
}

function GetTransactionTableSchema() {

    return {
        columns: [
            { Header: "date", accessor: "occuredOn", Cell: ({ value }) => <DateCell value={value} format="dateAndTime" display="locale" /> },
            { Header: "type", accessor: "type", Cell: ({ value }) => <TextCell value={value} /> },
            { Header: "amount", accessor: "amount", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useFormatter={true} useColorScheme={true} /> },
            { Header: "description", accessor: "description", Cell: ({ value }) => <TextCell value={value} /> }
        ],
        rows: [],
    };
}

function GetEmptySchema() {
    return {
        columns: [],
        rows: []
    }
}


const AccountingCard = ({ cardroom, account }) => {

    const [transactionsTable, setTransactionsTable] = useState(GetEmptySchema());
    const [transactions, setTransactions] = useState(null);
    const [participants, setParticipants] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading account transactions...", handleRetry)

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    useEffect(() => {
        if (!transactions && !participants) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading account transactions...")
            showProgress();

            // [HttpGet("{entityId:Guid}/holders/{holderId:Guid}/accounts/{accountId:Guid}/transactions", Name = "GetAccountTransactions")]
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + account.accountHolderId + "/accounts/" + account.id + "/transactions").then((response) => {
                if (response) {
                    setTransactions(response);
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load account transactions, please try again...")
            });
        }
    }, [accountRequest.execute, transactions, retryCounter])

    useEffect(() => {
        if (!participants && transactions) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading account information...")
            showProgress();

            const idmap = [];
            const ids = [];

            function copyAccountHolder(accnt) {
                if (!account) return;
                const holderId = accnt.accountHolderId;
                if (holderId != accnt.casinoId && !idmap[holderId]) {
                    ids.push(holderId);
                    idmap[holderId] = holderId;
                }
            }

            for (var i = 0; i < transactions.length; i++) {
                if (transactions[i]) {
                    copyAccountHolder(transactions[i].sourceAccount);
                    copyAccountHolder(transactions[i].targetAccount);
                }
            }

            /*
             from: transactions[i].sourceAccount,
                            to: transactions[i].targetAccount
                             */

            //  [HttpPut("venue/{venueId:Guid}/participants", Name = "FillParticipantList")]
            peopleRequest.execute("PUT", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", ids).then((response) => {
                if (response) {
                    setParticipants(response);
                    setTransactionsTable(convertTransactionsToTable(transactions, response, cardroom, GetTransactionTableSchema()));
                    progressIndicatorProps.close();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load account information, please try again...")
            });
        }
    }, [peopleRequest.execute, participants, transactions, retryCounter])

    return <Card>
        <Grid container>
            <Grid item m={1} xs={12} mr="auto" ml="auto">
                <ProgressIndicator {...progressIndicatorProps} />
                {!progressIndicatorProps.visible ?
                    <DataTable table={transactionsTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} pagination={{ color: "dark" }} /> : null}
            </Grid>
        </Grid>
    </Card>;
};




export default AccountingCard;