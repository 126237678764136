/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from '@mui/material/TableCell';
import Icon from "@mui/material/Icon";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "./components/DataTableHeadCell";
import DataTableBodyCell from "./components/DataTableBodyCell";

function DataTable({
    entriesPerPage,
    canSearch,
    showTotalEntries,
    table,
    pagination,
    isSorted,
    noEndBorder,
    isHierarchical,
    widget
}) {
    const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
    const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
    const entriesPerPageDropdownVisible = entriesPerPage.visible;

    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);
    const collapsibleDetailAccessor = table.collapsibleDetailAccessor;

    const tableInstance = useTable(
        { columns, data, initialState: { pageIndex: 0 } },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        pageOptions,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = tableInstance;

    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

    // Set the entries per page value based on the select value
    const setEntriesPerPage = ({ value }) => setPageSize(value);

    // Render the paginations
    const renderPagination = pageOptions.map((option) => (
        <SoftPagination
            item
            key={option}
            onClick={() => gotoPage(Number(option))}
            active={pageIndex === option}
        >
            {option + 1}
        </SoftPagination>
    ));

    // Handler for the input to set the pagination index
    const handleInputPagination = ({ target: { value } }) =>
        value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

    // Customized page options starting from 1
    const customizedPageOptions = pageOptions.map((option) => option + 1);

    // Setting value for the pagination input
    const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

    // Search input value state
    const [search, setSearch] = useState(globalFilter);

    // Search input state handle
    const onSearchChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 100);

    // A function that sets the sorted value for the table
    const setSortedValue = (column) => {
        let sortedValue;

        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asce";
        } else if (isSorted) {
            sortedValue = "none";
        } else {
            sortedValue = false;
        }

        return sortedValue;
    };


    const TRow = ({ row, key, detailAccessor }) => {
        //const { row, key } = props;
        const [open, setOpen] = useState(false);

        return (<>
            <TableRow key={key} {...row.getRowProps()}>
                {isHierarchical ? <TableCell style={{ padding: "5px 0px 5px 10px" }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell> : null}

                {/* hierarchySupport={isHierarchical} */}
                {row.cells.map((cell, key) => (
                    <DataTableBodyCell
                        key={key}
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                    >
                        {cell.render("Cell")}
                    </DataTableBodyCell>
                ))}
            </TableRow>
            {isHierarchical && row.original && detailAccessor && row.original[detailAccessor] ? <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={row.cells.length + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {row.original[detailAccessor]}
                    </Collapse>
                </TableCell>
            </TableRow> : null}
        </>
        );
    };

    // Setting the entries starting point
    const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

    // Setting the entries ending point
    let entriesEnd;

    if (pageIndex === 0) {
        entriesEnd = pageSize;
    } else if (pageIndex === pageOptions.length - 1) {
        entriesEnd = rows.length;
    } else {
        entriesEnd = pageSize * (pageIndex + 1);
    }

    return (
        <TableContainer sx={{ boxShadow: "none" }}>
            {(entriesPerPage && entriesPerPageDropdownVisible) || canSearch || widget ? (
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} style={{ border: "2px solid black;" }}>
                    {entriesPerPage && entriesPerPageDropdownVisible && (
                        <SoftBox display="flex" alignItems="center">
                            <SoftSelect
                                defaultValue={{ value: defaultValue, label: defaultValue }}
                                options={entries.map((entry) => ({ value: entry, label: entry }))}
                                onChange={setEntriesPerPage}
                                size="small"
                            />
                            <SoftTypography variant="caption" color="secondary">
                                &nbsp;&nbsp;entries per page
                            </SoftTypography>
                        </SoftBox>
                    )}
                    {widget ? <SoftBox alignItems="center">{widget}</SoftBox> : null}

                    {canSearch && (
                        <SoftBox width="12rem" ml="auto">
                            <SoftInput
                                placeholder="Search..."
                                value={search}
                                onChange={({ currentTarget }) => {
                                    setSearch(search);
                                    onSearchChange(currentTarget.value);
                                }}
                            />
                        </SoftBox>
                    )}
                </SoftBox>
            ) : null}
            <Table {...getTableProps()}>
                <SoftBox component="thead">
                    {headerGroups.map((headerGroup, key) => (
                        <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                            {isHierarchical ? <TableCell width={15} /> : null}
                            {headerGroup.headers.map((column, key) => (
                                <DataTableHeadCell
                                    key={key}
                                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                                    width={column.width ? column.width : "auto"}
                                    align={column.align ? column.align : "left"}
                                    sorted={setSortedValue(column)}
                                >
                                    {column.render("Header")}
                                </DataTableHeadCell>
                            ))}
                        </TableRow>
                    ))}
                </SoftBox>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, key) => {
                        prepareRow(row);
                        return <TRow row={row} key={key} detailAccessor={collapsibleDetailAccessor} />
                    })}
                </TableBody>
            </Table>

            <SoftBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
            >
                {showTotalEntries && (
                    <SoftBox mb={{ xs: 3, sm: 0 }}>
                        <SoftTypography variant="button" color="secondary" fontWeight="regular">
                            Showing {entriesStart} to {entriesEnd} of {rows.length} entries
                        </SoftTypography>
                    </SoftBox>
                )}
                {pageOptions.length > 1 && (
                    <SoftPagination
                        variant={pagination.variant ? pagination.variant : "gradient"}
                        color={pagination.color ? pagination.color : "info"}
                    >
                        {canPreviousPage && (
                            <SoftPagination item onClick={() => previousPage()}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                            </SoftPagination>
                        )}
                        {renderPagination.length > 6 ? (
                            <SoftBox width="5rem" mx={1}>
                                <SoftInput
                                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                                    value={customizedPageOptions[pageIndex]}
                                    onChange={(handleInputPagination, handleInputPaginationValue)}
                                />
                            </SoftBox>
                        ) : (
                            renderPagination
                        )}
                        {canNextPage && (
                            <SoftPagination item onClick={() => nextPage()}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                            </SoftPagination>
                        )}
                    </SoftPagination>
                )}
            </SoftBox>
        </TableContainer>
    );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
    entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25], visible: true },
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: true,
    noEndBorder: false,
    isHierarchical: false
};

// Typechecking props for the DataTable
DataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
            visible: PropTypes.bool
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
    isHierarchical: PropTypes.bool,
    widget: PropTypes.element
};

export default DataTable;
