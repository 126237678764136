import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import ParticipantCell from "components/Elements/DataTable/components/Cells/ParticipantCell";
import MoneyCell from "components/Elements/DataTable/components/Cells/MoneyCell";
import PhoneCell from "components/Elements/DataTable/components/Cells/PhoneCell";
import ButtonCell from "components/Elements/DataTable/components/Cells/ButtonCell";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useDialog } from "components/Elements/Dialog/common";
import { AddPlayerTransactionDialog, AddPlayerInlineTransactionDialog } from "layouts/Cardroom/Session/components/Dialogs";

import DataTable from "components/Elements/DataTable";
import { PlayerSessionTransactions } from 'layouts/Cardroom/Participants/Player/components/PlayerTransactions';
import { PlayerEditorDialog } from 'layouts/Cardroom/Participants/Player/components/PlayerEditor';
import { useCardroomContext } from 'features';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';



function convertSessionPlayersToTable(players, table, cardroom, session, onTransactionUpdatedHandler) {
    var rows = [];

    if (players && players.length > 0) {
        for (var i = 0; i < players.length; i++) {
            let playerName = players[i].name;
            let playerPhone = players[i].phoneNumber;
            let playerHasPhoto = players[i].hasPhoto;
            let creditLimit = 0;

            if (players[i].properties) {
                playerName = players[i].properties.name ? players[i].properties.name : playerName;
                playerPhone = players[i].properties.phoneNumber ? players[i].properties.phoneNumber : playerPhone;
                playerHasPhoto = players[i].properties.hasPhoto ? players[i].properties.hasPhoto : playerHasPhoto;
                creditLimit = players[i].properties.creditLimit;

                if (players[i].properties.nickName) playerName += " (" + players[i].properties.nickName + ")";
            }

            let playerImage = playerHasPhoto ? apiConfig.images.endpoint + "/player/" + players[i].id + ".jpg" : playerName.substring(0, 1);

            let row = {
                id: players[i].id,
                name: [playerName, { image: playerImage }], // format name from name & nickname
                phone: playerPhone,
                hasImage: playerHasPhoto,
                buyinsTotal: players[i].totalBuyinAmount,
                buinsCount: players[i].buyinCount,
                balance: (-1) * players[i].borrowedAmount,
                accountBalance: players[i].account ? players[i].account.accountsReceivable - players[i].account.accountsPayable : 0,
                creditLimit: creditLimit,
                netResult: players[i].totalCashoutAmount - players[i].totalBuyinAmount,
                details: <PlayerSessionTransactions player={players[i]} cardroom={cardroom} session={session} disableTableChrome={true} onTransactionUpdated={onTransactionUpdatedHandler} />,
                //details: <SessionPlayers cardroom={cardroom} session={session}/>,
                player: players[i]
            };
            rows.push(row);
        }
    }

    if (rows.length > 0) {
        rows.sort(function (a, b) {
            let aName = a.name && a.name[0] ? a.name[0].toLowerCase() : a.name;
            let bName = b.name && b.name[0] ? b.name[0].toLowerCase() : b.name;

            if (aName < bName) { return -1; }
            if (aName > bName) { return 1; }

            return 0;
        });
    }

    table.rows = rows;

    return table;
}
function mergePlayerRecords(splayers, pplayers, accounts) {
    var map = [];
    var accountMap = [];
    var result = [];

    if (accounts) {
        for (var i = 0; i < accounts.length; i++) {
            accountMap[accounts[i].accountHolderId] = accounts[i];
        }
    }

    //it's possible for splayers to contain duped players that have different masks, i.e mask 4 (dealer) and mask 5 (dealer & player)
    //we should merge splayers into pplayers & if dupes are found, & mask

    if (pplayers) {
        for (var i = 0; i < pplayers.length; i++) {
            map[pplayers[i].id] = pplayers[i];
        }

        if (splayers) {
            const fmap = [];

            for (var i = 0; i < splayers.length; i++) {
                let pp = map[splayers[i].id];
                if (pp) {
                    pp = Object.assign(pp, splayers[i]);
                    pp.account = accountMap[pp.id];


                    if (!fmap[pp.id]) {
                        result.push(pp);
                        fmap[pp.id] = pp;
                    }
                    else {
                        fmap[pp.id].type |= pp.type;
                    }

                }
            }
        }

    }
    return result;
}
function GetSessionPlayersTableSchema(addHandler, participantClickHandler, session, canTransact) {
    const schema = {
        columns: [
            {
                Header: "name", accessor: "name", Cell: ({ value: [name, data], row }) => (
                    <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} onClick={participantClickHandler} checked={false} participant={row && row.original && row.original.player ? row.original.player : null} />
                ),
            },
            { Header: "phone", accessor: "phone", Cell: ({ value, row }) => row && row.original && row.original.player && row.original.player.isAnonymous ? <TextCell value="Anonymous" /> : <PhoneCell value={value} obfuscate={true} /> },
            { Header: "$ buy-ins", accessor: "buyinsTotal", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useColorScheme={false} /> },
            { Header: "# of buy-ins", accessor: "buinsCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
            { Header: "marker", accessor: "balance", Cell: ({ value }) => <MoneyCell value={value} prefix="$" /> }
        ],
        collapsibleDetailAccessor: "details",
        rows: []
    };

    if (session && session.status !== 4) {
        schema.columns.push({ Header: "accnt balance", accessor: "accountBalance", Cell: ({ value }) => <MoneyCell value={value} prefix="$" /> });
    }


    schema.columns.push({ Header: "credit limit", accessor: "creditLimit", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useColorScheme={false} /> });
    schema.columns.push({ Header: "net result", accessor: "netResult", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useColorScheme={true} /> });

    if (session && (session.status === 2 || session.status === 3) && canTransact) {
        schema.columns.push({ Header: "action", accessor: "id", Cell: ({ value, row }) => <ButtonCell id={row && row.original ? row.original : value} onButtonClicked={addHandler} icon="add_box" color="secondary" /> });
    }

    return schema;
}

function GetEmptySchema() {
    return {
        columns: [],
        rows: []
    }
}

const SessionPlayers = ({ open, close, cardroom, session, onPlayerStateChanged, onPlayersLoaded }) => {

    const handleAddPlayerTransactionClick = (item) => {
        setCurrentPlayer(item.player);
        openAddPlayerTxDialog();
    };

    const handleParticipantClick = (player) => {
        if (player) {
            setCurrentPlayer(player);
            openPlayerEditorDialog();
        }
    };

    const [currentPlayer, setCurrentPlayer] = useState(null);

    const [playerTable, setPlayerTable] = useState(GetEmptySchema());
    const [players, setPlayers] = useState(null);

    const [openAddPlayerTxDialog, addPlayerDialogTxProps] = useDialog();
    const [openPlayerEditorDialog, openPlayerEditorDialogProps] = useDialog();

    const [sessionPlayers, setSessionPlayers] = useState(null);
    const [playerAccounts, setPlayerAccounts] = useState(null);

    const [playerLoadStage, setPlayerLoadStage] = useState(0);

    const [retryCounter, setRetryCounter] = useState(0);
    const [retryCounter2, setRetryCounter2] = useState(0);

    function handleProgressRetry() {
        if (playerLoadStage == 2) {
            setRetryCounter2(retryCounter + 1);
        }
        else {
            setRetryCounter(retryCounter + 1);
        }
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading session players...", handleProgressRetry)

    const refreshSessionPlayerData = () => {
        //trigger reload of player table...

        setSessionPlayers(null);
        setPlayers(null);

        if (onPlayerStateChanged) {
            onPlayerStateChanged();
        }
    };

    const [context, actions, features] = useCardroomContext(cardroom);

    const casinoReq = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read
    });

    const peopleReq = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read
    });

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    function canCreateTransaction() {
        if (!context) return false;
        return context.isAllowed(actions.casino.session.transaction.create);
    }

    //load player sessions
    useEffect(() => {
        if (!sessionPlayers) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session players...")
            showProgress();
            setPlayerLoadStage(1);
            //{casinoId:Guid}/sessions/{sessionId:Guid}/participants/{type:int?}
            casinoReq.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/participants/1").then((response) => {
                if (response) {
                    if (response.length === 0) {
                        //setLoading(false);
                        progressIndicatorProps.close();
                    }

                    setSessionPlayers(response);
                }
            }).catch((ex) => {
                //setOverlayMessage("An error occured, please refresh");
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load session players, plese try again...");
            });
        }
    }, [casinoReq.execute, sessionPlayers, retryCounter])


    useEffect(() => {
        if (!players && sessionPlayers && sessionPlayers.length > 0) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session players' details...")
            setPlayerLoadStage(2);

            let list = [];
            for (var i = 0; i < sessionPlayers.length; i++) {
                list.push(sessionPlayers[i].id);
            }

            if (list.length > 0) {
                peopleReq.execute("PUT", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", list).then((response) => {
                    if (response) {
                        setPlayers(response);
                        setPlayerAccounts(null);
                    }
                }).catch((ex) => {
                    //setOverlayMessage("An error occured, please refresh");
                    progressIndicatorProps.setMode("errorWithRetry");
                    progressIndicatorProps.setMessage("Unable to load session players' details, plese try again...");
                });
            }
        }
    }, [peopleReq.execute, sessionPlayers, players, retryCounter2])

    //load player accounts
    useEffect(() => {
        if (players && !playerAccounts) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading player accounts...")

            setPlayerLoadStage(3);

            // [HttpGet("{entityId:Guid}/holders/accounts/{type:int}", Name = "GetEntityAccounts")]
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/accounts/1").then((response) => {
                if (response) {
                    setPlayerAccounts(response);

                    let merged = mergePlayerRecords(sessionPlayers, players, response);

                    if (onPlayersLoaded) {
                        onPlayersLoaded(merged);
                    }

                    setPlayerTable(convertSessionPlayersToTable(merged, GetSessionPlayersTableSchema(handleAddPlayerTransactionClick, handleParticipantClick, session, canCreateTransaction()), cardroom, session, refreshSessionPlayerData));

                    progressIndicatorProps.close();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load player accounts, please try again...")
            });
        }
    }, [accountRequest.execute, playerAccounts, players, retryCounter])



    return (<>
        <ProgressIndicator {...progressIndicatorProps} />
        {/* AddPlayerInlineTransactionDialog */}
        <AddPlayerTransactionDialog {...addPlayerDialogTxProps} cardroom={cardroom} session={session} player={currentPlayer} onAdded={refreshSessionPlayerData} />
        <PlayerEditorDialog {...openPlayerEditorDialogProps} cardroom={cardroom} player={currentPlayer} />
        {!progressIndicatorProps.visible ? <DataTable table={playerTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} isHierarchical={true} /> : null}
    </>);
}


export default SessionPlayers;