/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

function DataTableBodyCell({ noBorder, align, children }) {
    const { light } = colors;
    const { size } = typography;
    const { borderWidth } = borders;

    return (
        <SoftBox
            component="td"
            textAlign={align}
            fontSize={size.sm}
            borderBottom={noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`}
            py={1.5}
            px={3}
        >
            <SoftBox
                display="inline-block"
                width="max-content"
                color="text" width="100%"
                sx={{ verticalAlign: "middle" }}
            >
                {children}
            </SoftBox>
        </SoftBox>
    );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
    noBorder: false,
    align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
    children: PropTypes.node.isRequired,
    noBorder: PropTypes.bool,
    align: PropTypes.oneOf(["left", "right", "center"]),
    childTable: PropTypes.objectOf(PropTypes.array)
};

export default DataTableBodyCell;
