import form from "./form";


const {
    formField: {
        unionId,
        defaultClubFeeScheduleId,
        tokenUnitCost,
        unionManagerFee,
        primaryClubId
    },
} = form;

const initialValues = {
    [unionId.name]: "",
    [defaultClubFeeScheduleId.name]: "",
    [tokenUnitCost.name]: 0,
    [unionManagerFee.name]: 0,
    [primaryClubId.name]: ""
};

export default initialValues;
