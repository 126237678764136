import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { apiConfig } from 'config/apiConfig'
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import DataTable from "components/Elements/DataTable";


import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import DateCell from "components/Elements/DataTable/components/Cells/DateCell";
import ButtonCell from "components/Elements/DataTable/components/Cells/ButtonCell";
import TextCell from "components/Elements/DataTable/components/Cells/TextCell";
import IconCell from "components/Elements/DataTable/components/Cells/IconCell";

import Grid from "@mui/material/Grid";
import SoftBox from 'components/SoftBox';
import { Card, Tooltip } from '@mui/material';


const ReportingPeriods = ({ cardroom, club, union, agency, audience }) => {

    function prepareReportsForPresentation(reports, table) {
        let rows = [];

        function mapStatus(s) {
            switch (s) {
                case 0:
                    return "init";
                case 1:
                    return "running";
                case 2:
                    return "merging";
                case 3:
                    return "completed";
                case 4:
                    return "failed";
                case 5:
                    return "403";
                case 6:
                    return "nofunds";
            }
            return "unknown";
        }
        function formatOwnerDisplayName(owner) {
            if (!owner || owner.entityType == 0) return "Unknown";

            if (owner.entityType == 1) {
                return owner.name + " (" + owner.appEntityId + ")";

            }

            if (owner.entityType == 2) {
                return owner.name + " (" + owner.appEntityId + ")";
            }

            return "Unknown";

        }

        for (var i = 0; i < reports.length; i++) {

            rows.push({
                id: reports[i].id,
                startDate: reports[i].startTime,
                endDate: reports[i].endTime,
                status: mapStatus(reports[i].status),
                ingestedOn: reports[i].ingestedOnUtc,
                sourceFile: reports[i].sourceFileUri.split("/").pop(), //extract file name
                sourceAppName: reports[i].app ? reports[i].app.name : "None",
                ownerDisplayName: formatOwnerDisplayName(reports[i].owner),
                cost: reports[i].cost,
                report: reports[i]
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {

                let aDate = Date.parse(a.startDate);
                let bDate = Date.parse(b.startDate);

                if (aDate > bDate) { return -1; }
                if (aDate < bDate) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetEmptySchema(openReportHandler, retryProcessingHandler, authorizeUserHandler, addBudgetHandler, downloadHandler) {
        return {
            columns: [
                { Header: "Start Time", accessor: "startDate", Cell: ({ value }) => <DateCell value={value} format="dateAndTime" display="locale" /> },
                { Header: "End Time", accessor: "endDate", Cell: ({ value }) => <DateCell value={value} format="dateAndTime" display="locale" /> },
                { Header: "Source", accessor: "sourceAppName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Produced For", accessor: "ownerDisplayName", Cell: ({ value }) => <TextCell value={value} /> },
                {
                    Header: "Status", accessor: "status", Cell: ({ value }) => {
                        let icon = "", color = "text";
                        let valueText = value;
                        let description = "";

                        switch (value) {
                            case "init":
                                icon = "schedule";
                                color = "info";
                                valueText = "pending"
                                description = "Report generation has been scheduled and will begin shortly, please check back in a bit."
                                break;
                            case "running":
                            case "merging":
                                icon = "sync";
                                color = "success";
                                valueText = "in progress"
                                description = "Report generation is in progress, please check back in a bit."
                                break;
                            case "completed":
                                icon = "done";
                                color = "success";
                                description = "Report generation conmpleted."
                                break;
                            case "failed":
                                icon = "sync_problem";
                                color = "error";
                                description = "Report generation failed, try running it again, there's no charge for this."
                                break;
                            case "unknown":
                                icon = "help_outline";
                                color = "warning";
                                description = "We've encountered an unknown status. Our team will investigate."
                                break;
                            case "403":
                                icon = "gpp_bad"
                                color = "error";
                                valueText = "unauthorized"
                                description = "The user that requested this report does is not authorized to do so."
                                break;
                            case "nofunds":
                                icon = "currency_exchange";
                                color = "error";
                                valueText = "insufficent";
                                description = "Your account does not have enough tokens to process this request."
                                break;
                        }
                        return <Tooltip title={description}><span><IconCell value={valueText} icon={icon} color={color} /></span></Tooltip>;
                    }
                },
                { Header: "Processed On", accessor: "ingestedOn", Cell: ({ value }) => <DateCell value={value + "Z"} format="dateAndTime" display="locale" /> },
                {
                    Header: "actions", accessor: "report", Cell: ({ value, row }) => {

                        function getDownloadIndicatorStatus(r) {
                            if (!r) return "error";
                            if (!r.downloadStatus) return "secondary";

                            return r.downloadStatus;
                        }

                        if (row && row.original) {
                            switch (row.original.status) {
                                case "completed":
                                    return <SoftBox ><Tooltip title="view report"><span style={{ marginRight: "25px" }}><ButtonCell id={value} onButtonClicked={openReportHandler} icon="launch" color="secondary" /></span></Tooltip>{audience === "agency" ? null : <Tooltip title="download report"><span><ButtonCell pl={8} id={value} onButtonClicked={downloadHandler} icon="cloud_download" color={getDownloadIndicatorStatus(value)} /></span></Tooltip>}</SoftBox>;
                                case "failed":
                                    return <Tooltip title="retry"><span><ButtonCell id={value} onButtonClicked={retryProcessingHandler} icon="replay" color="success" /></span></Tooltip>;
                                case "403":
                                    return <Tooltip title="authorize user"><span><ButtonCell id={value} onButtonClicked={authorizeUserHandler} icon="gpp_good" color="success" /></span></Tooltip>;
                                case "nofunds":
                                    return <Tooltip title="add tokens"><span><ButtonCell id={value} onButtonClicked={addBudgetHandler} icon="shopping_cart" color="secondary" /></span></Tooltip>;
                                case "init":
                                    break;
                                case "unknown":
                                case "running":
                                case "merging":
                                    break;
                            }
                        }
                        return null;
                    }
                },
            ],
            rows: []
        };
    }

    const navigate = useNavigate();

    const [reportsTable, setReportsTable] = useState(null);
    const [reportPeriods, setReportsPeriods] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function openReport(report) {
        //  /dashboards/[club|union]/report"
        if (report) {
            let path = "/dashboards/" + audience + "/report";
            navigate(path, { state: { cardroom: cardroom, union: union, club: club, agency: agency, report: report } });
        }
    }

    const [downloadRequest, setDownloadRequest] = useState(null);

    function downloadReport(report) {
        if (!report) return;

        function buildFileName() {
            let name = "";
            if (report.app) {
                name = report.app.name + "-";
            }

            new Date(Date.parse(report.startTime)).toLocaleDateString()
            name += new Date(Date.parse(report.startTime)).toLocaleDateString() + " - " + new Date(Date.parse(report.endTime)).toLocaleDateString();

            if (club) {
                name = club.name + "-" + name;
            }

            if (union) {
                name = union.name + "-" + name;
            }

            if (report.owner) {
                name = report.owner.name + "-" + name;
            }

            return name + ".xlsx";
        }

        let clubId = club ? club.id : null;
        if (report.owner && report.owner.id == clubId) {
            clubId = null;
        }
        setDownloadRequest({
            cardroomId: cardroom.id,
            reportId: report.id,
            clubId: clubId,
            report: report,
            fileName: buildFileName()
        });
    }

    function retryProcessing(report) { }
    function authorizeUser(report) { }
    function addBudget(report) { }

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading recent reports...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    //load casino sessions
    useEffect(() => {
        if (!reportPeriods) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading recent reports...")
            showProgress();

            // [HttpGet("casino/{casinoId:Guid}/reports?rows=123", Name = "GetReportingPeriods")]
            execute("GET", apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports?rows=52").then((response) => {
                if (response) {
                    setReportsPeriods(response);
                    setReportsTable(null);
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load recent reports, please try again...")
            });
        }
    }, [execute, reportPeriods, retryCounter])

    useEffect(() => {

        if (reportPeriods && !reportsTable) {
            //map table
            setReportsTable(prepareReportsForPresentation(reportPeriods, GetEmptySchema(openReport, retryProcessing, authorizeUser, addBudget, downloadReport)));
            progressIndicatorProps.close();
        }
    }, [reportPeriods, reportsTable]);


    useEffect(() => {
        if (downloadRequest) {

            downloadRequest.report.downloadStatus = "info";

            // [HttpGet("casino/{casinoId:Guid}/reports/{reportingPeriodId:Guid}/download/excel", Name = "GetReportingPeriodFile")]
            let url = apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + downloadRequest.reportId + "/download/excel";
            if (downloadRequest.clubId) url += "?clubId=" + downloadRequest.clubId;

            execute("GET", url, null, true).then((response) => {

                if (response) {
                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }

                    if (response.blob) {

                        const fileURL = window.URL.createObjectURL(response.blob);
                        // Setting various property values
                        let alink = document.createElement('a');
                        alink.href = fileURL;

                        let fileName = downloadRequest.fileName;

                        if (!fileName) {
                            fileName = response.headers["content-disposition"];
                            if (fileName) {
                                fileName = fileName.split('"')[1];
                            }
                        }

                        if (!fileName) {
                            fileName = "report-extract.xlsx";
                        }

                        alink.download = fileName;

                        alink.click();

                        downloadRequest.report.downloadStatus = "success";
                    } else {
                        downloadRequest.report.downloadStatus = "warning";
                    }
                }
                setDownloadRequest(null);

            }).catch((ex) => {
                downloadRequest.report.downloadStatus = "error";
                setDownloadRequest(null);
            });
        }

    }, [execute, downloadRequest])


    return <Card>
        <SoftBox>
            <Grid container>
                <Grid item m={1} xs={12} mr="auto" ml="auto">
                    <ProgressIndicator {...progressIndicatorProps} />
                    {!progressIndicatorProps.visible && reportsTable ? <DataTable table={reportsTable} entriesPerPage={{ defaultValue: 10, visible: false }} pagination={{ color: "dark" }} /> : null}
                </Grid>
            </Grid>
        </SoftBox>
    </Card>;
};


export default ReportingPeriods;