import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';

import { ErrorMessage } from "formik";
import FormField from "components/Elements/Forms/FormField";
import FormSwitch from "components/Elements/Forms/FormSwitch";
import { NumericFormat } from "react-number-format";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import SignatureCollectionForm from '../../Signature';

import chipsAvatar from "assets/graphics/casino/chips_256.png";
import FormImageField from 'components/Elements/Forms/FormImageField';
import { useCardroomContext } from 'features';



const BuyInTransactionForm = ({ cardroom, player, session, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, amount, method, verified, markerBalance, accountBalance, availableCredit, note, provider, providerOther, providerRefMethod, providerRef, providerRefImage, participantSignatureImage, participantSignatureRequired, requestSignature } = formField;
    const {
        type: typeV,
        amount: amountV,
        method: methodV,
        verified: verifiedV,
        markerBalance: markerBalanceV,
        availableCredit: availableCreditV,
        accountBalance: accountBalanceV,
        provider: providerV,
        providerOther: providerOtherV,
        providerRefMethod: providerRefMethodV,
        providerRef: providerRefV,
        providerRefImage: providerRefImageV,
        participantSignatureImage: participantSignatureImageV,
        participantSignatureRequired: participantSignatureRequiredV,
        requestSignature: requestSignatureV,
        note: noteV
    } = values;



    function onPaymentMethodRadioGroupChanged(e, newValue) {
        setFieldValue(method.name, newValue);
    }

    function onEWalletProviderChanged(e, newValue) {
        setFieldValue(provider.name, newValue);
    }

    function onSignatureCollected(sigImg) {
        setFieldValue(participantSignatureImage.name, sigImg);
    }

    function onVendorRefMethodRadioGroupChanged(e, newValue) {
        setFieldValue(providerRefMethod.name, newValue);
    }

    function onSignatureCleared() {

        //TODO: figure out why clearing signature doesn't cause validation to kick in until second time
        setFieldValue(participantSignatureImage.name, "");
        //if (participantSignatureRequiredV) {
        //    setFieldError(participantSignatureImage.name, participantSignatureImage.errorMsg);
        //}
    }

    const [formDataInitialized, setFormDataInitialized] = useState(false);
    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(method.name, "cash");
            //let v = values;
            //let b = markerBalanceV;
            setFieldValue(participantSignatureRequired.name, player && player.properties && player.properties.mustSign);

            let balance = player && player.borrowedAmount ? player.borrowedAmount : 0; //TODO: get from player object or query api

            setFieldValue(markerBalance.name, balance);

            //player && player.properties ? (player.properties.creditLimit - (isNaN(parseFloat(markerBalanceV)) ? 0 : parseFloat(markerBalanceV))) : 0
            let credit = player && player.properties ? player.properties.creditLimit : 0;
            let accntBalance = player && player.account ? player.account.accountsReceivable - player.account.accountsPayable : 0;

            setFieldValue(accountBalance.name, accntBalance);

            credit -= (balance - accntBalance);
            //credit -= balance; 

            setFieldValue(availableCredit.name, credit);

            setFieldValue(type.name, 1);

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    const [context, actions, features] = useCardroomContext(cardroom);

    function canOfflineSignatureBeRequested() {
        if (!player) return false;
        if (!player.properties) return false;

        if (context) {
            if (false === context.isEnabled(features.casino.smsNotifications)) {
                return false;
            }

            if (false === context.isEnabled(features.casino.offlineSignatureCollection)) {
                return false;
            }
        }

        return ((player.properties.notificationMask & 64) > 0);
    }

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={chipsAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {method.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row={false} defaultValue={methodV} onChange={onPaymentMethodRadioGroupChanged} style={{ marginLeft: "1.25em" }}>
                                        <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                                        <FormControlLabel value="electronic" control={<Radio />} label="e-Wallet" />
                                        {parseFloat(availableCreditV) > 0 ? <FormControlLabel value="credit" control={<Radio />} label="Credit" /> : null}
                                    </RadioGroup>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={method.name} />
                                    </SoftTypography>

                                </SoftTypography>

                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {amount.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                    type={amount.type}
                                    name={amount.name}
                                    value={amountV}
                                    placeholder={amount.placeholder}
                                    error={errors.amount && touched.amount}
                                    success={amountV && amountV.toString().length > 0 && !errors.amount}
                                /></Grid>
                        </Grid>

                        {methodV === "credit" ? <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Available credit
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <NumericFormat value={availableCreditV} displayType="text" thousandSeparator={true} prefix="$" />
                                </SoftTypography>
                            </Grid>
                        </Grid> : null}


                        {methodV === "credit" && participantSignatureRequiredV ? <>
                            {canOfflineSignatureBeRequested() ?
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                {requestSignature.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormSwitch mr="auto"
                                            name={requestSignature.name}
                                            value={requestSignatureV}
                                            {...formData} />
                                    </Grid>
                                </Grid> : null}
                            {!requestSignatureV || !canOfflineSignatureBeRequested() ?
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                {participantSignatureImage.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SignatureCollectionForm size="sm" onSignatureCollected={onSignatureCollected} onSignatureCleared={onSignatureCleared} />

                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            color="error">
                                            <ErrorMessage name={participantSignatureImage.name} />
                                        </SoftTypography>

                                    </Grid>
                                </Grid> : null}
                        </> : null}

                        {methodV === "electronic" ? <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {provider.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row defaultValue={providerV} onChange={onEWalletProviderChanged}>
                                        <FormControlLabel value="zelle" control={<Radio />} label="Zelle" />
                                        <FormControlLabel value="paypal" control={<Radio />} label="PayPal" />
                                        <FormControlLabel value="venmo" control={<Radio />} label="Venmo" />
                                        <FormControlLabel value="cashapp" control={<Radio />} label="CashApp" />
                                        <FormControlLabel value="apple" control={<Radio />} label="ApplePay" />
                                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                                    </RadioGroup>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={provider.name} />
                                    </SoftTypography>
                                </SoftTypography>
                            </Grid>
                        </Grid> : null}

                        {methodV === "electronic" && providerV === "other" ? <Grid container xs={12}>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {providerOther.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField type={providerOther.type} onFocus={e => e.currentTarget.select()}
                                        name={providerOther.name}
                                        value={providerOtherV}
                                        placeholder={providerOther.placeholder}
                                        error={errors.providerOther && touched.providerOther}
                                        success={providerOtherV && providerOtherV.length > 0 && !errors.providerOther}
                                    />
                                </Grid>
                            </Grid>
                        </Grid> : null}


                        {methodV !== "credit" ?
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {verified.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>

                                    <FormSwitch mr="auto"
                                        name={verified.name}
                                        value={verifiedV}
                                        {...formData} />

                                </Grid>
                            </Grid> : null}

                        {methodV === "electronic" && verifiedV ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {providerRefMethod.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography
                                        variant="caption"
                                        textTransform="capitalize">
                                        <RadioGroup row={true} value={providerRefMethodV} onChange={onVendorRefMethodRadioGroupChanged} style={{ marginLeft: "0.75em" }}>
                                            <FormControlLabel value="number" control={<Radio />} label="Number" />
                                            <FormControlLabel value="image" control={<Radio />} label="Image" />
                                        </RadioGroup>
                                    </SoftTypography>
                                </Grid>
                            </Grid>

                            {providerRefMethodV === "number" ?
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {providerRef.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormField type={providerRef.type} onFocus={e => e.currentTarget.select()}
                                            name={providerRef.name}
                                            value={providerRefV}
                                            placeholder={providerRef.placeholder}
                                            error={errors.providerRef && touched.providerRef}
                                            success={providerRefV && providerRefV.toString().length > 0 && !errors.providerRef}
                                        />

                                    </Grid>
                                </Grid> : null}

                            {providerRefMethodV === "image" ?
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                {providerRefImage.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormImageField name={providerRefImage.name} imgWidth="120px" {...formData} />
                                    </Grid>
                                </Grid> : null}
                        </> : null}

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {note.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()}
                                    type={note.type}
                                    name={note.name}
                                    value={noteV}
                                    placeholder={note.placeholder}
                                    error={errors.note && touched.note}
                                    success={noteV && noteV.length > 0 && !errors.note}
                                />
                            </Grid>
                        </Grid>

                        {/* Hidden Fields */}

                        <FormField type={participantSignatureImage.type}
                            name={participantSignatureImage.name}
                            value={participantSignatureImageV} />

                        <FormField type={providerRefImage.type}
                            name={providerRefImage.name}
                            value={providerRefImageV} />

                        <FormField type={markerBalance.type}
                            name={markerBalance.name}
                            value={markerBalanceV} />

                        <FormField type={availableCredit.type}
                            name={availableCredit.name}
                            value={availableCreditV} />

                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;

};



export default BuyInTransactionForm;