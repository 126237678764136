/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useLocation } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
//import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import PageLayout from "components/Elements/PageLayout";
import Navbar from "components/Elements/Navbar";
import Footer from "components/Elements/Footer";

import { useCardroomContext } from "features";
import ReportDetails from "./components/ReportDetails";
import { useState, useEffect } from "react";


function ReportPage({ audience }) {

    const location = useLocation();

    const [securityContext, actions, features] = useCardroomContext(location.state.cardroom);


    const [cardroom, setCardroom] = useState(location.state.cardroom);
    const [club, setClub] = useState(location.state.club);
    const [union, setUnion] = useState(location.state.union);
    const [agency, setAgency] = useState(location.state.agency);
    const [report, setReport] = useState(location.state.report);

    useEffect(() => {
        setCardroom(location.state.cardroom);
        setUnion(location.state.union);
        setClub(location.state.club);
        setAgency(location.state.agency);
        setReport(location.state.report);
    }, [location.state]);

    useEffect(() => {
        setReport(location.state.report);
    }, [location.state.report]);

    useEffect(() => {
        setCardroom(location.state.cardroom);
    }, [location.state.cardroom]);

    useEffect(() => {
        setClub(location.state.club);
    }, [location.state.club]);

    useEffect(() => {
        setUnion(location.state.union);
    }, [location.state.union]);

    useEffect(() => {
        setAgency(location.state.agency);
    }, [location.state.agency]);
    return (
        <PageLayout>
            <Navbar />
            <SoftBox pt="3px">
                <SoftBox mb={3} pt={0}>
                    <ReportDetails cardroom={cardroom} club={club} audience={audience} union={union} agency={agency} reportingPeriod={report} />
                </SoftBox>
            </SoftBox>
            <Footer />
        </PageLayout>
    );
}

export default ReportPage;
