import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Routes, Route, Navigate, Link, useLocation, useNavigate } from "react-router-dom";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DataTable from "components/Elements/DataTable";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import ParticipantCell from "components/Elements/DataTable/components/Cells/ParticipantCell";
import PhoneCell from "components/Elements/DataTable/components/Cells/PhoneCell";
import MoneyCell from "components/Elements/DataTable/components/Cells/MoneyCell";
import DateCell from "components/Elements/DataTable/components/Cells/DateCell";
import ButtonCell from "components/Elements/DataTable/components/Cells/ButtonCell";
import TextCell from "components/Elements/DataTable/components/Cells/TextCell";
import IconCell from "components/Elements/DataTable/components/Cells/IconCell";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useDialog } from 'components/Elements/Dialog/common';
import { StaffEditorDialog } from 'layouts/Cardroom/Participants/Staff/components/StaffEditor';

function convertStaffToTable(staff, accounts, partners, table) {

    const accountMap = [];
    if (accounts) {
        for (var i = 0; i < accounts.length; i++) {
            accountMap[accounts[i].accountHolderId] = accounts[i];
        }
    }


    const partnerMap = [];
    if (partners) {
        for (var i = 0; i < partners.length; i++) {
            partnerMap[partners[i].id] = partners[i];
        }
    }

    var rows = [];

    if (staff && staff.length > 0) {
        for (var i = 0; i < staff.length; i++) {

            let staffName = staff[i].name;
            let staffPhone = staff[i].phoneNumber;
            let staffHasPhoto = staff[i].hasPhoto || (staff[i].properties && staff[i].properties.hasPhoto)
            let ptype = 0;
            let partner = null;

            if (staff[i].properties) {
                ptype = staff[i].properties.participantType;
                staffName = staff[i].properties.name ? staff[i].properties.name : staffName;
                staffPhone = staff[i].properties.phoneNumber ? staff[i].properties.phoneNumber : staffPhone;
                staffHasPhoto = staff[i].properties.hasPhoto ? staff[i].properties.hasPhoto : staffHasPhoto;

                if (staff[i].properties.nickName) staffName += " (" + staff[i].properties.nickName + ")";

                if (staff[i].properties.responsiblePartnerId && partnerMap[staff[i].properties.responsiblePartnerId])
                    partner = partnerMap[staff[i].properties.responsiblePartnerId];
            }

            let staffImage = staffHasPhoto ? apiConfig.images.endpoint + "/player/" + staff[i].id + ".jpg" : staffName.substring(0, 1);

            var ptypeStr = ptype < 2 ? "" : "|";
            if ((ptype & 2) > 0) ptypeStr += "host|";
            if ((ptype & 4) > 0) ptypeStr += "dealer|";
            if ((ptype & 8) > 0) ptypeStr += "chef|";
            if ((ptype & 16) > 0) ptypeStr += "valet|";
            if ((ptype & 32) > 0) ptypeStr += "security|";
            if ((ptype & 64) > 0) ptypeStr += "other|";

            var account = accountMap[staff[i].id];

            var row = {
                id: staff[i].id,
                name: [staffName, { image: staffImage }],
                partner: [partner ? partner.displayName : "N/A", { user: partner }],
                roles: ptypeStr,
                phoneNumber: staffPhone,
                balance: account ? account.accountsPayable - account.accountsReceivable : 0,
                gamesWorked: 0,
                staff: staff[i]
            };

            rows.push(row);
        }
    }

    if (rows.length > 0) {
        rows.sort(function (a, b) {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        });
    }
    table.rows = rows;

    return table;
}

function GetStaffTableSchema(playerDetailButtonClickHandler, staffClickHandler) {

    return {
        columns: [
            {
                Header: "staff",
                accessor: "name",
                Cell: ({ value: [name, data], row }) => (
                    <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} onClick={staffClickHandler} participant={row && row.original && row.original.staff ? row.original.staff : null} />
                ),
            },
            { Header: "phone", accessor: "phoneNumber", Cell: ({ value, row }) => row && row.original && row.original.staff && row.original.staff.isAnonymous ? <TextCell value="Anonymous" /> : <PhoneCell value={value} obfuscate={true} /> },
            { Header: "balance", accessor: "balance", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useFormatter={true} useColorScheme={true} /> },
            { Header: "roles", accessor: "roles", Cell: ({ value }) => <TextCell value={value} /> },
            { Header: "partner", accessor: "partner", Cell: ({ value: [name, data] }) => <TextCell value={name} /> },
            { Header: "details", accessor: "id", Cell: ({ value }) => <ButtonCell id={value} onButtonClicked={playerDetailButtonClickHandler} icon="launch" color="secondary" /> },
        ],

        rows: [],
    };
}

function GetEmptySchema() {
    return {
        columns: [],
        rows: []
    }
}

const CasinoStaffCard = ({ cardroom }) => {

    const navigate = useNavigate();

    function onStaffDetailsButtonClicked(staffId) {
        if (staffId) {
            navigate("/dashboards/cardroom/staff", { state: { id: staffId, cardroom: cardroom } });
        }
    }

    const handleStaffClick = (staff) => {
        if (staff) {
            setCurrentStaff(staff);
            openStaffEditorDialog();
        }
    };

    const [currentStaff, setCurrentStaff] = useState(null);
    const [openStaffEditorDialog, openStaffEditorDialogProps] = useDialog();

    const [staffTable, setStaffTable] = useState(GetEmptySchema());
    const [staff, setStaff] = useState(null);
    const [accounts, setAccounts] = useState(null);
    const [partners, setPartners] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading staff data...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const partnerRequest = useFetchWithMsal({
        scopes: apiConfig.user.scopes.read,
    });


    //load casino staff
    useEffect(() => {
        if (!staff) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading staff data...")
            showProgress();

            execute("GET", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants/254").then((response) => {
                if (response) {
                    setStaff(response);
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load staff data, please try again...")
            });
        }
    }, [execute, staff, retryCounter]) //cardroomListData 

    //load staff accounts
    useEffect(() => {
        if (staff && !accounts) {

            if (cardroom.accounts) {
                setAccounts(cardroom.accounts);
                progressIndicatorProps.close();
                return;
            }

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading staff accounts...")
            showProgress();

            // [HttpGet("{entityId:Guid}/holders/accounts/{type:int}", Name = "GetEntityAccounts")]
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/accounts/1").then((response) => {
                if (response) {
                    cardroom.accounts = response;
                    setAccounts(response);
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load staff accounts, please try again...")
            });
        }
    }, [accountRequest.execute, accounts, staff, retryCounter])


    //load partners
    useEffect(() => {
        if (staff && accounts && !partners) {

            if (cardroom.partners) {
                setPartners(cardroom.partners);
                progressIndicatorProps.close();
                return;
            }

            const idList = [];
            const idMap = [];

            for (var i = 0; i < staff.length; i++) {
                if (!staff[i] || !staff[i].properties) continue;
                var partnerId = staff[i].properties.responsiblePartnerId;
                if (partnerId && !idMap[partnerId]) {
                    idMap[partnerId] = true;
                    idList.push(partnerId);
                }
            }

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading partner data...")

            partnerRequest.execute("PUT", apiConfig.user.endpoint + "/", idList).then((response) => {
                if (response) {
                    cardroom.partners = response;
                    setPartners(response);
                }

                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load partner data, please try again...")
            });
        }
    }, [partnerRequest.execute, staff, accounts, partners, retryCounter]);

    useEffect(() => {
        if (accounts && staff && partners) {
            setStaffTable(convertStaffToTable(staff, accounts, partners, GetStaffTableSchema(onStaffDetailsButtonClicked, handleStaffClick)));
        }
    }, [accounts, staff, partners])

    return <Card>
        <Grid container>
            <Grid item m={1} xs={12} mr="auto" ml="auto">
                <ProgressIndicator {...progressIndicatorProps} />
                <StaffEditorDialog {...openStaffEditorDialogProps} cardroom={cardroom} staff={currentStaff} />
                {!progressIndicatorProps.visible ?
                    <DataTable table={staffTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} pagination={{ color: "dark" }} /> : null}
            </Grid>
        </Grid>
    </Card>;
};




export default CasinoStaffCard;