import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Formik, Form, ErrorMessage } from "formik";
import FormField from "components/Elements/Forms/FormField";

import defaultTxAvatar from "assets/graphics/casino/cash_256.png";
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import FormImageField from 'components/Elements/Forms/FormImageField';



const PlayerPayoutForm = ({ cardroom, session, player, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, amount, method, verified, provider, providerOther, providerRefMethod, providerRef, providerRefImage, markerBalance, note } = formField;
    const {
        type: typeV,
        amount: amountV,
        method: methodV,
        provider: providerV,
        providerOther: providerOtherV,
        markerBalance: markerBalanceV,
        providerRefMethod: providerRefMethodV,
        providerRef: providerRefV,
        providerRefImage: providerRefImageV,
        note: noteV
    } = values;


    function onPaymentMethodRadioGroupChanged(e, newValue) {
        setFieldValue(method.name, newValue);
    }

    function onEWalletProviderChanged(e, newValue) {
        setFieldValue(provider.name, newValue);
    }

    function onVendorRefMethodRadioGroupChanged(e, newValue) {
        setFieldValue(providerRefMethod.name, newValue);
    }

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(type.name, 8);
            setFieldValue(verified.name, true);

            //set markerBalance
            let balance = player && player.borrowedAmount ? player.borrowedAmount : 0;
            setFieldValue(markerBalance.name, balance);

            //validateForm();
            //values.verified

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>

                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={defaultTxAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>

                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {amount.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                    type={amount.type}
                                    name={amount.name}
                                    value={amountV}
                                    placeholder={amount.placeholder}
                                    error={errors.amount && touched.amount}
                                    success={amountV && amountV.toString().length > 0 && !errors.amount}
                                /></Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Available balance
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <MoneyCell value={parseFloat(markerBalanceV) * (-1)} prefix="$" useColorScheme={true} />
                                </SoftTypography>
                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {method.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row={false} value={methodV} onChange={onPaymentMethodRadioGroupChanged}>
                                        <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                                        <FormControlLabel value="electronic" control={<Radio />} label="e-Wallet" />
                                    </RadioGroup>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={method.name} />
                                    </SoftTypography>
                                </SoftTypography>

                            </Grid>
                        </Grid>

                        {methodV === "electronic" ? <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {provider.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row value={providerV} onChange={onEWalletProviderChanged}>
                                        <FormControlLabel value="zelle" control={<Radio />} label="Zelle" />
                                        <FormControlLabel value="paypal" control={<Radio />} label="PayPal" />
                                        <FormControlLabel value="venmo" control={<Radio />} label="Venmo" />
                                        <FormControlLabel value="cashapp" control={<Radio />} label="CashApp" />
                                        <FormControlLabel value="apple" control={<Radio />} label="ApplePay" />
                                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                                    </RadioGroup>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={provider.name} />
                                    </SoftTypography>
                                </SoftTypography>
                            </Grid>
                        </Grid> : null}

                        {methodV === "electronic" && providerV === "other" ? <Grid container xs={12}>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {providerOther.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField type={providerOther.type} onFocus={e => e.currentTarget.select()}
                                        name={providerOther.name}
                                        value={providerOtherV}
                                        placeholder={providerOther.placeholder}
                                        error={errors.providerOther && touched.providerOther}
                                        success={providerOtherV && providerOtherV.length > 0 && !errors.providerOther}
                                    />
                                </Grid>
                            </Grid>
                        </Grid> : null}

                        {methodV === "electronic" ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {providerRefMethod.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography
                                        variant="caption"
                                        textTransform="capitalize">
                                        <RadioGroup row={true} value={providerRefMethodV} onChange={onVendorRefMethodRadioGroupChanged} style={{ marginLeft: "0.75em" }}>
                                            <FormControlLabel value="number" control={<Radio />} label="Number" />
                                            <FormControlLabel value="image" control={<Radio />} label="Image" />
                                        </RadioGroup>
                                    </SoftTypography>
                                </Grid>
                            </Grid>

                            {providerRefMethodV === "number" ?
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {providerRef.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormField type={providerRef.type} onFocus={e => e.currentTarget.select()}
                                            name={providerRef.name}
                                            value={providerRefV}
                                            placeholder={providerRef.placeholder}
                                            error={errors.providerRef && touched.providerRef}
                                            success={providerRefV && providerRefV.toString().length > 0 && !errors.providerRef}
                                        />

                                    </Grid>
                                </Grid> : null}

                            {providerRefMethodV === "image" ?
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                {providerRefImage.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormImageField name={providerRefImage.name} imgWidth="120px" {...formData} />
                                    </Grid>
                                </Grid> : null}
                        </> : null}

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {note.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()}
                                    type={note.type}
                                    name={note.name}
                                    value={noteV}
                                    placeholder={note.placeholder}
                                    error={errors.note && touched.note}
                                    success={noteV && noteV.length > 0 && !errors.note}
                                />
                            </Grid>
                        </Grid>



                        <FormField type={providerRefImage.type}
                            name={providerRefImage.name}
                            value={providerRefImageV} />
                    </SoftBox>
                </Grid>




            </Grid>
        </SoftBox>
    </SoftBox>

};



export default PlayerPayoutForm;