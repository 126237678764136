import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { unionId, defaultClubFeeScheduleId, tokenUnitCost, unionManagerFee, primaryClubId },
} = form;



const validations = {

    //add logic to account for markerBalance
    editor: Yup.object().shape({
        [unionId.name]: Yup.string().required("Union context must be set"),
        [defaultClubFeeScheduleId.name]: Yup.string(),
        [primaryClubId.name]: Yup.string(),
        [tokenUnitCost.name]: Yup.number().min(0, "Unit cost must be >= 0").required(tokenUnitCost.errorMsg),
        [unionManagerFee.name]: Yup.number().min(0, "Unit manager fee must be >= 0").required(unionManagerFee.errorMsg)
    }),

};

export default validations;