
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import { Formik, Form } from "formik";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import Search from "./components/Search";
import Edit from "./components/Edit";
import Account from "./components/Account";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

function getSteps() {
    return ["Find", "Edit", "Validate"];
}

function getStepContent(stepIndex, type, cardroom, formData) {
    switch (stepIndex) {
        case 0:
            return <Search type={type} cardroom={cardroom} formData={formData} />;
        case 1:
            return <Edit type={type} cardroom={cardroom} formData={formData} />;
        case 2:
            return <Account type={type} cardroom={cardroom} formData={formData} />;
        default:
            return null;
    }
}

const ParticipantFinder = ({ type, cardroom, session, mode, onAdded }) => {
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const isLastStep = activeStep === steps.length - 1;

    const { formId, formField } = form;
    const currentValidation = validations[activeStep];

    const [finalFormValues, setFinalFormValues] = useState(null);
    const [participantSaveStage, setParticipantSaveStage] = useState(0);
    const [participantGlobalId, setParticipantGlobalId] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);
    const [retryCounter2, setRetryCounter2] = useState(0);

    function handleRetry() {
        if (participantSaveStage == 1) {
            setRetryCounter(retryCounter + 1);
        }
        else if (participantSaveStage == 2) {
            setRetryCounter2(retryCounter2 + 1);
        }
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "searching database...", handleRetry)


    const casinoReq = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.write
    });

    const peopleReq = useFetchWithMsal({
        scopes: apiConfig.people.scopes.write
    });


    useEffect(() => {
        if (finalFormValues && participantSaveStage == 1) {
            //if new, create record, if global, add to current venue
            //default players to signature requested, even if "don't notify", this can be turned off in player editor later
            const defaultNotificationMask = (finalFormValues.mask & 1) > 0 ? finalFormValues.mustSign ? 64 : 0 : 0;

            function mapWithholdingSchedule(sched, rate) {
                if (sched === "flat") return 1;
                if (sched === "progressive") return 2;

                if (rate > 0) return "flat";

                return "none";

            }


            let withhold = true;
            if (finalFormValues.withholdingSchedule === "none") {
                withhold = false;
            }
            if (finalFormValues.withholdingRate === 0) {
                withhold = false;
            }

            var p = {
                id: finalFormValues.candidateState == "new" ? "00000000-0000-0000-0000-000000000000" : finalFormValues.candidateId,
                name: finalFormValues.name,
                phoneNumber: finalFormValues.isAnonymous ? null : finalFormValues.phoneNumber.toString(),
                hasPhoto: false,
                isAnonymous: finalFormValues.isAnonymous,
                properties: {
                    venueId: cardroom.id,
                    participantType: finalFormValues.mask,
                    creditLimit: finalFormValues.creditLimit,
                    name: finalFormValues.name,
                    nickName: finalFormValues.nickName,
                    phoneNumber: finalFormValues.isAnonymous ? null : finalFormValues.phoneNumber.toString(),
                    mustSign: finalFormValues.mustSign,
                    mustNotify: finalFormValues.mustNotify,
                    notificationMask: finalFormValues.mustNotify ? finalFormValues.notificationMask : defaultNotificationMask,
                    withholdingRate: withhold ? parseFloat(finalFormValues.withholdingRate) / 100 : 0,
                    withholdingThreshold: withhold ? finalFormValues.withholdingThreshold : 0,
                    withholdingSchedule: withhold ? mapWithholdingSchedule(finalFormValues.withholdingSchedule, parseFloat(finalFormValues.withholdingRate) / 100) : 0,
                    responsiblePartnerId: finalFormValues.partnerId
                },
                initialBalance: 0
            };

            if (finalFormValues.initialBalanceType === "credit") {
                p.initialBalance = -1 * parseFloat(finalFormValues.initialBalance);
            }
            else if (finalFormValues.initialBalanceType === "balance") {
                p.initialBalance = parseFloat(finalFormValues.initialBalance);
            }


            //we only need to save NEW players...

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving participant data...")
            showProgress();
            //[HttpPost("venue/{venueId:Guid}/participants", Name = "SaveVenueParticipant")]
            peopleReq.execute("POST", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", p).then((response) => {
                if (response) {
                    setParticipantGlobalId(response.id);
                }

                if (mode === "session") {
                    setParticipantSaveStage(2);
                }
                else {
                    progressIndicatorProps.close();
                    setParticipantSaveStage(3);
                }

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save participant, please try again...")
            });
        }
    }, [participantSaveStage, finalFormValues, retryCounter]);

    useEffect(() => {
        if (finalFormValues && participantGlobalId && participantSaveStage === 2) {

            //call service...
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Adding participant to session...")
            showProgress();

            //participantGlobalId

            //[HttpPost("{casinoId:Guid}/sessions/{sessionId:Guid}/participants", Name = "AddSessionParticipants")]
            //public async Task<IEnumerable<SessionParticipant>> AddSessionParticipantsAsync(Guid casinoId, Guid sessionId, IEnumerable<Participant> participants)
            let pa = [];
            pa.push({
                id: participantGlobalId,
                type: finalFormValues.mask,
                buyinCount: 0,
                transactionCount: 0,
                totalBuyinAmount: 0,
                borrowedAmount: 0
            });

            casinoReq.execute("POST", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/participants", pa).then((response) => {
                if (response) {

                } else {
                    //do we retry???
                }

                progressIndicatorProps.close();
                setParticipantSaveStage(3);

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to add participant to session, please try again...")
            });

        }
    }, [participantSaveStage, finalFormValues, participantGlobalId, retryCounter2]);

    useEffect(() => {
        if (finalFormValues && participantSaveStage === 3) {
            //close dialog
            if (onAdded) {
                onAdded();
            }
        }
    }, [participantSaveStage, participantGlobalId, finalFormValues]);


    const submitForm = async (values, actions) => {

        setFinalFormValues(values);

        if (participantSaveStage === 0) {
            if (values.candidateState === "new" || values.candidateState === "global") {
                setParticipantSaveStage(1);
            }
            else {
                setParticipantGlobalId(values.candidateId);
                if (mode === "session" && session)
                    setParticipantSaveStage(2);
                else
                    setParticipantSaveStage(1);
            }
        }
    };

    const handleSubmit = (values, actions) => {
        if (isLastStep) {
            submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    };

    const handleBack = () => setActiveStep(activeStep - 1);

    return (


        <SoftBox pt={3} pb={8}>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={8}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => (
                            <Form id={formId} autoComplete="off">
                                <Card>
                                    <SoftBox p={2}>
                                        <SoftBox>
                                            <ProgressIndicator {...progressIndicatorProps} />

                                            {progressIndicatorProps.visible ? <></> :

                                                getStepContent(activeStep, type, cardroom, {
                                                    values,
                                                    touched,
                                                    formField,
                                                    errors,
                                                    setFieldValue,
                                                    setFieldError,
                                                    setFieldTouched,
                                                    validateForm,
                                                    resetForm
                                                })

                                            }
                                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                                {activeStep === 0 ? (
                                                    <SoftBox />
                                                ) : (
                                                    <SoftButton variant="gradient" color="light" onClick={handleBack}>
                                                        back
                                                    </SoftButton>
                                                )}
                                                <SoftButton
                                                    variant="gradient"
                                                    color="dark"
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                >
                                                    {isLastStep ? "add" : "next"}
                                                </SoftButton>
                                            </SoftBox>
                                        </SoftBox>
                                    </SoftBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>

                </Grid>
            </Grid>
        </SoftBox>


    );

};

ParticipantFinder.defaultProps = {
    type: "player",
    mode: "session"
};

ParticipantFinder.propTypes = {
    type: PropTypes.oneOf([
        "player",
        "staff",
    ]).isRequired,
    cardroom: PropTypes.object.isRequired,
    session: PropTypes.object,
    mode: PropTypes.oneOf(["venue", "session"]).isRequired
};


export default ParticipantFinder;