import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { FormControlLabel, Grid, Radio, RadioGroup, Tooltip, tooltipClasses } from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';
import FormField from 'components/Elements/Forms/FormField';
import SoftAvatar from 'components/SoftAvatar';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useCardroomContext } from 'features';
import ClubFeeSelector from 'layouts/Club/components/ClubFees/Selector';
import ClubSelector from 'layouts/Club/components/Club/Selector';

const UnionDefaultEditorForm = ({ cardroom, union, formData, dense, refresh }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { unionId, defaultClubFeeScheduleId, tokenUnitCost, unionManagerFee, primaryClubId } = formField;
    const {
        unionId: unionIdV,
        defaultClubFeeScheduleId: defaultClubFeeScheduleIdV,
        tokenUnitCost: tokenUnitCostV,
        unionManagerFee: unionManagerFeeV,
        primaryClubId: primaryClubIdV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [unionClubs, setUnionClubs] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditUnionDefaults() {
        if (!context) return false;

        return context.isAllowed(actions.cardroom.fees.defaults.edit);
    }

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (true === refresh) {
            setFormDataInitialized(false);
            refresh = false;
        }
    }, [refresh]);

    useEffect(() => {
        if (cardroom && union) {
            if (union.clubs) {
                setUnionClubs(union.clubs);
                return;
            }

            if (!unionClubs) {
                // [HttpGet("{unionId:Guid}/casino/{casinoId:Guid}/clubs
                execute("GET", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/clubs").then((response) => {
                    if (response) {
                        union.clubs = response;
                        setUnionClubs(response);
                    }
                }).catch((e) => {

                });
            }
        }
    }, [execute, cardroom, union, unionClubs]);

    useEffect(() => {
        if (!formDataInitialized && union) {

            setFieldValue(unionId.name, union.id);
            setFieldValue(defaultClubFeeScheduleId.name, union.defaultClubFeeSchedule ? union.defaultClubFeeSchedule.id : "");
            setFieldValue(tokenUnitCost.name, union.tokenUnitCost);
            setFieldValue(unionManagerFee.name, union.unionManagerFee);
            setFieldValue(primaryClubId.name, union.primaryClubId);

            setFormDataInitialized(true);
        }
    }, [formDataInitialized, union]);

    function onDefaultClubFeeScheduleChanged(item) {
        if (item) {
            setFieldValue(defaultClubFeeScheduleId.name, item.id);
            // setDefaultClubFeeSchedule(item);
        }
    }

    function onPrimaryClubChanged(item) {
        if (item) {
            setFieldValue(primaryClubId.name, item.id);
        }
    }

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                {dense === true ? null :
                    <Grid item xs={12} sm={4} container justifyContent="center">
                        <SoftBox position="relative" height="max-content" mx="auto">
                            <SoftAvatar src={null} size="xxl" variant="rounded" bgColor="dark" />
                        </SoftBox>
                    </Grid>}
                <Grid item xs={12} sm={dense === true ? 12 : 8}>
                    <SoftBox mb={2} textAlign="left">

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {primaryClubId.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                {unionClubs ?
                                    <ClubSelector readonly={!canEditUnionDefaults()} placeholder={primaryClubId.placeholder} value={primaryClubIdV} onSelectionChanged={onPrimaryClubChanged} clubs={unionClubs} /> : "Loading clubs..."}

                                {errors.primaryClubId && touched.primaryClubId ?
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={primaryClubId.name} />
                                    </SoftTypography> : null}
                            </Grid>
                        </Grid>

                        <Grid container xs={12} mt={1.25}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {defaultClubFeeScheduleId.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <ClubFeeSelector cardroom={cardroom} union={union} value={defaultClubFeeScheduleIdV} type="dropdown" placeholder={defaultClubFeeScheduleId.placeholder}
                                    onSelectionChanged={onDefaultClubFeeScheduleChanged} emptyValue={{ id: "", name: "None" }} readonly={!canEditUnionDefaults()} />
                                {errors.defaultClubFeeScheduleId && touched.defaultClubFeeScheduleId ?
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={defaultClubFeeScheduleId.name} />
                                    </SoftTypography> : null}
                            </Grid>
                        </Grid>

                        <Grid container xs={12} mt={1.25}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {union && union.app ? union.app.tokenName + " Unit Cost" : tokenUnitCost.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditUnionDefaults()}
                                    type={tokenUnitCost.type}
                                    name={tokenUnitCost.name}
                                    value={tokenUnitCostV}
                                    placeholder={tokenUnitCost.placeholder}
                                    error={errors.tokenUnitCost && touched.tokenUnitCost}
                                    success={tokenUnitCostV && tokenUnitCostV.toString().length > 0 && !errors.tokenUnitCost}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {unionManagerFee.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditUnionDefaults()}
                                    type={unionManagerFee.type}
                                    name={unionManagerFee.name}
                                    value={unionManagerFeeV}
                                    placeholder={unionManagerFee.placeholder}
                                    error={errors.unionManagerFee && touched.unionManagerFee}
                                    success={unionManagerFeeV && unionManagerFeeV.toString().length > 0 && !errors.unionManagerFee}
                                />
                            </Grid>
                        </Grid>

                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;

};

UnionDefaultEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    dense: PropTypes.bool
};

const UnionDefaultsEditor = ({ cardroom, union, onClose, dense }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;

    const [retryCounter, setRetryCounter] = useState(0);
    const [unionRecord, setUnionRecord] = useState(null);

    const [refreshEditorForm, setRefreshEditorForm] = useState(false);


    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving union defaults...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditUnionDefaults() {
        if (!context) return false;

        return context.isAllowed(actions.cardroom.fees.defaults.edit);
    }

    const handleSubmit = (values, actions) => {

        //alert("submitting");

        if (false === canEditUnionDefaults()) return;

        //construct participant object
        const nu = {
            id: values.unionId,
            tokenUnitCost: values.tokenUnitCost,
            unionManagerFee: values.unionManagerFee,
            primaryClubId: values.primaryClubId ? values.primaryClubId : "00000000-0000-0000-0000-000000000000"
        };

        if (values.defaultClubFeeScheduleId) {
            nu.defaultClubFeeSchedule = {
                id: values.defaultClubFeeScheduleId,
                name: "",
                description: ""
            };
        }

        //update schedule...


        setUnionRecord(nu);
    };


    useEffect(() => {
        if (unionRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving union defaults...")
            showProgress();

            //[HttpPut("{unionId:Guid}/casino/{casinoId:Guid}", Name = "SetUnionDefaults")]
            execute("PUT", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id, unionRecord).then((response) => {
                if (response) {
                    //raiseOnTransactionCreated();

                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }

                    union.defaultClubFeeSchedule = response.defaultClubFeeSchedule;
                    union.tokenUnitCost = response.tokenUnitCost;
                    union.unionManagerFee = response.unionManagerFee;
                    union.primaryClubId = response.primaryClubId;
                    //if (schedule) schedule = Object.assign(schedule, response);
                    // else schedule = response;
                }

                setUnionRecord(null);
                progressIndicatorProps.close();
                raiseOnClose(false);

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save union defaults, please try again...")
            });
        }

    }, [unionRecord, execute, retryCounter]);


    function onCanceled() {
        //reset form...
        //reload form???
        setRefreshEditorForm(true);
        raiseOnClose(true);
    }

    useEffect(() => {
        if (true === refreshEditorForm) setRefreshEditorForm(false);
    }, [refreshEditorForm])


    function raiseOnClose(canceled) {
        if (onClose) {
            onClose(canceled);
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <UnionDefaultEditorForm cardroom={cardroom} union={union} dense={dense} refresh={refreshEditorForm} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={onCanceled}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canEditUnionDefaults()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

UnionDefaultsEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    dense: PropTypes.bool
};


export default UnionDefaultsEditor;