import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import ReactDOM from 'react-dom'
import SignaturePad from 'react-signature-canvas'
import SoftButton from 'components/SoftButton';

import styles from './sig.styles.css'
import SoftBox from 'components/SoftBox';
import { Grid, Icon, IconButton } from '@mui/material';


const SignatureCollectionForm = ({ size, onSignatureCollected, onSignatureCleared }) => {

    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    var sigPad = {};

    const clear = () => {
        setTrimmedDataURL(null);
        sigPad.clear();

        if (onSignatureCleared) {
            onSignatureCleared();
        }
        //sigPad.on();
    };

    const trim = () => {
        let imgData = sigPad.getTrimmedCanvas().toDataURL('image/png');
        setTrimmedDataURL(imgData);

        if (onSignatureCollected) {
            onSignatureCollected(imgData);
        }

        //sigPad.off();
    };

    function updateSigPadRef(ref) {
        sigPad = ref;

        if (sigPad && size === "sm") {
            var canvas = sigPad.getCanvas();
            canvas.height = 80;
        }
    }

    return <Grid container xs={12}>

        {trimmedDataURL ? <Grid container xs={12}>
            <Grid item xs={11}><img className={styles.sigImage} src={trimmedDataURL} /></Grid>
            <Grid item xs={1}>
                <SoftBox style={{ position: "relative", top: 0, right: 0 }} m={1}>
                    <SoftButton onClick={clear} variant="outlined" size="small" color="error" title="clear selection" iconOnly>
                        <Icon >clear</Icon>
                    </SoftButton>
                </SoftBox>
            </Grid>
        </Grid> : <>
            {size === "sm" ? <Grid container xs={12}>
                <Grid item xs={9}>
                    <div class="sig-canvas-container">
                        <SignaturePad canvasProps={{ className: styles.sigPad }} ref={updateSigPadRef} />
                    </div>
                </Grid>

                <Grid item xs={3}>
                    <SoftBox p={0.25}>
                        <SoftButton onClick={trim} iconOnly variant="outlined" color="success">
                            <Icon>check</Icon>
                        </SoftButton ></SoftBox>
                    <SoftBox p={0.25}>
                        <SoftButton onClick={clear} iconOnly variant="outlined" color="error">
                            <Icon >clear</Icon>
                        </SoftButton >
                    </SoftBox>
                </Grid>
            </Grid> : <Grid container xs={12}>
                <Grid item xs={12} textAlign="center">
                    <SignaturePad canvasProps={{ className: styles.sigPad }} ref={updateSigPadRef} style={{ border: "1px solid" }} />
                </Grid>

                <Grid item mt={3} display="flex" justifyContent="space-between" xs={12}>
                    <SoftButton onClick={clear} iconOnly variant="outlined" color="error">
                        <Icon >clear</Icon>
                    </SoftButton >
                    <SoftButton onClick={trim} iconOnly variant="outlined" color="success">
                        <Icon>check</Icon>
                    </SoftButton >
                </Grid>
            </Grid>}</>
        }


    </Grid>
};


SignatureCollectionForm.propTypes = {
    size: PropTypes.oneOf([
        "sm",
        "md",
        "lg",
    ]).isRequired,
    onSignatureCollected: PropTypes.func,
    onSignatureCleared: PropTypes.func
};

export default SignatureCollectionForm;