import form from "./form";


const {
    formField: {
        partnerId
    },
} = form;

const initialValues = {
    [partnerId.name]: ""
};

export default initialValues;
