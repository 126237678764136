import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Routes, Route, Navigate, Link, useLocation, useNavigate } from "react-router-dom";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DataTable from "components/Elements/DataTable";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import ParticipantCell from "components/Elements/DataTable/components/Cells/ParticipantCell";
import PhoneCell from "components/Elements/DataTable/components/Cells/PhoneCell";
import MoneyCell from "components/Elements/DataTable/components/Cells/MoneyCell";
import DateCell from "components/Elements/DataTable/components/Cells/DateCell";
import ButtonCell from "components/Elements/DataTable/components/Cells/ButtonCell";
import TextCell from "components/Elements/DataTable/components/Cells/TextCell";
import IconCell from "components/Elements/DataTable/components/Cells/IconCell";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { PlayerEditorDialog } from 'layouts/Cardroom/Participants/Player/components/PlayerEditor';
import { useDialog } from 'components/Elements/Dialog/common';

function convertPlayersToTable(players, accounts, partners, table) {

    const accountMap = [];
    if (accounts) {
        for (var i = 0; i < accounts.length; i++) {
            accountMap[accounts[i].accountHolderId] = accounts[i];
        }
    }

    const partnerMap = [];
    if (partners) {
        for (var i = 0; i < partners.length; i++) {
            partnerMap[partners[i].id] = partners[i];
        }
    }

    var rows = [];

    if (players && players.length > 0) {
        for (var i = 0; i < players.length; i++) {
            //var logo = players[i].hasPhoto || (players[i].properties && players[i].properties.hasPhoto) ? apiConfig.images.endpoint + "/player/" + players[i].id + ".jpg" : players[i].name.substring(0, 1).toUpperCase();

            let playerName = players[i].name;
            let playerPhone = players[i].phoneNumber;
            let playerHasPhoto = players[i].hasPhoto;
            let creditLimit = 0;
            let partner = null;

            if (players[i].properties) {
                playerName = players[i].properties.name ? players[i].properties.name : playerName;
                playerPhone = players[i].properties.phoneNumber ? players[i].properties.phoneNumber : playerPhone;
                playerHasPhoto = players[i].properties.hasPhoto ? players[i].properties.hasPhoto : playerHasPhoto;
                creditLimit = players[i].properties.creditLimit;

                if (players[i].properties.nickName) playerName += " (" + players[i].properties.nickName + ")";

                if (players[i].properties.responsiblePartnerId && partnerMap[players[i].properties.responsiblePartnerId])
                    partner = partnerMap[players[i].properties.responsiblePartnerId];
            }

            let playerImage = playerHasPhoto ? apiConfig.images.endpoint + "/player/" + players[i].id + ".jpg" : playerName.substring(0, 1);

            var account = accountMap[players[i].id];

            var row = {
                id: players[i].id,
                name: [playerName, { image: playerImage }],
                partner: [partner ? partner.displayName : "N/A", { user: partner }],
                phoneNumber: playerPhone,
                balance: account ? account.accountsReceivable - account.accountsPayable : 0,
                gamesPlayed: 0,
                creditLimit: creditLimit,
                player: players[i]
            };

            rows.push(row);
        }
    }

    if (rows.length > 0) {
        rows.sort(function (a, b) {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        });
    }

    table.rows = rows;

    return table;
}

function GetPlayerTableSchema(playerDetailButtonClickHandler, playerClickHandler) {

    return {
        columns: [
            {
                Header: "player",
                accessor: "name",
                Cell: ({ value: [name, data], row }) => (
                    <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} onClick={playerClickHandler} participant={row && row.original && row.original.player ? row.original.player : null} />
                ),
            },
            { Header: "phone", accessor: "phoneNumber", Cell: ({ value, row }) => row && row.original && row.original.player && row.original.player.isAnonymous ? <TextCell value="Anonymous" /> : <PhoneCell value={value} obfuscate={true} /> },
            { Header: "balance", accessor: "balance", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useFormatter={true} useColorScheme={true} /> },
            { Header: "credit limit", accessor: "creditLimit", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useFormatter={true} useColorScheme={false} /> },
            { Header: "partner", accessor: "partner", Cell: ({ value: [name, data] }) => <TextCell value={name} /> },
            { Header: "details", accessor: "id", Cell: ({ value }) => <ButtonCell id={value} onButtonClicked={playerDetailButtonClickHandler} icon="launch" color="secondary" /> },
        ],

        rows: [],
    };
}

function GetEmptySchema(handler) {
    return {
        columns: [],
        rows: []
    };
}

const CasinoPlayersCard = ({ cardroom }) => {

    const navigate = useNavigate();

    function onPlayerDetailsButtonClicked(playerId) {
        if (playerId) {
            navigate("/dashboards/cardroom/player", { state: { id: playerId, cardroom: cardroom } });
        }
    }

    const handlePlayerClick = (player) => {
        if (player) {
            setCurrentPlayer(player);
            openPlayerEditorDialog();
        }
    };

    const [playersTable, setPlayersTable] = useState(GetEmptySchema(onPlayerDetailsButtonClicked));
    const [players, setPlayers] = useState(null);

    const [accounts, setAccounts] = useState(null);

    const [partners, setPartners] = useState(null);

    const [currentPlayer, setCurrentPlayer] = useState(null);
    const [openPlayerEditorDialog, openPlayerEditorDialogProps] = useDialog();

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading player data...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const partnerRequest = useFetchWithMsal({
        scopes: apiConfig.user.scopes.read,
    });

    //load casino players
    useEffect(() => {
        if (!players) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading player data...")
            showProgress();

            execute("GET", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants/1").then((response) => {
                if (response) {
                    setPlayers(response);
                    cardroom.accounts = null; // remove accounts from cardroom cache
                    setAccounts(null); //clear accounts so they can be reloaded
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load player data, please try again...")
            });
        }
    }, [execute, players, retryCounter]) //cardroomListData 

    //load partners
    useEffect(() => {
        if (players && accounts && !partners) {

            if (cardroom.partners) {
                setPartners(cardroom.partners);
                progressIndicatorProps.close();
                return;
            }

            const idList = [];
            const idMap = [];

            for (var i = 0; i < players.length; i++) {
                if (!players[i] || !players[i].properties) continue;
                var partnerId = players[i].properties.responsiblePartnerId;
                if (partnerId && !idMap[partnerId]) {
                    idMap[partnerId] = true;
                    idList.push(partnerId);
                }
            }

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading partner data...")

            partnerRequest.execute("PUT", apiConfig.user.endpoint + "/", idList).then((response) => {
                if (response) {
                    cardroom.partners = response;
                    setPartners(response);
                }

                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load partner data, please try again...")
            });
        }
    }, [partnerRequest.execute, players, accounts, partners, retryCounter]);

    //load player accounts
    useEffect(() => {
        if (players && !accounts) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading player accounts...")
            showProgress();

            // [HttpGet("{entityId:Guid}/holders/accounts/{type:int}", Name = "GetEntityAccounts")]
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/accounts/1").then((response) => {
                if (response) {
                    cardroom.accounts = response;
                    setAccounts(response);
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load player accounts, please try again...")
            });
        }
    }, [accountRequest.execute, accounts, players, retryCounter])


    useEffect(() => {
        if (accounts && players && partners) {
            let newTable = GetPlayerTableSchema(onPlayerDetailsButtonClicked, handlePlayerClick);
            setPlayersTable(convertPlayersToTable(players, accounts, partners, newTable));
        }
    }, [accounts, players, partners])

    return <Card>
        <Grid container>
            <Grid item m={1} xs={12} mr="auto" ml="auto">
                <ProgressIndicator {...progressIndicatorProps} />
                <PlayerEditorDialog {...openPlayerEditorDialogProps} cardroom={cardroom} player={currentPlayer} />
                {!progressIndicatorProps.visible ?
                    <DataTable table={playersTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} pagination={{ color: "dark" }} /> : null}
            </Grid>
        </Grid>
    </Card>;
};




export default CasinoPlayersCard;