import { Icon } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { apiConfig } from "config/apiConfig";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { useEffect } from "react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import defaultCasino from "assets/graphics/logos/default-casino.png";

const NavItem = ({ icon, label, href, children, onSelected, index, type, level, selectedItem }) => {
    if (!level) level = 0;
    const theme = useTheme();

    const itemKey = `item-${level}-${index}`;


    function onSelectionMade() {
        if (href) {
            if (onSelected) {
                onSelected(href);
            }
        }
    }

    const subItem = (children || []).map((item, k) => {
        const ik = `item-${level}-${k}`;
        return <SoftBox index={ik} pl={(level ? level : 0) * 1} >
            <NavItem icon={item.icon} label={item.label} href={item.href} children={item.children} onSelected={item.onSelected} index={k} selectedItem={selectedItem} type="child" level={(level ? level : 0) + 1} />
        </SoftBox>;

    })

    return (<>
        <SoftBox key={itemKey} component="li" pl={(level ? level : 0) * 1} pt={0} mt={0}>

            <SoftTypography
                component="a"
                onClick={onSelectionMade}
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
                style={{ cursor: href ? "pointer" : "default", backgroundColor: (href && href === selectedItem) ? theme.palette.light.main : "transparent" }}

                sx={({
                    borders: { borderRadius },
                    functions: { pxToRem },
                    palette: { light },
                    transitions,
                }) => ({
                    display: "flex",
                    alignItems: "center",
                    borderRadius: borderRadius.md,
                    padding: `${pxToRem(10)} ${pxToRem(16)}`,
                    transition: transitions.create("background-color", {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.shorter,
                    }),
                    "&:hover": {
                        backgroundColor: href ? light.main : "none",
                    },
                })}
            >
                <SoftBox mr={1.5} lineHeight={1}>
                    {icon}
                </SoftBox>
                {label}
            </SoftTypography>
            {subItem}

        </SoftBox>

    </>);
}

export { NavItem };

const SettingsNav = ({ onSelectionChanged }) => {

    const [casinos, setCasinos] = useState(null);

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const [selection, setSelection] = useState(null);
    function onNewSelection(item) {
        if (item) {
            setSelection(item);
            if (onSelectionChanged) {
                onSelectionChanged(item);
            }
        }
    }


    useEffect(() => {
        if (!casinos) { // || cardroomListData.length === 0) {
            execute("GET", apiConfig.casino.endpoint).then((response) => {
                if (response) {
                    const list = [];
                    for (var i = 0; i < response.length; i++) {

                        var logo = <SoftBox component="img"
                            src={response[i].hasLogo ? apiConfig.images.endpoint + "/casino/" + response[i].id + ".png" : defaultCasino}
                            ml="auto"
                            mr="auto"
                            width="32px" />

                        var item = { icon: logo, label: response[i].name, href: response[i], children: null, onSelected: onNewSelection };
                        list.push(item);
                    }

                    if (list && list.length > 0) {

                        list.sort(function (a, b) {
                            if (a.label < b.label) { return -1; }
                            if (a.label > b.label) { return 1; }

                            return 0;
                        });

                        setCasinos(list);
                    }
                }
            });
        }

    }, [execute, casinos]);

    const sidenavItems = [
        { icon: <Icon color="dark" fontSize="large">badge</Icon>, label: "profile", href: "profile", children: null, onSelected: onNewSelection },
        { icon: <Icon color="dark" fontSize="large">rsvp</Icon>, label: "redeem invitation", href: "invitation", children: null, onSelected: onNewSelection },
        { icon: <Icon color="dark" fontSize="large">casino</Icon>, label: "cardrooms", href: null, children: casinos, onSelected: onNewSelection },
    ];

    const renderSidenavItems = sidenavItems.map(({ icon, label, href, children, onSelected }, key) => {
        return <NavItem icon={icon} label={label} href={href} children={children} onSelected={onSelected} index={key} type="" level={0} selectedItem={selection} />;
    });

    return (
        <Card
            sx={{
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                position: "sticky",
                top: "1%",
            }}
        >
            <SoftBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                sx={{ listStyle: "none" }}            >
                {renderSidenavItems}
            </SoftBox>
        </Card>
    );
}

export default SettingsNav;