import React, { useState, useEffect } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BootstrapDialog } from "components/Elements/Dialog/common"
import { useTheme } from '@emotion/react';
import { Card, Grid, useMediaQuery } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import DataTable from 'components/Elements/DataTable';
import DateCell from 'components/Elements/DataTable/components/Cells/DateCell';

const ReportingPeriodMap = ({ cardroom, union, reportingPeriod, clubs }) => {
    //settlementAmount


    function prepareForPresentation(cs, u, table) {
        let rows = [];
        if (!cs) return null;


        class Account {
            constructor(club) {
                this.club = club;
                this.amountOwed = club.settlementAmount; // > 0 ? club.settlementAmount : 0;
                //this.amountOwes = club.settlementAmount < 0 ? club.settlementAmount : 0;

                this.originalDebt = -1 * this.amountOwed;
                this.balance = this.originalDebt;
            }
        }

        class Transaction {
            constructor(payer, payee, amount) {
                this.payer = payer;
                this.payee = payee;
                this.amount = amount;

                this.payerStartingBalance = payer.balance;
                payer.balance -= amount;
                this.payerRemainingBalance = payer.balance;

                this.payeeStartingBalance = payee.balance;
                payee.balance += amount;
                this.payeeRemainingBalance = payee.balance;

            }
        }


        let primaryClubAccount = null;
        let totalClubSettlements = 0;

        const transactions = [];
        const accounts = [];
        const creditors = [];
        const debtors = []



        for (const club of cs) {

            if (u.isVirtual && club.parentUnionId != u.id) continue;

            totalClubSettlements += club.settlementAmount;

            const account = new Account(club);


            if (u.primaryClubId && u.primaryClubId == club.club.id) {
                primaryClubAccount = account;
            }

            accounts.push(account);

            if (account.amountOwed > 0) creditors.push(account);
            else debtors.push(account);
        }

        //alert(totalClubSettlements);
        //        const unionAccount = new Account({ club: { name: "UNION: " + union.name }, settlementAmount: totalClubSettlements });
        const unionAccount = new Account({ club: { name: "UNION: " + union.name }, settlementAmount: 0 });

        //alert(unionAccount.balance);

        // add union account...
        //accounts.push(unionAccount);

        //if (unionAccount.amountOwed > 0) creditors.push(unionAccount);
        //else debtors.push(unionAccount);




        if (primaryClubAccount) {
            // this is not a valid assumption... total club settlement doesn't just get added to the club, does it?
            //primaryClubAccount.amountOwed -= totalClubSettlements; //whatever the settlement amount is, it is paid to main club as DEBT
            //primaryClubAccount.amountOwed += -1 * totalClubSettlements;

            //alert(primaryClubAccount.amountOwed);
        }



        creditors.sort((a, b) => a.amountOwed - b.amountOwed);
        debtors.sort((a, b) => b.amountOwed - a.amountOwed);

        let creditorIndex = 0;
        let debtorIndex = 0;

        while (creditorIndex < creditors.length && debtorIndex < debtors.length) {
            const creditor = creditors[creditorIndex];
            const debtor = debtors[debtorIndex];

            const amountToSettle = Math.min(-debtor.amountOwed, creditor.amountOwed);

            if (amountToSettle > 0)
                transactions.push(new Transaction(debtor, creditor, amountToSettle));

            //        console.log(`${debtor.name} pays ${creditor.name}: ${amountToSettle.toFixed(2)}`);

            debtor.amountOwed += amountToSettle;
            creditor.amountOwed -= amountToSettle;

            if (creditor.amountOwed === 0) {
                creditorIndex++;
            }

            if (debtor.amountOwed === 0) {
                debtorIndex++;
            }
        }


        for (const debtor of debtors) {
            if (parseFloat(debtor.amountOwed.toFixed(2)) != 0) {
                transactions.push(new Transaction(debtor, unionAccount, -1 * debtor.amountOwed));
            }
        }

        for (const creditor of creditors) {
            if (parseFloat(creditor.amountOwed.toFixed(2)) != 0) {
                transactions.push(new Transaction(unionAccount, creditor, creditor.amountOwed));
            }
        }

        for (const tx of transactions) {
            rows.push({
                payerName: tx.payer.club.club.name,
                payeeName: tx.payee.club.club.name,
                amount: tx.amount,
                payerStartBalance: -1 * tx.payerStartingBalance,
                payerEndBalance: -1 * tx.payerRemainingBalance,
                payeeStartBalance: -1 * tx.payeeStartingBalance,
                payeeEndBalance: -1 * tx.payeeRemainingBalance,
                transaction: tx
            });
        }

        if (rows.length) {
            rows.sort(function (a, b) {
                if (a.payerName.toLowerCase() < b.payerName.toLowerCase()) { return -1; }
                if (a.payerName.toLowerCase() > b.payerName.toLowerCase()) { return 1; }

                //if (a.amount < b.amount) { return 1; }
                //if (a.amount > b.amount) { return -1; }

                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function getTableSchema() {
        return {
            columns: [
                { Header: "Payer", accessor: "payerName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Owes", accessor: "payerStartBalance", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Payee", accessor: "payeeName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Owed", accessor: "payeeStartBalance", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Amount", accessor: "amount", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                //{ Header: "Payer Balance", accessor: "payerStartBalance", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                //{ Header: "Payer Balance *", accessor: "payerEndBalance", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                //{ Header: "Payee Balance", accessor: "payeeStartBalance", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                //{ Header: "Payee Balance *", accessor: "payeeEndBalance", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> }
            ],
            rows: []
        };
    }

    const [txTable, setTxTable] = useState(null);

    useEffect(() => {
        if (clubs && union && !txTable) {
            setTxTable(prepareForPresentation(clubs, union, getTableSchema()));
        }
    }, [union, clubs]);

    return <Card style={{ width: "100%" }}>
        <Grid container xs={12}>

            {txTable ? <DataTable table={txTable} entriesPerPage={{ defaultValue: 10, visible: true }} showTotalEntries={true} isHierarchical={false} /> : null}
        </Grid>
    </Card>
};

const ReportingPeriodPaymentsMapDialog = ({ open, close, cardroom, union, reportingPeriod, clubs }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen


    function onCloseInitiated() {
        close();
    }

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xxl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Payments Omptimizer: {union.name}  <SoftTypography variant="caption" fontWeight="medium" color="white">(from <DateCell value={reportingPeriod.startTime} color="white" /> to <DateCell value={reportingPeriod.endTime} color="white" />)</SoftTypography>
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox m={1}>
                <Grid container spacing={1} >
                    <ReportingPeriodMap cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} clubs={clubs} />
                </Grid>
            </SoftBox>
        </DialogContent>
        <DialogActions>
            <SoftButton onClick={onCloseInitiated} variant="outlined" color="secondary">
                close
            </SoftButton>
        </DialogActions>
    </BootstrapDialog >
};


export default ReportingPeriodPaymentsMapDialog;