import form from "./form";


const {
    formField: {
        schedule,
        scheduleDate,
        scheduleTime,
        copyPlayers,
        copyPlayersFromSession
    },
} = form;

const initialValues = {
    [schedule.name]: false,
    [scheduleDate.name]: "",
    [scheduleTime.name]: "19:00",
    [copyPlayers.name]: "none",
    [copyPlayersFromSession.name]: ""
};

export default initialValues;
