import React, { useEffect, useState, useContext, useRef } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

//import { ListView } from '../components/ListView';
import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DataBoundSelect from "components/Elements/DataBound/Dropdown";

const CardroomSelectContent = (props) => {
    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const onSelectionChanged = props.onChange;
    const onDataLoaded = props.onDataLoad;

    const [cardroomListData, setCardroomData] = useState(null); // useState(appContext.cardroomState.cardrooms);
    const [selectedCardRoom, setSelectedCardroom] = useState(null); //useState(appContext.cardroomState.currentCardroom);
    const [selectedCardRoomIndex, setSelectedCardroomIndex] = useState(-1);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        if (!cardroomListData) { // || cardroomListData.length === 0) {
            execute("GET", apiConfig.casino.endpoint).then((response) => {

                if (response) {

                    const counter = {
                        current: 0,
                        increment: function () {
                            counter.current++;
                        }
                    };

                    if (response.length === 0) {
                        handleCompleteDataLoad(response);
                    }
                    else {
                        for (var i = 0; i < response.length; i++) {
                            loadLicense(response[i], response, counter);
                        }
                    }
                }
            });

        }

    }, [execute, cardroomListData]) //cardroomListData

    function loadLicense(cardroom, cardrooms, counter) {
        execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/license").then((license) => {
            if (license) {
                cardroom.license = license;
            }

            counter.increment();
            if (cardrooms.length === counter.current) {
                handleCompleteDataLoad(cardrooms);
            }
        }).catch((ex) => {
            counter.increment();
            if (cardrooms.length === counter.current) {
                handleCompleteDataLoad(cardrooms);
            }
        });
    }

    function handleCompleteDataLoad(cardrooms) {
        setCardroomData(cardrooms);
        setIsDataLoaded(true);

        if (onDataLoaded) {
            onDataLoaded(cardrooms);
        }

        if (cardrooms.length > 0) {
            setSelectedCardroomIndex(0);
            setSelectedCardroom(cardrooms[0]);

            if (onSelectionChanged) {
                onSelectionChanged(cardrooms[0]);
            }
        }
    }

    function handleCardroomChange(e) {
        const item = e.item;
        const index = e.index;

        setSelectedCardroomIndex(index);
        setSelectedCardroom(item);

        if (onSelectionChanged) {
            onSelectionChanged(item);
        }
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (cardroomListData) {

        if (cardroomListData.length === 1) {
            return <SoftTypography
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                color="dark"
            >Cardroom: {
                    selectedCardRoom ? selectedCardRoom.name : "[none]"
                }
            </SoftTypography>

        }
        else if (isDataLoaded && cardroomListData.length > 0) {

            return <SoftBox>
                <DataBoundSelect placeholder="Select Cardroom" data={cardroomListData} valueName="id" textName="name" onChange={handleCardroomChange} size="medium" selectedIndex={selectedCardRoomIndex}></DataBoundSelect>
            </SoftBox>
        }
        else {
            return <></>
        }
    }
    else {
        return null;
    }

};

function CardroomSelect(props) {
    const authRequest = {
        ...loginRequest,
    };

    const onCasinoChanged = (room) => {
        if (props.onChange) {
            props.onChange(room);
        }
    };

    const onCasinoDataLoaded = (data) => {
        if (props.onDataLoad) {
            props.onDataLoad({ count: data ? data.length : 0 });
        }
    }

    return (
        <CardroomSelectContent onChange={onCasinoChanged} onDataLoad={onCasinoDataLoaded} />
    );
};

export default CardroomSelect;
