import form from "./form";


const {
  formField: {
    verified,
    provider,
    providerOther,
    providerRefMethod,
    providerRef,
    providerRefImage,
    participantSignatureImage,
    note
  },
} = form;

const initialValues = {
  [verified.name]: true,
  [provider.name]: "",
  [providerOther.name]: "",
  [providerRefMethod.name]: "number",
  [providerRef.name]: "",
  [providerRefImage.name]: "",
  [participantSignatureImage.name]: "",
  [note.name]: ""
};

export default initialValues;
