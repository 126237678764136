
const form = {
    formId: "agent-fee-schedule-editor-form",
    formField: {        
        scheduleId: {
            name: "scheduleId",
            label: "schedule id",
            type: "hidden"
        },
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "name",
            errorMsg: "Name is required.",
        },
        description: {
            name: "description",
            label: "Description",
            type: "text",
            placeholder: "description",
        },
        feeRate: {
            name: "feeRate",
            label: "Referral Rate",
            type: "number",
            placeholder: "referral percentage",
            errorMsg: "Referral rate is required",
        },
        feeThreshold: {
            name: "feeThreshold",
            label: "Referral exemption",
            type: "number",
            placeholder: "referral exemption",
            errorMsg: "Exempt amount is required",
        },
        feeScheduleScheme: {
            name: "feeScheduleScheme",
            label: "Referral Type",
            type: "number",
            placeholder: "referral type",
            errorMsg: "commission type is required",
        }
    },

};

export default form;
