
const form = {
    formId: "invitation-redeem-form",
    formField: {        
        code: {
            name: "code",
            label: "Invitation Code",
            type: "text",
            placeholder: "invitation code",
            errorMsg: "Invitation code is required.",
        },
        key: {
            name: "key",
            label: "Invitation Key",
            type: "text",
            placeholder: "invitation key",
            errorMsg: "Invitation key is required.",
        }
    },

};

export default form;
