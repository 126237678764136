
import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import { AppBar, Card, Divider, Grid, Icon, Paper, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tooltip, styled } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import defaultCasino from "assets/graphics/logos/default-casino.png";
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import Stack from '@mui/material/Stack';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import DataTable from 'components/Elements/DataTable';
import ButtonCell from 'components/Elements/DataTable/components/Cells/ButtonCell';
import ReportUtilities from 'layouts/Club/Report/components/Navigation';
import SoftButton from 'components/SoftButton';
import { useDialog } from 'components/Elements/Dialog/common';
import ReportingPeriodPaymentsMapDialog from '../PaymentsMap';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "none"
}));

const enableCache = true;

const dataLoader = {
    loadUnions: function (request, cardroom, union, onsuccess, onerror) {
        if (union && union.unions) {
            if (onsuccess) onsuccess(union.unions);
            return;
        }

        // [HttpGet("{unionId:Guid}/casino/{casinoId:Guid}/unions", Name = "GetUnionUnions")]
        request.execute("GET", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/unions").then((response) => {
            if (enableCache) union.unions = response;
            if (onsuccess) onsuccess(response);
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadClubs: function (request, cardroom, reportingPeriod, union, onsuccess, onerror) {

        if (reportingPeriod && reportingPeriod.clubs) {
            if (onsuccess) onsuccess(reportingPeriod.clubs);
            return;
        }

        let url = apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/clubs";

        // if (union && union.id) url += "?unionId=" + union.id; --- DO NOT USE THIS AS IT CAN MESS UP PRESENTATION, INSTEAD, FILTER OFF CLUBS WE DON'T WANT IN UI

        request.execute("GET", url).then((response) => {
            if (enableCache) reportingPeriod.clubs = response;
            if (onsuccess) onsuccess(response);
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });

    },
    loadTokens: function (request, cardroom, reportingPeriod, onsuccess, onerror) {
        if (reportingPeriod && reportingPeriod.tokens) {
            if (onsuccess) onsuccess(reportingPeriod.tokens);
            return;
        }

        request.execute("GET", apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/tokens").then((response) => {
            if (enableCache) reportingPeriod.tokens = response;
            if (onsuccess) onsuccess(response);
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadXUnionSummary: function (request, cardroom, union, reportingPeriod, onsuccess, onerror) {

        if (reportingPeriod && reportingPeriod.xUnionSummaries) {

            let result = null;
            if (!union || !union.id && reportingPeriod.xUnionSummaries["default"]) result = reportingPeriod.xUnionSummaries["default"];
            if (union && union.id && reportingPeriod.xUnionSummaries[union.id]) result = reportingPeriod.xUnionSummaries[union.id];

            if (result) {
                if (onsuccess) onsuccess(result);
                return;
            }
        }

        //get xunion summary
        let url = apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/xunion";
        if (union) url += "?unionId=" + union.id;

        request.execute("GET", url).then((response) => {
            if (enableCache) {
                if (!reportingPeriod.xUnionSummaries) reportingPeriod.xUnionSummaries = [];
                if (!union || !union.id) {
                    reportingPeriod.xUnionSummaries["default"] = response;
                }
                else {
                    reportingPeriod.xUnionSummaries[union.id] = response;
                }
            }
            if (onsuccess) onsuccess(response);
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    }
};


const ReportUnionClubs = ({ cardroom, union, reportingPeriod }) => {

    function prepareForPresentation(clubs, unions, activeOnly, table) {
        let rows = [];
        if (!clubs) return null;

        var map = [];
        for (var i = 0; i < unions.length; i++) {
            if (!map[unions[i].id]) {
                map[unions[i].id] = unions[i];
            }
        }


        function calcEarnings(clubSummary) {
            if (!clubSummary) return 0;
            return (clubSummary.winnings + clubSummary.fees);
        }
        function calcUnionDues(clubSummary) {
            if (!clubSummary) return 0;
            return (clubSummary.fees * clubSummary.unionDuesRate * -1) - clubSummary.unionDuesBase;
        }
        function calcXMTTDues(clubSummary) {
            if (!clubSummary) return 0;
            return (clubSummary.xmttFees * clubSummary.xmttDuesRate * -1);
        }
        function calcChipOffset(clubSummary) {
            if (!clubSummary) return 0;
            return (calcEarnings(clubSummary) * clubSummary.chipOffsetRate * -1);
        }
        function calcTaxRebate(clubSummary) {
            if (!clubSummary) return 0;

            let taxableAmount = calcEarnings(clubSummary) - clubSummary.xmttPnL + clubSummary.jackpotPnL;
            let winnerTax = taxableAmount > 0 ? taxableAmount * clubSummary.winTaxRate : 0;
            return (taxableAmount * clubSummary.taxRebateRate) - winnerTax;
        }

        for (var i = 0; i < clubs.length; i++) {
            //if (activeOnly && clubs[i].settlementAmount == 0 && clubs[i].fees == 0 && clubs[i].winnings == 0 && clubs[i].referralBonus == 0) continue;
            if (activeOnly && clubs[i].playerCount === 0) continue;

            if (union.isVirtual && clubs[i].parentUnionId != union.id) continue;

            var u = map[clubs[i].parentUnionId];

            rows.push({
                clubName: clubs[i].club ? clubs[i].club.name : "",
                unionName: u ? u.name : "",
                club: clubs[i],
                union: u,
                wins: clubs[i].winnings,
                fees: clubs[i].fees,
                unionDues: calcUnionDues(clubs[i]),
                xmttDues: calcXMTTDues(clubs[i]),
                chips: calcChipOffset(clubs[i]),
                tax: -1 * calcTaxRebate(clubs[i]),
                referrals: clubs[i].referralBonus,
                dividends: clubs[i].loyaltyRebate,
                settlement: clubs[i].settlementAmount, //should prob calc here
                summary: clubs[i]
            });
        }

        if (rows.length) {
            rows.sort(function (a, b) {
                if (a.clubName.toLowerCase() < b.clubName.toLowerCase()) { return -1; }
                if (a.clubName.toLowerCase() > b.clubName.toLowerCase()) { return 1; }

                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function getTableSchema(clubDetailButtonClicked) {
        return {
            columns: [
                { Header: "Club", accessor: "clubName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Winnings", accessor: "wins", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Fees", accessor: "fees", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Union Dues", accessor: "unionDues", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "XMTT Dues", accessor: "xmttDues", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Chip Offset", accessor: "chips", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Tax Rebate", accessor: "tax", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Referrals", accessor: "referrals", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Dividend", accessor: "dividends", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Settlement", accessor: "settlement", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Union", accessor: "unionName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "details", accessor: "summary", Cell: ({ value }) => <ButtonCell id={value} onButtonClicked={clubDetailButtonClicked} icon="launch" color="secondary" /> },
            ],
            rows: []
        };
    }

    function onClubReportClicked(summary) {
        if (!summary) return;

        let c = summary.club;
        c.app = reportingPeriod.app;

        ReportUtilities.publish("/dashboards/club/report", { cardroom: cardroom, union: union, club: c, report: reportingPeriod });
    }

    const [clubsTable, setClubsTable] = useState(null);
    const [clubs, setClubs] = useState(null);
    const [unions, setUnions] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading clubs...", handleProgressRetry);
    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (!clubs) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading club details...")
            showProgress();

            dataLoader.loadClubs(clubRequest, cardroom, reportingPeriod, union, (clubs) => {
                //success
                if (clubs) {
                    setClubs(clubs);
                    setClubsTable(null);
                }
            }, (ex) => {
                //error
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load club details, plese try again...");
            });
        }
    }, [clubRequest.execute, clubs, retryCounter]);

    useEffect(() => {

        if (!unions && clubs) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading unions...")
            showProgress();

            dataLoader.loadUnions(clubRequest, cardroom, union, (unions) => {
                //success
                if (unions) {
                    setUnions(unions);
                }
            }, (ex) => {
                //error
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load unions, plese try again...");
            });
        }

    }, [clubRequest.execute, clubs, unions, retryCounter]);

    useEffect(() => {
        if (clubs && unions && !clubsTable) {
            //load tables
            setClubsTable(prepareForPresentation(clubs, [...unions, union], activeClubsOnly, getTableSchema(onClubReportClicked)));
            progressIndicatorProps.close();
        }

    }, [clubs, unions, clubsTable]);

    const [activeClubsOnly, setActiveClubsOnly] = useState(true);
    const [tableRefreshNeeded, setTableRefreshNeeded] = useState(false);

    function onActiveClubsOnlySwitchToggle() {
        let newValue = !activeClubsOnly;
        setActiveClubsOnly(newValue);
        setTableRefreshNeeded(true);
    }

    useLayoutEffect(() => {
        if (clubs && clubsTable && tableRefreshNeeded) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Filtering clubs...")
            showProgress();

            setClubsTable(null);
            setTableRefreshNeeded(false);
        }
    }, [activeClubsOnly, tableRefreshNeeded, clubs, clubsTable])

    const filter = <Grid container xs={12} justifyContent="center" ml={5}>
        <Grid item>
            <SoftBox mt={0.25} >
                <Switch checked={activeClubsOnly} onChange={onActiveClubsOnlySwitchToggle} />
            </SoftBox>
        </Grid>
        <Grid item>
            <SoftBox ml={1} display="inlineBox">
                <SoftTypography component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    Show only active clubs
                </SoftTypography>
            </SoftBox></Grid>
    </Grid >;

    return (<Card p={1}>
        <ProgressIndicator {...progressIndicatorProps} />
        {clubsTable && !progressIndicatorProps.visible ? <DataTable table={clubsTable} entriesPerPage={{ defaultValue: 10, visible: true }} canSearch showTotalEntries={true} isHierarchical={false} widget={filter} /> : null}
    </Card>);
};

function formatDecimalValue(v) {
    if (!v) return 0;

    return v.toFixed(2);
}


ReportUnionClubs.propTypes = {
    cardroom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired
};

const ReportSummaryCard = ({ reportingPeriod, summary, legend }) => {

    return legend ? <Item style={{ maxWidth: "175px", backgroundColor: 'transparent', shadowOpacity: 0 }}>
        <Grid container xs={12} >
            <Grid xs={12}>&nbsp;</Grid>

            <Grid xs={12} style={{ whiteSpace: "nowrap", borderTop: "1px solid black" }} textAlign="left" >
                <Tooltip title="Amount won or lost by all clubs in the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Winnings
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Amount of fees generated by all clubs in the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Fee
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left" >
                <Tooltip title="Amount earned (winnings + fees) by all clubs in the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="bold" textTransform="uppercase">
                        Earnings
                    </SoftTypography>
                </Tooltip>
            </Grid>

            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Portion of all fees generated by all clubs in the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Proportion
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Number of clubs in the union that reported activity this reporting period" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Active Clubs
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }} textAlign="left">
                <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                    Expenses
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title={"Number of " + (reportingPeriod && reportingPeriod.app ? reportingPeriod.app.tokenName : "token").toLowerCase() + "s used by all clubs in the union"} placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        {reportingPeriod && reportingPeriod.app ? reportingPeriod.app.tokenName : "Token"}s Used
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title={"Cost of " + (reportingPeriod && reportingPeriod.app ? reportingPeriod.app.tokenName : "token").toLowerCase() + "s used by all clubs in the union"} placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        {reportingPeriod && reportingPeriod.app ? reportingPeriod.app.tokenName : "Token"}s Cost
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Gap in MTT guarantees incurred during this reporting period" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        MTT Overlay
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Dues owed based on XMTT Earnings (XMTT Dues Rate * XMTT Earnings)" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        XMTT Dues
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Union manager fees incurred during this reporting period" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Union Manager
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Data reporting costs incurred during this reporting period" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Reporting
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title={"Incurred expenses (" + (reportingPeriod && reportingPeriod.app ? reportingPeriod.app.tokenName : "token").toLowerCase() + " costs + MTT Overlay + XMTT Dues + Management & Reporting costs)"} placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="bold" textTransform="uppercase">
                        Total Expenses
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }} textAlign="left">
                <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                    Profit Calcs
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Sum of all of club settlements in the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Club Settlement
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Sum of all of club dues owed to the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Club Union Dues
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Total Expenses
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Amount owed as club referral fees" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Referrals Paid
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Amount paid in loyalty dividends" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Loyalty Dividends
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Total profit generated by the union (Union Settlements - all club settlements)" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Total Profit
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }} textAlign="left">
                <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                    Settlement Calcs
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Total Earnings
                </SoftTypography>
            </Grid>
            {/**
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Jackpot P&L
                </SoftTypography>
            </Grid>
           
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Spin'N'Go Offset
                </SoftTypography>
            </Grid>
             */}
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    XMTT Dues
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Chip Offset
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Tax Rebate
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Expenses
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", borderTop: "1px solid black" }} textAlign="left">
                <Tooltip title="Total earnings + XMTT Dues + Chip Offset + Tax Rebate + Expenses" placement="top">
                    <SoftTypography variant="caption" color="error" fontWeight="bold" textTransform="uppercase">
                        Net Settlement
                    </SoftTypography>
                </Tooltip>
            </Grid>
        </Grid>
    </Item> : <Item style={{ minWidth: "125px", backgroundColor: 'transparent', shadowOpacity: 0 }}>
        <Grid container xs={12} >
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign={"right"}>
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    {summary.name}
                </SoftTypography>
            </Grid>

            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderTop: "1px solid black" }}><MoneyCell value={formatDecimalValue(summary.winnings)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.fees)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.totalEarnings)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.proportion)} prefix="" suffix="%" useColorScheme={false} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={summary.activeClubCount} prefix="" useColorScheme={false} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }}> <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                &nbsp;
            </SoftTypography></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.tokens.used)} prefix="" useColorScheme={true} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.tokens.cost)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.mttOverlay)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.xmttDues)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.manager)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.reporting)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.totalExpenses)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }}> <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                &nbsp;
            </SoftTypography></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue((-1 * summary.clubSettlement))} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue((-1 * summary.unionDues))} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.totalExpenses)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.referrals)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.loyaltyRebate)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.totalProfit)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }}> <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                &nbsp;
            </SoftTypography></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.totalEarnings)} prefix="$" /></Grid>
            {/*
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={ formatDecimalValue(summary.jackpotPnL)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={ formatDecimalValue(summary.spinOffset)} prefix="$" /></Grid>
*/}
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.xmttDues)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.chipOffset)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.tax)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.totalExpenses)} prefix="$" /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderTop: "1px solid black" }}><MoneyCell value={formatDecimalValue(summary.settlement)} prefix="$" /></Grid>
        </Grid>
    </Item >
};

const ReportSummaryUnionsClubDetails = ({ summary }) => {
    //we expected a pre-calculate sub union summary, where each member of clubs/activeClubs represnets sub union club
    function prepareDataForPresentation(s, table) {
        let rows = [];

        //row.sort
        if (s && s.activeClubs) {
            for (var i = 0; i < s.activeClubs.length; i++) {
                rows.push({
                    clubName: s.activeClubs[i].club.name,
                    wins: s.activeClubs[i].winnings,
                    fees: s.activeClubs[i].fees,
                    unionDues: s.activeClubs[i].calc.unionDues,
                    xmttDues: s.activeClubs[i].calc.xmttDues,
                    taxRebate: s.activeClubs[i].calc.taxRebate,
                    chips: s.activeClubs[i].calc.chipOffset,
                    referrals: s.activeClubs[i].referralBonus,
                    dividends: s.activeClubs[i].loyaltyRebate,
                    settlement: s.activeClubs[i].settlementAmount,
                });
            }
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.clubName.toLowerCase() < b.clubName.toLowerCase()) { return -1; }
                if (a.clubName.toLowerCase() > b.clubName.toLowerCase()) { return 1; }

                return 0;
            });
        }

        table.rows = rows;
        return table;
    }

    function getTableSchema() {
        return {
            columns: [
                { Header: "Club", accessor: "clubName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Winnings", accessor: "wins", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Fees", accessor: "fees", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Union Dues", accessor: "unionDues", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "XMTT Dues", accessor: "xmttDues", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Chip Offset", accessor: "chips", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Tax Rebate", accessor: "taxRebate", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Referrals", accessor: "referrals", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                //{ Header: "Dividend", accessor: "dividends", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Settlement", accessor: "settlement", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> }
                //sub union nav may not work      // { Header: "details", accessor: "summary", Cell: ({ value }) => <ButtonCell id={value} onButtonClicked={unionDetailButtonClicked} icon="launch" color="secondary" /> },
            ],
            //hcontrol value
            rows: []
        };
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading clubs...", null)
    const [clubDetailsTable, setClubsDetailsTable] = useState(null);

    useEffect(() => {
        if (!clubDetailsTable && summary) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading union summaries...")
            showProgress();

            setClubsDetailsTable(prepareDataForPresentation(summary, getTableSchema()));
        }
        else if (clubDetailsTable) {
            progressIndicatorProps.close();
        }


    }, [clubDetailsTable, summary]);


    return <>
        <ProgressIndicator {...progressIndicatorProps} />
        {clubDetailsTable && !progressIndicatorProps.visible ?
            <DataTable table={clubDetailsTable} entriesPerPage={{ defaultValue: 1000, visible: false }} showTotalEntries={false} isHierarchical={false} />
            : null}
    </>;

};

const ReportSummaryUnions = ({ unions, clubs, summaries, reportingPeriod, union, cardroom }) => {


    function prepareDataForPresentation(us, cs, ss, table) {

        function calculateAmountOwed(clubs) {
            if (!clubs) return 0;
            let total = 0;
            for (var i = 0; i < clubs.length; i++) {
                total += clubs[i].settlementAmount;
            }
            return total;
        }

        let rows = [];
        for (var i = 0; i < ss.length; i++) {
            if (!ss[i].union) continue;
            rows.push({
                unionName: ss[i].name,
                clubSettlement: ss[i].clubSettlement,
                unionSettlement: ss[i].settlement,
                profit: ss[i].totalProfit,
                clubsThatOweCount: ss[i].clubsThatOwe.length,
                clubsThatAreOwedCount: ss[i].clubsThatAreOwed.length,
                owedToClubs: calculateAmountOwed(ss[i].clubsThatAreOwed),
                owedByClubs: calculateAmountOwed(ss[i].clubsThatOwe),
                summary: ss[i],
                players: <ReportSummaryUnionsClubDetails summary={ss[i]} />
            });
        }

        table.rows = rows;
        return table;
    }
    function getTableSchema(unionDetailButtonClicked) {
        return {
            columns: [
                { Header: "Sub Union", accessor: "unionName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Clubs Settlement", accessor: "clubSettlement", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                //REMOVE UNTIL ABLE TO FIX
                //{ Header: "Union Settlement", accessor: "unionSettlement", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Profit", accessor: "profit", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Owe", accessor: "clubsThatOweCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "Owed", accessor: "clubsThatAreOwedCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "Owed to", accessor: "owedToClubs", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "Owed by", accessor: "owedByClubs", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="$" useColorScheme={true} /> },
                { Header: "details", accessor: "summary", Cell: ({ value }) => value && value.allowNavigation ? <ButtonCell id={value} onButtonClicked={unionDetailButtonClicked} icon="launch" color="secondary" /> : null },
            ],
            collapsibleDetailAccessor: "players",
            rows: []
        };
    }

    function onUnionReportClicked(summary) {
        if (!summary) return;

        let c = summary.club;
        if (c) c.app = reportingPeriod.app;

        let u = summary.union;
        if (u) u.app = reportingPeriod.app;

        var rpClone = Object.assign({}, reportingPeriod);
        ReportUtilities.publish("/dashboards/union/report", { cardroom: cardroom, union: u, club: c, report: rpClone })
    }

    const [unionsTable, setUnionsTable] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading union summaries...", handleRetry)

    useEffect(() => {
        if (!unionsTable && unions && clubs && summaries) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading union summaries...")
            showProgress();

            setUnionsTable(prepareDataForPresentation(unions, clubs, summaries, getTableSchema(onUnionReportClicked)))
        }
        else if (unionsTable) {
            progressIndicatorProps.close();
        }

    }, [unionsTable, unions, clubs, summaries, retryCounter]);

    return <Card p={1}>
        <ProgressIndicator {...progressIndicatorProps} />
        {unionsTable && !progressIndicatorProps.visible ? <DataTable table={unionsTable} entriesPerPage={{ defaultValue: 10, visible: true }} showTotalEntries={true} isHierarchical={true} /> : null}
    </Card>;
};

const ReportSummaryXUnion = ({ totalSummary, xUnionSummary }) => {

    //if (xUnionSummary) { 
    //    xUnionSummary.
    //}

    return (totalSummary && xUnionSummary) ? <SoftBox mt={0}>
        <Grid container justifyContent="space-between">
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
        {/* Row 1 */}
        <Grid container xs={12} justifyContent="space-between">
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"left"}>
                        <Tooltip title="Cross union multi-table tournament earnings" placement="top-start">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                XMTT Earnings
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"left"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={formatDecimalValue(xUnionSummary.xmttEarnings)} prefix="$" />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"center"}>
                        <Tooltip title="Cross union multi-table tournament dues" placement="top">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                XMTT Dues
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"center"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={formatDecimalValue(totalSummary.xmttDues)} prefix="$" />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"right"}>
                        <Tooltip title="Bad beat jackpot profits & loss" placement="right-start">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                Jackpot P&L
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"right"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={formatDecimalValue(xUnionSummary.jackpotPnL)} prefix="$" />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <Grid container justifyContent="space-between">
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
        {/* Row 2 */}
        <Grid container xs={12} justifyContent="space-between">
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"left"}>
                        <Tooltip title="Total expenses incurred by the union" placement="top-start">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                Total Expenses
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"left"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={formatDecimalValue(totalSummary.totalExpenses)} prefix="$" />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"center"}>
                        <Tooltip title="Chip offset" placement="top">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                Chip Offset
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"center"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={formatDecimalValue(totalSummary.chipOffset)} prefix="$" />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"right"}>
                        <Tooltip title="Tax rebate" placement="right-start">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                Tax Rebate
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"right"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={formatDecimalValue(totalSummary.tax)} prefix="$" />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>


        <Grid container justifyContent="space-between">
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>


        {/* Row 3 */}
        <Grid container xs={12} justifyContent="space-between">
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"left"}>
                        <Tooltip title="Multi-table guarantee gap" placement="top-start">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                MTT Overlay
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"left"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={formatDecimalValue(totalSummary.mttOverlay)} prefix="$" />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"center"}>
                        <Tooltip title="Spin'n'go offset" placement="top-start">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                Spin N Go Offset
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"center"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={formatDecimalValue(totalSummary.spinOffset)} prefix="$" />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"right"}>
                        <Tooltip title="Bubble protection" placement="top">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                Bubble Protection
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"right"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={formatDecimalValue(xUnionSummary.bubbleProtection)} prefix="$" />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <Grid container justifyContent="space-between">
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    </SoftBox> : null;
};

const ReportSummarySelf = ({ reportingPeriod, summaries }) => {

    return (reportingPeriod && summaries) ? <Stack direction={"row"} mt={2} mb={2} style={{ backgroundColor: 'transparent', shadowOpacity: 0 }}>
        <ReportSummaryCard reportingPeriod={reportingPeriod} summary={null} legend={true} />
        <Stack direction={"row"}
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
            style={{ overflowX: "auto" }}>
            {summaries.map((s, index) => {
                return <ReportSummaryCard reportingPeriod={reportingPeriod} summary={s} legend={false} />;
            })}
        </Stack>
    </Stack> : null;
};



const ReportSummary = ({ cardroom, union, reportingPeriod, summary, mode }) => {
    const cardroomLogo = cardroom && cardroom.hasLogo ? apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png" : defaultCasino;
    const appLogo = reportingPeriod && reportingPeriod.app ? apiConfig.images.endpoint + "/app/" + reportingPeriod.app.id + ".png" : null;

    const [retryCounter, setRetryCounter] = useState(0);
    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report details...", handleRetry)

    //build summaries
    const [summaries, setSummaries] = useState(null);
    const [clubs, setClubs] = useState(null);
    const [unions, setUnions] = useState(null);
    // const [tokens, setTokens] = useState(null);
    const [xUnionSummary, setXUnionSummary] = useState(null);

    const [netSettlement, setNetSettlement] = useState(0);

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (!clubs && reportingPeriod) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading club details...")
            showProgress();

            dataLoader.loadClubs(clubRequest, cardroom, reportingPeriod, union, (clubs) => {
                //success
                if (clubs) {
                    setClubs(clubs);
                }
            }, (ex) => {
                //error
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load club details, plese try again...");
            });
        }

    }, [clubRequest.execute, clubs, reportingPeriod, retryCounter]);

    useEffect(() => {
        if (!unions && union && clubs) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading unions...")
            showProgress();

            dataLoader.loadUnions(clubRequest, cardroom, union, (unions) => {
                //success
                setUnions([...unions, union])
            }, (ex) => {
                //error
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load unions, plese try again...");
            });
        }

    }, [clubRequest.execute, clubs, unions, union, retryCounter]);

    useEffect(() => {
        // tokens are not needed in xunion mode
        if (!xUnionSummary && (mode === "xunion" || mode === "unions") && reportingPeriod && clubs && unions) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading cross union summary...")
            showProgress();

            dataLoader.loadXUnionSummary(clubRequest, cardroom, union, reportingPeriod, (xuSummary) => {
                //success
                setXUnionSummary(xuSummary);
            }, (ex) => {
                //error
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load cross union summary, plese try again...");
            });

        }

    }, [clubRequest.execute, mode, xUnionSummary, clubs, unions, reportingPeriod, retryCounter]);

    function buildSummaries(us, cs, summary, xuSummary) {
        let rows = [];

        let totalTokens = summary.totalAllowedTokens;

        let uMap = [];
        for (var i = 0; i < us.length; i++) {
            if (!uMap[us[i].id]) {
                uMap[us[i].id] = us[i];
            }
        }

        function createSummaryStub(name, union) {
            return {
                name: name,
                union: union,
                winnings: 0,
                fees: 0,
                insurance: 0,
                spinOffset: 0,
                mttRealized: 0,
                mttImplied: 0,
                activeClubCount: 0,
                bubbleProtection: 0,
                leaderboardCost: 0,
                xmttFees: 0,
                xmttPnL: 0,
                jackpotPnL: 0,
                tokens: {
                    used: 0,
                    cost: 0
                },
                mttOverlay: 0,
                xmttDues: 0,
                manager: 0,
                reporting: 0,
                totalExpenses: 0,
                unionDues: 0,
                totalProfit: 0,
                totalEarnings: 0,
                referrals: 0,
                loyaltyRebate: 0,
                chipOffset: 0,
                tax: 0,
                clubs: [],
                clubsThatOwe: [],
                clubsThatAreOwed: [],
                activeClubs: [],
                percentage: 0,
                proportion: 0,
                settlement: 0,
                clubSettlement: 0,
                allowNavigation: true
            };
        }

        function calcEarnings(clubSummary) {
            if (!clubSummary) return 0;
            return (clubSummary.winnings + clubSummary.fees); //.toFixed(2);
        }
        function calcUnionDues(clubSummary) {
            if (!clubSummary) return 0;
            return ((clubSummary.fees * clubSummary.unionDuesRate * -1) - clubSummary.unionDuesBase); //.toFixed(2);
        }
        function calcXMTTDues(clubSummary) {
            if (!clubSummary) return 0;
            return (clubSummary.xmttFees * clubSummary.xmttDuesRate * -1); //.toFixed(2);
        }
        function calcChipOffset(clubSummary) {
            if (!clubSummary) return 0;
            return (calcEarnings(clubSummary) * clubSummary.chipOffsetRate * -1); //.toFixed(2);
        }

        function calcTaxRebate(clubSummary) {
            if (!clubSummary) return 0;

            let taxableAmount = calcEarnings(clubSummary) - clubSummary.xmttPnL + clubSummary.jackpotPnL;
            let winnerTax = taxableAmount > 0 ? taxableAmount * clubSummary.winTaxRate : 0;

            return ((taxableAmount * clubSummary.taxRebateRate * -1) - winnerTax); //.toFixed(2)
        }

        function calcUnionSettlement(uSummary) {
            if (!uSummary) return 0;
            return uSummary.totalEarnings + uSummary.xmttDues + uSummary.chipOffset + uSummary.tax + uSummary.totalExpenses;
        }

        function updateTotals(totalSummary, unionSummary) {
            if (!totalSummary || !unionSummary) return;

            totalSummary.winnings += unionSummary.winnings;
            totalSummary.fees += unionSummary.fees;
            totalSummary.totalEarnings += unionSummary.totalEarnings;
            totalSummary.xmttDues += unionSummary.xmttDues;

            unionSummary.activeClubCount = unionSummary.activeClubs.length;
            totalSummary.activeClubCount += unionSummary.activeClubCount;

            totalSummary.unionDues += unionSummary.unionDues;
            totalSummary.chipOffset += unionSummary.chipOffset;
            totalSummary.tax += unionSummary.tax;
            totalSummary.spinOffset += unionSummary.spinOffset;
            totalSummary.jackpotPnL += unionSummary.jackpotPnL;

            totalSummary.referrals += unionSummary.referrals;
            totalSummary.loyaltyRebate += unionSummary.loyaltyRebate;

            totalSummary.clubSettlement += unionSummary.clubSettlement;
        }

        function updateShareBasedFees(totalSummary, unionSummary) {
            if (!totalSummary || !unionSummary) return;
            unionSummary.percentage = unionSummary.fees / totalSummary.fees;
            unionSummary.proportion = unionSummary.percentage * 100;

            //totalSummary.percentage += unionSummary.percentage; //should equal 1 at the end
            //totalSummary.proportion += unionSummary.proportion; //should equal 100

            unionSummary.tokens.used = totalSummary.tokens.used * unionSummary.percentage;
            unionSummary.tokens.cost = unionSummary.tokens.used * union.tokenUnitCost;

            unionSummary.reporting = totalSummary.reporting * unionSummary.percentage;
            unionSummary.manager = totalSummary.manager * unionSummary.percentage;
            unionSummary.mttOverlay = totalSummary.mttOverlay * unionSummary.percentage;

            unionSummary.totalExpenses = unionSummary.tokens.cost + unionSummary.mttOverlay + unionSummary.xmttDues + unionSummary.manager + unionSummary.reporting;
            totalSummary.totalExpenses += unionSummary.totalExpenses;

            unionSummary.settlement = calcUnionSettlement(unionSummary);
            totalSummary.settlement += unionSummary.settlement;

            unionSummary.totalProfit = unionSummary.settlement - unionSummary.clubSettlement; // unionSummary.settlement - unionSummary.clubSettlement + unionSummary.totalExpenses;
            totalSummary.totalProfit += unionSummary.totalProfit;
        }

        let sMap = [];
        let totals = createSummaryStub("Union Totals", null);

        totals.percentage = summary.feeRatio;
        totals.proportion = totals.percentage * 100;

        totals.reporting = -1 * reportingPeriod.cost * totals.percentage;

        //THE LOGIC IS CORRECT< however, when navigating from PARENT union to CHILD union, this value is modfied by the child union...
        //which is really odd since totals is a local variable....
        totals.mttOverlay = summary.mttOverlay * summary.feeRatio; // * totals.percentage;

        totals.tokens.used = totalTokens * summary.feeRatio;
        totals.tokens.cost = union.tokenUnitCost * totals.tokens.used;
        totals.manager = union.unionManagerFee * -1 * summary.feeRatio;

        let directs = createSummaryStub("Unaffiliated", union);
        directs.allowNavigation = false;


        for (var i = 0; i < cs.length; i++) {
            if (!cs[i] || !cs[i].club) continue;

            let u = uMap[cs[i].club.parentUnionId];
            let s = null;
            if (u) {
                if (u.id === union.id) {
                    s = directs;
                }
                else {
                    if (!sMap[u.id]) {
                        s = createSummaryStub(u.name, u);
                        sMap[u.id] = s;
                        rows.push(s);
                    }
                    s = sMap[u.id];
                }
            }

            if (!s) continue; //this would happen if we navigated to sub union that still has all clubs cached, so we're just skipping clubs that don't belong...

            s.clubs.push(cs[i]);

            s.winnings += cs[i].winnings;
            s.fees += cs[i].fees;
            s.xmttFees += cs[i].xmttFees;
            s.xmttPnL += cs[i].xmttPnL;
            s.jackpotPnL += cs[i].jackpotPnL;
            s.insurance += cs[i].insurance;
            s.spinOffset += cs[i].spinNGoOffset;
            s.mttRealized += cs[i].mttRealized;
            s.mttImplied += cs[i].mttImplied;
            s.bubbleProtection += cs[i].bubbleProtection;
            s.leaderboardCost += cs[i].leaderboardCost;
            s.referrals += cs[i].referralBonus;
            s.loyaltyRebate += cs[i].loyaltyRebate;
            s.clubSettlement += cs[i].settlementAmount;

            cs[i].calc = {
                earnings: calcEarnings(cs[i]),
                unionDues: calcUnionDues(cs[i]),
                xmttDues: calcXMTTDues(cs[i]),
                chipOffset: calcChipOffset(cs[i]),
                taxRebate: calcTaxRebate(cs[i])
            };

            s.totalEarnings += cs[i].calc.earnings;
            s.unionDues += cs[i].calc.unionDues;
            s.xmttDues += cs[i].calc.xmttDues;
            s.chipOffset += cs[i].calc.chipOffset;
            s.tax += cs[i].calc.taxRebate;

            if (cs[i].winnings > 0 || cs[i].fees || cs[i].xmttFees) //add other properties to test for active
            {
                s.activeClubs.push(cs[i]);
            }

            if (cs[i].settlementAmount > 0) {
                s.clubsThatAreOwed.push(cs[i]);
            }
            else if (cs[i].settlementAmount < 0) {
                s.clubsThatOwe.push(cs[i]);
            }


        }

        //update totals & percentages
        if (directs.clubs.length > 0) {
            updateTotals(totals, directs);
        }

        for (var i = 0; i < rows.length; i++) {
            updateTotals(totals, rows[i]);
        }


        //now that totals have been calc'd, we can calculate percentage & update fees based on percentage
        //update totals & percentages
        if (directs.clubs.length > 0) {
            updateShareBasedFees(totals, directs);
        }

        for (var i = 0; i < rows.length; i++) {
            updateShareBasedFees(totals, rows[i]);
        }



        //summary.settlementAmount = totals.settlement;
        if (mode === "self" || mode === "unions" || union.isVirtual) {
            setNetSettlement(totals.settlement);
        }
        else if (mode === "xunion") {
            //these calcs apply only to root level union...
            let net = xuSummary.xmttEarnings;

            net += totals.xmttDues;
            net += totals.totalExpenses;
            net += totals.chipOffset;
            net += totals.tax;

            net -= xuSummary.jackpotPnL;
            net -= totals.mttOverlay;
            net -= xuSummary.bubbleProtection;
            net -= totals.spinOffset;

            setNetSettlement(net);
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                //reverse order
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return 1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return -1; }
                return 0;
            });
        }

        rows.unshift(totals);
        if (directs.activeClubs.length > 0 && rows.length > 1) {
            rows.push(directs);
        }

        return rows;
    }

    useEffect(() => {
        if (!summaries && unions && clubs && summary && (mode == "self" || ((mode === "xunion" || mode === "unions") && xUnionSummary))) {

            setSummaries(buildSummaries(unions, clubs, summary, xUnionSummary));

            progressIndicatorProps.close();
        }

    }, [summaries, xUnionSummary, unions, clubs, summary, mode]);

    //TODO: add tooltips to each heading explaing how it was put together
    return <Card>
        {!progressIndicatorProps.visible ? <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8}>
                    <Card >
                        <SoftBox p={3}>
                            <Grid container xs={12} justifyContent="space-between">
                                {union && reportingPeriod ? <>
                                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        <SoftBox width="100%" textAlign="left" mt={2}>
                                            <SoftBox textAlign="center" width="max-content">
                                                <SoftBox component="img" src={appLogo} width="96px" style={{}} p={1} mb={1} />
                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {reportingPeriod.app.name}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <SoftBox width="100%" textAlign={{ xs: "center", md: "center" }} mt={2}>

                                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                {(mode === "self" || mode === "unions") ? "Settlement" : "Union Responsibility"}
                                            </SoftTypography>

                                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                <MoneyCell value={netSettlement.toFixed(2)} prefix="$" />
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        <SoftBox justifyContent="right" textAlign="right" mt={2}>
                                            <SoftBox ml="auto" textAlign="center" width="max-content">
                                                <SoftBox component="img" src={cardroomLogo} width="96px" style={{}} p={1} mb={1} />
                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {union ? union.name : null}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid></> : null}
                            </Grid>

                            {summaries ? <>
                                {(mode === "self") ? <ReportSummarySelf reportingPeriod={reportingPeriod} summaries={summaries} /> : null}
                                {(mode === "xunion") ? <ReportSummaryXUnion xUnionSummary={xUnionSummary} totalSummary={summaries[0]} /> : null}
                                {(mode === "unions") ? <ReportSummaryUnions unions={unions} clubs={clubs} summaries={summaries} reportingPeriod={reportingPeriod} cardroom={cardroom} /> : null}
                            </>
                                : null}
                        </SoftBox>
                    </Card>


                </Grid>
            </Grid>
        </> : <ProgressIndicator {...progressIndicatorProps} />
        }
        <Divider />
    </Card >;
};

ReportSummary.propTypes = {
    cardroom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    mode: PropTypes.oneOf(["self", "xunion", "unions"])
};

const UnionReport = ({ cardroom, union, reportingPeriod, summary }) => {

    const TabPanel = function (props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <SoftBox sx={{ p: 3 }}>
                        <SoftTypography>{children}</SoftTypography>
                    </SoftBox>
                )}
            </div>
        );
    }

    const a11yProps = function (index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report details...", handleRetry)

    const [context, actions, features] = useCardroomContext(cardroom);

    function supportsSubUnions() {
        if (!union) return false;

        if (union.isVirtual) return false;

        return ((union.features & 16) > 0);
    }

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const [openPaymentsMapDialog, paymentsMapDialogProps] = useDialog();

    function createPaymentMap() {
        openPaymentsMapDialog();
    }

    return <SoftBox>
        {!progressIndicatorProps.visible ? <SoftBox>
            <Grid container spacing={1.5} alignItems="center">
                <Grid item xs={12} md={6} lg={4}>
                    <AppBar position="static">
                        <SoftBox sx={{ ml: 3 }}>
                            {tabValue === "clubs" && reportingPeriod && reportingPeriod.clubs ? <>
                                <ReportingPeriodPaymentsMapDialog {...paymentsMapDialogProps} cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} clubs={reportingPeriod.clubs} />
                                <SoftButton variant="outlined" size="small" color="dark" onClick={createPaymentMap}><Icon size="lg">turn_right</Icon>&nbsp;Optimize Payments</SoftButton>
                            </> : null}
                        </SoftBox>
                    </AppBar>
                </Grid>
                <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
                    <AppBar position="static">
                        <Tabs
                            orientation="horizontal"
                            value={tabValue}
                            onChange={handleSetTabValue}
                            sx={{ background: "transparent" }}>
                            <Tab label="Summary" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">summarize</Icon>} {...a11yProps(0)} />
                            <Tab label="Details" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">dashboard</Icon>} {...a11yProps(1)} value="details" />
                            {supportsSubUnions() ?
                                <Tab label="Unions" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">beach_access</Icon>} {...a11yProps(2)} value="unions" /> : null}
                            <Tab label="Clubs" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">nightlife</Icon>} {...a11yProps(3)} value="clubs" />
                        </Tabs>
                    </AppBar>
                </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>
                    <SoftBox>
                        <TabPanel value={tabValue} index={0}>
                            <ReportSummary cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} summary={summary} mode="xunion" />
                        </TabPanel>
                        <TabPanel value={tabValue} index={"details"}>
                            <ReportSummary cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} summary={summary} mode="self" />
                        </TabPanel>
                        <TabPanel value={tabValue} index={"unions"}>
                            <ReportSummary cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} summary={summary} mode="unions" />
                        </TabPanel>
                        <TabPanel value={tabValue} index={"clubs"}>
                            <ReportUnionClubs cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} />
                        </TabPanel>

                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox> : <ProgressIndicator {...progressIndicatorProps} />}
        <Divider />
    </SoftBox>;
};

UnionReport.defaultProps = {

};

UnionReport.propTypes = {
    cardroom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired
};

export default UnionReport;