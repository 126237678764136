import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { FormControlLabel, Grid, Radio, RadioGroup, Tooltip, tooltipClasses } from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';
import FormField from 'components/Elements/Forms/FormField';
import SoftAvatar from 'components/SoftAvatar';
import FormSwitch from 'components/Elements/Forms/FormSwitch';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { styled } from '@mui/material/styles';


import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useCardroomContext } from 'features';


const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});


const ReferralFeeScheduleEditorForm = ({ cardroom, union, schedule, formData, dense }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { scheduleId, name, description, feeRate, feeThreshold, feeScheduleScheme } = formField;
    const {
        scheduleId: scheduleIdV,
        name: nameV,
        description: descriptionV,
        feeRate: feeRateV,
        feeThreshold: feeThresholdV,
        feeScheduleScheme: feeScheduleSchemeV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);


    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditSchedule() {
        if (!context) return false;

        if (canEditReferralSchedule() === false) return false;

        return context.isAllowed(actions.cardroom.fees.referral.edit);
    }
    

    function canEditReferralSchedule() {
        if (!context) return false;

        // ReferralScheduleEdit = 4,
        if ((union.features & 4) === 0) return false;

        return true;
    }

    function getFeeShemeDisplayText(v) {
        if (v === 0) return "none";
        if (v === 1) return "flat";
        if (v === 2) return "progressive";
        return "none";
    }

    function getFeeSchemeDescription(v) {
        if (v === 0) return "no fees are imputed";
        if (v === 1) return "Fees are imputed as percentage of total earnings";
        if (v === 2) return "Fees are imputed as percentage of earnings above referral threshold.";

        return "unknown";
    }

    function onFeeSchedChanged(e, newValue) {
        let scheme = parseInt(newValue);
        if (isNaN(scheme)) return;
        setFieldValue(feeScheduleScheme.name, scheme);
    }

    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(feeScheduleScheme.name, schedule && schedule.id ? schedule.scheme : 0);
            setFieldValue(feeRate.name, schedule && schedule.id ? schedule.rate * 100 : 0);
            setFieldValue(feeThreshold.name, schedule && schedule.id ? schedule.threshold : 0);

            setFieldValue(name.name, schedule && schedule.id ? schedule.name : "");
            setFieldValue(description.name, schedule && schedule.id ? schedule.description : "");
            setFieldValue(scheduleId.name, schedule ? schedule.id : "");

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    useEffect(() => {
        if (schedule && formDataInitialized) {
            setFormDataInitialized(false);
        }
    }, [schedule])

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                {dense === true ? null :
                    <Grid item xs={12} sm={4} container justifyContent="center">
                        <SoftBox position="relative" height="max-content" mx="auto">
                            <SoftAvatar src={null} size="xxl" variant="rounded" bgColor="dark" />
                        </SoftBox>
                    </Grid>}
                <Grid item xs={12} sm={dense === true ? 12 : 8}>
                    <SoftBox mb={2} textAlign="left">

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {name.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditSchedule()}
                                    type={name.type}
                                    name={name.name}
                                    value={nameV}
                                    placeholder={name.placeholder}
                                    error={errors.name && touched.name}
                                    success={nameV && nameV.length > 0 && !errors.name}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {description.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditSchedule()}
                                    type={description.type}
                                    name={description.name}
                                    value={descriptionV}
                                    placeholder={description.placeholder}
                                    error={errors.description && touched.description}
                                    success={descriptionV && descriptionV.length > 0 && !errors.description}
                                />
                            </Grid>
                        </Grid>


                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {feeScheduleScheme.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography
                                    variant="caption"
                                    textTransform="capitalize">
                                    {canEditSchedule() ? <>
                                        <RadioGroup row={true} defaultValue={feeScheduleSchemeV} value={feeScheduleSchemeV} onChange={onFeeSchedChanged} style={{ marginLeft: "1.25em", marginTop: "3px" }}>
                                            <CustomWidthTooltip title={getFeeSchemeDescription(0)} placement="bottom">
                                                <FormControlLabel value="0" control={<Radio />} label={getFeeShemeDisplayText(0)} />
                                            </CustomWidthTooltip>
                                            <CustomWidthTooltip title={getFeeSchemeDescription(1)} placement="bottom">
                                                <FormControlLabel value="1" control={<Radio />} label={getFeeShemeDisplayText(1)} />
                                            </CustomWidthTooltip>
                                            <CustomWidthTooltip title={getFeeSchemeDescription(2)} placement="bottom">
                                                <FormControlLabel value="2" control={<Radio />} label={getFeeShemeDisplayText(2)} />
                                            </CustomWidthTooltip>
                                        </RadioGroup>
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            color="error">
                                            <ErrorMessage name={feeScheduleScheme.name} />
                                        </SoftTypography>
                                    </> : getFeeShemeDisplayText(feeScheduleSchemeV)}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        {feeScheduleSchemeV != 0 ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {feeRate.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField disabled={!canEditSchedule()}
                                        type={feeRate.type}
                                        name={feeRate.name}
                                        value={feeRateV}
                                        placeholder={feeRate.placeholder}
                                        error={errors.feeRate && touched.feeRate}
                                        success={feeRateV && feeRateV.toString().length > 0 && !errors.feeRate}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {feeThreshold.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField disabled={!canEditSchedule()}
                                        type={feeThreshold.type}
                                        name={feeThreshold.name}
                                        value={feeThresholdV}
                                        placeholder={feeThreshold.placeholder}
                                        error={errors.feeThreshold && touched.feeThreshold}
                                        success={feeThresholdV && feeThresholdV.toString().length > 0 && !errors.feeThreshold}
                                    />
                                </Grid>
                            </Grid>
                        </>
                            : null}

                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;
};

ReferralFeeScheduleEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    dense: PropTypes.bool
};

const ReferralFeeScheduleEditor = ({ cardroom, union, schedule, onClose, dense }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;

    const [retryCounter, setRetryCounter] = useState(0);
    const [scheduleRecord, setScheduleRecord] = useState(null);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving referral schedule...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditSchedule() {
        if (!context) return false;

        if (canEditReferralSchedule() === false) return false;

        return context.isAllowed(actions.cardroom.fees.referral.edit);
    }

    function canEditReferralSchedule() {
        if (!context) return false;

        // ReferralScheduleEdit = 4,
        if ((union.features & 4) === 0) return false;

        return true;
    }

    const handleSubmit = (values, actions) => {

        //alert("submitting");

        if (false === canEditSchedule()) return;

        //construct participant object
        const ns = Object.assign({}, schedule);

        //update schedule...
        if (!ns.id) ns.id = "00000000-0000-0000-0000-000000000000";

        ns.name = values.name;
        ns.description = values.description;
        ns.rate = parseFloat(values.feeRate) / 100;
        ns.threshold = values.feeThreshold;
        ns.scheme = values.feeScheduleScheme;

        setScheduleRecord(ns);
    };


    useEffect(() => {
        if (scheduleRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving referral schedule...")
            showProgress();

            //[HttpPost("{unionId:Guid}/casino/{casinoId:Guid}/schedules/referral", Name = "SaveClubReferralSchedule")]
            execute("POST", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/schedules/referral", scheduleRecord).then((response) => {
                if (response) {
                    //raiseOnTransactionCreated();
                    if (schedule) schedule = Object.assign(schedule, response);
                    else schedule = response;
                }

                setScheduleRecord(null);
                progressIndicatorProps.close();
                raiseOnClose(false);

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save referral schedule, please try again...")
            });
        }

    }, [scheduleRecord, execute, retryCounter]);


    function onCanceled() {
        raiseOnClose(true);
    }

    function raiseOnClose(canceled) {
        if (onClose) {
            onClose(canceled);
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <ReferralFeeScheduleEditorForm cardroom={cardroom} union={union} schedule={schedule} dense={dense} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={onCanceled}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canEditSchedule()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

ReferralFeeScheduleEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    dense: PropTypes.bool
};

export default ReferralFeeScheduleEditor;