
import React, { useState, useEffect } from 'react';
import { apiConfig } from 'config/apiConfig'
import { useTheme } from '@mui/material/styles';

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { Divider, Grid } from '@mui/material';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import defaultCasino from "assets/graphics/logos/default-casino.png";
import PhoneCell from 'components/Elements/DataTable/components/Cells/PhoneCell';
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import DataTable from 'components/Elements/DataTable';

const PartnerParticipantsList = ({ players, staff }) => {
    //drop down is causing re-render & infinite loop...

    function buildTable(players, staff) {
        const schema = {
            columns: [
                //   {
                //       Header: "participant", accessor: "participant", Cell: ({ value: [name, data] }) => (
                //           <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} />
                //       ),
                //   },
                { Header: "role", accessor: "role", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Pay", accessor: "payAmount", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useColorScheme={false} /> },
                { Header: "Collect", accessor: "collectAmount", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useColorScheme={false} /> }
            ],
            rows: []
        };

        function getRoles(p) {
            return "player";
        }

        function formatName(p) {
            return "frank";
        }
        if (players) {
            for (var i = 0; players.length; i++) {
                schema.rows.push({
                    participant: [formatName(players[i]), { color: "dark" }],
                    role: getRoles(players[i]),
                    payAmount: -100, // players[i].owedToParticipant,
                    collectAmount: -100 //players[i].owedByParticipant
                });
            }
        }
        if (staff) {
            for (var i = 0; staff.length; i++) {
                schema.rows.push({
                    participant: [formatName(staff[i]), { color: "dark" }],
                    role: getRoles(staff[i]),
                    payAmount: -99, //staff[i].owedToParticipant,
                    collectAmount: -99 //staff[i].owedByParticipant
                });
            }
        }

        return schema;

    }

    const [table, setTable] = useState(buildTable(players, staff));

    return <DataTable table={table} canSearch={false} showTotalEntries={false} entriesPerPage={{ defaultValue: 1000, visible: false }} isHierarchical={false} />
}

const SessionSummaryReport = ({ cardroom, session, onClose }) => {
    const theme = useTheme();

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading session summary...", handleRetry)

    const cardroomLogo = cardroom && cardroom.hasLogo ? apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png" : defaultCasino;

    function GetTableSchema() {
        const schema = {
            columns: [
                {
                    Header: "partner", accessor: "partner", Cell: ({ value: [name, data] }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} />
                    ),
                },
                { Header: "# of Players", accessor: "playerCount", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "# of Staff", accessor: "staffCount", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Pay", accessor: "payAmount", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useColorScheme={false} /> },
                { Header: "Collect", accessor: "collectAmount", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useColorScheme={false} /> }
            ],
            collapsibleDetailAccessor: "details",
            rows: []
        };

        return schema;
    }

    const [partnerTable, setPartnerTable] = useState(GetTableSchema());

    const [sessionSummary, setSessionSummary] = useState(null);
    const [partners, setPartners] = useState(null);
    const [participants, setParticipants] = useState(null);

    const userRequest = useFetchWithMsal({
        scopes: apiConfig.user.scopes.read,
    });

    const casinoRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    function formatDate(d) {
        let dt = new Date(Date.parse(d));
        return dt.toLocaleString();
    }

    function getDuration(start, end) {

        let startMs = Date.parse(start);
        let endMs = Date.parse(end);

        let ms = endMs - startMs;

        let seconds = Math.floor(ms / 1000);
        ms -= seconds * 1000;
        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        let hours = Math.floor(minutes / 60);
        minutes -= hours * 60;

        let days = Math.floor(hours / 24);
        hours -= days * 24

        let str = "";
        if (days > 0) str = days + ".";

        str += hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');

        return str;
    }

    function shapeSummary(summary) {
        if (!summary) return;
        if (summary.shaped === true) return;

        const tx = {
            buyin: {
                cash: { count: 0, amount: 0 },
                electronic: { count: 0, amount: 0 },
                credit: { count: 0, amount: 0 },
                account: { count: 0, amount: 0 }
            },
            cashout: {
                cash: { count: 0, amount: 0 },
                electronic: { count: 0, amount: 0 },
                credit: { count: 0, amount: 0 },
                account: { count: 0, amount: 0 }
            }
        };

        for (var i = 0; i < summary.transactions.length; i++) {
            var tt = null;

            switch (summary.transactions[i].type) {
                case 1:
                    tt = tx.buyin;
                    break;
                case 2:
                    tt = tx.cashout;
                    break;
            }

            if (tt) {
                var pt = null;
                switch (summary.transactions[i].paymentType) {
                    case 1:
                        pt = tt.cash;
                        break;
                    case 2: // electronic
                        pt = tt.electronic;
                        break;
                    case 3: //credit
                        pt = tt.credit;
                        break;
                    case 4: //account
                        pt = tt.account;
                        break;
                }

                if (pt) {
                    pt.count += summary.transactions[i].count;
                    pt.amount += summary.transactions[i].amount;
                }
            }
        }


        summary.shapedTransactions = tx;

        summary.shaped = true;
        return summary;
    }

    function createTable(summary, partners, participants) {
        const table = partnerTable ? partnerTable : GetTableSchema();

        function sortParticipants(arr) {

            if (!arr || arr.length === 0) return;

            arr.sort(function (a, b) {
                let aName = a.displayName ? a.displayName.toLowerCase() : null;
                let bName = b.displayName ? b.displayName.toLowerCase() : null;

                if (aName < bName) { return -1; }
                if (aName > bName) { return 1; }

                return 0;
            });
        }

        const partnerMap = [];
        const participantMap = [];

        for (var i = 0; i < partners.length; i++) {
            partnerMap[partners[i].id] = partners[i];
        }

        for (var i = 0; i < participants.length; i++) {
            participantMap[participants[i].id] = participants[i];

            //add displayName, avataar & formattedPhone
            participants[i].displayName = formatParticipantName(participants[i]);
            participants[i].avatar = formatParticipantImage(participants[i]);
            participants[i].formattedPhone = formatParticipantPhone(participants[i]);
        }

        const partnerRows = [];
        for (var i = 0; i < summary.participantsWithDebt.length; i++) {
            var partner = partnerMap[summary.participantsWithDebt[i].responsiblePartyId];
            if (!partner.summary) {
                partner.summary = {
                    players: [],
                    staff: [],
                    pay: 0,
                    collect: 0
                };
                partnerRows.push(partner);
            }

            if (participantMap[summary.participantsWithDebt[i].id]) {
                summary.participantsWithDebt[i] = Object.assign(summary.participantsWithDebt[i], participantMap[summary.participantsWithDebt[i].id]);
            }

            if ((summary.participantsWithDebt[i].type & 1) > 0) {
                partner.summary.players.push(summary.participantsWithDebt[i]);
            }
            if ((summary.participantsWithDebt[i].type & 254) > 0) {
                partner.summary.staff.push(summary.participantsWithDebt[i]);
            }

            partner.summary.pay += summary.participantsWithDebt[i].owedToParticipant;
            partner.summary.collect += summary.participantsWithDebt[i].owedByParticipant;

        }

        for (var i = 0; i < partnerRows.length; i++) {

            var row = {
                partner: [partnerRows[i].displayName, { image: null, color: "dark" }],
                playerCount: partnerRows[i].summary.players.length,
                staffCount: partnerRows[i].summary.staff.length,
                payAmount: partnerRows[i].summary.pay,
                collectAmount: partnerRows[i].summary.collect,
                participants: [...partnerRows[i].summary.players, ...partnerRows[i].summary.staff],
                details: <PartnerParticipantsList players={partnerRows[i].summary.players} staff={partnerRows[i].summary.staff} />
            };

            //sort
            sortParticipants(row.participants);
            table.rows.push(row);
        }

        sortParticipants(table.rows);

        return table;
    }

    function formatParticipantName(p) {
        if (!p) return null;
        let name = p.name;
        if (p.properties) {
            name = p.properties.name ? p.properties.name : name;

            if (p.properties.nickName) name += " (" + p.properties.nickName + ")";
        }

        return name;
    }

    function formatParticipantImage(p) {
        if (!p) return null;

        let hasPhoto = p.hasPhoto;
        if (p.properties) {
            hasPhoto = p.properties.hasPhoto ? p.properties.hasPhoto : hasPhoto;
        }

        return hasPhoto ? apiConfig.images.endpoint + "/player/" + p.id + ".jpg" : formatParticipantName(p).substring(0, 1);
    }

    function formatParticipantPhone(p) {
        if (!p) return null;
        let phone = p.phoneNumber;

        if (p.properties) {
            phone = p.properties.phoneNumber ? p.properties.phoneNumber : phone;
        }

        return phone;
        /*
        
        let playerName = players[i].name;
                    
                    let playerHasPhoto = players[i].hasPhoto;
                   
                    if (players[i].properties) {
                        playerName = players[i].properties.name ? players[i].properties.name : playerName;
                        playerPhone = players[i].properties.phoneNumber ? players[i].properties.phoneNumber : playerPhone;
                        playerHasPhoto = players[i].properties.hasPhoto ? players[i].properties.hasPhoto : playerHasPhoto;
                        creditLimit = players[i].properties.creditLimit;
        
                        if (players[i].properties.nickName) playerName += " (" + players[i].properties.nickName + ")";
                    }
        
                    let playerImage = playerHasPhoto ? apiConfig.images.endpoint + "/player/" + players[i].id + ".jpg" : playerName.substring(0, 1);
        
        
        */

    }
    function formatParticipantRoles(p) {
        if (!p) return null;

        let ptype = 0;


        if (p.properties) {
            ptype = p.properties.participantType;
        }
        let ptypeStr = "|";
        if ((ptype & 1) > 0) ptypeStr += "player|";
        if ((ptype & 2) > 0) ptypeStr += "host|";
        if ((ptype & 4) > 0) ptypeStr += "dealer|";
        if ((ptype & 8) > 0) ptypeStr += "chef|";
        if ((ptype & 16) > 0) ptypeStr += "valet|";
        if ((ptype & 32) > 0) ptypeStr += "security|";
        if ((ptype & 64) > 0) ptypeStr += "other|";

        return ptypeStr;
    }

    useEffect(() => {
        if (!sessionSummary) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session summary...")
            showProgress();

            //[HttpGet("{casinoId:Guid}/sessions/{sessionId:Guid}/summary", Name = "GetSessionSummar")]
            casinoRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/summary").then((response) => {
                if (response) {
                    setSessionSummary(shapeSummary(response));
                }
                else {
                    progressIndicatorProps.setMode("warnWithRetry");
                    progressIndicatorProps.setMessage("Session summary is not yet available, please try again...");
                }

            }).catch((e) => {
                if (e.name == 'SyntaxError') {
                    progressIndicatorProps.setMode("warnWithRetry");
                    progressIndicatorProps.setMessage("Session summary is not yet available (2), please try again...")
                }
                else {
                    progressIndicatorProps.setMode("errorWithRetry");
                    progressIndicatorProps.setMessage("Unable to load session summary, please try again...")
                }
            });

        }

    }, [casinoRequest.execute, sessionSummary, retryCounter]);

    useEffect(() => {
        if (sessionSummary && !participants) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session participants...")
            showProgress();

            const list = [];
            const map = [];
            if (sessionSummary.participantsWithDebt) {
                for (var i = 0; i < sessionSummary.participantsWithDebt.length; i++) {
                    if (!map[sessionSummary.participantsWithDebt[i].id]) {
                        list.push(sessionSummary.participantsWithDebt[i].id);
                        map[sessionSummary.participantsWithDebt[i].id] = true;
                    }
                }
            }

            if (list.length > 0) {
                //[HttpPut("venue/{venueId:Guid}/participants", Name = "FillParticipantList")]
                peopleRequest.execute("PUT", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", list).then((response) => {
                    if (response) {
                        setParticipants(response);
                    }
                }).catch((e) => {
                    progressIndicatorProps.setMode("errorWithRetry");
                    progressIndicatorProps.setMessage("Unable to load session participants, please try again...")
                });
            }
            else {
                progressIndicatorProps.close();
            }
        }

    }, [peopleRequest.execute, participants, sessionSummary, retryCounter]);

    useEffect(() => {
        if (sessionSummary && participants && !partners) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session partners...")
            showProgress();

            const list = [];
            const map = [];
            if (sessionSummary.participantsWithDebt) {
                for (var i = 0; i < sessionSummary.participantsWithDebt.length; i++) {
                    if (!map[sessionSummary.participantsWithDebt[i].responsiblePartyId]) {
                        list.push(sessionSummary.participantsWithDebt[i].responsiblePartyId);
                        map[sessionSummary.participantsWithDebt[i].responsiblePartyId] = true;
                    }
                }
            }

            if (list.length > 0) {
                //[HttpPut("venue/{venueId:Guid}/participants", Name = "FillParticipantList")]
                userRequest.execute("PUT", apiConfig.user.endpoint, list).then((response) => {
                    if (response) {
                        setPartners(response);
                        setPartnerTable(createTable(sessionSummary, response, participants));
                        progressIndicatorProps.close();
                    }
                }).catch((e) => {
                    progressIndicatorProps.setMode("errorWithRetry");
                    progressIndicatorProps.setMessage("Unable to load session partners, please try again...")
                });
            }
            else {
                progressIndicatorProps.close();
            }
        }

    }, [userRequest.execute, partners, sessionSummary, participants, retryCounter]);

    return <Grid container justifyContent="center">
        <Grid item xs={12}>
            <ProgressIndicator {...progressIndicatorProps} />
            {progressIndicatorProps.visible || !sessionSummary ? <></> :
                <SoftBox>
                    <Grid container justifyContent="space-between">

                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Started On
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        {formatDate(sessionSummary.startedOn)}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    Ended On
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption">
                                    {formatDate(sessionSummary.endedOn)}
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    Duration
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption">
                                    {getDuration(sessionSummary.startedOn, sessionSummary.endedOn)}
                                </SoftTypography></Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        # of Players
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        {sessionSummary.totalPlayers}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    # of Staff
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption">
                                    {sessionSummary.totalStaff}
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    # of Tables
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption">
                                    ?
                                </SoftTypography></Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Rake
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        <MoneyCell value={sessionSummary.totalRake} useColorScheme={false} />
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    Taxes
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption">
                                    <MoneyCell value={sessionSummary.totalTax} useColorScheme={false} />
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    Promos
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption">
                                    <MoneyCell value={sessionSummary.totalPromos} useColorScheme={false} />
                                </SoftTypography></Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider dark />
                            <Grid container >
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    Count
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    Amount
                                </SoftTypography></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    Count
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    Amount
                                </SoftTypography></Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    Buyins
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                >
                                    {sessionSummary.numberOfBuyins}
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption">
                                    <MoneyCell value={sessionSummary.totalBuyIns} />
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    Cashouts
                                </SoftTypography></Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                >
                                    {sessionSummary.numberOfCashouts}
                                </SoftTypography></Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold">
                                        <MoneyCell value={sessionSummary.totalCashouts} />
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize" ml={3}>
                                        Cash
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        {sessionSummary.shapedTransactions.buyin.cash.count}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption">
                                    <MoneyCell value={sessionSummary.shapedTransactions.buyin.cash.amount} useColorScheme={false} />
                                </SoftTypography></Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize" ml={3}>
                                        Cash
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        {sessionSummary.shapedTransactions.cashout.cash.count}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        <MoneyCell value={sessionSummary.shapedTransactions.cashout.cash.amount} useColorScheme={false} />
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize" ml={3}>
                                        e-Wallet
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        {sessionSummary.shapedTransactions.buyin.electronic.count}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption">
                                    <MoneyCell value={sessionSummary.shapedTransactions.buyin.electronic.amount} useColorScheme={false} />
                                </SoftTypography></Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize" ml={3}>
                                        e-Wallet
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        {sessionSummary.shapedTransactions.cashout.electronic.count}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        <MoneyCell value={sessionSummary.shapedTransactions.cashout.electronic.amount} useColorScheme={false} />
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize" ml={3}>
                                        Marker
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        {sessionSummary.shapedTransactions.buyin.credit.count}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        <MoneyCell value={sessionSummary.shapedTransactions.buyin.credit.amount} useColorScheme={false} />
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize" ml={3}>
                                        Marker
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        {sessionSummary.shapedTransactions.cashout.credit.count}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        <MoneyCell value={sessionSummary.shapedTransactions.cashout.credit.amount} useColorScheme={false} />
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize" ml={3}>
                                        Account
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        {sessionSummary.shapedTransactions.buyin.account.count}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}><SoftTypography
                                    component="label"
                                    variant="caption">
                                    <MoneyCell value={sessionSummary.shapedTransactions.buyin.account.amount} useColorScheme={false} />
                                </SoftTypography></Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize" ml={3}>
                                        Account
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        {sessionSummary.shapedTransactions.cashout.account.count}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption">
                                        <MoneyCell value={sessionSummary.shapedTransactions.cashout.account.amount} useColorScheme={false} />
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container xs={12} mt={2}>
                                {/*<DataTable table={partnerTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} isHierarchical={true} />*/}
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableBody>
                                            <TableRow style={{ backgroundColor: theme.palette.secondary.main }}>
                                                <TableCell colSpan={3} >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Partner
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        # of Players
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        # of Staff
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Pay
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Collect
                                                    </SoftTypography>
                                                </TableCell>
                                            </TableRow>
                                            {partnerTable.rows.map((row) => {
                                                return <>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" colSpan={3}>
                                                            <ParticipantCell image={row.partner[1].image} color={row.partner[1].color || "dark"} name={row.partner[0]} selectable={false} checked={false} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption">
                                                                {row.playerCount}
                                                            </SoftTypography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption">
                                                                {row.staffCount}
                                                            </SoftTypography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <MoneyCell value={row.payAmount} prefix="$" useColorScheme={false} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <MoneyCell value={row.collectAmount} prefix="$" useColorScheme={false} />
                                                        </TableCell>
                                                    </TableRow>
                                                    {row.participants && row.participants.length > 0 ?
                                                        <TableRow style={{ backgroundColor: theme.palette.light.main }}>
                                                            <TableCell>&nbsp;</TableCell>
                                                            <TableCell colSpan={2}>
                                                                <SoftTypography
                                                                    component="label"
                                                                    variant="caption"
                                                                    fontWeight="bold"
                                                                    textTransform="capitalize">
                                                                    Participant
                                                                </SoftTypography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <SoftTypography
                                                                    component="label"
                                                                    variant="caption"
                                                                    fontWeight="bold"
                                                                    textTransform="capitalize">
                                                                    Phone
                                                                </SoftTypography></TableCell>
                                                            <TableCell align="right">
                                                                <SoftTypography
                                                                    component="label"
                                                                    variant="caption"
                                                                    fontWeight="bold"
                                                                    textTransform="capitalize">
                                                                    Role
                                                                </SoftTypography></TableCell>
                                                            <TableCell align="right">
                                                                <SoftTypography
                                                                    component="label"
                                                                    variant="caption"
                                                                    fontWeight="bold"
                                                                    textTransform="capitalize">
                                                                    Owed
                                                                </SoftTypography></TableCell>
                                                            <TableCell align="right">
                                                                <SoftTypography
                                                                    component="label"
                                                                    variant="caption"
                                                                    fontWeight="bold"
                                                                    textTransform="capitalize">
                                                                    Owes
                                                                </SoftTypography></TableCell>
                                                        </TableRow> : null}
                                                    {row.participants.map((p) => {
                                                        return <TableRow>
                                                            <TableCell width="10px">&nbsp;</TableCell>
                                                            <TableCell colSpan={2}>
                                                                <ParticipantCell image={p.avatar} color="dark" name={p.displayName} selectable={false} checked={false} />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <PhoneCell value={p.formattedPhone} obfuscate={true} />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <SoftTypography
                                                                    component="label"
                                                                    variant="caption">
                                                                    {formatParticipantRoles(p)}
                                                                </SoftTypography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <SoftTypography
                                                                    component="label"
                                                                    variant="caption">
                                                                    <MoneyCell value={p.owedToParticipant} prefix="$" useColorScheme={false} />
                                                                </SoftTypography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <SoftTypography
                                                                    component="label"
                                                                    variant="caption">
                                                                    <MoneyCell value={p.owedByParticipant} prefix="$" useColorScheme={false} />

                                                                </SoftTypography>
                                                            </TableCell>
                                                        </TableRow>
                                                    })}

                                                </>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            }
        </Grid>
    </Grid>
};

export default SessionSummaryReport;