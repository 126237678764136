
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


import DataTable from "components/Elements/DataTable";
import MoneyCell from "components/Elements/DataTable/components/Cells/MoneyCell";
import DateCell from "components/Elements/DataTable/components/Cells/DateCell";
import ButtonCell from "components/Elements/DataTable/components/Cells/ButtonCell";
import TextCell from "components/Elements/DataTable/components/Cells/TextCell";
import IconCell from "components/Elements/DataTable/components/Cells/IconCell";
import ImageCell from "components/Elements/DataTable/components/Cells/ImgCell";

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { BootstrapDialog } from "components/Elements/Dialog/common"

import SoftButton from "components/SoftButton";

import { useDialog } from "components/Elements/Dialog/common";
import TransactionDialog from 'layouts/Cardroom/Transaction';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import apple from "assets/graphics/logos/paynet/96px/apple.png";
import cashapp from "assets/graphics/logos/paynet/96px/cashapp.png";
import paypal from "assets/graphics/logos/paynet/96px/paypal.png";
import venmo from "assets/graphics/logos/paynet/96px/venmo.png";
import zelle from "assets/graphics/logos/paynet/96px/zelle.png";


function convertSessionTransactionsToTable(transactions, table) {
    var rows = [];

    function getPayType(v) {
        if (v === 1) return "cash";
        if (v === 2) return "ewallet";
        if (v === 3) return "credit";

        return null;
    }

    function getTxType(v) {
        if (v === 1) return "buyin";
        if (v === 2) return "cashout";
        if (v === 3) return "promo";
        if (v === 4) return "withholding";
        if (v === 5) return "rake";
        if (v === 6) return "seated";
        if (v === 7) return "expense";
        if (v === 8) return "payout";
        if (v === 9) return "tip";

        return "other";
    }

    function getTxStatus(v) {

        if (v === 1) return "pending";
        if (v === 2) return "committed";
        if (v === 3) return "canceled";

        return "other";

    }

    function getSignedAmountValue(txtype, v) {
        if (txtype === 1 || txtype === 4 || txtype === 5)
            return -1 * v;

        return v;
    }

    if (transactions && transactions.length > 0) {
        for (var i = 0; i < transactions.length; i++) {
            if (transactions[i].type === 6) continue; //ignore "seated" transaction...

            let row = {
                id: transactions[i].id,
                type: getTxType(transactions[i].type),
                amount: getSignedAmountValue(transactions[i].type, transactions[i].amount),
                participantRole: transactions[i].particpantType,
                payType: getPayType(transactions[i].paymentType),
                status: getTxStatus(transactions[i].status),
                createdOn: transactions[i].transactionDate,
                eWalletVendor: transactions[i].ePaymentVendor,
                eWalletVendorRefNo: transactions[i].ePaymentVendorReferenceNo,
                eWalletVendorRefImg: transactions[i].ePaymentVendorReferenceImage,
                signature: transactions[i].participantSignature,
                transaction: transactions[i]
            };

            rows.push(row);
        }
    }

    table.rows = rows;

    return table;
}
function GetStaffTransactionsTableSchema(statusIconClickHandler, detailsIconClickHandler) {
    return {
        columns: [
            { Header: "tx type", accessor: "type", Cell: ({ value }) => <TextCell value={value} /> },
            { Header: "amount", accessor: "amount", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useFormatter={true} useColorScheme={true} /> },
            {
                Header: "payment type", accessor: "payType",
                Cell: ({ value, row }) => {
                    let icon = "", color = "text";
                    let valueText = value;
                    let vendor = row.original.eWalletVendor;


                    switch (value) {
                        case "cash":
                            icon = "money";
                            color = "success";
                            break;
                        case "ewallet":
                            icon = "local_atm";
                            color = "success";
                            if (vendor) {

                                switch (vendor) {
                                    case "zelle":
                                        valueText = "Zelle";
                                        //icon =zelle; // 
                                        icon = <SoftBox component="img" src={zelle} width="16px" />;
                                        break;
                                    case "venmo":
                                        valueText = "Venmo";
                                        //icon = venmo; //
                                        icon = <SoftBox component="img" src={venmo} width="16px" />;
                                        break;
                                    case "apple":
                                        valueText = "Apple Pay";
                                        //icon = apple; //
                                        icon = <SoftBox component="img" src={apple} width="16px" />;
                                        break;
                                    case "paypal":
                                        valueText = "PayPal";
                                        //icon = paypal; //
                                        icon = <SoftBox component="img" src={paypal} width="16px" />;
                                        break;
                                    case "cashapp":
                                        valueText = "Cash App";
                                        //icon = cashapp; //
                                        icon = <SoftBox component="img" src={cashapp} width="16px" />;
                                        break;
                                    default:
                                        valueText = vendor ? vendor : valueText;
                                        break;
                                }
                            }
                            break;
                        case "credit":
                            icon = "credit_card";
                            color = "warning";
                            break;
                        default:
                            color = "error";
                            icon = "question_mark";
                            break;
                    }

                    return <IconCell value={valueText} icon={icon} color={color} />; //<ImageCell height="16px" value={valueText} img={icon} width={null} />;
                }
            },
            {
                Header: "status", accessor: "status",
                Cell: ({ value }) => {
                    let icon = "", color = "text";
                    let valueText = value;

                    switch (value) {
                        case "pending":
                            icon = "hourglass_empty_outlined";
                            color = "warning";
                            break;
                        case "committed":
                            icon = "check";
                            color = "success";
                            break;
                        case "canceled":
                            icon = "close_outlined";
                            color = "info";
                            break;
                        default:
                            icon = "question_mark";
                            color = "error";
                            break;
                    }

                    return <IconCell value={valueText} icon={icon} color={color} clickHandler={statusIconClickHandler} />;
                }
            },
            { Header: "created on", accessor: "createdOn", Cell: ({ value }) => <DateCell value={value + "Z"} format="dateAndTime" display="locale" /> },
            //{ Header: "signature", accessor: "signature", Cell: ({ value }) => <ImageCell img={value} height="16px" width={null} /> },
            { Header: "details", accessor: "id", Cell: ({ value, row }) => <ButtonCell id={row && row.original ? row.original : value} onButtonClicked={detailsIconClickHandler} icon="launch" color="secondary" /> }
        ],

        rows: [],
    };
}

function GetEmptySchema() {
    return {
        columns: [],
        rows: []
    }
}

const StaffSessionTransactionsDialog = ({ open, close, cardroom, staff, session }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        onClose={close}
        maxWidth="xl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Staff Session Transactions
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox>
                <StaffSessionTransactions cardroom={cardroom} staff={staff} session={session} />
            </SoftBox>
        </DialogContent>
        <DialogActions>
            <SoftButton onClick={close} variant="outlined" color="info">
                close
            </SoftButton>
        </DialogActions>
    </BootstrapDialog>;
}


StaffSessionTransactionsDialog.defaultProps = {

};

StaffSessionTransactionsDialog.propTypes = {
    cardoom: PropTypes.object.isRequired,
    staff: PropTypes.object.isRequired,
    sessionId: PropTypes.string.isRequired,
};

const StaffSessionTransactions = ({ staff, session, cardroom, disableTableChrome, onTransactionUpdated }) => {

    const [gamesTable, setGamesTable] = useState(GetStaffTransactionsTableSchema(onTransactionStatusClicked, onTransactionDetailsClicked));
    const [transactions, setTransactions] = useState(null);

    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const [openTransactionDialog, openTransactionDialogProps] = useDialog();

    function onTransactionDetailsClicked(item) {
        //alert("show TRANSACTION DETAILS dialog");
        if (item && item.transaction) {
            setSelectedTransaction(item.transaction);
            openTransactionDialog();
        }
    }

    function onTransactionStatusClicked() {
        //alert("show status set dialog");
    }

    function handleTransactionUpdated(tx) {
        setTransactions(null);
        if (onTransactionUpdated) {
            onTransactionUpdated(tx);
        }
    }


    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading transaction history...", handleRetry)

    const entriesPerPage = { defaultValue: disableTableChrome ? 1000 : 10, visible: !disableTableChrome };

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    //load staff sessions

    useEffect(() => {
        if (!transactions) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading transaction history...")
            showProgress();

            execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/participants/" + staff.id + "/transactions/254").then((response) => {
                if (response) {
                    setTransactions(response);
                    //create dataTable
                    //GetStaffTransactionsTableSchema(onTransactionStatusClicked, onTransactionDetailsClicked)
                    setGamesTable(convertSessionTransactionsToTable(response, gamesTable));
                    progressIndicatorProps.close();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load transaction history, please try again...")
            });
        }
    }, [execute, transactions, retryCounter])


    return <Card>
        <Grid container>
            <Grid item xs={12} mr="auto" ml="auto">
                <ProgressIndicator {...progressIndicatorProps} />
                {!progressIndicatorProps.visible ? <DataTable table={gamesTable} showTotalEntries={!disableTableChrome}
                    entriesPerPage={entriesPerPage}
                    pagination={{ color: "dark" }} /> : null}
            </Grid>
        </Grid>
        <TransactionDialog {...openTransactionDialogProps} cardroom={cardroom} session={session} transaction={selectedTransaction} participant={staff} onTransactionUpdated={handleTransactionUpdated} />
    </Card>;
};


StaffSessionTransactions.defaultProps = {
    disableTableChrome: false
};

StaffSessionTransactions.propTypes = {
    cardroom: PropTypes.object.isRequired,
    staff: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    disableTableChrome: PropTypes.bool
};

export { StaffSessionTransactions, StaffSessionTransactionsDialog };