
import { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Doughnut } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import configs from "./configs";
import TransactionByTypeDoughnutChartItem from "./TransactionByTypeDoughnutChartItem";
import { NumericFormat } from "react-number-format";
import { Divider } from "@mui/material";

const TransactionByTypeDoughnutChart = ({ title, count, chart, tooltip }) => {
    const { data, options } = configs(chart.labels || [], chart.datasets || {});

    const renderItems =
        chart.labels && chart.datasets
            ? chart.labels.map((label, key) => (
                <TransactionByTypeDoughnutChartItem
                    color={chart.datasets.backgroundColors ? chart.datasets.backgroundColors[key] : "dark"}
                    title={label}
                    key={label}
                    percentage={<NumericFormat value={chart.datasets.data ? chart.datasets.data[key] : 0} displayType="text" thousandSeparator={true} prefix={count.isMoney ? "$" : ""} />}
                    hasBorder={key !== chart.labels.length - 1}
                />
            ))
            : null;

    return (
        <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <SoftTypography variant="h6">{title}</SoftTypography>
                {/*
                <Tooltip title={tooltip} placement="bottom" arrow>
                    <SoftButton variant="outlined" color="secondary" size="small" circular iconOnly>
                        <Icon>priority_high</Icon>
                    </SoftButton>
                </Tooltip>
                */}
            </SoftBox>
            <SoftBox p={2}>
                {useMemo(
                    () => (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={5}>
                                <SoftBox textAlign="center" position="relative" height={{ xs: "143px", sm: "220px" }}>
                                    <SoftBox height={{ xs: "160px", sm: "240px" }} style={{ minWidth: "185px" }} mt={{ xs: 0, sm: 0 }} mb={{ xs: 1, sm: 1 }}>
                                        <Doughnut data={data} options={options} />
                                    </SoftBox>
                                    <SoftBox
                                        mt={{ xs: 0, sm: -18.25 }}
                                        position="relative"
                                        top={{ xs: "-8.25rem", sm: 0 }}
                                    >
                                        <SoftTypography variant="h4" fontWeight="medium" color={count.color}>
                                                <NumericFormat value={count.number} displayType="text" thousandSeparator={true} prefix={count.isMoney ? "$" : ""} />
                                        </SoftTypography>
                                        <SoftTypography
                                            variant="button"
                                            color="text"
                                            textTransform="uppercase"
                                            fontWeight="medium"
                                        >
                                            {count.text}
                                        </SoftTypography>
                                    </SoftBox>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} sm={7} mt="auto" mb="auto">
                                {renderItems}
                            </Grid>
                        </Grid>
                    ),
                    [chart]
                )}
            </SoftBox>
            <Divider dark />
        </Card>
    );
};


TransactionByTypeDoughnutChart.defaultProps = {
    tooltip: "",
};

// Typechecking props for the ReportsDoughnutChart
TransactionByTypeDoughnutChart.propTypes = {
    title: PropTypes.string.isRequired,
    count: PropTypes.shape({
        number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string.isRequired,
        isMoney: PropTypes.bool.isRequired
    }).isRequired,
    chart: PropTypes.shape({
        labels: PropTypes.arrayOf(PropTypes.string).isRequired,
        datasets: PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string])
        ).isRequired,
    }).isRequired,
    tooltip: PropTypes.string,
};



export default TransactionByTypeDoughnutChart;