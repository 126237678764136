
import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import DataTable from 'components/Elements/DataTable';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import { Card } from '@mui/material';
import IconCell from 'components/Elements/DataTable/components/Cells/IconCell';
import { useDialog } from 'components/Elements/Dialog/common';
import UnionEditorDialog from '../SubUnion';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';

const UnionUnions = ({ cardroom, union }) => {

    function prepareUnionsForPresentation(unions, table) {
        let rows = [];

        for (var i = 0; i < unions.length; i++) {
            let unionImage = unions[i].name.substring(0, 1);

            rows.push({
                name: [unions[i].name, { image: unionImage }],
                displayName: unions[i].name,
                displayNameNoCase: unions[i].name.toLowerCase(),
                appUnionId: unions[i].appUnionId,
                playerCount: unions[i].playerCount,
                agentCount: unions[i].agentCount,
                clubCount: unions[i].clubCount,
                sourceAppName: unions[i].app ? unions[i].app.name : "None",
                union: unions[i]
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetEmptySchema(manageClubsHandler) {

        let schema = {
            columns: [
                {
                    Header: "Union",
                    accessor: "name",
                    Cell: ({ value: [name, data], row }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} onClick={manageClubsHandler} participant={row && row.original && row.original.union ? row.original.union : null} />
                    ),
                },
                { Header: "Union Id", accessor: "appUnionId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "# Clubs", accessor: "clubCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "# Players", accessor: "playerCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "# Agents", accessor: "agentCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "Source", accessor: "sourceAppName", Cell: ({ value }) => <TextCell value={value} /> }
            ],
            rows: []
        };

        //if (manageClubsHandler) {
        //    schema.columns.push({ Header: "Manage", accessor: "union", Cell: ({ value, row }) => <IconCell icon="manage_accounts" color="text" clickHandler={manageClubsHandler} id={row && row.original ? row.original.union : null} /> });
        //}

        return schema;
    }

    const [unions, setUnions] = useState(null);
    const [unionsTable, setUnionsTable] = useState(null);
    const [currentUnion, setCurrentUnion] = useState(null);

    const [openUnionEditorDialog, openUnionEditorDialogProps] = useDialog();
    const [context, actions, features] = useCardroomContext(cardroom);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading unions...", handleRetry)

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    function manageUnionDetails(u) {
        if (u) {
            setCurrentUnion(u);

            if (canManageUnion()) openUnionEditorDialog();
        }
    }
    function canManageUnion() {
        if (!context) return false;

        return true;
    }

    useEffect(() => {
        if (!unions) {

            if (union.unions) {
                setUnions(union.unions);
                setUnionsTable(null);
                return;
            }

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading unions...")
            showProgress();
            //[HttpGet("{unionId:Guid}/casino/{casinoId:Guid}/unions", Name = "GetUnionUnions")]
            clubRequest.execute("GET", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/unions").then((response) => {
                if (response) {
                    union.unions = response;
                    setUnions(response);
                    setUnionsTable(null);
                }

            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load unions, please try again...")
            });
        }
    }, [clubRequest.execute, unions, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (unions && !unionsTable) {
            setUnionsTable(prepareUnionsForPresentation(unions, GetEmptySchema(canManageUnion ? manageUnionDetails : null)));
            progressIndicatorProps.close();
        }
    }, [unions, unionsTable]);

    return <Card>
        <UnionEditorDialog {...openUnionEditorDialogProps} cardroom={cardroom} union={union} subunion={currentUnion} />
        {(cardroom && unions) && !progressIndicatorProps.visible ? <>
            {unionsTable ?
                <DataTable table={unionsTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} pagination={{ color: "dark" }} /> : null}
        </> : <ProgressIndicator {...progressIndicatorProps} />}
    </Card>;
};

export default UnionUnions