
import React from 'react';
import PropTypes from "prop-types";
import SoftTypography from 'components/SoftTypography';
import { Card, Grid } from '@mui/material';

const PremiumServiceSubscriptionRequired = ({ service }) => {
    return <Card sx={{ height: 375 }} p={1}>
        <Grid container xs={12} justifyContent="center" alignItems="center">
            <Grid item xs={12} textAlign={"center"}>

                <SoftTypography
                    component="h2"                    
                    color="info">
                    Premium subscription is required to access {service}
                </SoftTypography>

            </Grid>
        </Grid>
    </Card>
};

PremiumServiceSubscriptionRequired.propTypes = {
    service: PropTypes.string.isRequired
};

export default PremiumServiceSubscriptionRequired;