import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BootstrapDialog } from "components/Elements/Dialog/common"

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import ParticipantCell from "components/Elements/DataTable/components/Cells/ParticipantCell";
import PhoneCell from "components/Elements/DataTable/components/Cells/PhoneCell";


import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import { Card, Grid } from '@mui/material';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';

const CasinoDebtSheetView = ({ cardroom }) => {

    const theme = useTheme();

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading debt details...", handleRetry)

    function createPartnerTable(partners, participants, accounts) {
        const table = GetTableSchema();

        function sortParticipants(arr) {

            if (!arr || arr.length === 0) return;

            arr.sort(function (a, b) {
                let aName = a.displayName ? a.displayName.toLowerCase() : null;
                let bName = b.displayName ? b.displayName.toLowerCase() : null;

                if (aName < bName) { return -1; }
                if (aName > bName) { return 1; }

                return 0;
            });
        }

        const accountMap = [];
        const partnerMap = [];
        const participantMap = [];

        for (var i = 0; i < partners.length; i++) {
            partnerMap[partners[i].id] = partners[i];
        }
        for (var i = 0; i < accounts.length; i++) {
            accountMap[accounts[i].accountHolderId] = accounts[i];
        }


        const partnerRows = [];

        for (var i = 0; i < participants.length; i++) {
            participantMap[participants[i].id] = participants[i];

            participants[i].account = accountMap[participants[i].id];
            //add displayName, avataar & formattedPhone
            participants[i].displayName = formatParticipantName(participants[i]);
            participants[i].avatar = formatParticipantImage(participants[i]);
            participants[i].formattedPhone = formatParticipantPhone(participants[i]);

            participants[i].owedToParticipant = participants[i].account.accountsReceivable;
            participants[i].owedByParticipant = participants[i].account.accountsPayable;

            var partner = partnerMap[participants[i].properties.responsiblePartnerId];

            if (!partner.summary) {
                partner.summary = {
                    players: [],
                    staff: [],
                    pay: 0,
                    collect: 0
                };
                partnerRows.push(partner);
            }

            if ((participants[i].properties.participantType & 1) > 0) {
                partner.summary.players.push(participants[i]);
            }
            if ((participants[i].properties.participantType & 254) > 0) {
                partner.summary.staff.push(participants[i]);
            }

            partner.summary.pay += participants[i].account.accountsReceivable;
            partner.summary.collect += participants[i].account.accountsPayable;
        }


        for (var i = 0; i < partnerRows.length; i++) {

            var row = {
                partner: [partnerRows[i].displayName, { image: null, color: "dark" }],
                playerCount: partnerRows[i].summary.players.length,
                staffCount: partnerRows[i].summary.staff.length,
                payAmount: partnerRows[i].summary.pay,
                collectAmount: partnerRows[i].summary.collect,
                participants: [...partnerRows[i].summary.players, ...partnerRows[i].summary.staff],
                details: null
            };

            //sort
            sortParticipants(row.participants);
            table.rows.push(row);
        }

        sortParticipants(table.rows);

        return table;
    }
    function GetTableSchema() {
        const schema = {
            columns: [
                {
                    Header: "partner", accessor: "partner", Cell: ({ value: [name, data] }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} />
                    ),
                },
                { Header: "# of Players", accessor: "playerCount", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "# of Staff", accessor: "staffCount", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Pay", accessor: "payAmount", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useColorScheme={false} /> },
                { Header: "Collect", accessor: "collectAmount", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useColorScheme={false} /> }
            ],
            collapsibleDetailAccessor: "details",
            rows: []
        };

        return schema;
    }

    function formatParticipantName(p) {
        if (!p) return null;
        let name = p.name;
        if (p.properties) {
            name = p.properties.name ? p.properties.name : name;

            if (p.properties.nickName) name += " (" + p.properties.nickName + ")";
        }

        return name;
    }
    function formatParticipantImage(p) {
        if (!p) return null;

        let hasPhoto = p.hasPhoto;
        if (p.properties) {
            hasPhoto = p.properties.hasPhoto ? p.properties.hasPhoto : hasPhoto;
        }

        return hasPhoto ? apiConfig.images.endpoint + "/player/" + p.id + ".jpg" : formatParticipantName(p).substring(0, 1);
    }
    function formatParticipantPhone(p) {
        if (!p) return null;
        let phone = p.phoneNumber;

        if (p.properties) {
            phone = p.properties.phoneNumber ? p.properties.phoneNumber : phone;
        }

        return phone;
    }
    function formatParticipantRoles(p) {
        if (!p) return null;

        let ptype = 0;


        if (p.properties) {
            ptype = p.properties.participantType;
        }
        let ptypeStr = "|";
        if ((ptype & 1) > 0) ptypeStr += "player|";
        if ((ptype & 2) > 0) ptypeStr += "host|";
        if ((ptype & 4) > 0) ptypeStr += "dealer|";
        if ((ptype & 8) > 0) ptypeStr += "chef|";
        if ((ptype & 16) > 0) ptypeStr += "valet|";
        if ((ptype & 32) > 0) ptypeStr += "security|";
        if ((ptype & 64) > 0) ptypeStr += "other|";

        return ptypeStr;
    }


    const [partnerTable, setPartnerTable] = useState(null);
    const [partners, setPartners] = useState(cardroom && cardroom.partners ? cardroom.partners : null);
    const [partnerIdList, setPartnerIdList] = useState(null);

    const [participants, setParticipants] = useState(cardroom && cardroom.participants ? cardroom.participants : null);
    const [participantsIdList, setParticipantsIdList] = useState(null);

    const [accounts, setAccounts] = useState(cardroom && cardroom.accounts ? cardroom.accounts : null);

    const userRequest = useFetchWithMsal({
        scopes: apiConfig.user.scopes.read,
    });

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    useEffect(() => {
        if (!accounts) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading accounts...")
            showProgress();

            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/accounts/1").then((response) => {
                if (response) {
                    const arr = [];
                    const plist = [];
                    for (var i = 0; i < response.length; i++) {
                        if (response[i].accountsReceivable !== 0 || response[i].accountsPayable !== 0) {
                            arr.push(response[i]);
                            plist.push(response[i].accountHolderId);
                        }
                    }
                    if (arr.length === 0) {
                        progressIndicatorProps.close();
                    }
                    else {
                        setAccounts(arr);
                        setParticipantsIdList(plist);
                    }
                }
                else {
                    progressIndicatorProps.close();
                }

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load accounts, please try again...")
            });

        }
    }, [accountRequest.execute, accounts, retryCounter]);

    useEffect(() => {
        if (!participants && participantsIdList && accounts) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading participants...")
            showProgress();

            // [HttpPut("venue/{venueId:Guid}/participants", Name = "FillParticipantList")]
            peopleRequest.execute("PUT", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", participantsIdList).then((response) => {
                if (response) {
                    const arr = [];
                    const plist = [];
                    const pmap = [];
                    for (var i = 0; i < response.length; i++) {
                        arr.push(response[i]);
                        if (response[i].properties && !pmap[response[i].properties.responsiblePartnerId]) {
                            plist.push(response[i].properties.responsiblePartnerId);
                            pmap[response[i].properties.responsiblePartnerId] = response[i].properties.responsiblePartnerId;
                        }
                    }
                    if (arr.length === 0) {
                        progressIndicatorProps.close();
                    }
                    else {
                        setParticipants(arr);
                        setPartnerIdList(plist);
                    }
                }
                else {
                    progressIndicatorProps.close();
                }

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load participants, please try again...")
            });

        }
    }, [peopleRequest.execute, accounts, participantsIdList, participants, retryCounter]);

    useEffect(() => {
        if (!partners && partnerIdList && participants && accounts) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading partners...")
            showProgress();

            // [HttpPut("venue/{venueId:Guid}/participants", Name = "FillParticipantList")]
            userRequest.execute("PUT", apiConfig.user.endpoint, partnerIdList).then((response) => {
                if (response) {
                    //merge partners & build table
                    setPartnerTable(createPartnerTable(response, participants, accounts));
                }
                progressIndicatorProps.close();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load partners, please try again...")
            });

        }
    }, [userRequest.execute, accounts, participants, partnerIdList, partners, retryCounter]);



    return <Grid container justifyContent="center">
        <Grid item xs={12}>
            <ProgressIndicator {...progressIndicatorProps} />
            {progressIndicatorProps.visible ? <></> :
                <SoftBox>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} >
                            <Grid container xs={12} mt={2}>
                                {partnerTable ?
                                    <TableContainer component={Paper}>
                                        <Table aria-label="customized table">
                                            <TableBody>
                                                <TableRow style={{ backgroundColor: theme.palette.secondary.main }}>
                                                    <TableCell colSpan={3} >
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                            color="light">
                                                            Partner
                                                        </SoftTypography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                            color="light">
                                                            # of Players
                                                        </SoftTypography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                            color="light">
                                                            # of Staff
                                                        </SoftTypography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                            color="light">
                                                            Pay
                                                        </SoftTypography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                            color="light">
                                                            Collect
                                                        </SoftTypography>
                                                    </TableCell>
                                                </TableRow>
                                                {partnerTable.rows.map((row) => {
                                                    return <>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row" colSpan={3}>
                                                                <ParticipantCell image={row.partner[1].image} color={row.partner[1].color || "dark"} name={row.partner[0]} selectable={false} checked={false} />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <SoftTypography
                                                                    component="label"
                                                                    variant="caption">
                                                                    {row.playerCount}
                                                                </SoftTypography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <SoftTypography
                                                                    component="label"
                                                                    variant="caption">
                                                                    {row.staffCount}
                                                                </SoftTypography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <MoneyCell value={row.payAmount} prefix="$" useColorScheme={false} />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <MoneyCell value={row.collectAmount} prefix="$" useColorScheme={false} />
                                                            </TableCell>
                                                        </TableRow>
                                                        {row.participants && row.participants.length > 0 ?
                                                            <TableRow style={{ backgroundColor: theme.palette.light.main }}>
                                                                <TableCell>&nbsp;</TableCell>
                                                                <TableCell colSpan={2}>
                                                                    <SoftTypography
                                                                        component="label"
                                                                        variant="caption"
                                                                        fontWeight="bold"
                                                                        textTransform="capitalize">
                                                                        Participant
                                                                    </SoftTypography>
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <SoftTypography
                                                                        component="label"
                                                                        variant="caption"
                                                                        fontWeight="bold"
                                                                        textTransform="capitalize">
                                                                        Phone
                                                                    </SoftTypography></TableCell>
                                                                <TableCell align="right">
                                                                    <SoftTypography
                                                                        component="label"
                                                                        variant="caption"
                                                                        fontWeight="bold"
                                                                        textTransform="capitalize">
                                                                        Role
                                                                    </SoftTypography></TableCell>
                                                                <TableCell align="right">
                                                                    <SoftTypography
                                                                        component="label"
                                                                        variant="caption"
                                                                        fontWeight="bold"
                                                                        textTransform="capitalize">
                                                                        Owed
                                                                    </SoftTypography></TableCell>
                                                                <TableCell align="right">
                                                                    <SoftTypography
                                                                        component="label"
                                                                        variant="caption"
                                                                        fontWeight="bold"
                                                                        textTransform="capitalize">
                                                                        Owes
                                                                    </SoftTypography></TableCell>
                                                            </TableRow> : null}
                                                        {row.participants.map((p) => {
                                                            return <TableRow>
                                                                <TableCell width="10px">&nbsp;</TableCell>
                                                                <TableCell colSpan={2}>
                                                                    <ParticipantCell image={p.avatar} color="dark" name={p.displayName} selectable={false} checked={false} />
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <PhoneCell value={p.formattedPhone} obfuscate={true} />
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <SoftTypography
                                                                        component="label"
                                                                        variant="caption">
                                                                        {formatParticipantRoles(p)}
                                                                    </SoftTypography>
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <SoftTypography
                                                                        component="label"
                                                                        variant="caption">
                                                                        <MoneyCell value={p.owedToParticipant} prefix="$" useColorScheme={false} />
                                                                    </SoftTypography>
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <SoftTypography
                                                                        component="label"
                                                                        variant="caption">
                                                                        <MoneyCell value={p.owedByParticipant} prefix="$" useColorScheme={false} />

                                                                    </SoftTypography>
                                                                </TableCell>
                                                            </TableRow>
                                                        })}

                                                    </>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer> : <SoftBox>
                                        <SoftTypography>
                                            No debt information found...
                                        </SoftTypography>
                                    </SoftBox>}
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            }
        </Grid>
    </Grid>
};

const DebtServiceDialog = ({ open, close, cardroom }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    function onDialogClosureRequested() {
        close();
    }

    function onHouseTransactionAdded() {
        onDialogClosureRequested();
    }


    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            onClose={close}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="xl">
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
                <SoftBox>
                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        Pending Debt
                    </SoftTypography>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>
                <Card style={{ minHeight: 200 }} mt={3}>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <SoftBox pb={0} px={2} textAlign="center" lineHeight={1.25}>
                                <CasinoDebtSheetView cardroom={cardroom} />
                            </SoftBox></Grid>
                    </Grid>
                </Card>
            </DialogContent>
            <DialogActions>
                <SoftButton onClick={close} variant="outlined" color="secondary">
                    close
                </SoftButton>
            </DialogActions>
        </BootstrapDialog >
    );

};

export {
    DebtServiceDialog
};