import form from "./form";


const {
    formField: {
        invitationId,
        sendTo,
        protocol,
        role
    },
} = form;

const initialValues = {
    [invitationId.name]: "",
    [sendTo.name]: "",
    [protocol.name]: 1,
    [role.name]: 0
};

export default initialValues;
