import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { scheduleId, name, description, feeRate, feeThreshold, feeScheduleScheme },
} = form;



const validations = {
    editor: Yup.object().shape({
        //[scheduleId.name]: Yup.string(),
        [name.name]: Yup.string().required(name.errorMsg),
        //[description.name]: Yup.string(),
        [feeScheduleScheme.name]: Yup.number().oneOf([0, 1, 2]).required(feeScheduleScheme.errorMsg),
        [feeRate.name]: Yup.number().when([feeScheduleScheme.name], {
            is: (scheduleV) => scheduleV > 0,
            then: Yup.number().min(0.0001, "Referral rate must be between 0 and 100%.").max(100, "Referral rate must be between 0 and 100%.").required(feeRate.errorMsg),
        }),
        [feeThreshold.name]: Yup.number().when([feeScheduleScheme.name], {
            is: (scheduleV) => scheduleV > 0,
            then: Yup.number().min(0, "Referral exemption cannot be negative.").required(feeThreshold.errorMsg),
        })
    }),

};

export default validations;