import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { type, amount, tableNo, note, provider, method, providerRef, providerRefImage, providerOther, providerRefMethod,
        reimburse, reimburseParticipantType, reimburseParticipant, reimburseParticipantMask },
} = form;



const validations = {
    //add logic to account for markerBalance
    rake: Yup.object().shape({
        [amount.name]: Yup.number().min(1, amount.errorMsg),
        [tableNo.name]: Yup.number().min(1)
    }),
    expenses: Yup.object().shape({
        [amount.name]: Yup.number().min(1, amount.errorMsg),
        [note.name]: Yup.string().min(4, "Description must be at least 4 chars").required(note.errorMsg),
        //[method.name]: Yup.string().oneOf(["cash", "electronic", "credit"]).test("creditOnlyForReimburse", "Invalid payment method", function (value, ctx) {
        //    return (value === "credit" && ctx.parent.reimburse);
        //}),
        [provider.name]: Yup.string().when([method.name], {
            is: (methodV) => (methodV === "electronic"),
            then: Yup.string().required(provider.errorMsg)
        }),
        [providerOther.name]: Yup.string().when([method.name, provider.name], {
            is: (methodV, providerV) => (methodV === "electronic" && providerV === "other"),
            then: Yup.string().required(providerOther.errorMsg)
        }),
        [providerRef.name]: Yup.string().when([method.name, providerRefMethod.name], {
            is: (methodV, refMethodV) => methodV === "electronic" && refMethodV === "number",
            then: Yup.string().required(providerRef.errorMsg)
        }),
        [providerRefImage.name]: Yup.string().when([method.name, providerRefMethod.name], {
            is: (methodV, refMethodV) => methodV === "electronic" && refMethodV === "image",
            then: Yup.string().required(providerRef.errorMsg)
        }),
        [reimburseParticipant.name]: Yup.string().when([reimburse.name, reimburseParticipantType.name], {
            is: (reimburseV, reimburseParticipantTypeV) => reimburseV === true,
            then: Yup.string().required(reimburseParticipant.errorMsg)
        }),
        [reimburseParticipantMask.name]: Yup.number().when([reimburse.name], {
            is: (reimburseV) => reimburseV === true,
            then: Yup.number().min(1).required("Invalid participant")
        }),
    })

};

export default validations;