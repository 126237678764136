import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { AppBar, FormControlLabel, Grid, Icon, Radio, RadioGroup, Tab, Tabs, Tooltip, tooltipClasses } from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';
import FormField from 'components/Elements/Forms/FormField';
import SoftAvatar from 'components/SoftAvatar';
import FormSwitch from 'components/Elements/Forms/FormSwitch';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { styled } from '@mui/material/styles';


import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useCardroomContext } from 'features';
import { InvitationsEditor } from 'components/Elements/Invitation/Editor';




const TabPanel = function (props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <SoftBox >
                    <SoftTypography>{children}</SoftTypography>
                </SoftBox>
            )}
        </div>
    );
}

const a11yProps = function (index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});


const UnionEditorForm = ({ cardroom, union, subunion, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { unionId, name, appUnionId, featuresFlag } = formField;
    const { unionId: unionIdV, name: nameV, appUnionId: appUnionIdV, featuresFlag: featuresFlagV } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditUnion() {
        if (!context) return false;

        return context.isAllowed(actions.club.subunion.edit);
    }

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading unions...", handleRetry)

    useEffect(() => {
        if (!formDataInitialized && subunion) {

            setFieldValue(unionId.name, subunion.id);
            setFieldValue(name.name, subunion.name);
            setFieldValue(featuresFlag.name, parseInt(subunion.features));
            setFieldValue(appUnionId.name, subunion.appUnionId);

            setFormDataInitialized(true);
        }
    }, [formDataInitialized, subunion]);


    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={null} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        {formDataInitialized || !subunion ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {name.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField disabled={!canEditUnion()}
                                        type={name.type}
                                        name={name.name}
                                        value={nameV}
                                        placeholder={name.placeholder}
                                        error={errors.name && touched.name}
                                        success={nameV && nameV.length > 0 && !errors.name}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid container xs={12}>
                                    <Grid item xs={12}>
                                        <SoftBox mb={1} mt={0} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                Union Features
                                            </SoftTypography>
                                            <SoftBox mt={0.75}>
                                                <SoftTypography component="div" variant="caption" color="error">
                                                    <ErrorMessage name={featuresFlag.name} />
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12}>
                                    <Grid item xs={6}>
                                        <SoftBox ml={0.5} mb={0.25}>
                                            <FormSwitch label="Manage Club Fees" readOnly={!canEditUnion()}
                                                name={featuresFlag.name}
                                                value={featuresFlagV}
                                                mask={3}
                                                variant="bit"
                                                {...formData} />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SoftBox ml={0.5} mb={0.25}>
                                            <FormSwitch label="Manage Referral Fees" readOnly={!canEditUnion()}
                                                name={featuresFlag.name}
                                                value={featuresFlagV}
                                                mask={12}
                                                variant="bit"
                                                {...formData} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>

                                <Grid container xs={12}>
                                    <Grid item xs={6}>
                                        <SoftBox ml={0.5} mb={0.25}>
                                            <FormSwitch label="Manage Sub Unions" readOnly={!canEditUnion()}
                                                name={featuresFlag.name}
                                                value={featuresFlagV}
                                                mask={16}
                                                variant="bit"
                                                {...formData} />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={6}>

                                    </Grid>
                                </Grid>
                            </Grid>

                            {subunion && subunion.id && false ?
                                <Grid container xs={12}>
                                    <Grid item xs={12} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                MANAGE SUB UNION MEMBER CLUBS
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <ProgressIndicator {...progressIndicatorProps} />
                                        {!progressIndicatorProps.visible ?
                                            <>LIST BOX</>
                                            : null}
                                    </Grid>
                                </Grid>
                                : null}
                        </> : null}
                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;
};

UnionEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    subunion: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired
};

const UnionSubEditor = ({ cardroom, union, subunion, onClose, onUnionAdded }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;

    const [retryCounter, setRetryCounter] = useState(0);
    const [subUnionRecord, setSubUnionRecord] = useState(null);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving union...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditUnion() {
        if (!context) return false;

        if (!union) return false;

        if ((union.features & 16) === 0) return false;

        return context.isAllowed(actions.club.subunion.edit);
    }


    const handleSubmit = (values, actions) => {

        if (false === canEditUnion()) return;

        var su = {
            id: values.unionId || "00000000-0000-0000-0000-000000000000",
            name: values.name,
            appUnionId: values.appUnionId,
            isVirtual: true,
            features: values.featuresFlag,
            app: {
                id: "3C5B3BB9-D11B-4B63-BB60-93AD5AA8ECFF", //gambooler guid
                name: "Gambooler",
                tokenName: "Token"
            }
        };

        setSubUnionRecord(su);
    };


    useEffect(() => {
        if (subUnionRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving union...")
            showProgress();

            //  [HttpPost("{unionId:Guid}/casino/{casinoId:Guid}/unions", Name = " SaveUnionUnion")]
            execute("POST", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/unions", subUnionRecord).then((response) => {
                let isNewUnion = (!subunion);

                if (response) {
                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }

                    if (!subunion) subunion = {};
                    subunion = Object.assign(subunion, response);
                }

                setSubUnionRecord(null);
                progressIndicatorProps.close();

                if (isNewUnion && union.unions) {
                    union.unions.push(subunion);
                }

                raiseOnClose(isNewUnion);

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save union, please try again...")
            });
        }

    }, [subUnionRecord, execute, retryCounter]);


    function raiseOnClose(changes) {

        if (changes && onUnionAdded) {
            onUnionAdded();
        }

        if (onClose) {
            onClose();
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <UnionEditorForm cardroom={cardroom} union={union} subunion={subunion} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canEditUnion()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};


const UnionEditor = ({ cardroom, union, subunion, onClose, onUnionAdded }) => {


    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);
    return <SoftBox>
        <Grid container xs={12} minHeight="400px">
            <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
                <AppBar position="static">
                    <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue} sx={{ background: "transparent" }}>
                        <Tab label="Properties" icon={<Icon fontSize="small" color="inherit">contact_phone</Icon>}  {...a11yProps(0)} />
                        <Tab label="Invitations" icon={<Icon fontSize="small" color="inherit">person_add</Icon>}  {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }} >
                <SoftBox>
                    <TabPanel value={tabValue} index={0}>
                        <Grid item xs={12}>
                            <SoftBox mb={2} textAlign="left">
                                <UnionSubEditor cardroom={cardroom} union={union} subunion={subunion} onClose={onClose} onUnionAdded={onUnionAdded} />
                            </SoftBox>
                        </Grid>

                    </TabPanel>

                    <TabPanel value={tabValue} index={1}>
                        <Grid item xs={12}>
                            <SoftBox mb={2} textAlign="left">
                                <InvitationsEditor cardroom={cardroom} union={subunion} dense={true} />
                            </SoftBox>
                        </Grid>

                    </TabPanel>

                </SoftBox>

            </Grid>
        </Grid>
    </SoftBox >;

}

UnionEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    subunion: PropTypes.object.isRequired
};

export default UnionEditor;