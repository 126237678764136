
const form = {
    formId: "cardroom-editor-form",
    formField: {
        cardroomId: {
            name: "cardroomId",
            type: "hidden"
        },
        type: {
            name: "type",
            type: "number"
        },
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "name",
            errorMsg: "Name is required.",
        },
        logo: {
            name: "logo",
            label: "",
            type: "text",
            placeholder: "logo",
            errorMsg: "",
        },
        phoneNumber: {
            name: "phoneNumber",
            label: "Phone Number",
            type: "number",
            placeholder: "phone number",
            errorMsg: "A 10 digit phone number is required",
        },
        smsNumber: {
            name: "smsNumber",
            label: "SMS Number",
            type: "number",
            placeholder: "sms number",
            errorMsg: "sms number is required",
        },
        smsVanityNumber: {
            name: "smsVanityNumber",
            label: "SMS Vanity Number",
            type: "string",
            placeholder: "sms vanity number",
            errorMsg: "sms vanity number is required",
        },
        emailAddress: {
            name: "emailAddress",
            label: "Email Address",
            type: "string",
            placeholder: "email address",
            errorMsg: "email address is required"
        }
    },

};

export default form;
