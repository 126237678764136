
import { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'
import useFetchWithMsal from 'hooks/useFetchWithMsal';

const roles = {
    owner: 1,
    admin: 2,
    chipRunner: 4,
    cageManager: 8,
    reader: 16,
    parnter: 32,
    player: 64,
    intergration: 128
};

class action {
    constructor(id, mask, types) {
        this.id = id;
        this.mask = mask;
        this.types = types;
    }
}

const actions = () => {
    return {
        casino: {
            staff: {
                payout: {
                    overrideWitheldAmount: new action("casino.staff.payout.staff.overridewithholdings", roles.owner | roles.admin | roles.parnter, [1])
                }
            },
            participant: {
                add: new action("casino.participant.add", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1])
            },
            account: {
                transaction: {
                    add: new action("casino.account.transaction.add", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1]),
                    preauth: {
                        create: new action("casino.account.transaction.preauth.create", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1]),
                        revoke: new action("casino.account.transaction.preauth.revoke", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1])
                    }
                }
            },
            session: {
                create: new action("casino.session.create", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1]),
                close: new action("casino.session.close", roles.owner | roles.admin | roles.cageManager, [1]),
                end: new action("casino.session.end", roles.owner | roles.admin | roles.cageManager, [1]),
                start: new action("casino.session.start", roles.owner | roles.admin | roles.cageManager, [1]),
                transaction: {
                    create: new action("casino.session.transaction.add", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1]),
                    update: new action("casino.session.transaction.update", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1]),
                    cancel: new action("casino.session.transaction.cancel", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1])
                },
                participant: {
                    add: new action("casino.session.participant.add", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1])
                }
            }
        },
        participant: {
            player: {
                edit: new action("participant.player.edit", roles.owner | roles.admin | roles.cageManager, [1])
            },
            staff: {
                edit: new action("participant.staff.edit", roles.owner | roles.admin | roles.cageManager, [1])
            }
        },
        cardroom: {
            security: {
                manageUser: new action("cardroom.security.user.manage", roles.owner | roles.admin, [1, 2, 3, 4, 5])
            },
            properties: {
                edit: new action("cardroom.properties.edit", roles.owner | roles.admin, [1, 2, 3, 4, 5])
            },
            fees: {
                club: {
                    edit: new action("cardroom.fees.club.edit", roles.owner | roles.admin, [2, 3])
                },
                referral: {
                    edit: new action("cardroom.fees.referral.edit", roles.owner | roles.admin, [2, 3])
                },
                defaults: {
                    edit: new action("cardroom.fees.defaults.edit", roles.owner | roles.admin, [2, 3])
                },
                agent: {
                    edit: new action("cardroom.fees.agent.edit", roles.owner | roles.admin, [2, 3])
                }
            }
        },
        club: {
            union: {},
            subunion: {
                edit: new action("club.subunion.edit", roles.owner | roles.admin, [2, 3])
            },
            club: {
                edit: new action("club.club.edit", roles.owner | roles.admin, [2, 3])
            },
            reports: {
                upload: new action("club.reports.upload", roles.owner | roles.admin, []) //[2, 3] --temp disabled
            },
        },
        invitations: {
            edit: new action("invitations.edit", roles.owner | roles.admin, [1, 2, 3, 4, 5])
        }
    };
}

const features = () => {
    return {
        casino: {
            smsNotifications: new action("casino.smsNotifications", 1, [1, 2, 3, 4]),
            offlineSignatureCollection: new action("casino.offlineSignatureCollection", 2, [1])
        },
        club: {
            reporting: {
                agentReport: new action("club.reporting.agentReport", 4, [2, 3, 5]),
                playerReport: new action("club.reporting.playerReport", 8, [2, 3, 5]),
                selfUpload: new action("club.reporting.uploadReport", 12, [2, 3])
            }
        }
    };
}

const useCardroomContext = (cardroom) => {

    const [license, setLicense] = useState((cardroom && cardroom.license) ? cardroom.license : null);

    const isSupported = (types) => {
        if (!types) return false;
        if (types.length === 0) return false;

        return types.includes(cardroom.type);
    }

    const isEnabled = (feature) => {
        if (!feature) return false;
        if (!feature.mask) return false;

        if (!isSupported(feature.types)) return false;

        return (feature.mask & cardroom.features) > 0;
    };

    const isAllowed = (action) => {
        if (!action) return false;
        if (!action.mask) return false;

        if (!isSupported(action.types)) return false;

        return (action.mask & cardroom.myRole) > 0;
    };

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const cardroomRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    useEffect(() => {
        if (cardroom && !license) { //get license
            // [HttpGet("{casinoId:Guid}/license", Name = "GetCasinoLicense")]
            cardroomRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/license").then((response) => {
                if (response) {
                    cardroom.license = response;
                    setLicense(cardroom.license);
                }
            }).catch((ex) => {
                if (retryCounter < 10) {
                    handleRetry();
                }
            });
        }
    }, [cardroomRequest.execute, license, retryCounter]) //cardroomListData 

    const state = {
        cardroom,
        licenese: null,
        roles: cardroom.myRole
    };

    const context = {
        isEnabled,
        isAllowed
    };
    return [context, actions(), features()];
};

export {
    useCardroomContext
};