import React from 'react';
import PropTypes from "prop-types";

import { useEffect, useState, useContext, useLayoutEffect } from 'react';
import SoftSelect from "components/SoftSelect";

import { angleToPosition } from 'react-circular-slider-svg/dist/circularGeometry';
import { current } from '@reduxjs/toolkit';

function DataBoundSelect({ placeholder, valueName, textName, data, onChange, size, selectedIndex }) {

    function getOptions(d) {

        if (d) {

            return d.map((item) => {

                var v = (valueName ? item[valueName] : item);
                var lbl = (textName ? item[textName] : item);
                return { value: v, label: lbl };
            });
        }

        return null;
    }

    function getOptionByIndex(idx) {
        if (!options) return null;

        var option = null;

        if (isNaN(idx)) idx = -1;
        if (idx > -1 && options.length >= idx) option = options[idx];

        return option;
    }

    const [options, setOptions] = useState(getOptions(data));
    const [currentSelection, setCurrentSelection] = useState(isNaN(selectedIndex) ? null : getOptionByIndex(selectedIndex));


    useEffect(() => {
        if (data) { 
            setOptions(getOptions(data));
        }
    }, [data, options]);

    useLayoutEffect(() => {
        if (options && selectedIndex != null) {
            //update current selection -- should this be corresponding OPTION
            let idx = parseInt(selectedIndex);
            let newSelection = getOptionByIndex(idx);
            if (!currentSelection || currentSelection != newSelection) {
                setCurrentSelection(newSelection);
            }
        }
    }, [options, selectedIndex, currentSelection]);



    function handleSelectionChanged(e) {

        var item = null;
        var itemIndex = -1;

        //find item

        if (data) {
            for (var i = 0; i < data.length; i++) {
                var iV = valueName ? data[i][valueName] : data[i];
                if (e.value == iV) {
                    item = data[i];
                    itemIndex = i;
                    break;
                }
            }
        }

        setCurrentSelection(getOptionByIndex(itemIndex));

        if (onChange) {
            onChange({ item: item, index: itemIndex });
        }
    }

    return (<>
        {options ?
            <SoftSelect placeholder={placeholder} options={options} value={currentSelection} onChange={e => handleSelectionChanged(e)} size={size} isSearchable={true} blurInputOnSelect={true}>
            </SoftSelect> : null}</>
    );
};

DataBoundSelect.defaultProps = {
    placeholder: "make a selection"
};

DataBoundSelect.propTypes = {
    placeholder: PropTypes.string,
    valueName: PropTypes.string,
    textName: PropTypes.string,
    data: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    selectedIndex: PropTypes.number,
    // readOnly: PropTypes.bool
};

export default DataBoundSelect;