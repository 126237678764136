
const form = {
    formId: "sub-union-editor-form",
    formField: {
        unionId: {
            name: "unionId",
            label: "Union",
            type: "hidden",
            errorMsg: "union must be selected"
        },
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "name",
            errorMsg: "Name is required.",
        },
        appUnionId: {
            name: "appUnionId",
            label: "Union application id",
            type: "text",
            placeholder: "union application id",
            errorMsg: "Union application id is required",
        },
        featuresFlag: {
            name: "featuresFlag",
            label: "Features",
            type: "number",
            placeholder: "features",
            errorMsg: "Feature flags are required",
        }
    },

};

export default form;
