import React from 'react';
import PropTypes from "prop-types";

import { useEffect, useState, useLayoutEffect } from 'react';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';


function DataBoundList({ valueName, textName, data, onBuildListItem, onChange, selectedIndex, maxHeight }) {

    function getItemByIndex(idx) {
        if (!data) return null;

        var item = null;

        if (isNaN(idx)) idx = -1;
        if (idx > -1 && data.length >= idx) item = data[idx];

        return item;
    }

    const [currentSelection, setCurrentSelection] = useState(isNaN(selectedIndex) ? null : getItemByIndex(selectedIndex));

    function handleSelectionChanged(event, item, idx) {

        setCurrentSelection(item);

        if (onChange) {
            onChange({ item: item, index: idx });
        }
    }

    return (<>
        {data ?
            <SoftBox style={{ maxHeight: maxHeight, overflowY: "auto" }}>
                <List component="nav">
                    {data.map((item, index) => {

                        var v = (valueName ? item[valueName] : item);
                        var lbl = (textName ? item[textName] : item);
                        // return { value: v, label: lbl };

                        return <ListItemButton style={{ borderRadius: "5px" }} selected={currentSelection && (currentSelection === item || currentSelection[valueName] === v)} onClick={(event) => handleSelectionChanged(event, item, index)}>
                            {onBuildListItem ? onBuildListItem(item) : <ListItemText>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    {lbl}
                                </SoftTypography>
                            </ListItemText>}
                        </ListItemButton >
                    })}
                </List> </SoftBox> : null}</>
    );
};

DataBoundList.defaultProps = {
    maxHeight: "400px"
};

DataBoundList.propTypes = {
    valueName: PropTypes.string,
    textName: PropTypes.string,
    data: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    onBuildListItem: PropTypes.func,
    selectedIndex: PropTypes.number,
    maxHeight: PropTypes.string
};

export default DataBoundList;