import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { cardroomId, name, phoneNumber, smsNumber, smsVanityNumber },
} = form;



const validations = {

    //add logic to account for markerBalance
    editor: Yup.object().shape({
        [cardroomId.name]: Yup.string().required("Cardroom context must be set"),
        [name.name]: Yup.string().required(name.errorMsg),
        //following required only if type == 1
        //[phoneNumber.name]: Yup.string().required(phoneNumber.errorMsg),
        //[smsNumber.name]: Yup.string().required(smsNumber.errorMsg),
        //[smsVanityNumber.name]: Yup.string().required(smsVanityNumber.errorMsg),

    }),

};

export default validations;